/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
goog.exportSymbol('proto.advancedtemplate.Banner', null, global);
goog.exportSymbol('proto.advancedtemplate.BannerType', null, global);
goog.exportSymbol('proto.advancedtemplate.CommonReply', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumBadge', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumChartNoteEditorType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumClientType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumEditorType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumPublishState', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumPublishType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumPurchaseType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumServiceLevel', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumSourceType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumTemplateType', null, global);
goog.exportSymbol('proto.advancedtemplate.EnumTerminalType', null, global);
goog.exportSymbol('proto.advancedtemplate.Pageable', null, global);
goog.exportSymbol('proto.advancedtemplate.Platform', null, global);
goog.exportSymbol('proto.advancedtemplate.Series', null, global);
goog.exportSymbol('proto.advancedtemplate.SimpleRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.Template', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Template = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.Template.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.Template, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Template.displayName = 'proto.advancedtemplate.Template';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Series = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Series, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Series.displayName = 'proto.advancedtemplate.Series';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Banner = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Banner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Banner.displayName = 'proto.advancedtemplate.Banner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Pageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Pageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Pageable.displayName = 'proto.advancedtemplate.Pageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.CommonReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.CommonReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.CommonReply.displayName = 'proto.advancedtemplate.CommonReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.SimpleRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.SimpleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.SimpleRequest.displayName = 'proto.advancedtemplate.SimpleRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.Template.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.Template.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.Template.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.Template} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Template.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    servicelevel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    construction: jspb.Message.getFieldWithDefault(msg, 8, ""),
    thumbnailurl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createuser: jspb.Message.getFieldWithDefault(msg, 11, 0),
    updateuser: jspb.Message.getFieldWithDefault(msg, 12, 0),
    createtime: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatetime: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updateusername: jspb.Message.getFieldWithDefault(msg, 15, ""),
    purchasetype: jspb.Message.getFieldWithDefault(msg, 16, 0),
    price: jspb.Message.getFieldWithDefault(msg, 17, 0),
    badge: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isfree: jspb.Message.getFieldWithDefault(msg, 19, false),
    terminaltype: jspb.Message.getFieldWithDefault(msg, 20, 0),
    platformList: jspb.Message.getRepeatedField(msg, 21),
    productcode: jspb.Message.getFieldWithDefault(msg, 22, ""),
    chartnoteeditortype: jspb.Message.getFieldWithDefault(msg, 23, 0),
    badgeexpired: jspb.Message.getFieldWithDefault(msg, 24, 0),
    applyBaseCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.Template.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Template;
  return proto.advancedtemplate.Template.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Template} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.Template.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumPublishState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.advancedtemplate.EnumTemplateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {!proto.advancedtemplate.EnumServiceLevel} */ (reader.readEnum());
      msg.setServicelevel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstruction(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailurl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateuser(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdateuser(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatetime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatetime(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateusername(value);
      break;
    case 16:
      var value = /** @type {!proto.advancedtemplate.EnumPurchaseType} */ (reader.readEnum());
      msg.setPurchasetype(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 18:
      var value = /** @type {!proto.advancedtemplate.EnumBadge} */ (reader.readEnum());
      msg.setBadge(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfree(value);
      break;
    case 20:
      var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
      msg.setTerminaltype(value);
      break;
    case 21:
      var value = /** @type {!Array<!proto.advancedtemplate.Platform>} */ (reader.readPackedEnum());
      msg.setPlatformList(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 23:
      var value = /** @type {!proto.advancedtemplate.EnumChartNoteEditorType} */ (reader.readEnum());
      msg.setChartnoteeditortype(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeexpired(value);
      break;
    case 25:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setApplybasecount(value);
        break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Template.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Template.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Template} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Template.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServicelevel();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getConstruction();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreateuser();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUpdateuser();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCreatetime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatetime();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdateusername();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPurchasetype();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIsfree();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getPlatformList();
  if (f.length > 0) {
    writer.writePackedEnum(
      21,
      f
    );
  }
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getChartnoteeditortype();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getBadgeexpired();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getApplybasecount();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumPublishState state = 2;
 * @return {!proto.advancedtemplate.EnumPublishState}
 */
proto.advancedtemplate.Template.prototype.getState = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishState} value */
proto.advancedtemplate.Template.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 version = 3;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional EnumTemplateType type = 5;
 * @return {!proto.advancedtemplate.EnumTemplateType}
 */
proto.advancedtemplate.Template.prototype.getType = function() {
  return /** @type {!proto.advancedtemplate.EnumTemplateType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.advancedtemplate.EnumTemplateType} value */
proto.advancedtemplate.Template.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional EnumServiceLevel serviceLevel = 7;
 * @return {!proto.advancedtemplate.EnumServiceLevel}
 */
proto.advancedtemplate.Template.prototype.getServicelevel = function() {
  return /** @type {!proto.advancedtemplate.EnumServiceLevel} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.advancedtemplate.EnumServiceLevel} value */
proto.advancedtemplate.Template.prototype.setServicelevel = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string construction = 8;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getConstruction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setConstruction = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string thumbnailUrl = 9;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getThumbnailurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setThumbnailurl = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 createUser = 11;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getCreateuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setCreateuser = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 updateUser = 12;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getUpdateuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setUpdateuser = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string createTime = 13;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getCreatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setCreatetime = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updateTime = 14;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getUpdatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setUpdatetime = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updateUserName = 15;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getUpdateusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setUpdateusername = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional EnumPurchaseType purchaseType = 16;
 * @return {!proto.advancedtemplate.EnumPurchaseType}
 */
proto.advancedtemplate.Template.prototype.getPurchasetype = function() {
  return /** @type {!proto.advancedtemplate.EnumPurchaseType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {!proto.advancedtemplate.EnumPurchaseType} value */
proto.advancedtemplate.Template.prototype.setPurchasetype = function(value) {
  jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional int32 price = 17;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setPrice = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional EnumBadge badge = 18;
 * @return {!proto.advancedtemplate.EnumBadge}
 */
proto.advancedtemplate.Template.prototype.getBadge = function() {
  return /** @type {!proto.advancedtemplate.EnumBadge} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {!proto.advancedtemplate.EnumBadge} value */
proto.advancedtemplate.Template.prototype.setBadge = function(value) {
  jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional bool isFree = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.Template.prototype.getIsfree = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.advancedtemplate.Template.prototype.setIsfree = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional EnumTerminalType terminalType = 20;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.Template.prototype.getTerminaltype = function() {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.Template.prototype.setTerminaltype = function(value) {
  jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * repeated Platform platform = 21;
 * @return {!Array<!proto.advancedtemplate.Platform>}
 */
proto.advancedtemplate.Template.prototype.getPlatformList = function() {
  return /** @type {!Array<!proto.advancedtemplate.Platform>} */ (jspb.Message.getRepeatedField(this, 21));
};


/** @param {!Array<!proto.advancedtemplate.Platform>} value */
proto.advancedtemplate.Template.prototype.setPlatformList = function(value) {
  jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {!proto.advancedtemplate.Platform} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.Template.prototype.addPlatform = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.Template.prototype.clearPlatformList = function() {
  this.setPlatformList([]);
};


/**
 * optional string productCode = 22;
 * @return {string}
 */
proto.advancedtemplate.Template.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.advancedtemplate.Template.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional EnumChartNoteEditorType chartNoteEditorType = 23;
 * @return {!proto.advancedtemplate.EnumChartNoteEditorType}
 */
proto.advancedtemplate.Template.prototype.getChartnoteeditortype = function() {
  return /** @type {!proto.advancedtemplate.EnumChartNoteEditorType} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {!proto.advancedtemplate.EnumChartNoteEditorType} value */
proto.advancedtemplate.Template.prototype.setChartnoteeditortype = function(value) {
  jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional int64 badgeExpired = 24;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getBadgeexpired = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setBadgeexpired = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 applyBaseCount= 25;
 * @return {number}
 */
proto.advancedtemplate.Template.prototype.getApplybasecount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.advancedtemplate.Template.prototype.setApplybasecount = function (value) {
  jspb.Message.setProto3IntField(this, 25, value);
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.Series.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.Series.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.Series} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Series.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    parentname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hasbadge: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isrecommend: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createuser: jspb.Message.getFieldWithDefault(msg, 9, 0),
    updateuser: jspb.Message.getFieldWithDefault(msg, 10, 0),
    terminaltype: jspb.Message.getFieldWithDefault(msg, 11, 0),
    seriestype: jspb.Message.getFieldWithDefault(msg, 12, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 13, 0),
    iconurllight: jspb.Message.getFieldWithDefault(msg, 14, ""),
    iconurldark: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.Series.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Series;
  return proto.advancedtemplate.Series.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Series} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.Series.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasbadge(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsrecommend(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateuser(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdateuser(value);
      break;
    case 11:
      var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
      msg.setTerminaltype(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeriestype(value);
      break;
    case 13:
      var value = /** @type {!proto.advancedtemplate.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconurllight(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconurldark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Series.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Series.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Series} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Series.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParent();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getParentname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHasbadge();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsrecommend();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreateuser();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUpdateuser();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSeriestype();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getIconurllight();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIconurldark();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 parent = 4;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getParent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setParent = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string parentName = 5;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getParentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setParentname = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setOrder = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string hasBadge = 7;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getHasbadge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setHasbadge = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string isRecommend = 8;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getIsrecommend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setIsrecommend = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 createUser = 9;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getCreateuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setCreateuser = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 updateUser = 10;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getUpdateuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setUpdateuser = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional EnumTerminalType terminalType = 11;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.Series.prototype.getTerminaltype = function() {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.Series.prototype.setTerminaltype = function(value) {
  jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 seriesType = 12;
 * @return {number}
 */
proto.advancedtemplate.Series.prototype.getSeriestype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.advancedtemplate.Series.prototype.setSeriestype = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Platform platform = 13;
 * @return {!proto.advancedtemplate.Platform}
 */
proto.advancedtemplate.Series.prototype.getPlatform = function() {
  return /** @type {!proto.advancedtemplate.Platform} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {!proto.advancedtemplate.Platform} value */
proto.advancedtemplate.Series.prototype.setPlatform = function(value) {
  jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string iconUrlLight = 14;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getIconurllight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setIconurllight = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string iconUrlDark = 15;
 * @return {string}
 */
proto.advancedtemplate.Series.prototype.getIconurldark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.advancedtemplate.Series.prototype.setIconurldark = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.Banner.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.Banner.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.Banner} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.Banner.toObject = function (includeInstance, msg) {
    var f, obj = {
      terminaltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
      platform: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, 0),
      value: jspb.Message.getFieldWithDefault(msg, 4, ""),
      enabled: jspb.Message.getFieldWithDefault(msg, 5, false),
      bannerid: jspb.Message.getFieldWithDefault(msg, 6, 0),
      bannerimage: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Banner}
 */
proto.advancedtemplate.Banner.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Banner;
  return proto.advancedtemplate.Banner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Banner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Banner}
 */
proto.advancedtemplate.Banner.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
        msg.setTerminaltype(value);
        break;
      case 2:
        var value = /** @type {!proto.advancedtemplate.Platform} */ (reader.readEnum());
        msg.setPlatform(value);
        break;
      case 3:
        var value = /** @type {!proto.advancedtemplate.BannerType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setValue(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEnabled(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setBannerid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readInt32());
        msg.setBannerimage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Banner.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Banner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Banner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Banner.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBannerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBannerimage();
  if (f !== 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional EnumTerminalType terminalType = 1;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.Banner.prototype.getTerminaltype = function () {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.Banner.prototype.setTerminaltype = function (value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Platform platform = 2;
 * @return {!proto.advancedtemplate.Platform}
 */
proto.advancedtemplate.Banner.prototype.getPlatform = function () {
  return /** @type {!proto.advancedtemplate.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.Platform} value */
proto.advancedtemplate.Banner.prototype.setPlatform = function (value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BannerType type = 3;
 * @return {!proto.advancedtemplate.BannerType}
 */
proto.advancedtemplate.Banner.prototype.getType = function () {
  return /** @type {!proto.advancedtemplate.BannerType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.advancedtemplate.BannerType} value */
proto.advancedtemplate.Banner.prototype.setType = function (value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.advancedtemplate.Banner.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.advancedtemplate.Banner.prototype.setValue = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.Banner.prototype.getEnabled = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.advancedtemplate.Banner.prototype.setEnabled = function (value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 bannerId = 6;
 * @return {number}
 */
proto.advancedtemplate.Banner.prototype.getBannerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.advancedtemplate.Banner.prototype.setBannerid = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string bannerImage = 6;
 * @return {number}
 */
proto.advancedtemplate.Banner.prototype.getBannerimage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.advancedtemplate.Banner.prototype.setBannerimage = function (value) {
  jspb.Message.setProto3StringField(this, 7, value);
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.Pageable.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.Pageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.Pageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Pageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    current: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.Pageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Pageable;
  return proto.advancedtemplate.Pageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Pageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.Pageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Pageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Pageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Pageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Pageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrent();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 pagesize = 1;
 * @return {number}
 */
proto.advancedtemplate.Pageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.Pageable.prototype.setPagesize = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current = 2;
 * @return {number}
 */
proto.advancedtemplate.Pageable.prototype.getCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.Pageable.prototype.setCurrent = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.CommonReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.CommonReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.CommonReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CommonReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.CommonReply}
 */
proto.advancedtemplate.CommonReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.CommonReply;
  return proto.advancedtemplate.CommonReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.CommonReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.CommonReply}
 */
proto.advancedtemplate.CommonReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.CommonReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.CommonReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.CommonReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CommonReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.CommonReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.CommonReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.CommonReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.CommonReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.SimpleRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.SimpleRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.SimpleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.SimpleRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      param: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.SimpleRequest}
 */
proto.advancedtemplate.SimpleRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.SimpleRequest;
  return proto.advancedtemplate.SimpleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.SimpleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.SimpleRequest}
 */
proto.advancedtemplate.SimpleRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParam(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.SimpleRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.SimpleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.SimpleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SimpleRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParam();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string param = 1;
 * @return {string}
 */
proto.advancedtemplate.SimpleRequest.prototype.getParam = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.SimpleRequest.prototype.setParam = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.advancedtemplate.EnumPublishType = {
  PUBLISH_TYPE_DEFAULT: 0,
  TEMPLATE: 1,
  SERIES: 2
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumPublishState = {
  DRAFT: 0,
  PRODUCTION: 1,
  HISTORY: 2,
  NONUSE: -1,
  RESCIND: 3,
  ISLET: 4
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumServiceLevel = {
  SERVICE_LEVEL_DEFAULT: 0,
  BASIC: 1,
  PLUS: 2,
  PREMIUM: 3,
  PRO: 5,
  NONE: -1
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumTemplateType = {
  TEMPLATE_TYPE_DEFAULT: 0,
  SPACE: 1,
  NOTEBOOK: 2,
  NOTE: 3
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumEditorType = {
  EDITOR_TYPE_DEFAULT: 0,
  UNO: 1,
  PESO: 2,
  MARKDOWN: 3,
  MINDMAP: 4,
  FLOW_CHART: 6,
  OTHER: 5
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumChartNoteEditorType = {
  CHART_NOTE_EDITOR_TYPE_DEFAULT: 0,
  CHART_NOTE_FLOW_CHART: 1,
  CHART_NOTE_MIND_MAP: 2
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumTerminalType = {
  PC: 0,
  MD: 1,
  PC_AND_MD: 2
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumClientType = {
  CLIENT_TYPE_DEFAULT: 0,
  IOS: 1,
  ANDROID: 2,
  MAC: 3,
  WINDOWS: 4
};

/**
 * @enum {number}
 */
proto.advancedtemplate.Platform = {
  PLATFORM_DEFAULT: 0,
  YXBJ: 1,
  YXTJ: 2,
  EVERPAD: 3
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumSourceType = {
  SOURCE_TYPE_DEFAULT: 0,
  OFFICIAL_WEBSITE: 1
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumPurchaseType = {
  PURCHASE_TYPE_DEFAULT: 0,
  SERVICE_LEVEL: 1,
  SERVICE_LEVEL_AND_PRICE: 2,
  PRICE: 3,
  IS_FREE: 4,
  MATERIAL_VIP: 5,
  MATERIAL_VIP_AND_PRICE: 6
};

/**
 * @enum {number}
 */
proto.advancedtemplate.EnumBadge = {
  BADGE_NO: 0,
  LATESR: 1,
  HOT: 2,
  FEATURED: 3
};

/**
 * @enum {number}
 */
proto.advancedtemplate.BannerType = {
  UNKNOWN: 0,
  DEEPLINK: 1,
  URL: 2,
  TEMPLATE_BANNER: 3,
  SERIES_BANNER: 4
};

goog.object.extend(exports, proto.advancedtemplate);

/* eslint-disable no-undef */
/**
 * @fileoverview gRPC-Web generated client stub for orders
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


// var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var common_pb = require('./common_pb.js')
const proto = {};
proto.orders = require('./wallet-admin_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.orders.WalletAdminServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.orders.WalletAdminServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetListOrdersRequest,
 *   !proto.orders.GetListOrdersReply>}
 */
const methodDescriptor_WalletAdminService_GetListOrders = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/GetListOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.GetListOrdersRequest,
  proto.orders.GetListOrdersReply,
  /** @param {!proto.orders.GetListOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetListOrdersRequest,
 *   !proto.orders.GetListOrdersReply>}
 */
const methodInfo_WalletAdminService_GetListOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.GetListOrdersReply,
  /** @param {!proto.orders.GetListOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.GetListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.GetListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.GetListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.getListOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/GetListOrders',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetListOrders,
      callback);
};


/**
 * @param {!proto.orders.GetListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.GetListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.getListOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/GetListOrders',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetListPurchaseOrdersWithProductTypeRequest,
 *   !proto.orders.GetListOrdersReply>}
 */
const methodDescriptor_WalletAdminService_GetListPurchaseOrdersWithProductType = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/GetListPurchaseOrdersWithProductType',
  grpc.web.MethodType.UNARY,
  proto.orders.GetListPurchaseOrdersWithProductTypeRequest,
  proto.orders.GetListOrdersReply,
  /** @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetListPurchaseOrdersWithProductTypeRequest,
 *   !proto.orders.GetListOrdersReply>}
 */
const methodInfo_WalletAdminService_GetListPurchaseOrdersWithProductType = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.GetListOrdersReply,
  /** @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.GetListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.GetListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.getListPurchaseOrdersWithProductType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/GetListPurchaseOrdersWithProductType',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetListPurchaseOrdersWithProductType,
      callback);
};


/**
 * @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.GetListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.getListPurchaseOrdersWithProductType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/GetListPurchaseOrdersWithProductType',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetListPurchaseOrdersWithProductType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetBalanceByUserRequest,
 *   !proto.orders.GetBalanceByUserReply>}
 */
const methodDescriptor_WalletAdminService_GetBalanceByUser = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/GetBalanceByUser',
  grpc.web.MethodType.UNARY,
  proto.orders.GetBalanceByUserRequest,
  proto.orders.GetBalanceByUserReply,
  /** @param {!proto.orders.GetBalanceByUserRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetBalanceByUserReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetBalanceByUserRequest,
 *   !proto.orders.GetBalanceByUserReply>}
 */
const methodInfo_WalletAdminService_GetBalanceByUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.GetBalanceByUserReply,
  /** @param {!proto.orders.GetBalanceByUserRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.GetBalanceByUserReply.deserializeBinary
);


/**
 * @param {!proto.orders.GetBalanceByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.GetBalanceByUserReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.GetBalanceByUserReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.getBalanceByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/GetBalanceByUser',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetBalanceByUser,
      callback);
};


/**
 * @param {!proto.orders.GetBalanceByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.GetBalanceByUserReply>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.getBalanceByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/GetBalanceByUser',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetBalanceByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodDescriptor_WalletAdminService_GetOrder = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/GetOrder',
  grpc.web.MethodType.UNARY,
  common_pb.GetOrderRequest,
  common_pb.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodInfo_WalletAdminService_GetOrder = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.getOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetOrder,
      callback);
};


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderResult>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.getOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodDescriptor_WalletAdminService_GetOrderWithStatusChangeAndMQTrigger = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/GetOrderWithStatusChangeAndMQTrigger',
  grpc.web.MethodType.UNARY,
  common_pb.GetOrderRequest,
  common_pb.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodInfo_WalletAdminService_GetOrderWithStatusChangeAndMQTrigger = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.getOrderWithStatusChangeAndMQTrigger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/GetOrderWithStatusChangeAndMQTrigger',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetOrderWithStatusChangeAndMQTrigger,
      callback);
};


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderResult>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.getOrderWithStatusChangeAndMQTrigger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/GetOrderWithStatusChangeAndMQTrigger',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_GetOrderWithStatusChangeAndMQTrigger);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.CreateOrderAndMarkAsPaidRequest,
 *   !proto.orders.OrderResult>}
 */
const methodDescriptor_WalletAdminService_CreateOrderAndMarkAsPaid = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/CreateOrderAndMarkAsPaid',
  grpc.web.MethodType.UNARY,
  proto.orders.CreateOrderAndMarkAsPaidRequest,
  common_pb.OrderResult,
  /** @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.CreateOrderAndMarkAsPaidRequest,
 *   !proto.orders.OrderResult>}
 */
const methodInfo_WalletAdminService_CreateOrderAndMarkAsPaid = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.OrderResult,
  /** @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.OrderResult.deserializeBinary
);


/**
 * @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.createOrderAndMarkAsPaid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/CreateOrderAndMarkAsPaid',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_CreateOrderAndMarkAsPaid,
      callback);
};


/**
 * @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderResult>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.createOrderAndMarkAsPaid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/CreateOrderAndMarkAsPaid',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_CreateOrderAndMarkAsPaid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.RefundOrderForAdminRequest,
 *   !proto.orders.RefundOrderForAdminResult>}
 */
const methodDescriptor_WalletAdminService_RefundOrderForAdmin = new grpc.web.MethodDescriptor(
  '/orders.WalletAdminService/RefundOrderForAdmin',
  grpc.web.MethodType.UNARY,
  proto.orders.RefundOrderForAdminRequest,
  proto.orders.RefundOrderForAdminResult,
  /** @param {!proto.orders.RefundOrderForAdminRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.RefundOrderForAdminResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.RefundOrderForAdminRequest,
 *   !proto.orders.RefundOrderForAdminResult>}
 */
const methodInfo_WalletAdminService_RefundOrderForAdmin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.RefundOrderForAdminResult,
  /** @param {!proto.orders.RefundOrderForAdminRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.RefundOrderForAdminResult.deserializeBinary
);


/**
 * @param {!proto.orders.RefundOrderForAdminRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.RefundOrderForAdminResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.RefundOrderForAdminResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.WalletAdminServiceClient.prototype.refundOrderForAdmin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.WalletAdminService/RefundOrderForAdmin',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_RefundOrderForAdmin,
      callback);
};


/**
 * @param {!proto.orders.RefundOrderForAdminRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.RefundOrderForAdminResult>}
 *     A native promise that resolves to the response
 */
proto.orders.WalletAdminServicePromiseClient.prototype.refundOrderForAdmin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.WalletAdminService/RefundOrderForAdmin',
      request,
      metadata || {},
      methodDescriptor_WalletAdminService_RefundOrderForAdmin);
};


module.exports = proto.orders;


import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Button,
    Table, Checkbox, Popconfirm, Tabs,
    message, Icon, notification, Select
} from 'antd';
import './layout.css';
import {
    OfficialMaterialAdminService, Pageable,GetDraftMaterialByTypeRequest,GetMaterialTypeRequest,GetPublishListRequest,PublishMaterialRequest,RescindPublishMaterial
} from "./grpcweb/OfficialMaterialAdminService";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

export default class PublishTemplatesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //渲染待发布素材表格
            draftMaterial: [],
            //选中的material
            selectedMaterial: [],
            //已发布素材
            materialList: [],
            total: 0,
            current: 1,
            pageSize: 20,
            disablePublishBtn: false,
            materialTypeList:[],
            materialType: 2,
            tab: 1
        };
    };

    /**
     * 模版列表项被选中触发
     */
    materialRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // debugger;
            this.setState({
                selectedMaterial: selectedRows
            });
        },
        getCheckboxProps: record => ({
            disabled: false, // Column configuration not to be checked
            name: record.name,
        }),
    };

    componentDidMount = () => {
        // debugger;
        this.queryMaterialType();
        this.getDraftMaterial(this.state.materialType);
    }

    /**
     * 请求素材类型
     */
    queryMaterialType = () => {
        let request = new GetMaterialTypeRequest();
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        OfficialMaterialAdminService.getMaterialType(request, {}, (err, response) => {
            //debugger
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== "") {
                    message.error(res.message);
                } else {
                    this.setState({ materialTypeList: res.materialtypeList });
                }
            }
        });
    };

    /**
      * 查询待发布模版列表
      */
    getDraftMaterial = (materialType) => {
        // debugger;
        let request = new GetDraftMaterialByTypeRequest();
        request.setMaterialtype(materialType);
        OfficialMaterialAdminService.getDraftMaterialByType(request, {}, (err, response) => {
            // debugger;
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 0) {
                    message.error(res.message);
                } else {
                    console.log("待发布结果：" +res);
                    res.materialsList.forEach((item) => {
                        this.getTemplateEnumName(item);
                        console.log("待发布创建时间：" + item.createtime);
                        console.log("待发布更新时间：" + item.updatetime);
                        item.createtime = new Date(item.createtime).toLocaleString();
                        item.updatetime = new Date(item.updatetime).toLocaleString();
                    })
                    // debugger;
                    this.setState({
                        draftMaterial: res.materialsList,
                        disablePublishBtn: false
                    }, () => {
                        // debugger;
                        // console.log(this.state.draftMaterial);
                    });
                }
            }
        });
    };

    getTemplateEnumName = (item) => {
        item.status = item.status === 0 ? '草稿' : (item.status === 1 ? '发布' : (item.status === 2 ? '历史' : (item.status === 3 ? '撤销' : (item.status === -1 ? '停用' : null))));
        item.materialtype = item.materialtype === 2 ? 'WORD_FORM' : (item.materialtype === 3 ? 'SHARING_THEMES' : (item.materialtype === 4 ? 'SHARING_WATER_MARK' : (item.materialtype === 5 ? 'HOME_BACKGROUND': null)));
        item.servicelevel = item.servicelevel === 1 ? '免费账户' : (item.servicelevel === 2 ? '标准账户' : (item.servicelevel === 3 ? '高级账户' : (item.servicelevel === 5 ? '专业账户' : null)));
        return item;
    }

    /**
     * 发布模版
     */
    publishSelectedMaterial = (records) => {
        if (records.length === 0) {
            message.error('请选择要发布的素材');
            return;
        }
        this.setState({ disablePublishBtn: true }, () => {
            let request = new PublishMaterialRequest();
            records.forEach((item) => {
                request.addMaterialid(item.id);
            });
            OfficialMaterialAdminService.publishMaterial(request, {}, (err, response) => {
                this.setState({ disablePublishBtn: false });
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    // debugger;
                    switch (res.responsecode) {
                        case 0:
                            message.success('发布模版成功');
                            break;
                        default:
                            message.error(res.msg);
                            break;
                    }
                    this.getDraftMaterial(this.state.materialType);
                }
            });
        });
    };

    //已发布模版
    getPublishedMaterial = (materialType,page) => {
        let request = new GetPublishListRequest();
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pageSize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        request.setType(materialType);
        OfficialMaterialAdminService.getPublishMaterialList(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                // console.log("response.Material: " + JSON.stringify(res.MaterialList));
                if (res.code !== 0) {
                    message.error(res.message);
                } else {
                    console.log("已发布结果：" +res);
                    res.materialsList.forEach((item) => {
                        this.getTemplateEnumName(item);
                        console.log("已发布创建时间：" + item.createtime);
                        let curTime = new Date(item.createtime).toLocaleString();
                        item.createtime = curTime;
                    })
                    // debugger;
                    this.setState(
                        {
                            materialsList: res.materialsList,
                            total: res.total,
                            current: page,
                        },
                        () => console.log("INFO:getMaterial : ", this.state.materialsList)
                    );
                }
            }
        });
    };

    //撤销模版
    rescindTemplate = (id) => {
        let request = new RescindPublishMaterial();
        request.setMaterialid(id);
        OfficialMaterialAdminService.rescindPublishMaterial(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.responsecode !== 0) {
                    message.error(res.msg);
                }
                else {
                    if(res.status === 11015) {
                        message.warn('该素材已挂载在某一系列下无法撤销发布！！！')
                    } else {
                        message.success('撤销成功');
                        this.getPublishedMaterial(this.state.materialType, 1);
                    }

                }
            }
        });
    };

    handleTabsChange = (key) => {
        this.setState({tab: key});
        if (key === '1') {
            this.getDraftMaterial(this.state.materialType,);
        }
        else {
            this.getPublishedMaterial(this.state.materialType,1);
        }
    };

    openNotification = () => {
        notification.open({
            message: '模版撤销规则及条件：',
            description:
                '1.此功能为模版从用户端撤销操作，一旦撤销将不可恢复，请慎重操作。2.撤销模版将在用户端不可见。'
                + '撤销条件：不能关联系列，请在系列管理中解除相关系列关系',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    onChangePage = page => {
        this.getPublishedMaterial(this.state.materialType,page);
    };

    //选择素材类型后动作
    handleMaterialTypeChange = (value) => {
        console.log(`selected ${value}`);
        let materialType = (typeof value == "undefined") ? null : value;
        this.setState({materialType: materialType});
        if (this.state.tab === 1) {
            this.getDraftMaterial(materialType);
        } else {
            this.getPublishedMaterial(materialType,1);
        }

    };

    displayMaterialType = () => {
        if(typeof(this.state.materialTypeList) !== "undefined") {
            return this.state.materialTypeList.map(
              (item) => (<Option key={item.code} value={item.code}>{item.name}</Option>)
            )
        } else {
            return ;
        }
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/material/template/list">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发布管理</Breadcrumb.Item>
                    <Breadcrumb.Item>发布素材</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={16}>
                        <Col span={2} offset={0}>
                            素材类型
                        </Col>
                        <Col span={4} offset={0}>
                            <Select defaultValue={this.state.materialType} style={{ width: 160 }} onChange={this.handleMaterialTypeChange} allowClear>
                                {this.displayMaterialType()}
                            </Select>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1" onChange={this.handleTabsChange}>
                        <TabPane tab="待发布" key="1">
                            <div>
                                <Table
                                    rowSelection={{
                                        type: Checkbox,
                                        ...this.materialRowSelection,
                                    }}
                                    columns={
                                        [
                                            { title: '名称', align: 'center', dataIndex: 'title', },
                                            { title: '类型', align: 'center', dataIndex: 'materialtype', },
                                            { title: '描述', align: 'center', dataIndex: 'description', ellipsis: true },
                                            { title: '等级', align: 'center', dataIndex: 'servicelevel', },
                                            { title: '创建时间', align: 'center', dataIndex: 'createtime', },
                                            { title: '更新时间', align: 'center', dataIndex: 'updatetime', },
                                        ]
                                    }
                                    dataSource={this.state.draftMaterial}
                                    pagination={false}
                                />
                            </div>
                            <div>
                                <Row gutter={[16, 16]}>
                                    <Col />
                                    <Col span={2} offset={11}>
                                        <Button type="primary" disabled={this.state.disablePublishBtn}
                                            onClick={() => this.publishSelectedMaterial(this.state.selectedMaterial)}>
                                            发布
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="已发布" key="2">
                            <div>
                                <Table
                                    columns={
                                        [
                                            { title: '名称', align: 'center', dataIndex: 'title', },
                                            { title: '类型', align: 'center', dataIndex: 'materialtype', },
                                            { title: '状态', align: 'center', dataIndex: 'status', },
                                            { title: '描述', align: 'center', dataIndex: 'description', ellipsis: true },
                                            { title: '等级', align: 'center', dataIndex: 'servicelevel', },
                                            { title: '发布时间', align: 'center', dataIndex: 'createtime', },
                                            {
                                                title: '操作', align: 'center', key: 'action',
                                                render: (text, record) => {
                                                    return (
                                                        <div>
                                                            <span>
                                                                {record.status === '发布' &&
                                                                    <Popconfirm title="确定撤销？" onConfirm={() => this.rescindTemplate(record.id)} okText="撤销" cancelText="取消">
                                                                        <a style={{ marginRight: 16 }}>撤销</a>
                                                                    </Popconfirm>}
                                                                {record.state === '发布' && <Icon type="info-circle" onClick={this.openNotification}></Icon>}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            },
                                        ]
                                    }
                                    dataSource={this.state.materialsList}
                                    pagination={{
                                        pageSize: this.state.pageSize,
                                        total: this.state.total,
                                        onChange: this.onChangePage,
                                    }}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                </div>
            </Content>
        );
    }
}
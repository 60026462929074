import React from 'react';
import { Layout, Menu, message } from 'antd';
import { Redirect, Switch, Link, Route } from 'react-router-dom';
import './layout.css';

import WalletInfoContent from "./WalletInfoContent";

const { Footer, Sider } = Layout;
const { SubMenu } = Menu;

class WalletAdmin extends React.Component {
	
	state = {
        collapsed: false,
        content: null,
        templateMeta: {
            templates: [],
        },
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={240} style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                >
                    <div className="ant-layout-logo">
                        <div className="logo-image"></div>
                        <div className="logo-title">钱包管理后台</div>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
						<Menu.Item key="wallet-admin">
							<Link to={'/wallet-admin/walletInfo'}>
								<span>用户钱包信息</span>
							</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: 240 }}>
                    <div className="ant-layout-content">
                        <Switch>
                            <Route path={"/wallet-admin/walletInfo"} component={WalletInfoContent} />
                            <Redirect to={"/wallet-admin/walletInfo"} />
                        </Switch>
                    </div>
                    <Footer style={{ textAlign: 'center' }}>印象笔记 ©2020 </Footer>
                </Layout>
            </Layout>
        );
    }

}

export default WalletAdmin;
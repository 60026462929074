/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.advancedtemplate.AddNoteReply', null, global);
goog.exportSymbol('proto.advancedtemplate.AddNoteRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.AddRelationRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.AddSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.AddTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.CheckUserTemplatePermissionReply', null, global);
goog.exportSymbol('proto.advancedtemplate.CheckUserTemplatePermissionRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.DeleteRelationRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.DeleteSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.DeletedTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.EmptyRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetAllSeriesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetAllSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetAllSeriesV2Reply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetAllSeriesV2Request', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftSeriesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftTemplateReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesV2Reply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesV2Request', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesV3Reply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetIncrementalTemplatesV3Request', null, global);
goog.exportSymbol('proto.advancedtemplate.GetLastVersionTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetLastVersionTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetLatestTemplateByIdReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetLatestTemplateByIdRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublicNoteInfosReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublicNoteInfosRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishListReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishListRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishSeriesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishTemplateReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishedTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPublishedTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPurchasedTemplateIdsData', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPurchasedTemplateIdsReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPurchasedTemplateIdsRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPurchasedTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetPurchasedTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetRecommendTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetRecommendTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetResourceDownloadMetaRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetResourceDownloadMetaResponse', null, global);
goog.exportSymbol('proto.advancedtemplate.GetResourceDownloadMetadata', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSeriesRelationTemplateReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSeriesRelationTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSeriesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSeriesTreeRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSigReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetSigRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplateInfoReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplateInfoRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplateStatisticsRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplateStatisticsResponse', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplatesReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.IncrementTemplateUsageCountReply', null, global);
goog.exportSymbol('proto.advancedtemplate.IncrementTemplateUsageCountRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.Note', null, global);
goog.exportSymbol('proto.advancedtemplate.OrderSeries', null, global);
goog.exportSymbol('proto.advancedtemplate.PublishContext', null, global);
goog.exportSymbol('proto.advancedtemplate.PublishSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.PublishTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.QuerySeriesTypeReply', null, global);
goog.exportSymbol('proto.advancedtemplate.Relation', null, global);
goog.exportSymbol('proto.advancedtemplate.RescindTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.SeriesRelationTemplate', null, global);
goog.exportSymbol('proto.advancedtemplate.SeriesResultDetail', null, global);
goog.exportSymbol('proto.advancedtemplate.SeriesType', null, global);
goog.exportSymbol('proto.advancedtemplate.SynNoteRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.TemplateOrder', null, global);
goog.exportSymbol('proto.advancedtemplate.TemplateSeries', null, global);
goog.exportSymbol('proto.advancedtemplate.TemplateStatistics', null, global);
goog.exportSymbol('proto.advancedtemplate.TemplateUsageCount', null, global);
goog.exportSymbol('proto.advancedtemplate.TemplatesResultDetail', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateNoteReply', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateNoteRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateOrderSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateOrderTemplatesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateRecommendRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateSeriesRelationRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateTemplateStatisticsRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UploadThumbnailReply', null, global);
goog.exportSymbol('proto.advancedtemplate.UploadThumbnailRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplatesRequest.displayName = 'proto.advancedtemplate.GetTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetTemplatesReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplatesReply.displayName = 'proto.advancedtemplate.GetTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplateInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetTemplateInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplateInfoRequest.displayName = 'proto.advancedtemplate.GetTemplateInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplateInfoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetTemplateInfoReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetTemplateInfoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplateInfoReply.displayName = 'proto.advancedtemplate.GetTemplateInfoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.AddTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.AddTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddTemplateRequest.displayName = 'proto.advancedtemplate.AddTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.UpdateTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.UpdateTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateTemplateRequest.displayName = 'proto.advancedtemplate.UpdateTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.DeletedTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.DeletedTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.DeletedTemplateRequest.displayName = 'proto.advancedtemplate.DeletedTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UploadThumbnailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UploadThumbnailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UploadThumbnailRequest.displayName = 'proto.advancedtemplate.UploadThumbnailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublicNoteInfosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublicNoteInfosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublicNoteInfosRequest.displayName = 'proto.advancedtemplate.GetPublicNoteInfosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Note.displayName = 'proto.advancedtemplate.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublicNoteInfosReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPublicNoteInfosReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPublicNoteInfosReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublicNoteInfosReply.displayName = 'proto.advancedtemplate.GetPublicNoteInfosReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TemplateSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TemplateSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TemplateSeries.displayName = 'proto.advancedtemplate.TemplateSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UploadThumbnailReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UploadThumbnailReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UploadThumbnailReply.displayName = 'proto.advancedtemplate.UploadThumbnailReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSeriesRequest.displayName = 'proto.advancedtemplate.GetSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSeriesTreeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetSeriesTreeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSeriesTreeRequest.displayName = 'proto.advancedtemplate.GetSeriesTreeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSeriesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetSeriesReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetSeriesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSeriesReply.displayName = 'proto.advancedtemplate.GetSeriesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetSeriesRelationTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSeriesRelationTemplateRequest.displayName = 'proto.advancedtemplate.GetSeriesRelationTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.SeriesRelationTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.SeriesRelationTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.SeriesRelationTemplate.displayName = 'proto.advancedtemplate.SeriesRelationTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetSeriesRelationTemplateReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetSeriesRelationTemplateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSeriesRelationTemplateReply.displayName = 'proto.advancedtemplate.GetSeriesRelationTemplateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.AddSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddSeriesRequest.displayName = 'proto.advancedtemplate.AddSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateSeriesRequest.displayName = 'proto.advancedtemplate.UpdateSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.DeleteSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.DeleteSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.DeleteSeriesRequest.displayName = 'proto.advancedtemplate.DeleteSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateSeriesRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.UpdateSeriesRelationRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.UpdateSeriesRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateSeriesRelationRequest.displayName = 'proto.advancedtemplate.UpdateSeriesRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Relation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Relation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Relation.displayName = 'proto.advancedtemplate.Relation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.DeleteRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.DeleteRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.DeleteRelationRequest.displayName = 'proto.advancedtemplate.DeleteRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.AddRelationRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.AddRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddRelationRequest.displayName = 'proto.advancedtemplate.AddRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateRecommendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateRecommendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateRecommendRequest.displayName = 'proto.advancedtemplate.UpdateRecommendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.OrderSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.OrderSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.OrderSeries.displayName = 'proto.advancedtemplate.OrderSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateOrderSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.UpdateOrderSeriesRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.UpdateOrderSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateOrderSeriesRequest.displayName = 'proto.advancedtemplate.UpdateOrderSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TemplateOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TemplateOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TemplateOrder.displayName = 'proto.advancedtemplate.TemplateOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.UpdateOrderTemplatesRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.UpdateOrderTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateOrderTemplatesRequest.displayName = 'proto.advancedtemplate.UpdateOrderTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.EmptyRequest.displayName = 'proto.advancedtemplate.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.QuerySeriesTypeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.QuerySeriesTypeReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.QuerySeriesTypeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.QuerySeriesTypeReply.displayName = 'proto.advancedtemplate.QuerySeriesTypeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.SeriesType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.SeriesType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.SeriesType.displayName = 'proto.advancedtemplate.SeriesType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublishListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishListRequest.displayName = 'proto.advancedtemplate.GetPublishListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPublishListReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPublishListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishListReply.displayName = 'proto.advancedtemplate.GetPublishListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.PublishContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.PublishContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.PublishContext.displayName = 'proto.advancedtemplate.PublishContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublishSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishSeriesRequest.displayName = 'proto.advancedtemplate.GetPublishSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishSeriesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublishSeriesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishSeriesReply.displayName = 'proto.advancedtemplate.GetPublishSeriesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublishTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishTemplateRequest.displayName = 'proto.advancedtemplate.GetPublishTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishTemplateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPublishTemplateReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPublishTemplateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishTemplateReply.displayName = 'proto.advancedtemplate.GetPublishTemplateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.PublishTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.PublishTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.PublishTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.PublishTemplateRequest.displayName = 'proto.advancedtemplate.PublishTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.PublishSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.PublishSeriesRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.PublishSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.PublishSeriesRequest.displayName = 'proto.advancedtemplate.PublishSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetDraftTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftTemplateRequest.displayName = 'proto.advancedtemplate.GetDraftTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftTemplateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetDraftTemplateReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetDraftTemplateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftTemplateReply.displayName = 'proto.advancedtemplate.GetDraftTemplateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetDraftSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftSeriesRequest.displayName = 'proto.advancedtemplate.GetDraftSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftSeriesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetDraftSeriesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftSeriesReply.displayName = 'proto.advancedtemplate.GetDraftSeriesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.AddNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddNoteRequest.displayName = 'proto.advancedtemplate.AddNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddNoteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.AddNoteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddNoteReply.displayName = 'proto.advancedtemplate.AddNoteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateNoteRequest.displayName = 'proto.advancedtemplate.UpdateNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateNoteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateNoteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateNoteReply.displayName = 'proto.advancedtemplate.UpdateNoteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishedTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPublishedTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishedTemplatesRequest.displayName = 'proto.advancedtemplate.GetPublishedTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPublishedTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPublishedTemplatesReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPublishedTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPublishedTemplatesReply.displayName = 'proto.advancedtemplate.GetPublishedTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.RescindTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.RescindTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.RescindTemplateRequest.displayName = 'proto.advancedtemplate.RescindTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetLastVersionTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetLastVersionTemplatesRequest.displayName = 'proto.advancedtemplate.GetLastVersionTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetLastVersionTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetLastVersionTemplatesReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetLastVersionTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetLastVersionTemplatesReply.displayName = 'proto.advancedtemplate.GetLastVersionTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.SynNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.SynNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.SynNoteRequest.displayName = 'proto.advancedtemplate.SynNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetResourceDownloadMetaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetResourceDownloadMetaRequest.displayName = 'proto.advancedtemplate.GetResourceDownloadMetaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetResourceDownloadMetaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetResourceDownloadMetaResponse.displayName = 'proto.advancedtemplate.GetResourceDownloadMetaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetResourceDownloadMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetResourceDownloadMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetResourceDownloadMetadata.displayName = 'proto.advancedtemplate.GetResourceDownloadMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetAllSeriesV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetAllSeriesV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetAllSeriesV2Request.displayName = 'proto.advancedtemplate.GetAllSeriesV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetAllSeriesV2Reply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetAllSeriesV2Reply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetAllSeriesV2Reply.displayName = 'proto.advancedtemplate.GetAllSeriesV2Reply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetAllSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetAllSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetAllSeriesRequest.displayName = 'proto.advancedtemplate.GetAllSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetAllSeriesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetAllSeriesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetAllSeriesReply.displayName = 'proto.advancedtemplate.GetAllSeriesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.SeriesResultDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.SeriesResultDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.SeriesResultDetail.displayName = 'proto.advancedtemplate.SeriesResultDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesV2Request.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesV2Reply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesV2Reply.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesV2Reply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesV3Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesV3Request.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesV3Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesV3Reply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesV3Reply.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesV3Reply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetRecommendTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetRecommendTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetRecommendTemplatesRequest.displayName = 'proto.advancedtemplate.GetRecommendTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetRecommendTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetRecommendTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetRecommendTemplatesReply.displayName = 'proto.advancedtemplate.GetRecommendTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.CheckUserTemplatePermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.CheckUserTemplatePermissionRequest.displayName = 'proto.advancedtemplate.CheckUserTemplatePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.CheckUserTemplatePermissionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.CheckUserTemplatePermissionReply.displayName = 'proto.advancedtemplate.CheckUserTemplatePermissionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetLatestTemplateByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetLatestTemplateByIdRequest.displayName = 'proto.advancedtemplate.GetLatestTemplateByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetLatestTemplateByIdReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetLatestTemplateByIdReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetLatestTemplateByIdReply.displayName = 'proto.advancedtemplate.GetLatestTemplateByIdReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.IncrementTemplateUsageCountRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.IncrementTemplateUsageCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.IncrementTemplateUsageCountRequest.displayName = 'proto.advancedtemplate.IncrementTemplateUsageCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TemplateUsageCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TemplateUsageCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TemplateUsageCount.displayName = 'proto.advancedtemplate.TemplateUsageCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.IncrementTemplateUsageCountReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.IncrementTemplateUsageCountReply.displayName = 'proto.advancedtemplate.IncrementTemplateUsageCountReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesRequest.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetIncrementalTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetIncrementalTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetIncrementalTemplatesReply.displayName = 'proto.advancedtemplate.GetIncrementalTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TemplatesResultDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TemplatesResultDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TemplatesResultDetail.displayName = 'proto.advancedtemplate.TemplatesResultDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetSigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSigRequest.displayName = 'proto.advancedtemplate.GetSigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetSigReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetSigReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetSigReply.displayName = 'proto.advancedtemplate.GetSigReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPurchasedTemplatesRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPurchasedTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPurchasedTemplatesRequest.displayName = 'proto.advancedtemplate.GetPurchasedTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPurchasedTemplatesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPurchasedTemplatesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPurchasedTemplatesReply.displayName = 'proto.advancedtemplate.GetPurchasedTemplatesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPurchasedTemplateIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPurchasedTemplateIdsRequest.displayName = 'proto.advancedtemplate.GetPurchasedTemplateIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetPurchasedTemplateIdsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPurchasedTemplateIdsReply.displayName = 'proto.advancedtemplate.GetPurchasedTemplateIdsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetPurchasedTemplateIdsData.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetPurchasedTemplateIdsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetPurchasedTemplateIdsData.displayName = 'proto.advancedtemplate.GetPurchasedTemplateIdsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplateStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetTemplateStatisticsRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetTemplateStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplateStatisticsRequest.displayName = 'proto.advancedtemplate.GetTemplateStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetTemplateStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetTemplateStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetTemplateStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetTemplateStatisticsResponse.displayName = 'proto.advancedtemplate.GetTemplateStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TemplateStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TemplateStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TemplateStatistics.displayName = 'proto.advancedtemplate.TemplateStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateTemplateStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateTemplateStatisticsRequest.displayName = 'proto.advancedtemplate.UpdateTemplateStatisticsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    seriesid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && common_pb.Pageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplatesRequest}
 */
proto.advancedtemplate.GetTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplatesRequest;
  return proto.advancedtemplate.GetTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplatesRequest}
 */
proto.advancedtemplate.GetTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 3:
      var value = new common_pb.Pageable;
      reader.readMessage(value,common_pb.Pageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Pageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional Template template = 1;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 1));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetTemplatesRequest.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 seriesId = 2;
 * @return {number}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplatesRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Pageable pageable = 3;
 * @return {?proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.getPageable = function() {
  return /** @type{?proto.advancedtemplate.Pageable} */ (
    jspb.Message.getWrapperField(this, common_pb.Pageable, 3));
};


/** @param {?proto.advancedtemplate.Pageable|undefined} value */
proto.advancedtemplate.GetTemplatesRequest.prototype.setPageable = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.clearPageable = function() {
  this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetTemplatesRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetTemplatesReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    common_pb.Template.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplatesReply}
 */
proto.advancedtemplate.GetTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplatesReply;
  return proto.advancedtemplate.GetTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplatesReply}
 */
proto.advancedtemplate.GetTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplatesReply.prototype.setTotal = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Template templates = 4;
 * @return {!Array<!proto.advancedtemplate.Template>}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Template>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Template, 4));
};


/** @param {!Array<!proto.advancedtemplate.Template>} value */
proto.advancedtemplate.GetTemplatesReply.prototype.setTemplatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.Template=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetTemplatesReply.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.Template, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetTemplatesReply.prototype.clearTemplatesList = function() {
  this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplateInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplateInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplateInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplateInfoRequest}
 */
proto.advancedtemplate.GetTemplateInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplateInfoRequest;
  return proto.advancedtemplate.GetTemplateInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplateInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplateInfoRequest}
 */
proto.advancedtemplate.GetTemplateInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplateInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplateInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplateInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetTemplateInfoRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplateInfoRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetTemplateInfoReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplateInfoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplateInfoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateInfoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    common_pb.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplateInfoReply}
 */
proto.advancedtemplate.GetTemplateInfoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplateInfoReply;
  return proto.advancedtemplate.GetTemplateInfoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplateInfoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplateInfoReply}
 */
proto.advancedtemplate.GetTemplateInfoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 4:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplateInfoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplateInfoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateInfoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplateInfoReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetTemplateInfoReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Template template = 3;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 3));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetTemplateInfoReply.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Series series = 4;
 * @return {!Array<!proto.advancedtemplate.Series>}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Series, 4));
};


/** @param {!Array<!proto.advancedtemplate.Series>} value */
proto.advancedtemplate.GetTemplateInfoReply.prototype.setSeriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetTemplateInfoReply.prototype.clearSeriesList = function() {
  this.setSeriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.AddTemplateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    common_pb.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddTemplateRequest}
 */
proto.advancedtemplate.AddTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddTemplateRequest;
  return proto.advancedtemplate.AddTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddTemplateRequest}
 */
proto.advancedtemplate.AddTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional Template template = 1;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 1));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.AddTemplateRequest.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.AddTemplateRequest.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Series series = 2;
 * @return {!Array<!proto.advancedtemplate.Series>}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Series, 2));
};


/** @param {!Array<!proto.advancedtemplate.Series>} value */
proto.advancedtemplate.AddTemplateRequest.prototype.setSeriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.advancedtemplate.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.AddTemplateRequest.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.advancedtemplate.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.AddTemplateRequest.prototype.clearSeriesList = function() {
  this.setSeriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.UpdateTemplateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    common_pb.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateTemplateRequest}
 */
proto.advancedtemplate.UpdateTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateTemplateRequest;
  return proto.advancedtemplate.UpdateTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateTemplateRequest}
 */
proto.advancedtemplate.UpdateTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional Template template = 1;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 1));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.UpdateTemplateRequest.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Series series = 2;
 * @return {!Array<!proto.advancedtemplate.Series>}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Series, 2));
};


/** @param {!Array<!proto.advancedtemplate.Series>} value */
proto.advancedtemplate.UpdateTemplateRequest.prototype.setSeriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.advancedtemplate.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.advancedtemplate.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.UpdateTemplateRequest.prototype.clearSeriesList = function() {
  this.setSeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.DeletedTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.DeletedTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.DeletedTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeletedTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.DeletedTemplateRequest}
 */
proto.advancedtemplate.DeletedTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.DeletedTemplateRequest;
  return proto.advancedtemplate.DeletedTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.DeletedTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.DeletedTemplateRequest}
 */
proto.advancedtemplate.DeletedTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.DeletedTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.DeletedTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.DeletedTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeletedTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.DeletedTemplateRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.DeletedTemplateRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UploadThumbnailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UploadThumbnailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UploadThumbnailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filetype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thumbnail: msg.getThumbnail_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UploadThumbnailRequest}
 */
proto.advancedtemplate.UploadThumbnailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UploadThumbnailRequest;
  return proto.advancedtemplate.UploadThumbnailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UploadThumbnailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UploadThumbnailRequest}
 */
proto.advancedtemplate.UploadThumbnailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UploadThumbnailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UploadThumbnailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UploadThumbnailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string fileType = 1;
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.UploadThumbnailRequest.prototype.setFiletype = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileName = 2;
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.UploadThumbnailRequest.prototype.setFilename = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes thumbnail = 3;
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes thumbnail = 3;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.getThumbnail_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThumbnail()));
};


/**
 * optional bytes thumbnail = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UploadThumbnailRequest.prototype.getThumbnail_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThumbnail()));
};


/** @param {!(string|Uint8Array)} value */
proto.advancedtemplate.UploadThumbnailRequest.prototype.setThumbnail = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublicNoteInfosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageable: (f = msg.getPageable()) && common_pb.Pageable.toObject(includeInstance, f),
    editortype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublicNoteInfosRequest}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublicNoteInfosRequest;
  return proto.advancedtemplate.GetPublicNoteInfosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublicNoteInfosRequest}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_pb.Pageable;
      reader.readMessage(value,common_pb.Pageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditortype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublicNoteInfosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Pageable.serializeBinaryToWriter
    );
  }
  f = message.getEditortype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Pageable pageable = 2;
 * @return {?proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.getPageable = function() {
  return /** @type{?proto.advancedtemplate.Pageable} */ (
    jspb.Message.getWrapperField(this, common_pb.Pageable, 2));
};


/** @param {?proto.advancedtemplate.Pageable|undefined} value */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.setPageable = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.clearPageable = function() {
  this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string editorType = 3;
 * @return {string}
 */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.getEditortype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublicNoteInfosRequest.prototype.setEditortype = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    noteid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    editortype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    noteurl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Note}
 */
proto.advancedtemplate.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Note;
  return proto.advancedtemplate.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Note}
 */
proto.advancedtemplate.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.advancedtemplate.EnumEditorType} */ (reader.readEnum());
      msg.setEditortype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoteid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEditortype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNoteurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string noteId = 1;
 * @return {string}
 */
proto.advancedtemplate.Note.prototype.getNoteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.Note.prototype.setNoteid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 version = 2;
 * @return {number}
 */
proto.advancedtemplate.Note.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.Note.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.advancedtemplate.Note.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.Note.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EnumEditorType editorType = 4;
 * @return {!proto.advancedtemplate.EnumEditorType}
 */
proto.advancedtemplate.Note.prototype.getEditortype = function() {
  return /** @type {!proto.advancedtemplate.EnumEditorType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.advancedtemplate.EnumEditorType} value */
proto.advancedtemplate.Note.prototype.setEditortype = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string noteUrl = 5;
 * @return {string}
 */
proto.advancedtemplate.Note.prototype.getNoteurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.advancedtemplate.Note.prototype.setNoteurl = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPublicNoteInfosReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublicNoteInfosReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublicNoteInfosReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublicNoteInfosReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.advancedtemplate.Note.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublicNoteInfosReply}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublicNoteInfosReply;
  return proto.advancedtemplate.GetPublicNoteInfosReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublicNoteInfosReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublicNoteInfosReply}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = new proto.advancedtemplate.Note;
      reader.readMessage(value,proto.advancedtemplate.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublicNoteInfosReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublicNoteInfosReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublicNoteInfosReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.advancedtemplate.Note.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.setTotal = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Note notes = 4;
 * @return {!Array<!proto.advancedtemplate.Note>}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.Note, 4));
};


/** @param {!Array<!proto.advancedtemplate.Note>} value */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.setNotesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Note}
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPublicNoteInfosReply.prototype.clearNotesList = function() {
  this.setNotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TemplateSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TemplateSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TemplateSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    relationorder: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TemplateSeries}
 */
proto.advancedtemplate.TemplateSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TemplateSeries;
  return proto.advancedtemplate.TemplateSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TemplateSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TemplateSeries}
 */
proto.advancedtemplate.TemplateSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelationorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TemplateSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TemplateSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TemplateSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRelationorder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.TemplateSeries.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateSeries.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 templateId = 2;
 * @return {number}
 */
proto.advancedtemplate.TemplateSeries.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateSeries.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 relationOrder = 3;
 * @return {number}
 */
proto.advancedtemplate.TemplateSeries.prototype.getRelationorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateSeries.prototype.setRelationorder = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UploadThumbnailReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UploadThumbnailReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UploadThumbnailReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UploadThumbnailReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thumbnailurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UploadThumbnailReply}
 */
proto.advancedtemplate.UploadThumbnailReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UploadThumbnailReply;
  return proto.advancedtemplate.UploadThumbnailReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UploadThumbnailReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UploadThumbnailReply}
 */
proto.advancedtemplate.UploadThumbnailReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UploadThumbnailReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UploadThumbnailReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UploadThumbnailReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UploadThumbnailReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.UploadThumbnailReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UploadThumbnailReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.UploadThumbnailReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string thumbnailUrl = 3;
 * @return {string}
 */
proto.advancedtemplate.UploadThumbnailReply.prototype.getThumbnailurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.UploadThumbnailReply.prototype.setThumbnailurl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    terminaltype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSeriesRequest}
 */
proto.advancedtemplate.GetSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSeriesRequest;
  return proto.advancedtemplate.GetSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSeriesRequest}
 */
proto.advancedtemplate.GetSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
      msg.setTerminaltype(value);
      break;
    case 3:
      var value = /** @type {!proto.advancedtemplate.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSeriesRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSeriesRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumTerminalType terminalType = 2;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.GetSeriesRequest.prototype.getTerminaltype = function() {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.GetSeriesRequest.prototype.setTerminaltype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Platform platform = 3;
 * @return {!proto.advancedtemplate.Platform}
 */
proto.advancedtemplate.GetSeriesRequest.prototype.getPlatform = function() {
  return /** @type {!proto.advancedtemplate.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.advancedtemplate.Platform} value */
proto.advancedtemplate.GetSeriesRequest.prototype.setPlatform = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSeriesTreeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSeriesTreeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSeriesTreeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesTreeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSeriesTreeRequest}
 */
proto.advancedtemplate.GetSeriesTreeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSeriesTreeRequest;
  return proto.advancedtemplate.GetSeriesTreeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSeriesTreeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSeriesTreeRequest}
 */
proto.advancedtemplate.GetSeriesTreeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSeriesTreeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSeriesTreeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSeriesTreeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesTreeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 all = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSeriesTreeRequest.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSeriesTreeRequest.prototype.setAll = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetSeriesReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSeriesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSeriesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSeriesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    common_pb.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSeriesReply}
 */
proto.advancedtemplate.GetSeriesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSeriesReply;
  return proto.advancedtemplate.GetSeriesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSeriesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSeriesReply}
 */
proto.advancedtemplate.GetSeriesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSeriesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSeriesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSeriesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSeriesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSeriesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetSeriesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetSeriesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Series series = 3;
 * @return {!Array<!proto.advancedtemplate.Series>}
 */
proto.advancedtemplate.GetSeriesReply.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Series, 3));
};


/** @param {!Array<!proto.advancedtemplate.Series>} value */
proto.advancedtemplate.GetSeriesReply.prototype.setSeriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Series}
 */
proto.advancedtemplate.GetSeriesReply.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetSeriesReply.prototype.clearSeriesList = function() {
  this.setSeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSeriesRelationTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSeriesRelationTemplateRequest}
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSeriesRelationTemplateRequest;
  return proto.advancedtemplate.GetSeriesRelationTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSeriesRelationTemplateRequest}
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSeriesRelationTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSeriesRelationTemplateRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.SeriesRelationTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.SeriesRelationTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesRelationTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    relationorder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    istop: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.SeriesRelationTemplate}
 */
proto.advancedtemplate.SeriesRelationTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.SeriesRelationTemplate;
  return proto.advancedtemplate.SeriesRelationTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.SeriesRelationTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.SeriesRelationTemplate}
 */
proto.advancedtemplate.SeriesRelationTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelationorder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.SeriesRelationTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.SeriesRelationTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesRelationTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getRelationorder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIstop();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.SeriesRelationTemplate.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.SeriesRelationTemplate.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Template template = 3;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 3));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.SeriesRelationTemplate.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 relationOrder = 4;
 * @return {number}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.getRelationorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.advancedtemplate.SeriesRelationTemplate.prototype.setRelationorder = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isTop = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.SeriesRelationTemplate.prototype.getIstop = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.advancedtemplate.SeriesRelationTemplate.prototype.setIstop = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSeriesRelationTemplateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relationList: jspb.Message.toObjectList(msg.getRelationList(),
    proto.advancedtemplate.SeriesRelationTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSeriesRelationTemplateReply}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSeriesRelationTemplateReply;
  return proto.advancedtemplate.GetSeriesRelationTemplateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSeriesRelationTemplateReply}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.SeriesRelationTemplate;
      reader.readMessage(value,proto.advancedtemplate.SeriesRelationTemplate.deserializeBinaryFromReader);
      msg.addRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSeriesRelationTemplateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.advancedtemplate.SeriesRelationTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SeriesRelationTemplate relation = 3;
 * @return {!Array<!proto.advancedtemplate.SeriesRelationTemplate>}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.getRelationList = function() {
  return /** @type{!Array<!proto.advancedtemplate.SeriesRelationTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.SeriesRelationTemplate, 3));
};


/** @param {!Array<!proto.advancedtemplate.SeriesRelationTemplate>} value */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.setRelationList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.SeriesRelationTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.SeriesRelationTemplate}
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.addRelation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.SeriesRelationTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetSeriesRelationTemplateReply.prototype.clearRelationList = function() {
  this.setRelationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    series: (f = msg.getSeries()) && common_pb.Series.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddSeriesRequest}
 */
proto.advancedtemplate.AddSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddSeriesRequest;
  return proto.advancedtemplate.AddSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddSeriesRequest}
 */
proto.advancedtemplate.AddSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.setSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeries();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional Series series = 1;
 * @return {?proto.advancedtemplate.Series}
 */
proto.advancedtemplate.AddSeriesRequest.prototype.getSeries = function() {
  return /** @type{?proto.advancedtemplate.Series} */ (
    jspb.Message.getWrapperField(this, common_pb.Series, 1));
};


/** @param {?proto.advancedtemplate.Series|undefined} value */
proto.advancedtemplate.AddSeriesRequest.prototype.setSeries = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.AddSeriesRequest.prototype.clearSeries = function() {
  this.setSeries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.AddSeriesRequest.prototype.hasSeries = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    series: (f = msg.getSeries()) && common_pb.Series.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateSeriesRequest}
 */
proto.advancedtemplate.UpdateSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateSeriesRequest;
  return proto.advancedtemplate.UpdateSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateSeriesRequest}
 */
proto.advancedtemplate.UpdateSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Series;
      reader.readMessage(value,common_pb.Series.deserializeBinaryFromReader);
      msg.setSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeries();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * optional Series series = 1;
 * @return {?proto.advancedtemplate.Series}
 */
proto.advancedtemplate.UpdateSeriesRequest.prototype.getSeries = function() {
  return /** @type{?proto.advancedtemplate.Series} */ (
    jspb.Message.getWrapperField(this, common_pb.Series, 1));
};


/** @param {?proto.advancedtemplate.Series|undefined} value */
proto.advancedtemplate.UpdateSeriesRequest.prototype.setSeries = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.UpdateSeriesRequest.prototype.clearSeries = function() {
  this.setSeries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.UpdateSeriesRequest.prototype.hasSeries = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.DeleteSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.DeleteSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.DeleteSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeleteSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.DeleteSeriesRequest}
 */
proto.advancedtemplate.DeleteSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.DeleteSeriesRequest;
  return proto.advancedtemplate.DeleteSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.DeleteSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.DeleteSeriesRequest}
 */
proto.advancedtemplate.DeleteSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.DeleteSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.DeleteSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.DeleteSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeleteSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.DeleteSeriesRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.DeleteSeriesRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateSeriesRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateSeriesRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relationList: jspb.Message.toObjectList(msg.getRelationList(),
    proto.advancedtemplate.Relation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateSeriesRelationRequest}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateSeriesRelationRequest;
  return proto.advancedtemplate.UpdateSeriesRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateSeriesRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateSeriesRelationRequest}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = new proto.advancedtemplate.Relation;
      reader.readMessage(value,proto.advancedtemplate.Relation.deserializeBinaryFromReader);
      msg.addRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateSeriesRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateSeriesRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRelationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.advancedtemplate.Relation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Relation relation = 2;
 * @return {!Array<!proto.advancedtemplate.Relation>}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.getRelationList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Relation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.Relation, 2));
};


/** @param {!Array<!proto.advancedtemplate.Relation>} value */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.setRelationList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.advancedtemplate.Relation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Relation}
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.addRelation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.advancedtemplate.Relation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.UpdateSeriesRelationRequest.prototype.clearRelationList = function() {
  this.setRelationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.Relation.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.Relation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.Relation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Relation.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Relation}
 */
proto.advancedtemplate.Relation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Relation;
  return proto.advancedtemplate.Relation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Relation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Relation}
 */
proto.advancedtemplate.Relation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Relation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Relation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Relation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Relation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.Relation.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.Relation.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.advancedtemplate.Relation.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.Relation.prototype.setOrder = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.DeleteRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.DeleteRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.DeleteRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeleteRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    relation: (f = msg.getRelation()) && proto.advancedtemplate.SeriesRelationTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.DeleteRelationRequest}
 */
proto.advancedtemplate.DeleteRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.DeleteRelationRequest;
  return proto.advancedtemplate.DeleteRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.DeleteRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.DeleteRelationRequest}
 */
proto.advancedtemplate.DeleteRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.advancedtemplate.SeriesRelationTemplate;
      reader.readMessage(value,proto.advancedtemplate.SeriesRelationTemplate.deserializeBinaryFromReader);
      msg.setRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.DeleteRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.DeleteRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.DeleteRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeleteRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.advancedtemplate.SeriesRelationTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional SeriesRelationTemplate relation = 1;
 * @return {?proto.advancedtemplate.SeriesRelationTemplate}
 */
proto.advancedtemplate.DeleteRelationRequest.prototype.getRelation = function() {
  return /** @type{?proto.advancedtemplate.SeriesRelationTemplate} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.SeriesRelationTemplate, 1));
};


/** @param {?proto.advancedtemplate.SeriesRelationTemplate|undefined} value */
proto.advancedtemplate.DeleteRelationRequest.prototype.setRelation = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.DeleteRelationRequest.prototype.clearRelation = function() {
  this.setRelation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.DeleteRelationRequest.prototype.hasRelation = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.AddRelationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateidList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddRelationRequest}
 */
proto.advancedtemplate.AddRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddRelationRequest;
  return proto.advancedtemplate.AddRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddRelationRequest}
 */
proto.advancedtemplate.AddRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.AddRelationRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.AddRelationRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 templateId = 2;
 * @return {!Array<number>}
 */
proto.advancedtemplate.AddRelationRequest.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.AddRelationRequest.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.AddRelationRequest.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.AddRelationRequest.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateRecommendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateRecommendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateRecommendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateRecommendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentseriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    preseriesid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateRecommendRequest}
 */
proto.advancedtemplate.UpdateRecommendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateRecommendRequest;
  return proto.advancedtemplate.UpdateRecommendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateRecommendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateRecommendRequest}
 */
proto.advancedtemplate.UpdateRecommendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentseriesid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreseriesid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateRecommendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateRecommendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateRecommendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateRecommendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentseriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPreseriesid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 currentSeriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateRecommendRequest.prototype.getCurrentseriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateRecommendRequest.prototype.setCurrentseriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 preSeriesId = 2;
 * @return {number}
 */
proto.advancedtemplate.UpdateRecommendRequest.prototype.getPreseriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateRecommendRequest.prototype.setPreseriesid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.OrderSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.OrderSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.OrderSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.OrderSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.OrderSeries}
 */
proto.advancedtemplate.OrderSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.OrderSeries;
  return proto.advancedtemplate.OrderSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.OrderSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.OrderSeries}
 */
proto.advancedtemplate.OrderSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.OrderSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.OrderSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.OrderSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.OrderSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.OrderSeries.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.OrderSeries.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.advancedtemplate.OrderSeries.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.OrderSeries.prototype.setOrder = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateOrderSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderseriesList: jspb.Message.toObjectList(msg.getOrderseriesList(),
    proto.advancedtemplate.OrderSeries.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateOrderSeriesRequest}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateOrderSeriesRequest;
  return proto.advancedtemplate.UpdateOrderSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateOrderSeriesRequest}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.advancedtemplate.OrderSeries;
      reader.readMessage(value,proto.advancedtemplate.OrderSeries.deserializeBinaryFromReader);
      msg.addOrderseries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateOrderSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderseriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.advancedtemplate.OrderSeries.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderSeries orderSeries = 1;
 * @return {!Array<!proto.advancedtemplate.OrderSeries>}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.getOrderseriesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.OrderSeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.OrderSeries, 1));
};


/** @param {!Array<!proto.advancedtemplate.OrderSeries>} value */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.setOrderseriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.advancedtemplate.OrderSeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.OrderSeries}
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.addOrderseries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.advancedtemplate.OrderSeries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.UpdateOrderSeriesRequest.prototype.clearOrderseriesList = function() {
  this.setOrderseriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TemplateOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TemplateOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TemplateOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TemplateOrder}
 */
proto.advancedtemplate.TemplateOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TemplateOrder;
  return proto.advancedtemplate.TemplateOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TemplateOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TemplateOrder}
 */
proto.advancedtemplate.TemplateOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TemplateOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TemplateOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TemplateOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.TemplateOrder.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateOrder.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.advancedtemplate.TemplateOrder.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateOrder.prototype.setOrder = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateOrderTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateorderList: jspb.Message.toObjectList(msg.getTemplateorderList(),
    proto.advancedtemplate.TemplateOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateOrderTemplatesRequest}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateOrderTemplatesRequest;
  return proto.advancedtemplate.UpdateOrderTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateOrderTemplatesRequest}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = new proto.advancedtemplate.TemplateOrder;
      reader.readMessage(value,proto.advancedtemplate.TemplateOrder.deserializeBinaryFromReader);
      msg.addTemplateorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateOrderTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateorderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.advancedtemplate.TemplateOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TemplateOrder templateOrder = 2;
 * @return {!Array<!proto.advancedtemplate.TemplateOrder>}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.getTemplateorderList = function() {
  return /** @type{!Array<!proto.advancedtemplate.TemplateOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.TemplateOrder, 2));
};


/** @param {!Array<!proto.advancedtemplate.TemplateOrder>} value */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.setTemplateorderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.advancedtemplate.TemplateOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.TemplateOrder}
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.addTemplateorder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.advancedtemplate.TemplateOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.UpdateOrderTemplatesRequest.prototype.clearTemplateorderList = function() {
  this.setTemplateorderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.EmptyRequest}
 */
proto.advancedtemplate.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.EmptyRequest;
  return proto.advancedtemplate.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.EmptyRequest}
 */
proto.advancedtemplate.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.QuerySeriesTypeReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.QuerySeriesTypeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.QuerySeriesTypeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.QuerySeriesTypeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seriestypeList: jspb.Message.toObjectList(msg.getSeriestypeList(),
    proto.advancedtemplate.SeriesType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.QuerySeriesTypeReply}
 */
proto.advancedtemplate.QuerySeriesTypeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.QuerySeriesTypeReply;
  return proto.advancedtemplate.QuerySeriesTypeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.QuerySeriesTypeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.QuerySeriesTypeReply}
 */
proto.advancedtemplate.QuerySeriesTypeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.SeriesType;
      reader.readMessage(value,proto.advancedtemplate.SeriesType.deserializeBinaryFromReader);
      msg.addSeriestype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.QuerySeriesTypeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.QuerySeriesTypeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.QuerySeriesTypeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeriestypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.advancedtemplate.SeriesType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SeriesType seriesType = 3;
 * @return {!Array<!proto.advancedtemplate.SeriesType>}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.getSeriestypeList = function() {
  return /** @type{!Array<!proto.advancedtemplate.SeriesType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.SeriesType, 3));
};


/** @param {!Array<!proto.advancedtemplate.SeriesType>} value */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.setSeriestypeList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.SeriesType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.SeriesType}
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.addSeriestype = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.SeriesType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.QuerySeriesTypeReply.prototype.clearSeriestypeList = function() {
  this.setSeriestypeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.SeriesType.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.SeriesType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.SeriesType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    desc: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.SeriesType}
 */
proto.advancedtemplate.SeriesType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.SeriesType;
  return proto.advancedtemplate.SeriesType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.SeriesType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.SeriesType}
 */
proto.advancedtemplate.SeriesType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.SeriesType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.SeriesType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.SeriesType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.advancedtemplate.SeriesType.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.SeriesType.prototype.setType = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string desc = 2;
 * @return {string}
 */
proto.advancedtemplate.SeriesType.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.SeriesType.prototype.setDesc = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishListRequest}
 */
proto.advancedtemplate.GetPublishListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishListRequest;
  return proto.advancedtemplate.GetPublishListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishListRequest}
 */
proto.advancedtemplate.GetPublishListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.advancedtemplate.EnumPublishType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumPublishState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional EnumPublishType type = 1;
 * @return {!proto.advancedtemplate.EnumPublishType}
 */
proto.advancedtemplate.GetPublishListRequest.prototype.getType = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishType} value */
proto.advancedtemplate.GetPublishListRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional EnumPublishState state = 2;
 * @return {!proto.advancedtemplate.EnumPublishState}
 */
proto.advancedtemplate.GetPublishListRequest.prototype.getState = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishState} value */
proto.advancedtemplate.GetPublishListRequest.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 version = 3;
 * @return {number}
 */
proto.advancedtemplate.GetPublishListRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishListRequest.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPublishListReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publishlistList: jspb.Message.toObjectList(msg.getPublishlistList(),
    proto.advancedtemplate.PublishContext.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishListReply}
 */
proto.advancedtemplate.GetPublishListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishListReply;
  return proto.advancedtemplate.GetPublishListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishListReply}
 */
proto.advancedtemplate.GetPublishListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.PublishContext;
      reader.readMessage(value,proto.advancedtemplate.PublishContext.deserializeBinaryFromReader);
      msg.addPublishlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublishlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.advancedtemplate.PublishContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishListReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishListReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPublishListReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublishListReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PublishContext publishList = 3;
 * @return {!Array<!proto.advancedtemplate.PublishContext>}
 */
proto.advancedtemplate.GetPublishListReply.prototype.getPublishlistList = function() {
  return /** @type{!Array<!proto.advancedtemplate.PublishContext>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.PublishContext, 3));
};


/** @param {!Array<!proto.advancedtemplate.PublishContext>} value */
proto.advancedtemplate.GetPublishListReply.prototype.setPublishlistList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.PublishContext=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.PublishContext}
 */
proto.advancedtemplate.GetPublishListReply.prototype.addPublishlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.PublishContext, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPublishListReply.prototype.clearPublishlistList = function() {
  this.setPublishlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.PublishContext.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.PublishContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.PublishContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    publishtime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    publishuser: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.PublishContext}
 */
proto.advancedtemplate.PublishContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.PublishContext;
  return proto.advancedtemplate.PublishContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.PublishContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.PublishContext}
 */
proto.advancedtemplate.PublishContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishid(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumPublishType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {!proto.advancedtemplate.EnumPublishState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishtime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.PublishContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.PublishContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.PublishContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPublishtime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPublishuser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 publishId = 1;
 * @return {number}
 */
proto.advancedtemplate.PublishContext.prototype.getPublishid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.PublishContext.prototype.setPublishid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumPublishType type = 2;
 * @return {!proto.advancedtemplate.EnumPublishType}
 */
proto.advancedtemplate.PublishContext.prototype.getType = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishType} value */
proto.advancedtemplate.PublishContext.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 version = 3;
 * @return {number}
 */
proto.advancedtemplate.PublishContext.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.PublishContext.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional EnumPublishState state = 4;
 * @return {!proto.advancedtemplate.EnumPublishState}
 */
proto.advancedtemplate.PublishContext.prototype.getState = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishState} value */
proto.advancedtemplate.PublishContext.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string publishTime = 5;
 * @return {string}
 */
proto.advancedtemplate.PublishContext.prototype.getPublishtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.advancedtemplate.PublishContext.prototype.setPublishtime = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string publishUser = 6;
 * @return {string}
 */
proto.advancedtemplate.PublishContext.prototype.getPublishuser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.advancedtemplate.PublishContext.prototype.setPublishuser = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishSeriesRequest}
 */
proto.advancedtemplate.GetPublishSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishSeriesRequest;
  return proto.advancedtemplate.GetPublishSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishSeriesRequest}
 */
proto.advancedtemplate.GetPublishSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 publishId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishSeriesRequest.prototype.getPublishid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishSeriesRequest.prototype.setPublishid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishSeriesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishSeriesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishSeriesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    snapshot: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishSeriesReply}
 */
proto.advancedtemplate.GetPublishSeriesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishSeriesReply;
  return proto.advancedtemplate.GetPublishSeriesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishSeriesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishSeriesReply}
 */
proto.advancedtemplate.GetPublishSeriesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {!proto.advancedtemplate.EnumPublishState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishSeriesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishSeriesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishSeriesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSnapshot();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishSeriesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublishSeriesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EnumPublishState state = 3;
 * @return {!proto.advancedtemplate.EnumPublishState}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.getState = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishState} value */
proto.advancedtemplate.GetPublishSeriesReply.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 version = 4;
 * @return {number}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishSeriesReply.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string snapshot = 5;
 * @return {string}
 */
proto.advancedtemplate.GetPublishSeriesReply.prototype.getSnapshot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublishSeriesReply.prototype.setSnapshot = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishTemplateRequest}
 */
proto.advancedtemplate.GetPublishTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishTemplateRequest;
  return proto.advancedtemplate.GetPublishTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishTemplateRequest}
 */
proto.advancedtemplate.GetPublishTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 publishId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishTemplateRequest.prototype.getPublishid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishTemplateRequest.prototype.setPublishid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPublishTemplateReply.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishTemplateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishTemplateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishTemplateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    common_pb.Template.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishTemplateReply}
 */
proto.advancedtemplate.GetPublishTemplateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishTemplateReply;
  return proto.advancedtemplate.GetPublishTemplateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishTemplateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishTemplateReply}
 */
proto.advancedtemplate.GetPublishTemplateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {!proto.advancedtemplate.EnumPublishState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishTemplateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishTemplateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishTemplateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishTemplateReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublishTemplateReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EnumPublishState state = 3;
 * @return {!proto.advancedtemplate.EnumPublishState}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.getState = function() {
  return /** @type {!proto.advancedtemplate.EnumPublishState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.advancedtemplate.EnumPublishState} value */
proto.advancedtemplate.GetPublishTemplateReply.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 version = 4;
 * @return {number}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishTemplateReply.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Template templates = 5;
 * @return {!Array<!proto.advancedtemplate.Template>}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Template>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Template, 5));
};


/** @param {!Array<!proto.advancedtemplate.Template>} value */
proto.advancedtemplate.GetPublishTemplateReply.prototype.setTemplatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.advancedtemplate.Template=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.advancedtemplate.Template, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPublishTemplateReply.prototype.clearTemplatesList = function() {
  this.setTemplatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.PublishTemplateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.PublishTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.PublishTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.PublishTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateidList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.PublishTemplateRequest}
 */
proto.advancedtemplate.PublishTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.PublishTemplateRequest;
  return proto.advancedtemplate.PublishTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.PublishTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.PublishTemplateRequest}
 */
proto.advancedtemplate.PublishTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.PublishTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.PublishTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.PublishTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 templateId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.PublishTemplateRequest.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.PublishTemplateRequest.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.PublishTemplateRequest.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.PublishTemplateRequest.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.PublishSeriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.PublishSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.PublishSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesidList: jspb.Message.getRepeatedField(msg, 1),
    terminaltype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.PublishSeriesRequest}
 */
proto.advancedtemplate.PublishSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.PublishSeriesRequest;
  return proto.advancedtemplate.PublishSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.PublishSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.PublishSeriesRequest}
 */
proto.advancedtemplate.PublishSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSeriesidList(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
      msg.setTerminaltype(value);
      break;
    case 3:
      var value = /** @type {!proto.advancedtemplate.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.PublishSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.PublishSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.PublishSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated int64 seriesId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.getSeriesidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.PublishSeriesRequest.prototype.setSeriesidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.addSeriesid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.clearSeriesidList = function() {
  this.setSeriesidList([]);
};


/**
 * optional EnumTerminalType terminalType = 2;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.getTerminaltype = function() {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.PublishSeriesRequest.prototype.setTerminaltype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Platform platform = 3;
 * @return {!proto.advancedtemplate.Platform}
 */
proto.advancedtemplate.PublishSeriesRequest.prototype.getPlatform = function() {
  return /** @type {!proto.advancedtemplate.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.advancedtemplate.Platform} value */
proto.advancedtemplate.PublishSeriesRequest.prototype.setPlatform = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftTemplateRequest}
 */
proto.advancedtemplate.GetDraftTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftTemplateRequest;
  return proto.advancedtemplate.GetDraftTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftTemplateRequest}
 */
proto.advancedtemplate.GetDraftTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetDraftTemplateReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftTemplateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftTemplateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftTemplateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    common_pb.Template.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftTemplateReply}
 */
proto.advancedtemplate.GetDraftTemplateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftTemplateReply;
  return proto.advancedtemplate.GetDraftTemplateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftTemplateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftTemplateReply}
 */
proto.advancedtemplate.GetDraftTemplateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftTemplateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftTemplateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftTemplateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetDraftTemplateReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetDraftTemplateReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Template templates = 3;
 * @return {!Array<!proto.advancedtemplate.Template>}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Template>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Template, 3));
};


/** @param {!Array<!proto.advancedtemplate.Template>} value */
proto.advancedtemplate.GetDraftTemplateReply.prototype.setTemplatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.Template=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.Template, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetDraftTemplateReply.prototype.clearTemplatesList = function() {
  this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    terminaltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftSeriesRequest}
 */
proto.advancedtemplate.GetDraftSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftSeriesRequest;
  return proto.advancedtemplate.GetDraftSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftSeriesRequest}
 */
proto.advancedtemplate.GetDraftSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.advancedtemplate.EnumTerminalType} */ (reader.readEnum());
      msg.setTerminaltype(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTerminaltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional EnumTerminalType terminalType = 1;
 * @return {!proto.advancedtemplate.EnumTerminalType}
 */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.getTerminaltype = function() {
  return /** @type {!proto.advancedtemplate.EnumTerminalType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumTerminalType} value */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.setTerminaltype = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Platform platform = 2;
 * @return {!proto.advancedtemplate.Platform}
 */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.getPlatform = function() {
  return /** @type {!proto.advancedtemplate.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.Platform} value */
proto.advancedtemplate.GetDraftSeriesRequest.prototype.setPlatform = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftSeriesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftSeriesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftSeriesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftSeriesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftSeriesReply}
 */
proto.advancedtemplate.GetDraftSeriesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftSeriesReply;
  return proto.advancedtemplate.GetDraftSeriesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftSeriesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftSeriesReply}
 */
proto.advancedtemplate.GetDraftSeriesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftSeriesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftSeriesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftSeriesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftSeriesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetDraftSeriesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetDraftSeriesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetDraftSeriesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetDraftSeriesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string result = 3;
 * @return {string}
 */
proto.advancedtemplate.GetDraftSeriesReply.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetDraftSeriesReply.prototype.setResult = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    guid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddNoteRequest}
 */
proto.advancedtemplate.AddNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddNoteRequest;
  return proto.advancedtemplate.AddNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddNoteRequest}
 */
proto.advancedtemplate.AddNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string guid = 1;
 * @return {string}
 */
proto.advancedtemplate.AddNoteRequest.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.AddNoteRequest.prototype.setGuid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddNoteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddNoteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddNoteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddNoteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddNoteReply}
 */
proto.advancedtemplate.AddNoteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddNoteReply;
  return proto.advancedtemplate.AddNoteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddNoteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddNoteReply}
 */
proto.advancedtemplate.AddNoteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddNoteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddNoteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddNoteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddNoteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.AddNoteReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.AddNoteReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.AddNoteReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.AddNoteReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    guid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateNoteRequest}
 */
proto.advancedtemplate.UpdateNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateNoteRequest;
  return proto.advancedtemplate.UpdateNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateNoteRequest}
 */
proto.advancedtemplate.UpdateNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string guid = 1;
 * @return {string}
 */
proto.advancedtemplate.UpdateNoteRequest.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.UpdateNoteRequest.prototype.setGuid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateNoteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateNoteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateNoteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateNoteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateNoteReply}
 */
proto.advancedtemplate.UpdateNoteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateNoteReply;
  return proto.advancedtemplate.UpdateNoteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateNoteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateNoteReply}
 */
proto.advancedtemplate.UpdateNoteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateNoteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateNoteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateNoteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateNoteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateNoteReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateNoteReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.UpdateNoteReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.UpdateNoteReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishedTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && common_pb.Pageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishedTemplatesRequest}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishedTemplatesRequest;
  return proto.advancedtemplate.GetPublishedTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishedTemplatesRequest}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = new common_pb.Pageable;
      reader.readMessage(value,common_pb.Pageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishedTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Pageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional Template template = 1;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 1));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pageable pageable = 2;
 * @return {?proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.getPageable = function() {
  return /** @type{?proto.advancedtemplate.Pageable} */ (
    jspb.Message.getWrapperField(this, common_pb.Pageable, 2));
};


/** @param {?proto.advancedtemplate.Pageable|undefined} value */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.setPageable = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.clearPageable = function() {
  this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetPublishedTemplatesRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPublishedTemplatesReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPublishedTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPublishedTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishedTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    common_pb.Template.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPublishedTemplatesReply}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPublishedTemplatesReply;
  return proto.advancedtemplate.GetPublishedTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPublishedTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPublishedTemplatesReply}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPublishedTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPublishedTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPublishedTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.setTotal = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Template templates = 4;
 * @return {!Array<!proto.advancedtemplate.Template>}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Template>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Template, 4));
};


/** @param {!Array<!proto.advancedtemplate.Template>} value */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.setTemplatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.Template=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.Template, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPublishedTemplatesReply.prototype.clearTemplatesList = function() {
  this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.RescindTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.RescindTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.RescindTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.RescindTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.RescindTemplateRequest}
 */
proto.advancedtemplate.RescindTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.RescindTemplateRequest;
  return proto.advancedtemplate.RescindTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.RescindTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.RescindTemplateRequest}
 */
proto.advancedtemplate.RescindTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.RescindTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.RescindTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.RescindTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.RescindTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.RescindTemplateRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.RescindTemplateRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetLastVersionTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageable: (f = msg.getPageable()) && common_pb.Pageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetLastVersionTemplatesRequest}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetLastVersionTemplatesRequest;
  return proto.advancedtemplate.GetLastVersionTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetLastVersionTemplatesRequest}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Pageable;
      reader.readMessage(value,common_pb.Pageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetLastVersionTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Pageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pageable pageable = 1;
 * @return {?proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.getPageable = function() {
  return /** @type{?proto.advancedtemplate.Pageable} */ (
    jspb.Message.getWrapperField(this, common_pb.Pageable, 1));
};


/** @param {?proto.advancedtemplate.Pageable|undefined} value */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.setPageable = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.clearPageable = function() {
  this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetLastVersionTemplatesRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetLastVersionTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    common_pb.Template.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetLastVersionTemplatesReply}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetLastVersionTemplatesReply;
  return proto.advancedtemplate.GetLastVersionTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetLastVersionTemplatesReply}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetLastVersionTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.setTotal = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Template templates = 4;
 * @return {!Array<!proto.advancedtemplate.Template>}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.advancedtemplate.Template>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Template, 4));
};


/** @param {!Array<!proto.advancedtemplate.Template>} value */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.setTemplatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.Template=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.Template, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetLastVersionTemplatesReply.prototype.clearTemplatesList = function() {
  this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.SynNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.SynNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.SynNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SynNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.SynNoteRequest}
 */
proto.advancedtemplate.SynNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.SynNoteRequest;
  return proto.advancedtemplate.SynNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.SynNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.SynNoteRequest}
 */
proto.advancedtemplate.SynNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.SynNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.SynNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.SynNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SynNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetResourceDownloadMetaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authtoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servicedata: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sig: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetaRequest}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetResourceDownloadMetaRequest;
  return proto.advancedtemplate.GetResourceDownloadMetaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetaRequest}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicedata(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetResourceDownloadMetaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServicedata();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSig();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string authToken = 1;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.setAuthtoken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serviceData = 2;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.getServicedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.setServicedata = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sig = 3;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.getSig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetaRequest.prototype.setSig = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetResourceDownloadMetaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.advancedtemplate.GetResourceDownloadMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetaResponse}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetResourceDownloadMetaResponse;
  return proto.advancedtemplate.GetResourceDownloadMetaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetaResponse}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.GetResourceDownloadMetadata;
      reader.readMessage(value,proto.advancedtemplate.GetResourceDownloadMetadata.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetResourceDownloadMetaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.GetResourceDownloadMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GetResourceDownloadMetadata data = 3;
 * @return {?proto.advancedtemplate.GetResourceDownloadMetadata}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.getData = function() {
  return /** @type{?proto.advancedtemplate.GetResourceDownloadMetadata} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.GetResourceDownloadMetadata, 3));
};


/** @param {?proto.advancedtemplate.GetResourceDownloadMetadata|undefined} value */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetResourceDownloadMetaResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetResourceDownloadMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetResourceDownloadMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    bucket: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objpath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetadata}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetResourceDownloadMetadata;
  return proto.advancedtemplate.GetResourceDownloadMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetResourceDownloadMetadata}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetResourceDownloadMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetResourceDownloadMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetResourceDownloadMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjpath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bucket = 1;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.setBucket = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string objPath = 2;
 * @return {string}
 */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.getObjpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetResourceDownloadMetadata.prototype.setObjpath = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetAllSeriesV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetAllSeriesV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetAllSeriesV2Request}
 */
proto.advancedtemplate.GetAllSeriesV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetAllSeriesV2Request;
  return proto.advancedtemplate.GetAllSeriesV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetAllSeriesV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetAllSeriesV2Request}
 */
proto.advancedtemplate.GetAllSeriesV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentversion(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetAllSeriesV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetAllSeriesV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 currentVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.getCurrentversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.setCurrentversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumSourceType sourceType = 2;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetAllSeriesV2Request.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetAllSeriesV2Reply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetAllSeriesV2Reply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesV2Reply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.SeriesResultDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetAllSeriesV2Reply}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetAllSeriesV2Reply;
  return proto.advancedtemplate.GetAllSeriesV2Reply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetAllSeriesV2Reply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetAllSeriesV2Reply}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.SeriesResultDetail;
      reader.readMessage(value,proto.advancedtemplate.SeriesResultDetail.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetAllSeriesV2Reply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetAllSeriesV2Reply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesV2Reply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.SeriesResultDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeriesResultDetail result = 3;
 * @return {?proto.advancedtemplate.SeriesResultDetail}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.SeriesResultDetail} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.SeriesResultDetail, 3));
};


/** @param {?proto.advancedtemplate.SeriesResultDetail|undefined} value */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetAllSeriesV2Reply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetAllSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetAllSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetAllSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetAllSeriesRequest}
 */
proto.advancedtemplate.GetAllSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetAllSeriesRequest;
  return proto.advancedtemplate.GetAllSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetAllSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetAllSeriesRequest}
 */
proto.advancedtemplate.GetAllSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentversion(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetAllSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetAllSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetAllSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 currentVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.GetAllSeriesRequest.prototype.getCurrentversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetAllSeriesRequest.prototype.setCurrentversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumSourceType sourceType = 2;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetAllSeriesRequest.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetAllSeriesRequest.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetAllSeriesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetAllSeriesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.SeriesResultDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetAllSeriesReply}
 */
proto.advancedtemplate.GetAllSeriesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetAllSeriesReply;
  return proto.advancedtemplate.GetAllSeriesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetAllSeriesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetAllSeriesReply}
 */
proto.advancedtemplate.GetAllSeriesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.SeriesResultDetail;
      reader.readMessage(value,proto.advancedtemplate.SeriesResultDetail.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetAllSeriesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetAllSeriesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetAllSeriesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.SeriesResultDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetAllSeriesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetAllSeriesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeriesResultDetail result = 3;
 * @return {?proto.advancedtemplate.SeriesResultDetail}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.SeriesResultDetail} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.SeriesResultDetail, 3));
};


/** @param {?proto.advancedtemplate.SeriesResultDetail|undefined} value */
proto.advancedtemplate.GetAllSeriesReply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetAllSeriesReply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.SeriesResultDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.SeriesResultDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.SeriesResultDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesResultDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seriesinfo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.SeriesResultDetail}
 */
proto.advancedtemplate.SeriesResultDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.SeriesResultDetail;
  return proto.advancedtemplate.SeriesResultDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.SeriesResultDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.SeriesResultDetail}
 */
proto.advancedtemplate.SeriesResultDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductionversion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.SeriesResultDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.SeriesResultDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.SeriesResultDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.SeriesResultDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeriesinfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 productionVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.SeriesResultDetail.prototype.getProductionversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.SeriesResultDetail.prototype.setProductionversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string seriesInfo = 2;
 * @return {string}
 */
proto.advancedtemplate.SeriesResultDetail.prototype.getSeriesinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.SeriesResultDetail.prototype.setSeriesinfo = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV2Request}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesV2Request;
  return proto.advancedtemplate.GetIncrementalTemplatesV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV2Request}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentversion(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 currentVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.getCurrentversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.setCurrentversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumSourceType sourceType = 2;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetIncrementalTemplatesV2Request.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesV2Reply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Reply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.TemplatesResultDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV2Reply}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesV2Reply;
  return proto.advancedtemplate.GetIncrementalTemplatesV2Reply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Reply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV2Reply}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.TemplatesResultDetail;
      reader.readMessage(value,proto.advancedtemplate.TemplatesResultDetail.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesV2Reply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Reply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.TemplatesResultDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TemplatesResultDetail result = 3;
 * @return {?proto.advancedtemplate.TemplatesResultDetail}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.TemplatesResultDetail} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.TemplatesResultDetail, 3));
};


/** @param {?proto.advancedtemplate.TemplatesResultDetail|undefined} value */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetIncrementalTemplatesV2Reply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesV3Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV3Request}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesV3Request;
  return proto.advancedtemplate.GetIncrementalTemplatesV3Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV3Request}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentversion(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesV3Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 currentVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.getCurrentversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.setCurrentversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumSourceType sourceType = 2;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetIncrementalTemplatesV3Request.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesV3Reply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Reply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.TemplatesResultDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV3Reply}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesV3Reply;
  return proto.advancedtemplate.GetIncrementalTemplatesV3Reply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Reply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesV3Reply}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.TemplatesResultDetail;
      reader.readMessage(value,proto.advancedtemplate.TemplatesResultDetail.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesV3Reply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Reply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.TemplatesResultDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TemplatesResultDetail result = 3;
 * @return {?proto.advancedtemplate.TemplatesResultDetail}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.TemplatesResultDetail} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.TemplatesResultDetail, 3));
};


/** @param {?proto.advancedtemplate.TemplatesResultDetail|undefined} value */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetIncrementalTemplatesV3Reply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetRecommendTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clienttype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesRequest}
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetRecommendTemplatesRequest;
  return proto.advancedtemplate.GetRecommendTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesRequest}
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.advancedtemplate.EnumClientType} */ (reader.readEnum());
      msg.setClienttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetRecommendTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClienttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional EnumClientType clientType = 1;
 * @return {!proto.advancedtemplate.EnumClientType}
 */
proto.advancedtemplate.GetRecommendTemplatesRequest.prototype.getClienttype = function() {
  return /** @type {!proto.advancedtemplate.EnumClientType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumClientType} value */
proto.advancedtemplate.GetRecommendTemplatesRequest.prototype.setClienttype = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetRecommendTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetRecommendTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    templates: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesReply}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetRecommendTemplatesReply;
  return proto.advancedtemplate.GetRecommendTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesReply}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetRecommendTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplates();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string templates = 3;
 * @return {string}
 */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.getTemplates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetRecommendTemplatesReply.prototype.setTemplates = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.CheckUserTemplatePermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.CheckUserTemplatePermissionRequest}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.CheckUserTemplatePermissionRequest;
  return proto.advancedtemplate.CheckUserTemplatePermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.CheckUserTemplatePermissionRequest}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.CheckUserTemplatePermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 templateId = 2;
 * @return {number}
 */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.CheckUserTemplatePermissionRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.CheckUserTemplatePermissionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.CheckUserTemplatePermissionReply}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.CheckUserTemplatePermissionReply;
  return proto.advancedtemplate.CheckUserTemplatePermissionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.CheckUserTemplatePermissionReply}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.CheckUserTemplatePermissionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.CheckUserTemplatePermissionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.CheckUserTemplatePermissionReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetLatestTemplateByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetLatestTemplateByIdRequest}
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetLatestTemplateByIdRequest;
  return proto.advancedtemplate.GetLatestTemplateByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetLatestTemplateByIdRequest}
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetLatestTemplateByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetLatestTemplateByIdRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetLatestTemplateByIdRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetLatestTemplateByIdReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isbuy: jspb.Message.getFieldWithDefault(msg, 3, false),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetLatestTemplateByIdReply}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetLatestTemplateByIdReply;
  return proto.advancedtemplate.GetLatestTemplateByIdReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetLatestTemplateByIdReply}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsbuy(value);
      break;
    case 4:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetLatestTemplateByIdReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetLatestTemplateByIdReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsbuy();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isBuy = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.getIsbuy = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.setIsbuy = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Template template = 4;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 4));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetLatestTemplateByIdReply.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.IncrementTemplateUsageCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clienttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.advancedtemplate.TemplateUsageCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.IncrementTemplateUsageCountRequest}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.IncrementTemplateUsageCountRequest;
  return proto.advancedtemplate.IncrementTemplateUsageCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.IncrementTemplateUsageCountRequest}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.advancedtemplate.EnumClientType} */ (reader.readEnum());
      msg.setClienttype(value);
      break;
    case 2:
      var value = new proto.advancedtemplate.TemplateUsageCount;
      reader.readMessage(value,proto.advancedtemplate.TemplateUsageCount.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.IncrementTemplateUsageCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClienttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.advancedtemplate.TemplateUsageCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnumClientType clientType = 1;
 * @return {!proto.advancedtemplate.EnumClientType}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.getClienttype = function() {
  return /** @type {!proto.advancedtemplate.EnumClientType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumClientType} value */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.setClienttype = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated TemplateUsageCount data = 2;
 * @return {!Array<!proto.advancedtemplate.TemplateUsageCount>}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.getDataList = function() {
  return /** @type{!Array<!proto.advancedtemplate.TemplateUsageCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.TemplateUsageCount, 2));
};


/** @param {!Array<!proto.advancedtemplate.TemplateUsageCount>} value */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.advancedtemplate.TemplateUsageCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.TemplateUsageCount}
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.advancedtemplate.TemplateUsageCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.IncrementTemplateUsageCountRequest.prototype.clearDataList = function() {
  this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TemplateUsageCount.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TemplateUsageCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TemplateUsageCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateUsageCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TemplateUsageCount}
 */
proto.advancedtemplate.TemplateUsageCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TemplateUsageCount;
  return proto.advancedtemplate.TemplateUsageCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TemplateUsageCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TemplateUsageCount}
 */
proto.advancedtemplate.TemplateUsageCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TemplateUsageCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TemplateUsageCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TemplateUsageCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateUsageCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.TemplateUsageCount.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateUsageCount.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.advancedtemplate.TemplateUsageCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateUsageCount.prototype.setCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.IncrementTemplateUsageCountReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.IncrementTemplateUsageCountReply}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.IncrementTemplateUsageCountReply;
  return proto.advancedtemplate.IncrementTemplateUsageCountReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.IncrementTemplateUsageCountReply}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.IncrementTemplateUsageCountReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.IncrementTemplateUsageCountReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesRequest}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesRequest;
  return proto.advancedtemplate.GetIncrementalTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesRequest}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentversion(value);
      break;
    case 2:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 currentVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.getCurrentversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.setCurrentversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EnumSourceType sourceType = 2;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetIncrementalTemplatesRequest.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetIncrementalTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.TemplatesResultDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesReply}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetIncrementalTemplatesReply;
  return proto.advancedtemplate.GetIncrementalTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetIncrementalTemplatesReply}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.TemplatesResultDetail;
      reader.readMessage(value,proto.advancedtemplate.TemplatesResultDetail.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetIncrementalTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.TemplatesResultDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TemplatesResultDetail result = 3;
 * @return {?proto.advancedtemplate.TemplatesResultDetail}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.TemplatesResultDetail} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.TemplatesResultDetail, 3));
};


/** @param {?proto.advancedtemplate.TemplatesResultDetail|undefined} value */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetIncrementalTemplatesReply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TemplatesResultDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TemplatesResultDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TemplatesResultDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplatesResultDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionversion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templates: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TemplatesResultDetail}
 */
proto.advancedtemplate.TemplatesResultDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TemplatesResultDetail;
  return proto.advancedtemplate.TemplatesResultDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TemplatesResultDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TemplatesResultDetail}
 */
proto.advancedtemplate.TemplatesResultDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductionversion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TemplatesResultDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TemplatesResultDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TemplatesResultDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplatesResultDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionversion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTemplates();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 productionVersion = 1;
 * @return {number}
 */
proto.advancedtemplate.TemplatesResultDetail.prototype.getProductionversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplatesResultDetail.prototype.setProductionversion = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string templates = 2;
 * @return {string}
 */
proto.advancedtemplate.TemplatesResultDetail.prototype.getTemplates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.TemplatesResultDetail.prototype.setTemplates = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcetype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSigRequest}
 */
proto.advancedtemplate.GetSigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSigRequest;
  return proto.advancedtemplate.GetSigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSigRequest}
 */
proto.advancedtemplate.GetSigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.advancedtemplate.EnumSourceType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional EnumSourceType sourceType = 1;
 * @return {!proto.advancedtemplate.EnumSourceType}
 */
proto.advancedtemplate.GetSigRequest.prototype.getSourcetype = function() {
  return /** @type {!proto.advancedtemplate.EnumSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.advancedtemplate.EnumSourceType} value */
proto.advancedtemplate.GetSigRequest.prototype.setSourcetype = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetSigReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetSigReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetSigReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSigReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sig: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetSigReply}
 */
proto.advancedtemplate.GetSigReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetSigReply;
  return proto.advancedtemplate.GetSigReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetSigReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetSigReply}
 */
proto.advancedtemplate.GetSigReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetSigReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetSigReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetSigReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetSigReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSig();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetSigReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetSigReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetSigReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetSigReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sig = 3;
 * @return {string}
 */
proto.advancedtemplate.GetSigReply.prototype.getSig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetSigReply.prototype.setSig = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPurchasedTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateidList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPurchasedTemplatesRequest}
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPurchasedTemplatesRequest;
  return proto.advancedtemplate.GetPurchasedTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPurchasedTemplatesRequest}
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPurchasedTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 templateId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPurchasedTemplatesRequest.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPurchasedTemplatesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    templates: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPurchasedTemplatesReply}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPurchasedTemplatesReply;
  return proto.advancedtemplate.GetPurchasedTemplatesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPurchasedTemplatesReply}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPurchasedTemplatesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplates();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string templates = 3;
 * @return {string}
 */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.getTemplates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPurchasedTemplatesReply.prototype.setTemplates = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPurchasedTemplateIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPurchasedTemplateIdsRequest;
  return proto.advancedtemplate.GetPurchasedTemplateIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPurchasedTemplateIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPurchasedTemplateIdsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.advancedtemplate.GetPurchasedTemplateIdsData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsReply}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPurchasedTemplateIdsReply;
  return proto.advancedtemplate.GetPurchasedTemplateIdsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsReply}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.GetPurchasedTemplateIdsData;
      reader.readMessage(value,proto.advancedtemplate.GetPurchasedTemplateIdsData.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPurchasedTemplateIdsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.advancedtemplate.GetPurchasedTemplateIdsData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GetPurchasedTemplateIdsData result = 3;
 * @return {?proto.advancedtemplate.GetPurchasedTemplateIdsData}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.getResult = function() {
  return /** @type{?proto.advancedtemplate.GetPurchasedTemplateIdsData} */ (
    jspb.Message.getWrapperField(this, proto.advancedtemplate.GetPurchasedTemplateIdsData, 3));
};


/** @param {?proto.advancedtemplate.GetPurchasedTemplateIdsData|undefined} value */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.setResult = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.clearResult = function() {
  this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsReply.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetPurchasedTemplateIdsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateidList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsData}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetPurchasedTemplateIdsData;
  return proto.advancedtemplate.GetPurchasedTemplateIdsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetPurchasedTemplateIdsData}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetPurchasedTemplateIdsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 templateId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetPurchasedTemplateIdsData.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplateStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplateStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateidList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplateStatisticsRequest}
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplateStatisticsRequest;
  return proto.advancedtemplate.GetTemplateStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplateStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplateStatisticsRequest}
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplateStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplateStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 templateId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetTemplateStatisticsRequest.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetTemplateStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetTemplateStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statisticsList: jspb.Message.toObjectList(msg.getStatisticsList(),
    proto.advancedtemplate.TemplateStatistics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetTemplateStatisticsResponse}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetTemplateStatisticsResponse;
  return proto.advancedtemplate.GetTemplateStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetTemplateStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetTemplateStatisticsResponse}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.advancedtemplate.TemplateStatistics;
      reader.readMessage(value,proto.advancedtemplate.TemplateStatistics.deserializeBinaryFromReader);
      msg.addStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetTemplateStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetTemplateStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.advancedtemplate.TemplateStatistics.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TemplateStatistics statistics = 3;
 * @return {!Array<!proto.advancedtemplate.TemplateStatistics>}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.getStatisticsList = function() {
  return /** @type{!Array<!proto.advancedtemplate.TemplateStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.TemplateStatistics, 3));
};


/** @param {!Array<!proto.advancedtemplate.TemplateStatistics>} value */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.setStatisticsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.TemplateStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.TemplateStatistics}
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.addStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.TemplateStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetTemplateStatisticsResponse.prototype.clearStatisticsList = function() {
  this.setStatisticsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TemplateStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TemplateStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TemplateStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usagecount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    previewcount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TemplateStatistics}
 */
proto.advancedtemplate.TemplateStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TemplateStatistics;
  return proto.advancedtemplate.TemplateStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TemplateStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TemplateStatistics}
 */
proto.advancedtemplate.TemplateStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsagecount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TemplateStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TemplateStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TemplateStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TemplateStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsagecount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPreviewcount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.TemplateStatistics.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateStatistics.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 usageCount = 2;
 * @return {number}
 */
proto.advancedtemplate.TemplateStatistics.prototype.getUsagecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateStatistics.prototype.setUsagecount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 previewCount = 3;
 * @return {number}
 */
proto.advancedtemplate.TemplateStatistics.prototype.getPreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.TemplateStatistics.prototype.setPreviewcount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateTemplateStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateTemplateStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usagecount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    previewcount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateTemplateStatisticsRequest}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateTemplateStatisticsRequest;
  return proto.advancedtemplate.UpdateTemplateStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateTemplateStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateTemplateStatisticsRequest}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsagecount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateTemplateStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateTemplateStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsagecount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPreviewcount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 usageCount = 2;
 * @return {number}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.getUsagecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.setUsagecount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 previewCount = 3;
 * @return {number}
 */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.getPreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateTemplateStatisticsRequest.prototype.setPreviewcount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.advancedtemplate);

import React from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table
} from 'antd';
import {Link} from 'react-router-dom';
import './layout.css';
import {
    AddSeriesRequest,
    AdvancedTemplateService,
    DeleteSeriesRequest,
    EmptyRequest,
    GetSeriesRequest,
    OrderSeries,
    Series,
    UpdateOrderSeriesRequest,
    UpdateRecommendRequest,
    UpdateSeriesRequest
} from "./grpcweb/AdvancedTemplateService";

const {Content} = Layout;
const {Option} = Select;

export default class SeriesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contentText: '',
            //渲染模版查询列表
            seriesList: [],
            visible: false,
            seriesid: 0,
            name: '',
            opt: '',
            firstSeries: [],
            seriesTypes: [],
            seriesType: 0,
            seriesTypeShow: '',
            baiduTagLinkShow: '',
            updateSeriesId: '',
            order: '',
            currentRecommend: '',
            perRecommend: '',
            hasBadge: '',
            disableUpdateOrderBtn: false,
            disableSaveRecommendBtn: false,
            initialValueName: '',
            returnSeriesId: 0,
            terminalType: 0,
            platform: 0,
            currewntUrl: '',
        };
        console.log('constructor:props=', props)
    };

    firstSeriesItems = () => {
        return this.state.firstSeries.map(
            (item) => (<Option key={item.seriesid} value={item.seriesid}>{item.name}</Option>)
        )
    };

    showModal = (record, opt) => {
        this.setState({
                visible: true,
                opt: opt,
            }, () => {
                console.log("INFO: : showModal ")
            }
        );
        if (opt === 'update') {
            this.setState({
                updateSeriesId: record.seriesid,
                name: record.name,
                seriesType: record.seriestype,
                order: record.order,
                hasBadge: record.hasbadge,
            }, () => {
                console.log("INFO: : update ")
            });
        }
        if (opt === 'add') {
            this.setState({
                name: '',
                seriesType: this.state.initialValueName === '一级系列' ? 1 : this.state.seriesType,
                order: '',
                hasBadge: 'NO',
            }, () => {
                console.log("visible: " + this.state.visible + " name :" + this.state.name)
            });
        }
    };

    handleOk = () => {
        this.setState({
            visible: false,
            name: '',
            updateSeriesId: '',
            opt: '',
            order: ''
        }, () => {
            console.log("INFO: : handleOk ")
        });
        if (this.state.opt === 'add') {
            this.addSeries();
        } else {
            this.updateSeries();
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            name: '',
            updateSeriesId: '',
            opt: '',
            order: '',
        }, () => {
            console.log("INFO: : handleCancel ")
        });
    };

    // 请求系列列表
    querySeriesList = (seriesId, terminalType, platform) => {
        console.log("querySeriesList terminalType, seriesId, platform: ",
            terminalType, seriesId, platform);
        let request = new GetSeriesRequest();
        request.setSeriesid(seriesId);
        request.setTerminaltype(terminalType);
        request.setPlatform(platform);
        AdvancedTemplateService.getSubSeries(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                console.log("response: " + JSON.stringify(res));
                let isRecommend = false;
                res.seriesList.map((series) => {
                    if (series.parent === 0) {
                        series.level = '一级系列';
                    } else {
                        series.level = '二级子系列';
                    }
                    if (series.isrecommend === "YES") {
                        this.setState({
                            currentRecommend: series.seriesid,
                            perRecommend: series.seriesid,
                        }, () => {
                            console.log('perRecommend:', series.seriesid)
                        });
                        series.checked = true;
                        isRecommend = true;
                    } else {
                        series.checked = false;
                    }
                    if (series.hasbadge === 'YES') {
                        series.hasbadgeName = '是';
                    } else {
                        series.hasbadgeName = '否';
                    }
                    if (series.parent !== 0) {
                        series.id = series.parent + '-' + series.seriesid;
                    } else {
                        series.id = series.seriesid;
                    }
                    series.seriestypedesc = this.state.seriesTypes.map((item, i) => {
                        if (item.type === series.seriestype) {
                            return item.desc;
                        }
                    })
                });
                console.log("seriesId: " + this.state.seriesid);
                // debugger;
                if (isRecommend === false) {
                    this.setState(
                        {
                            currentRecommend: '',
                            perRecommend: '',
                        });
                }
                if (seriesId === 0 && this.state.returnSeriesId === 0) {
                    this.setState(
                        {
                            firstSeries: res.seriesList,
                        });
                }
                console.log("res.seriesList: " + res.seriesList);
                this.setState(
                    {
                        seriesList: res.seriesList,
                    }
                );
            }
        });
    };

    // 查询系列类型
    querySeriesType = () => {
        let request = new EmptyRequest();
        AdvancedTemplateService.querySeriesType(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    console.log("querySeriesType response: " + JSON.stringify(res));
                    this.setState({seriesTypes: res.seriestypeList});
                }
            }
        });
    }

    //删除系列
    delelteSeries = (seriesid) => {
        console.log("INFO:delelteSeries : ", seriesid);
        let request = new DeleteSeriesRequest();
        request.setSeriesid(seriesid);

        AdvancedTemplateService.deleteSeries(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    message.success('删除成功');
                    this.setState({returnSeriesId: 0});
                    this.querySeriesList(this.state.seriesid, this.state.terminalType, this.state.platform);
                }
            }
        });
    };

    addSeries = () => {
        console.log("INFO:addSeries : " + this.state.name);
        let checkMassage = this.checkSeries();
        console.log("INFO:checkMassage : " + checkMassage);
        if (checkMassage !== '') {
            message.error(checkMassage);
            return '';
        }
        let request = new AddSeriesRequest();
        let series = new Series();
        series.setParent(this.state.seriesid);
        series.setName(this.state.name);
        series.setSeriestype(this.state.seriesType);
        series.setHasbadge(this.state.hasBadge);
        // debugger;
        console.log("terminalType :", this.state.terminalType);
        series.setTerminaltype(this.state.terminalType);
        series.setPlatform(this.state.platform);
        request.setSeries(series);
        AdvancedTemplateService.addSeries(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    message.success('添加成功');
                    // debugger;
                    this.querySeriesList(this.state.seriesid, this.state.terminalType, this.state.platform);
                }
            }
        });
    }

    checkSeries = () => {
        if (this.state.name === '') {
            return '系列名称不能为空';
        } else if (this.state.name.length > 10) {
            return '系列名称不能大于10字';
        }
        return '';
    }

    updateSeries = () => {
        console.log("INFO:updateSeries" + this.state.name);
        let checkMassage = this.checkSeries();
        console.log("INFO:checkMassage : " + checkMassage);
        if (checkMassage !== '') {
            message.error(checkMassage);
            return '';
        }
        let request = new UpdateSeriesRequest();
        let series = new Series();
        series.setSeriesid(this.state.updateSeriesId);
        series.setParent(this.state.seriesid);
        series.setName(this.state.name);
        series.setSeriestype(this.state.seriesType);
        series.setOrder(this.state.order);
        series.setHasbadge(this.state.hasBadge);
        request.setSeries(series);
        console.log(request);
        AdvancedTemplateService.updateSeries(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    message.success('修改成功');
                    this.querySeriesList(this.state.seriesid, this.state.terminalType, this.state.platform);
                }
            }
        });
    }

    saveRecommend = () => {
        console.log("INFO:saveRecommend" + this.state.currentRecommend + "perRecommend" + this.state.perRecommend);
        if (this.state.currentRecommend === this.state.perRecommend) {
            message.success("推荐位无变化，无需保存");
        } else {
            this.setState({disableSaveRecommendBtn: true}, () => {
                let request = new UpdateRecommendRequest();
                request.setCurrentseriesid(this.state.currentRecommend);
                request.setPreseriesid(this.state.perRecommend);
                console.log(request);
                AdvancedTemplateService.updateRecommend(request, {}, (err, response) => {
                    const res = response.toObject();
                    console.log("response: ");
                    console.log(res);
                    if (err) {
                        console.log("err.code " + err.code);
                        message.error(err.message);
                    } else {
                        if (res.code !== 200) {
                            message.error(res.message);
                        } else {
                            message.success('设置成功');
                            this.querySeriesList(this.state.seriesid, this.state.terminalType, this.state.platform);
                        }
                    }
                    this.setState({disableSaveRecommendBtn: false});
                });
            });
        }
    }

    updateOrderSeries = () => {
        console.log('排序');
        let arr = [];
        this.state.seriesList.map((seriesOrder) => {
            arr.push(seriesOrder.order);
        });
        console.log(arr);
        var arr2 = arr.filter((x, index, self) => self.indexOf(x) === index)
        console.log(arr.length);
        console.log(arr2.length);
        if (arr.length !== arr2.length) {
            message.error('存在重复序号，请检查');
        } else {
            this.setState({disableUpdateOrderBtn: true}, () => {
                let seriesOrderList = this.state.seriesList;
                let request = new UpdateOrderSeriesRequest();
                seriesOrderList.map((seriesOrder) => {
                    let order = new OrderSeries();
                    order.setSeriesid(seriesOrder.seriesid);
                    order.setOrder(seriesOrder.order);
                    request.addOrderseries(order);
                });
                AdvancedTemplateService.updateOrderSeries(request, {}, (err, response) => {
                    const res = response.toObject();
                    console.log("response: ");
                    console.log(res);
                    if (err) {
                        console.log("err.code " + err.code);
                        message.error(err.message);
                    } else {
                        if (res.code !== 200) {
                            message.error(res.message);
                        } else {
                            message.success('保存成功');
                            this.querySeriesList(this.state.seriesid, this.state.terminalType, this.state.platform);
                        }
                    }
                    this.setState({disableUpdateOrderBtn: false});
                });
            });
        }
    }

    //用户选择一级系列后的动作
    handleFirstSeriesChange = (value) => {
        console.log(`selected ${value}`);
        let seriesId = (typeof value == "undefined") ? 0 : value;
        this.querySeriesList(seriesId, this.state.terminalType, this.state.platform);
        this.setState({seriesid: seriesId});
        if (parseInt(value) === 0) {
            this.setState({
                initialValueName: '一级系列',
                seriesTypeShow: '',
                seriesType: 0
            });
        } else {
            this.state.firstSeries.forEach((item) => {
                if (item.seriesid === seriesId) {
                    this.setState({
                        initialValueName: item.name,
                        seriesTypeShow: 'none',
                        seriesType: item.seriestype
                    });
                }
            });
        }
    }

    componentDidMount() {
        let seriesId = (typeof this.props.match.params.seriesId == "undefined") ?
            0 : parseInt(this.props.match.params.seriesId);

        let terminalType = 0;
        if (this.props.location.pathname.indexOf('pc', 0) > -1) {
            terminalType = 0;
        } else {
            terminalType = 1;
        }

        let platform = 0;
        if (this.props.location.pathname.indexOf('yxbj', 0) > -1) {
            platform = 1;
        } else if (this.props.location.pathname.indexOf('yxtj', 0) > -1) {
            platform = 2;
        }

        this.setState({
            returnSeriesId: seriesId,
            currewntUrl: this.props.location.pathname,
            terminalType: terminalType,
            platform: platform,
        }, () => {
            console.log('init')
        });
        console.log("componentDidMount terminalType, seriesId, platform: ",
            terminalType, seriesId, platform);
        this.querySeriesType();
        this.handleReturnSeries(seriesId, terminalType, platform);
        this.querySeriesList(seriesId, terminalType, platform);
    };

    componentWillReceiveProps(nextprops) {
        console.log('nextprops', nextprops);
        // state : currewntUrl  (pc) (md)
        if (this.state.currewntUrl !== nextprops.location.pathname) {
            // debugger;
            let seriesId = (typeof nextprops.match.params.seriesId == "undefined") ?
                0 : parseInt(nextprops.match.params.seriesId);

            let terminalType = 0;
            if (nextprops.location.pathname.indexOf('pc', 0) > -1) {
                terminalType = 0;
            } else {
                terminalType = 1;
            }
            console.log('terminalType', terminalType);

            let platform = 0;
            if (nextprops.location.pathname.indexOf('yxbj', 0) > -1) {
                platform = 1;
            } else if (nextprops.location.pathname.indexOf('yxtj', 0) > -1) {
                platform = 2;
            }

            this.setState({
                returnSeriesId: seriesId,
                currewntUrl: nextprops.location.pathname,
                terminalType: terminalType,
                platform: platform,
            }, () => {
                console.log('init')
            });
            console.log("componentWillReceiveProps terminalType, seriesId, platform: ",
                terminalType, seriesId, platform);
            this.handleReturnSeries(seriesId, terminalType, platform);
            this.querySeriesList(seriesId, terminalType, platform);
        }
    };

    handleReturnSeries = (seriesId, terminalType, platform) => {

        if (seriesId === 0) {
            this.setState({initialValueName: "一级系列"});
            return;
        }

        if (this.state.firstSeries.length === 0) {
            let request = new GetSeriesRequest();
            request.setSeriesid(0);
            request.setTerminaltype(terminalType)
            request.setPlatform(platform)
            AdvancedTemplateService.getSubSeries(request, {}, (err, response) => {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                } else {
                    // debugger
                    console.log("response: " + JSON.stringify(res));
                    res.seriesList.map((series) => {
                        if (series.seriesid === seriesId) {
                            this.setState({initialValueName: series.name});
                        }
                    });
                    this.setState(
                        {
                            firstSeries: res.seriesList,
                        });
                }
            });
        } else {
            this.state.firstSeries.map((item) => {
                if (item.seriesid === seriesId) {
                    this.setState({initialValueName: item.name});
                }
            })
        }
    }

    //系列名称时触发
    handleSeriesNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
        console.log("系列名称：" + this.state.name);
    };

    onClickRecommend = (e, record) => {
        console.log('radio checked ', e.target.value);
        console.log('currentRecommend befor ', this.state.currentRecommend);
        let currentRecommend = ' ';
        if (e.target.value === this.state.currentRecommend) {
            this.setState({
                currentRecommend: ' ',
            }, () => {
                console.log("INFO: : currentRecommend after " + currentRecommend)
            });
        } else {
            currentRecommend = e.target.value;
            this.setState({
                currentRecommend: e.target.value,
            }, () => {
                console.log("INFO: : currentRecommend after " + currentRecommend)
            });
        }

        let recommendList = this.state.seriesList;
        recommendList.map((recommend) => {
            if ((recommend.seriesid === record.seriesid) && currentRecommend !== ' ') {
                recommend.checked = true;
            } else {
                recommend.checked = false;
            }
        });
    };

    onChangeBadge = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            hasBadge: e.target.value,
        });
    };

    onChangeOrder = (value, record) => {
        console.log('changed', value);
        let seriesOrderList = this.state.seriesList;
        seriesOrderList.map((seriesOrder) => {
            if (seriesOrder.seriesid === record.seriesid) {
                seriesOrder.order = value;
            }
        });
        this.setState(
            {
                seriesList: seriesOrderList,

            });
        console.log('changed', record.seriesid);
    }

    render() {
        return (
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系列管理</Breadcrumb.Item>
                    {this.state.terminalType === 0 ?
                        <Breadcrumb.Item>桌面端系列管理</Breadcrumb.Item>
                        :
                        <Breadcrumb.Item>移动端系列管理</Breadcrumb.Item>
                    }
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Row gutter={20}>
                        <Col span={2} offset={1}>
                            当前所属：
                        </Col>
                        <Col span={1}>
                            <Select value={this.state.initialValueName} style={{width: 120}}
                                    onChange={this.handleFirstSeriesChange} allowClear>
                                <Option value="0">一级系列</Option>
                                {this.firstSeriesItems()}
                            </Select>
                        </Col>
                        <Col span={1} offset={10}>
                            <Button type="primary" onClick={() => this.showModal(0, 'add')}>新增系列</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" disabled={this.state.disableUpdateOrderBtn}
                                    onClick={this.updateOrderSeries}>保存排序</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" disabled={this.state.disableSaveRecommendBtn}
                                    onClick={this.saveRecommend}>设置推荐</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                    <div>
                        <Table pagination={false}
                               columns={[
                                   {
                                       title: '推荐',
                                       key: 'recommendState',
                                       render: (text, record) => {
                                           return (
                                               <div>
                                                   {record.parent !== 0 ?
                                                       (<Radio disabled={false}
                                                               onClick={(event) => this.onClickRecommend(event, record)}
                                                               value={record.seriesid}
                                                               checked={record.checked}></Radio>)
                                                       :
                                                       (<Radio disabled={true} onClick={this.onClick}
                                                               value={record.seriesid}></Radio>)}
                                               </div>
                                           );
                                       }
                                   },
                                   {
                                       title: '顺序',
                                       dataIndex: 'order',
                                       render: (text, record) => {
                                           return (
                                               <InputNumber type="text" min={1} value={record.order.toString()}
                                                            onChange={(event) => this.onChangeOrder(event, record)}/>
                                           );
                                       }
                                   },
                                   {
                                       title: '系列ID',
                                       dataIndex: 'id',
                                   },
                                   {
                                       title: '名称',
                                       dataIndex: 'name',
                                   },
                                   {
                                       title: '类型',
                                       dataIndex: 'seriestypedesc',
                                   },
                                   {
                                       title: '层级',
                                       dataIndex: 'level',
                                   },
                                   {
                                       title: '图标显示',
                                       dataIndex: 'hasbadgeName',
                                   },
                                   {
                                       title: '操作',
                                       key: 'action',
                                       render: (text, record) => {
                                           return (
                                               <div>
                                                <span>
                                                    <Popconfirm title="确定删除？" title="确定删除？"
                                                                onConfirm={() => this.delelteSeries(record.seriesid)}
                                                                okText="确定" cancelText="取消">
                                                        <a style={{marginRight: 16}}>删除</a>
                                                    </Popconfirm>
                                                    <div>
                                                        <a style={{marginRight: 16}}
                                                           onClick={() => this.showModal(record, 'update')}>编辑</a>
                                                    </div>
                                                    {record.parent !== 0 && (
                                                        <a style={{marginRight: 16}}>
                                                            <Link to={{
                                                                pathname: '/advanced-template-et/series/bound-template',
                                                                state: {
                                                                    seriesid: record.seriesid,
                                                                    parent: record.parent,
                                                                    name: record.name,
                                                                    terminalType: this.state.terminalType,
                                                                    platform: this.state.platform,
                                                                }
                                                            }}>
                                                                <span>挂载模版</span>
                                                            </Link>
                                                        </a>
                                                    )}
                                                </span>

                                               </div>
                                           )
                                       }
                                   },
                               ]
                               }
                               dataSource={this.state.seriesList}
                               pagination={false}/>
                    </div>
                    <div>
                        <Modal title="增加系列" visible={this.state.visible} onOk={this.handleOk}
                               onCancel={this.handleCancel}>
                            <Row gutter={24}>
                                <Col span={8}> 系列名称：*(6字符)</Col>
                                <Col span={16}>
                                    <Input type="text" value={this.state.name.toString()} maxLength="6"
                                           onChange={this.handleSeriesNameChange}/>
                                </Col>
                            </Row>
                            <div style={{display: this.state.seriesTypeShow, paddingTop: '5px', paddingButton: '5px'}}>
                                <Row gutter={24}>
                                    <Col span={8}> 系列类型：</Col>
                                    <Col span={8}>
                                        <Select value={this.state.seriesType} style={{width: 120}}
                                                onChange={(value) => {
                                                    this.setState({seriesType: value})
                                                }}>
                                            {
                                                this.state.seriesTypes.length > 0 && this.state.seriesTypes.map((item, i) => {
                                                    return (
                                                        <Option key={item.type} value={item.type}>{item.desc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    {/*<Col span={8}>*/}
                                        {/*<a href={"https://docs.google.com/document/d/1JJYDaroONGAQ3a1qteI9nxrmw2vvpRKns4Aaf9qbwJo/edit"}*/}
                                           {/*target={"_blank"}*/}
                                           {/*style={{display: this.state.seriesType === 2 ? '' : 'none'}}>百度文库可选tag列表</a>*/}
                                    {/*</Col>*/}
                                </Row>
                            </div>
                            <Row gutter={24}>
                                <Col span={8}> 是否有new图标： </Col>
                                <Col span={4}>
                                    <Radio.Group onChange={this.onChangeBadge} value={this.state.hasBadge}>
                                        <Radio value={"YES"}>是</Radio><Radio value={"NO"}>否</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </Modal>
                    </div>
                </div>
            </Content>
        );
    }
}
import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Tree, Button, message, Switch
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService, GetDraftSeriesRequest, PublishSeriesRequest,
} from "./grpcweb/AdvancedTemplateService";

const { Content } = Layout;
const { TreeNode } = Tree;

export default class PublishSeriesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //查询回待发布的系列-json
            draftSeriesJson: '',
            //选中的系列之父系列id
            // selectedSeries: [],
            disablePublishBtn: false,
            currewntUrl: '',
            terminalType: 0,
            platform: 0,
            defaultCheckedSeries: []
        };
    };

    componentDidMount = () => {
       
        let terminalType = 0;

        if(this.props.location.pathname.indexOf('pc',0) > -1){
            terminalType = 0;
        }
        else {
            terminalType = 1;
        }
        let platform = 0;
        if (this.props.location.pathname.indexOf('yxbj', 0) > -1) {
            platform = 1;
        } else if (this.props.location.pathname.indexOf('yxtj', 0) > -1) {
            platform = 2;
        }
        this.setState({
            currewntUrl: this.props.location.pathname,
            terminalType: terminalType,
            platform: platform,
        });
        this.getDraftSeries(terminalType, platform);
    };

    componentWillReceiveProps(nextprops) {
        console.log('nextprops', nextprops);
        // state : currewntUrl  (pc) (md)
        if(this.state.currewntUrl !== nextprops.location.pathname) {

            let terminalType = 0;

            if(nextprops.location.pathname.indexOf('pc',0) > -1){
                terminalType = 0;
            }
            else {
                terminalType = 1;
            }
            let platform = 0;
            if (nextprops.location.pathname.indexOf('yxbj', 0) > -1) {
                platform = 1;
            } else if (nextprops.location.pathname.indexOf('yxtj', 0) > -1) {
                platform = 2;
            }
            console.log('terminalType', terminalType);

            this.setState({
                currewntUrl: nextprops.location.pathname,
                terminalType: terminalType,
                platform: platform,
            });
            this.getDraftSeries(terminalType, platform);
        }
    };

    /**
      * 查询待发布系列
      */
    getDraftSeries = (terminalType, platform) => {
        console.log("props is ==> ",this.props);
        let request = new GetDraftSeriesRequest();
        request.setTerminaltype(terminalType);
        request.setPlatform(platform);
        AdvancedTemplateService.getDraftSeries(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                // debugger
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    this.setState({
                        draftSeriesJson: res.result,
                    }, () => {
                        console.log("this.state.draftSeriesJson " + this.state.draftSeriesJson);
                    });
                }
            }
        });
    };

    /**
       * 发布选中系列
       */
    publishSelectedSeries = (selectedSeriesIds) => {
        this.setState({ disablePublishBtn: true }, () => {
            let request = new PublishSeriesRequest();
            request.setTerminaltype(this.state.terminalType);
            request.setPlatform(this.state.platform);
            selectedSeriesIds.forEach((item) => {
                request.addSeriesid(parseInt(item));
            });
            AdvancedTemplateService.publishSeries(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    switch (res.code) {
                        case 200:
                            message.success('发布系列成功');
                            this.getDraftSeries(this.state.terminalType, this.state.platform);
                            break;
                        default:
                            message.error(res.message);
                            break;
                    }
                }
                this.setState({ disablePublishBtn: false });
            });
        });
    };

    onCheck = (checkedKeys, info) => {
        // debugger
        this.setState({
            defaultCheckedSeries: checkedKeys,
        });
    };

    onSwitchChange = (checked) => {
        let checkAllSeries = [];
        if(checked) {
            if (this.state.draftSeriesJson && this.state.draftSeriesJson.length > 2) {
                let seriesInfo = [];
                seriesInfo = JSON.parse(this.state.draftSeriesJson);
                seriesInfo.forEach((ele) => {
                    let rootCheckable = this.isRootCheckable(ele);
                    if (rootCheckable) {
                        checkAllSeries.push('' + ele.seriesId);
                    }
                });
                console.log("checkAllSeries " + JSON.stringify(checkAllSeries));
            }
        }
        this.setState({
            defaultCheckedSeries: checkAllSeries,
        });
    };

    renderSelectAllBtn = () => {
       if(this.props.location.pathname.indexOf('yxtj', 0) > -1){
           return (
               <p>
               <font size="2" face="Verdana">
                   *图记只能发布一个系列
               </font>
           </p>
           );
       }
        return (
            <Row gutter={16} align="middle">
                <Col span={10} offset={4}>
                    <p>
                        <font size="2" face="Verdana">
                            全选
                        </font>
                    </p>
                    <Switch size="small" onChange={this.onSwitchChange} />
                </Col>
            </Row>
        )
    }

    // 渲染发布系列的系列树
    renderTreeNode = (draftSeriesJson) => {
        if (!draftSeriesJson && draftSeriesJson.length < 3) {
            return;
        }
        let seriesInfo = [];
        seriesInfo = JSON.parse(draftSeriesJson);
        let treeNode = [];
        seriesInfo.forEach((ele) => {
            let rootCheckable = this.isRootCheckable(ele);
            treeNode.push(
                <TreeNode checkable={rootCheckable} key={ele.seriesId} title={ele.name} >
                    {this.renderChild(ele.subseriesInfo)}
                </TreeNode>
            );
        });
        return treeNode;
    }
    //渲染子结点
    renderChild = (list) => {
        if (!list || typeof (list) === "undefined") {
            return;
        }
        let childLevel = [];
        list.forEach((ele) => {
            childLevel.push(
                <TreeNode checkable={false} key={ele.seriesId} title={ele.name} >
                    {this.renderGrandChild(ele.templates)}
                </TreeNode>
            );
        });
        return childLevel;
    }
    //渲染孙结点
    renderGrandChild = (list) => {
        if (!list || typeof (list) === "undefined") {
            return;
        }
        let grandChildLevel = [];
        list.forEach((ele) => {
            // debugger
            grandChildLevel.push(
                <TreeNode checkable={false} title={ele.title + ' (模版类型:' + ele.templateType + ' 等级:' + ele.serviceLevel + ') '} />
            );
        });
        return grandChildLevel;
    }

    //检查一级系列下是否有叶子结点，有才能被勾选
    isRootCheckable = (node) => {
        if (!node.subseriesInfo || typeof node.subseriesInfo === 'undefined' || node.subseriesInfo.length < 1) {
            return false;
        }
        let result = false;
        if(node.type !== 1) {
            // 系列类型不为印象笔记时可不包含模板数据
            result = true;
        } else {
            node.subseriesInfo.forEach((ele) => {
                if (ele.templates && typeof ele.templates !== 'undefined' && ele.templates.length > 0) {
                    result = true;
                }
            });
        }
        return result;
    }

    render() {
        console.log('render this.state.defaultCheckedSeries', this.state.defaultCheckedSeries);
        return (
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/advanced-template-verse/template/list">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发布管理</Breadcrumb.Item>
                    {this.state.terminalType === 0 ?
                        <Breadcrumb.Item>Web端发布系列</Breadcrumb.Item>
                        :
                        <Breadcrumb.Item>移动端发布系列</Breadcrumb.Item>
                    }
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <div>
                        <Row gutter={[16, 16]}>
                            <Col span={6} offset={1}>
                                <p>
                                    <font size="4" face="Verdana">
                                        选择要发布的系列:
                                    </font>
                                </p>
                            </Col>
                        </Row>
                        {this.renderSelectAllBtn()}
                        <Row gutter={16} align="middle">
                            <Col span={10} offset={4}>
                                <Tree checkable defaultExpandAll showLine
                                      checkedKeys={this.state.defaultCheckedSeries}
                                      onCheck={this.onCheck}>
                                    {this.renderTreeNode(this.state.draftSeriesJson)}
                                </Tree>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col span={2} offset={11}>
                            <Button type="primary" disabled={this.state.disablePublishBtn}
                                    onClick={() => this.publishSelectedSeries(this.state.defaultCheckedSeries)}>
                                发布
                            </Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                </div>
            </Content>
        );
    }
}
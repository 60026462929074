import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Button, Table,
    Popconfirm, message, Modal, Input, Checkbox, InputNumber
} from 'antd';
import './layout.css';
import {
    OfficialMaterialAdminService,
    Pageable,
    GetMaterialInGroupRequest,
    GetMaterialsRequest,
    MaterialType,UpdateMaterialInGroupRequest,MaterialRelation
} from "./grpcweb/OfficialMaterialAdminService";

const { Content } = Layout;

export default class TemplateRelationEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentText: '',
            relationList: [],
            id: '',
            groupId: 0,
            //添加素材模态框
            addTemplateModalShow: false,
            title: '',
            materialsList: [],
            selectedTableTemplates: [],
            selectedRowKeys: [],
            total: 0,
            current: 1,
            pageSize: 10,
            disableUpdateOrderBtn: false,
            parent: 0,
            materialType: 0
        };
    };

    showAddTemplateModal = () => {
        this.setState(
            {
                addTemplateModalShow: true,
            }
        );
        this.getMaterial(1);
    }

    handleAddTemplateOk = () => {

        if (this.state.selectedTableTemplates.length === 0) {
            message.error('请选择要添加的素材');
            return;
        }

        this.setState(
            {
                addTemplateModalShow: false,
                materialsList: [],
            }
        );
        this.addRelation();

    };

    handleAddTemplateCancel = () => {
        this.setState(
            {
                addTemplateModalShow: false,
                materialsList: [],
            }
        );
    };

    handelTitleChange = (e) => {
        this.setState({
            title: e.target.value
        });
    };

    getMaterial = (page) => {
        let request = new GetMaterialsRequest();
        if (this.state.title !== undefined || this.state.title !== '') {
            request.setTitle(this.state.title);
        }
        let materialType;
        if (this.state.materialType === 2) {
            materialType = MaterialType.WORD_FORM;
        } else if (this.state.materialType === 3) {
            materialType = MaterialType.SHARING_THEMES;
        } else if (this.state.materialType === 4) {
            materialType = MaterialType.SHARING_WATER_MARK;
        } else if (this.state.materialType === 5) {
            materialType = MaterialType.HOME_BACKGROUND;
        }
        request.setType(materialType);
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pageSize);
        pageable.setCurrent(page);
        request.setPageable(pageable);

        OfficialMaterialAdminService.getMaterials(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                console.log("response.Materials: " + JSON.stringify(res.materialsList));
                res.materialsList.map((template) => {
                    this.getTemplateEnumName(template);
                });
                // debugger;
                console.log("INFO:total : ", res.total)
                this.setState(
                    {
                        materialsList: res.materialsList,
                        total: res.total,
                        current: page,
                    },
                    () => console.log("INFO:getMaterial : ", this.state.materialsList)
                );
            }
        });
    };

    // 请求素材列表
    getGroupsRelationMaterial = (seriesid) => {
        debugger;
        let request = new GetMaterialInGroupRequest();
        request.setGroupid(seriesid);

        OfficialMaterialAdminService.getMaterialInGroup(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                console.log("response.getRelation: " + JSON.stringify(res.relationList));
                res.relationList.map((relation) => {
                    this.getTemplateEnumName(relation);
                });

                this.setState(
                    {
                        relationList: res.relationList,
                        groupId: seriesid
                    },
                    () => console.log("INFO:getRelation : ", this.state.relationList)
                );
            }
        });
    };

    getTemplateEnumName = (material) => {
        switch (material.materialtype) {
            case 2:
                material.typeName = 'WORD_FORM';
                break;
            case 3:
                material.typeName = 'SHARING_THEMES';
                break;
            case 4:
                material.typeName = 'SHARING_WATER_MARK';
                break;
            case 5:
                material.typeName = 'HOME_BACKGROUND';
                break;
            default: break;
        }
        switch (material.userlimit) {
            case 1:
                material.servicelevelName = '免费账户';
                break;
            case 2:
                material.servicelevelName = '标准账户';
                break;
            case 3:
                material.servicelevelName = '高级账户';
                break;
            case 5:
                material.servicelevelName = '专业账户';
                break;
            default: break;
        }
    };

    convertToRelation = (material) => {
        let relation = {};
        switch (material.materialtype) {
            case 2:
                relation.typeName = 'WORD_FORM';
                break;
            case 3:
                relation.typeName = 'SHARING_THEMES';
                break;
            case 4:
                relation.typeName = 'SHARING_WATER_MARK';
                break;
            case 5:
                relation.typeName = 'HOME_BACKGROUND';
                break;
            default: break;
        }
        switch (material.userlimit) {
            case 1:
                relation.servicelevelName = '免费账户';
                break;
            case 2:
                relation.servicelevelName = '标准账户';
                break;
            case 3:
                relation.servicelevelName = '高级账户';
                break;
            case 5:
                relation.servicelevelName = '专业账户';
                break;
            default: break;
        }
        relation.materialid = parseInt(material.id);
        relation.materialtitle = material.title;
        relation.order = 0;
        return relation;
    }

    //删除关系成功
    deleteRelation = (record) => {
        let relationList = this.state.relationList;
        if (record.materialid === '') {
            message.error('请选择要删除素材');
            return;
        }
        this.state.relationList.map((selectedMaterial, index) => {
            if(selectedMaterial.materialid === record.materialid) {
                relationList.splice(index,1);
            }
          }
        )
        message.success('成功删除，记得要保存素材才能生效');
        this.setState({relationList: relationList})
    };

    addRelation = () => {
        // debugger;
        console.log("INFO: : addRelation");
        message.success('添加成功，记得要保存素材才能生效');
        let relationList = this.state.relationList;
        this.state.selectedTableTemplates.map((selectedTableTemplate) => {
            let template = this.convertToRelation(selectedTableTemplate);
            relationList.push(template);
        });
        this.setState({relationList: relationList,selectedTableTemplates: []});

    };

    updateMaterial = () => {
        console.log('排序');
        let arr = [];
        this.state.relationList.map((relation) => {
            arr.push(relation.order);
        });
        console.log(arr);
        var arr2 = arr.filter((x, index, self) => self.indexOf(x) === index)
        console.log(arr.length);
        console.log(arr2.length);
        if (arr.length !== arr2.length) {
            message.error('存在重复序号，请检查');
        }
        else {
            this.setState({ disableUpdateOrderBtn: true }, () => {
                let materialOrderList = this.state.relationList;
                let request = new UpdateMaterialInGroupRequest();
                materialOrderList.map((templateOrder) => {

                    let order = new MaterialRelation();
                    order.setMaterialid(templateOrder.materialid);
                    order.setOrder(templateOrder.order);
                    request.addRelation(order);
                });
                request.setGroupid(this.state.groupId);

                OfficialMaterialAdminService.updateMaterialInGroup(request, {}, (err, response) => {
                    const res = response.toObject();
                    console.log("response: ");
                    console.log(res);
                    if (err) {
                        console.log("err.code " + err.code);
                        message.error(err.message);
                    } else {
                        if (res.responsecode !== 0) {
                            message.error(res.msg);
                        }
                        else {
                            message.success('保存成功');
                            this.getGroupsRelationMaterial(this.state.groupId);
                        }
                    }
                    this.setState({ disableUpdateOrderBtn: false });
                });
            });
        }
    }

    componentDidMount() {
        var data = this.props.location.state;
        if (data !== undefined && data.groupId !== undefined) {
            this.setState(
                {
                    parent: data.parent,
                    materialType: data.materialtype,
                    groupId: data.groupId
                });
            this.getGroupsRelationMaterial(data.groupId);
        }
    };

    onChange = (value, record) => {
        console.log('changed : ', value);
        console.log('changed', record.materialid);
        let orderList = this.state.relationList;
        orderList.map((order) => {
            if (order.materialid === record.materialid) {
                order.order = value;
            }
        });
        this.setState(
            {
                relationList: orderList,

            });
        console.log('changed', this.state.groupId);
    };

    onChangePage = page => {
        this.getMaterial(page);
        const selectedRowKeys = [];
        this.setState({ selectedRowKeys });
    };

    //点击返回按钮
    handSeriesContent = () => {
        console.log('您点击了返回');
        this.props.onSeriesContent(this.state.parent);
    };


    render() {

        const { selectedRowKeys } = this.state;
        // 添加笔记-笔记列表项被选中触发
        const templatesRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // debugger;
                this.setState({
                    selectedTableTemplates: selectedRows,
                    selectedRowKeys
                });
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            selectedRowKeys
        };
        let seriesName = this.props.location.state ? this.props.location.state.name : '';
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>系列管理</Breadcrumb.Item>
                    <Breadcrumb.Item>系列与素材</Breadcrumb.Item>
                    <Breadcrumb.Item>{seriesName}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={16}>
                        <Col span={1} offset={1}>
                            <Button type="primary" onClick={this.showAddTemplateModal} >新增素材</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" disabled={this.state.disableUpdateOrderBtn}
                                onClick={this.updateMaterial} >保存素材</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" >
                             <Link to={'/material/series/' + this.state.parent}>
                                    <span>返回</span>
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                    <Table
                        columns={
                            [
                                {
                                    title: '顺序',
                                    dataIndex: 'order',
                                    render: (text, record) => {
                                        return (
                                            <InputNumber type="text" min={1} value={record.order.toString()} onChange={(event) => this.onChange(event, record)} />
                                        );
                                    }
                                },
                                {
                                    title: '素材id',
                                    dataIndex: 'materialid',
                                },
                                {
                                    title: '素材名称',
                                    dataIndex: 'materialtitle',
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'typeName',
                                },
                                {
                                    title: '用户限制等级',
                                    dataIndex: 'servicelevelName',
                                },
                                {
                                    title: '操作',
                                    key: 'action',
                                    render: (text, record) => {
                                        return (
                                            <div>
                                                <span>
                                                    <Popconfirm title="确定从该系列中移除此素材？" title="确定移除素材？" onConfirm={() => this.deleteRelation(record)} okText="确定" cancelText="取消">
                                                        <a style={{ marginRight: 16 }}>移除</a>
                                                    </Popconfirm>
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                            ]
                        }
                        dataSource={this.state.relationList} />
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                    <div>
                        <Modal title="新增素材" visible={this.state.addTemplateModalShow} onOk={this.handleAddTemplateOk} onCancel={this.handleAddTemplateCancel} width={660} okText="新增"
                            cancelText="返回">
                            <Row gutter={16}>
                                <Col span={4} offset={2}> 素材名称</Col>
                                <Col span={10} >
                                    <Input type="text" value={this.state.title} onChange={this.handelTitleChange} />
                                </Col>
                                <Col span={2} offset={1}>
                                    <Button type="primary" style={{ width: 100 }} onClick={() => this.getMaterial(1)}>
                                        查询
                                    </Button>
                                </Col>
                            </Row>
                            <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                            <div>
                                <Table pagination={false}
                                    rowSelection={{
                                        type: Checkbox,
                                        ...templatesRowSelection,
                                    }}
                                    columns={
                                        [
                                            {
                                                title: '素材id',
                                                dataIndex: 'id',
                                            },
                                            {
                                                title: '素材名称',
                                                align: 'center',
                                                dataIndex: 'title',
                                            },
                                            {
                                                title: '类型',
                                                dataIndex: 'typeName',
                                            },
                                            {
                                                title: '用户限制等级',
                                                dataIndex: 'servicelevelName',
                                            }
                                        ]
                                    }
                                    dataSource={this.state.materialsList}
                                    pagination={{
                                        pageSize: this.state.pageSize,
                                        total: this.state.total,
                                        onChange: this.onChangePage,
                                    }}
                                />
                            </div>
                        </Modal>
                    </div>
                </div>
            </Content>
        );
    }

}
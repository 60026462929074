import {Checkbox, Input, Modal, Form, Icon, Select, Upload, message, Button} from "antd";
import React from "react";
import TemplateConfig from 'TemplateConfig';
import fetchService from "../../../utils/FetchService";

const Option = Select.Option;
const LANGUAGE = ["中文", "英文"];
// const Dragger = Upload.Dragger;

const MetadataEditor = Form.create()(

		// eslint-disable-next-line
		class extends React.Component {

			constructor(props) {
				super(props);
				this.state = { isDefault: props.isDefault, badges: props.badges }
			}

			onChange = e => this.setState({isDefault: e.target.checked});

			// 读取图片
			readImage = (img, callback) => {
				const reader = new FileReader();
				reader.addEventListener('load', () => callback(reader.result));
				reader.readAsDataURL(img);
			};

			// 请求徽标列表
			requestBadges = () => {
				fetchService.get(TemplateConfig.serverUrl + '/getBadges').then(result => {
					console.log("requestBadges : ", result);
					result.forEach(id => this.requestBadge(id));
				})
			};

			// 请求徽标
			requestBadge = id => {
				const { getBadgeCallback } = this.props;
				const { badges } = this.state;
				fetchService.getBlob(TemplateConfig.serverUrl + '/getBadge/' + id).then(result => {
					this.readImage(result, img => {
						badges[id] = img;
						getBadgeCallback(id, img);
						this.setState({ badges });
					});
				});
			};

			// 上传状态改变
			onUploadChange = (info) => {
				const status = info.file.status;
				if (status !== 'uploading') {
					console.log(info.file, info.fileList);
				}
				if (status === 'done') {
					message.success(`${info.file.name} file uploaded successfully.`);
					this.requestBadges();
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`);
				}
			};

			// Override
			render() {
				const uploadProps = {
					name: 'file',
					multiple: true,
					action: TemplateConfig.serverUrl + '/uploadImage/UploadBadge/0',
					onChange: this.onUploadChange,
					withCredentials: true,
				};
				const { visible, onCancel, onCreate, form, title, collection } = this.props;
				const { isDefault, badges } = this.state;
				const { getFieldDecorator } = form;
				const { label, original_label, badge, tag } = collection ? collection :
						{ label: { zh: '' }, original_label: '', badge: "", tag: "" };
				const onSelectBadge = value => collection.badge = value;
				console.log("on metadata editor isDefault : " + isDefault);
				return (
						<Modal
								visible={visible}
								title={title}
								okText="完成"
								cancelText="取消"
								onCancel={onCancel}
								onOk={onCreate}
								width={300}
						>
							<Form layout="vertical">
								<Form.Item label="名称">
									{getFieldDecorator('label', {
										initialValue: label.zh,
										rules: [{ required: true, message: '请输入名称!' }],
									})(<Input />)}
								</Form.Item>
								<Form.Item label="标示（英文别名）">
									{getFieldDecorator('original_label', {
										initialValue: original_label,
										rules: [{ required: true, message: '请输入标示!' }],
									})(<Input />)}
								</Form.Item>
								<Form.Item label="徽标">
									<div>
										{getFieldDecorator('badge', { initialValue: badge })(
												<Select style={{ width: 100 }} onChange={onSelectBadge} >
													<Option value="blank">无</Option>
													{
														Object.keys(badges).map(id => {
															return <Option key={id} value={id}>
																<img alt="example" src={badges[id]} style={{ width: 30, height: 25 }} />
															</Option>
														})
													}
												</Select>
										)}
										<Upload {...uploadProps}>
											<Button shape="circle" icon="upload" type="primary" />
										</Upload>
									</div>
								</Form.Item>
								<Form.Item label="标签">
									{getFieldDecorator('tag', { initialValue: tag })(<Input />)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator('isDefault', {initialValue: isDefault})
									(<Checkbox checked={isDefault} onChange={this.onChange}>默认显示</Checkbox>)}
								</Form.Item>
							</Form>
						</Modal>
				);
			}
		}

);

export default MetadataEditor;
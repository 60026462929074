import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
// 当天的结束时间

export function timeFormat(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    let clock = `${year}-`;
    if (month < 10) clock += '0';
    clock += `${month}-`;
    if (day < 10) clock += '0';
    clock += `${day} `;
    if (hour < 10) clock += '0';
    clock += `${hour}:`;
    if (minute < 10) clock += '0';
    clock += `${minute}:`;
    if (second < 10) clock += '0';
    clock += second;
    return clock;
}

export class TimePickerComponent extends React.Component{

    onOk(value) {
        console.log('onOk: ', value);
    }

    render(){
        return(
            <div >
                <DatePicker showTime
                            value={
                                this.props.time === undefined ||
                                this.props.time === '' ||
                                this.props.time === null
                                    ? null
                                    : moment(this.props.time, 'YYYY-MM-DD HH:mm:ss')
                            }
                            onChange={(value,dateString) => {this.props.onChange(value, dateString)}}
                            onOk={(value) => {this.onOk(value)}} />
            </div>

        );
    }
}
import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Button,
    Table, Checkbox, Popconfirm, Tabs,
    message, Icon, notification
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService, GetDraftTemplateRequest, PublishTemplateRequest,
    RescindTemplateRequest, GetLastVersionTemplatesRequest, Pageable
} from "./grpcweb/AdvancedTemplateService";

const { Content } = Layout;
const { TabPane } = Tabs;

export default class PublishTemplatesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //渲染待发布模版表格
            draftTemplates: [],
            //选中的templates
            selectedTemplates: [],
            //已发布模版
            templatesList: [],
            total: 0,
            current: 1,
            pagesize: 20,
            disablePublishBtn: false
        };
    };

    /**
     * 模版列表项被选中触发
     */
    templatesRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // debugger;
            this.setState({
                selectedTemplates: selectedRows
            });
        },
        getCheckboxProps: record => ({
            disabled: false, // Column configuration not to be checked
            name: record.name,
        }),
    };

    componentDidMount = () => {
        // debugger;
        this.getDraftTemplates();
    }

    /**
      * 查询待发布模版列表
      */
    getDraftTemplates = () => {
        let request = new GetDraftTemplateRequest();
        AdvancedTemplateService.getDraftTemplate(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    res.templatesList.forEach((item) => {
                        this.getTemplateEnumName(item);
                        switch (item.terminaltype) {
                            case 0:
                                item.terminaltype = '桌面端';
                                break;
                            case 1:
                                item.terminaltype = '移动端';
                                break;
                            case 2:
                                item.terminaltype = '桌面端和移动端';
                                break;
                            default:
                                break;
                        }
                        item.createtime = new Date(item.createtime).toLocaleString();
                        item.updatetime = new Date(item.updatetime).toLocaleString();
                    })

                    this.setState({
                        draftTemplates: res.templatesList,
                        disablePublishBtn: false
                    }, () => {
                        // console.log(this.state.draftTemplates);
                    });
                }
            }
        });
    };

    getTemplateEnumName = (item) => {
        item.state = item.state === 0 ? '草稿' : (item.state === 1 ? '发布' : (item.state === 2 ? '历史' : (item.state === 3 ? '撤销' : (item.state === -1 ? '停用' : null))));
        item.type = item.type === 1 ? '空间模版' : (item.type === 2 ? '笔记本模版' : (item.type === 3 ? '笔记模版' : null));
        item.servicelevel = item.servicelevel === 1 ? '免费账户' : (item.servicelevel === 2 ? '标准账户' : (item.servicelevel === 3 ? '高级账户' : (item.servicelevel === 5 ? '专业账户' : null)));
        return item;
    }

    /**
       * 发布模版
       */
    publishSelectedTemplates = (records) => {
        this.setState({ disablePublishBtn: true }, () => {
            let request = new PublishTemplateRequest();
            records.forEach((item) => {
                request.addTemplateid(item.templateid);
            });
            AdvancedTemplateService.publishTemplate(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    // debugger;
                    switch (res.code) {
                        case 200:
                            message.success('发布模版成功');
                            break;
                        default:
                            message.error(res.message);
                            break;
                    }
                    this.getDraftTemplates();
                }
            });
        });
    };

    //已发布模版
    getPublishedTemplates = (page) => {
        let request = new GetLastVersionTemplatesRequest();
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        AdvancedTemplateService.getLastVersionTemplates(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                // console.log("response.Templates: " + JSON.stringify(res.templatesList));
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    res.templatesList.forEach((item) => {
                        this.getTemplateEnumName(item);
                        switch (item.terminaltype) {
                            case 0:
                                item.terminaltype = '桌面端';
                                break;
                            case 1:
                                item.terminaltype = '移动端';
                                break;
                            case 2:
                                item.terminaltype = '桌面端和移动端';
                                break;
                            default:
                                break;
                        }
                        let curTime = new Date(item.updatetime).toLocaleString();
                        item.updatetime = curTime;
                    })
                    // debugger;
                    this.setState(
                        {
                            templatesList: res.templatesList,
                            total: res.total,
                            current: page,
                        },
                        () => console.log("INFO:getTemplates : ", this.state.templatesList)
                    );
                }
            }
        });
    };

    //撤销模版
    rescindTemplate = (templateid) => {
        let request = new RescindTemplateRequest();
        request.setTemplateid(templateid);
        AdvancedTemplateService.rescindTemplate(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                }
                else {
                    message.success('撤销成功');
                    this.getPublishedTemplates();
                }
            }
        });
    };

    handleTabsChange = (key) => {
        console.log("tab : " + key);
        if (key === 1) {
            this.getDraftTemplates();
        }
        else {
            this.getPublishedTemplates(1);
        }
    };

    openNotification = () => {
        notification.open({
            message: '模版撤销规则及条件：',
            description:
                '1.此功能为模版从用户端撤销操作，一旦撤销将不可恢复，请慎重操作。2.撤销模版将在用户端不可见。'
                + '撤销条件：不能关联系列，请在系列管理中解除相关系列关系',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    onChangePage = page => {
        this.getPublishedTemplates(page);
    };


    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/advanced-template-et/template/list">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发布管理</Breadcrumb.Item>
                    <Breadcrumb.Item>发布模版</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <Tabs defaultActiveKey="1" onChange={this.handleTabsChange}>
                        <TabPane tab="待发布" key="1">
                            <div>
                                <Table
                                    rowSelection={{
                                        type: Checkbox,
                                        ...this.templatesRowSelection,
                                    }}
                                    columns={
                                        [
                                            // { title: '状态', align: 'center', dataIndex: 'state', },
                                            // { title: '版本', align: 'center', dataIndex: 'version', },
                                            { title: '名称', align: 'center', dataIndex: 'title', },
                                            { title: '类型', align: 'center', dataIndex: 'type', },
                                            { title: '端类型', align: 'center', dataIndex: 'terminaltype', },
                                            // { title: '应用范围', align: 'center', dataIndex: 'platform', },
                                            { title: '描述', align: 'center', dataIndex: 'description', ellipsis: true },
                                            { title: '等级', align: 'center', dataIndex: 'servicelevel', },
                                            // { title: '缩略图', align: 'center', dataIndex: 'thumbnailurl', ellipsis: true },
                                            { title: '创建时间', align: 'center', dataIndex: 'createtime', },
                                            { title: '更新时间', align: 'center', dataIndex: 'updatetime', },
                                        ]
                                    }
                                    dataSource={this.state.draftTemplates}
                                    pagination={false}
                                />
                            </div>
                            <div>
                                <Row gutter={[16, 16]}>
                                    <Col />
                                    <Col span={2} offset={11}>
                                        <Button type="primary" disabled={this.state.disablePublishBtn}
                                            onClick={() => this.publishSelectedTemplates(this.state.selectedTemplates)}>
                                            发布
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="已发布" key="2">
                            <div>
                                <Table
                                    columns={
                                        [
                                            // { title: '状态', align: 'center', dataIndex: 'state', },
                                            // { title: '版本', align: 'center', dataIndex: 'version', },
                                            { title: '名称', align: 'center', dataIndex: 'title', },
                                            { title: '类型', align: 'center', dataIndex: 'type', },
                                            { title: '端类型', align: 'center', dataIndex: 'terminaltype', },
                                            // { title: '应用范围', align: 'center', dataIndex: 'platform', },
                                            { title: '状态', align: 'center', dataIndex: 'state', },
                                            { title: '描述', align: 'center', dataIndex: 'description', ellipsis: true },
                                            { title: '等级', align: 'center', dataIndex: 'servicelevel', },
                                            // { title: '缩略图', align: 'center', dataIndex: 'thumbnailurl', },
                                            { title: '发布人', align: 'center', dataIndex: 'updateusername', },
                                            { title: '发布时间', align: 'center', dataIndex: 'updatetime', },
                                            {
                                                title: '操作', align: 'center', key: 'action',
                                                render: (text, record) => {
                                                    return (
                                                        <div>
                                                            <span>
                                                                {record.state === '发布' &&
                                                                    <Popconfirm title="确定撤销？" onConfirm={() => this.rescindTemplate(record.templateid)} okText="撤销" cancelText="取消">
                                                                        <a style={{ marginRight: 16 }}>撤销</a>
                                                                    </Popconfirm>}
                                                                {record.state === '发布' && <Icon type="info-circle" onClick={this.openNotification}></Icon>}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            },
                                        ]
                                    }
                                    dataSource={this.state.templatesList}
                                    pagination={{
                                        pageSize: this.state.pagesize,
                                        total: this.state.total,
                                        onChange: this.onChangePage,
                                    }}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                </div>
            </Content>
        );
    }
}
import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Input, Button, Table, Checkbox, Select, Tree, Modal, message
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService, GetPublishListRequest, GetPublishSeriesRequest, GetPublishTemplateRequest
} from "./grpcweb/AdvancedTemplateService";

const { Option } = Select;
const { Content } = Layout;
const { TreeNode } = Tree;

// rowSelection object indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
    }),
};

export default class PublishingManageContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //发布列表查询参数
            enumPublishType: 0,
            enumPublishState: 0,
            versionInput: null,

            //渲染发布表格
            publishedMeta: [],

            showTemplateDetailModal: false,
            showSeriesDetailModal: false,

            publishedTemplatesDetail: [],
            publishedSeriesSnapshot: '',
        };
    };

    componentDidMount = () => {
        this.getPublishList();
    }


    handlePublishingTypeChange = (value) => {
        if (value === null || typeof value == "undefined") {
            this.setState({
                enumPublishType: null,
            });
        } else {
            this.setState({
                enumPublishType: parseInt(value),
            });
        }
    }

    handlePublishingStatusChange = (value) => {
        if (value === null || typeof value == "undefined") {
            this.setState({
                enumPublishState: null,
            });
        } else {
            this.setState({
                enumPublishState: parseInt(value),
            });
        }
    }

    handelVersionInputChange = (e) => {
        this.setState({
            versionInput: e.target.value
        });
    };

    //详情模态框
    showDetailModal = (record) => {
        if (record.type === '模版') {
            //TEMPLATE
            let request = new GetPublishTemplateRequest();
            request.setPublishid(record.publishid);
            AdvancedTemplateService.getPublishTemplate(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    // debugger;
                    // console.log(res.templatesList);
                    if (res.code !== 200) {
                        message.error(res.message);
                    } else {
                        this.setState({
                            publishedTemplatesDetail: res.templatesList.map((item) => {
                                item.state = item.state === 0 ? '草稿' : (item.state === 1 ? '发布' : (item.state === 2 ? '历史' : (item.state === 3 ? '撤销' : (item.state == -1 ? '停用' : null))));
                                item.type = item.type === 1 ? '空间模版' : (item.type === 2 ? '笔记本模版' : (item.type === 3 ? '笔记模版' : null));
                                item.servicelevel = item.servicelevel === 1 ? 'Verse basic会员' : (item.servicelevel === 2 ? '标准账户（暂不支持）' : (item.servicelevel === 3 ? '高级账户（暂不支持）' : (item.servicelevel === 5 ? 'Verse pro会员' : (item.servicelevel === 6 ? 'Verse svip会员' : null))));
                                return item;
                            })
                        });
                    }
                }
            });
            this.setState({
                showTemplateDetailModal: true,
                showSeriesDetailModal: false,
            });
        } else {
            //SERIES
            let request = new GetPublishSeriesRequest();
            request.setPublishid(record.publishid);
            AdvancedTemplateService.getPublishSeries(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    // console.log(res.snapshot);
                    if (res.code !== 200) {
                        message.error(res.message);
                    } else {
                        this.setState({ publishedSeriesSnapshot: res.snapshot });
                    }
                }
            });
            this.setState({
                showSeriesDetailModal: true,
                showTemplateDetailModal: false,
            });
        }
    };

    handleDetailOk = () => {
        this.setState({
            showTemplateDetailModal: false,
            showSeriesDetailModal: false,
        });
    }

    /**
     * 查询发布列表
     */
    getPublishList = () => {
        let request = new GetPublishListRequest();
        request.setType(this.state.enumPublishType);
        // request.setType(EnumPublishType.TEMPLATE);
        // request.setState(EnumPublishState.PRODUCTION);
        request.setState(this.state.enumPublishState);
        request.setVersion(this.state.versionInput);
        AdvancedTemplateService.getPublishList(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                // console.log("response.Templates: " + JSON.stringify(res.publishlistList));
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    this.setState({
                        publishedMeta: res.publishlistList.map((item) => {
                            item.type = item.type === 1 ? '模版' : (item.type === 2 ? '系列' : null);
                            item.state = item.state === 1 ? '生产' : (item.state === 2 ? '历史' : null);
                            let curTime = new Date(item.publishtime).toLocaleString();
                            item.publishtime = curTime;
                            return item;
                        })
                    });
                }
            }
        });
    };

    // 渲染发布系列的系列树
    renderTreeNode = (seriesInfoSnapshot) => {
        if (!seriesInfoSnapshot && seriesInfoSnapshot.length < 3) {
            return;
        }
        // console.log(seriesInfoSnapshot);
        let seriesInfo = JSON.parse(seriesInfoSnapshot);
        let treeNode = [];
        seriesInfo.forEach((ele) => {
            treeNode.push(
                <TreeNode key={ele.seriesId} title={ele.name} >
                    {this.renderChild(ele.subseriesInfo)}
                </TreeNode>
            );
        });
        return treeNode;
    }
    //渲染子结点
    renderChild = (list) => {
        let childLevel = [];
        list.forEach((ele) => {
            childLevel.push(
                <TreeNode key={ele.seriesId} title={ele.name} >
                    {this.renderGrandChild(ele.templates)}
                </TreeNode>
            );
        });
        return childLevel;
    }
    //渲染孙结点
    renderGrandChild = (list) => {
        let grandChildLevel = [];
        if (typeof list === 'undefined' || list === null || list.length < 1) {
            return;
        }
        list.forEach((ele) => {
            grandChildLevel.push(
                <TreeNode key={ele.templateId} title={ele.title} />
            );
        });
        return grandChildLevel;
    }

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/advanced-template-verse/template/list">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发布管理</Breadcrumb.Item>
                    <Breadcrumb.Item>发布记录</Breadcrumb.Item>
                </Breadcrumb>

                <Modal title="模版发布详情" visible={this.state.showTemplateDetailModal} onOk={this.handleDetailOk} onCancel={this.handleDetailOk} width={1000}>
                    <div>
                        <Table pagination={false}
                            columns={
                                [
                                    { title: '状态', align: 'center', dataIndex: 'state', },
                                    // { title: '版本', align: 'center', dataIndex: 'version', },
                                    { title: '名称', align: 'center', dataIndex: 'title', },
                                    { title: '类型', align: 'center', dataIndex: 'type', },
                                    { title: '描述', align: 'center', dataIndex: 'description', },
                                    { title: '等级', align: 'center', dataIndex: 'servicelevel', },
                                    // { title: '缩略图', align: 'center', dataIndex: 'thumbnailurl', ellipsis: true },
                                ]
                            }
                            dataSource={this.state.publishedTemplatesDetail}
                        />
                    </div>
                </Modal>
                <Modal title="系列发布详情" visible={this.state.showSeriesDetailModal} onOk={this.handleDetailOk} onCancel={this.handleDetailOk} width={600}>
                    <div>
                        <Tree showLine={true} defaultExpandAll={true}>
                            {this.renderTreeNode(this.state.publishedSeriesSnapshot)}
                        </Tree>
                    </div>
                </Modal>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={16} align="middle">
                        <Col span={1} offset={2}>
                            类型
                        </Col>
                        <Col span={3} offset={0}>
                            <Select defaultValue="发布类型" style={{ width: 120 }} onChange={this.handlePublishingTypeChange} allowClear>
                                <Option value="请选择" disabled>请选择</Option>
                                <Option value="1" >模版</Option>
                                <Option value="2" >系列</Option>
                            </Select>
                        </Col>
                        <Col span={1} offset={1}>
                            状态
                        </Col>
                        <Col span={3} offset={0}>
                            <Select defaultValue="发布状态" style={{ width: 120 }} onChange={this.handlePublishingStatusChange} allowClear>
                                <Option value="请选择" disabled>请选择</Option>
                                <Option value="1" >生产</Option>
                                <Option value="2" >历史</Option>
                            </Select>
                        </Col>
                        <Col span={1} offset={1}>
                            版本
                        </Col>
                        <Col span={4} offset={0}>
                            <Input id='version' onChange={this.handelVersionInputChange} />
                        </Col>
                        <Col span={1} offset={3}>
                            <Button type="primary" onClick={this.getPublishList}>查询</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                    <div>
                        <Table
                            rowSelection={{
                                type: Checkbox,
                                ...rowSelection,
                            }}
                            columns={
                                [
                                    {
                                        title: '发布类型',
                                        dataIndex: 'type',
                                    },
                                    {
                                        title: '版本',
                                        dataIndex: 'version',
                                    },
                                    {
                                        title: '发布人',
                                        dataIndex: 'publishuser',
                                    },
                                    {
                                        title: '发布时间',
                                        dataIndex: 'publishtime',
                                    },
                                    {
                                        title: '',
                                        key: 'detail',
                                        render: (text, record) => (
                                            <span>
                                                <a style={{ marginRight: 16 }} onClick={() => this.showDetailModal(record)}>发布详情</a>
                                            </span>
                                        ),
                                    },
                                ]
                            }
                            dataSource={this.state.publishedMeta}
                        />
                    </div>

                </div>
            </Content>
        );
    }
}
import {Component} from "react";
import {Button, Divider, Layout, Table, Tag} from "antd";
import React from "react";
import PropTypes from "prop-types";

const { Content, Footer } = Layout;

const METADATA_TYPE_CATEGORY = "category";
const COLUMNS = [
	{
		title: '标题',
		dataIndex: 'guid',
	}, {
		title: 'ID',
		dataIndex: 'id',
	}, {
		title: '分类',
		dataIndex: 'category',
	}, {
		title: '系列',
		dataIndex: 'collection',
		render: text => (
				<span>
					{text.map((tag, index) => {
						let color = index % 2 === 1 ? 'volcano' : 'green';
						return <Tag color={color} key={tag}>{tag}</Tag>;
					})}
    		</span>
		),
	}
];

export default class MetadataTemplateList extends Component {

	static propTypes = {
		templates: PropTypes.object,
		snapMetadatas: PropTypes.object,
		showTemplateImage: PropTypes.any,
		downDataFilter: PropTypes.any,
		addOperation: PropTypes.any,
		saveOperation: PropTypes.any,
		snapMetadata: PropTypes.object,
	};

	static defaultProps = {
		showTemplateImage: () => {},
	};

	constructor(props) {
		super(props);
		COLUMNS[0].render = guid => (
				<span>
					<img
							alt={"	"}
							src={props.templates[guid].img}
							style={{ float: "left", height: 40 }}
							guid={guid}
							can_editor="true"
							onClick={props.showTemplateImage}
					/>
					<span>{this.props.templates[guid].title == null ? this.props.templates[guid].description.en :
							this.props.templates[guid].title.zh}</span>
				</span>
		);

		this.state = {
			selectedUpKeys: [],
			selectedDownKeys: [],
			operations: [],
			addButton: {
				onClick: this.addTemplate,
				title: '添加',
			},
			deleteButton: {
				onClick: this.deleteTemplate,
				title: '删除',
			},
		};
	}

	// 显示模板列表
	showTemplateList = (table, button) => {
		return (
				<Content>
					<div>
						<Button
								type="primary"
								style={{ float: "left" }}
								disabled={table.selectedRowKeys.length === 0}
								onClick={button.onClick}
						>
							{button.title}
						</Button>
					</div>
					<br/>
					<br/>
					<br/>
					<div>
						<Table rowSelection={table} columns={COLUMNS} dataSource={table.data} />
					</div>
				</Content>
		);
	};

	// 上面的模板被选择
	onSelectUpTemplate = selectedUpKeys => this.setState({selectedUpKeys});

	// 下面的模板被选择
	onSelectDownTemplate = selectedDownKeys => this.setState({selectedDownKeys});

	// 添加模板
	addTemplate = () => {
		const { templates, snapMetadata, addOperation } = this.props;
		const { selectedDownKeys } = this.state;
		console.log("selectedDownKeys : ", selectedDownKeys);
		selectedDownKeys.forEach(guid => snapMetadata.template_note_ids.push(templates[guid].orig_note_guid));
		addOperation({
			command: 'TemplateAddMetadata',
			guids: selectedDownKeys,
			metadataId: snapMetadata.guid,
		});
		this.setState({selectedDownKeys: []});
	};

	// 删除模板
	deleteTemplate = () => {
		const { templates, snapMetadata, addOperation } = this.props;
		const { template_note_ids } = snapMetadata;
		const { selectedUpKeys } = this.state;
		console.log("selectedUpKeys : ", selectedUpKeys);
		selectedUpKeys.forEach(guid => {
			const pos = template_note_ids.indexOf(templates[guid].orig_note_guid);
			console.log("onDeleteTemplate : pos : " + pos + " metadata : ", templates[guid], snapMetadata);
			template_note_ids.splice(pos, 1);
		});
		addOperation({
			command: 'TemplateDeleteMetadata',
			guids: selectedUpKeys,
			metadataId: snapMetadata.guid,
		});
		this.setState({ selectedUpKeys: [] });
	};

	// 过滤模板
	filterTemplates = (filter) => {
		const data = {};
		const { templates, snapMetadatas } = this.props;
		Object.keys(templates).filter(guid => filter(templates[guid]))
				.forEach(guid => {
					const template = templates[guid];
					let row = data[guid];
					if (!row) {
						row = { key: guid, collection: [], img: template.img, guid: guid, id: guid };
						data[guid] = row;
					}

					Object.keys(snapMetadatas)
							.filter(mid => snapMetadatas[mid].template_note_ids.includes(template.orig_note_guid))
							.forEach(mid => {
						const metadata = snapMetadatas[mid];
						if (metadata.type === METADATA_TYPE_CATEGORY) {
							row.category = metadata.label.zh;
						} else {
							row.collection.push(metadata.label.zh);
						}
					});
				});
		return Object.values(data);
	};

	// Override
	render() {
		console.log('MetadataTemplateList.render.');
		const { selectedUpKeys, selectedDownKeys, addButton, deleteButton } = this.state;
		const { downDataFilter, saveOperation, snapMetadata } = this.props;
		// 筛选已选数据
		const upTable = {
			selectedRowKeys: selectedUpKeys,
			data: this.filterTemplates(template => {
				return snapMetadata.template_note_ids.includes(template.orig_note_guid);
			}),
			onChange: this.onSelectUpTemplate,
		};
		// 筛选未选数据
		const downTable = {
			selectedRowKeys: selectedDownKeys,
			data: this.filterTemplates(downDataFilter),
			onChange: this.onSelectDownTemplate,
		};
		// 渲染
		return (
				<div>
					{this.showTemplateList(upTable, deleteButton)}
					<Divider />
					{this.showTemplateList(downTable, addButton)}
					{/*底部元素*/}
					<Footer style={{ textAlign: 'center'}}>
						<Button type="primary" onClick={saveOperation}>保存操作</Button>
					</Footer>
				</div>
		)
	};

}
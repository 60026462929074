/* eslint-disable no-undef */
/**
 * @fileoverview gRPC-Web generated client stub for orders
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


// var google_api_annotations_pb = require('./google/api/annotations_pb.js')
const proto = {};
proto.orders = require('./orders_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.orders.OrdersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.orders.OrdersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.CreateOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodDescriptor_OrdersService_CreateOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.CreateOrderRequest,
  proto.orders.OrderResult,
  /** @param {!proto.orders.CreateOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.CreateOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodInfo_OrdersService_CreateOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.OrderResult,
  /** @param {!proto.orders.CreateOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderResult.deserializeBinary
);


/**
 * @param {!proto.orders.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_CreateOrder,
      callback);
};


/**
 * @param {!proto.orders.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderResult>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.PlaceThirdPartyOrderRequest,
 *   !proto.orders.OrderWithPayInfo>}
 */
const methodDescriptor_OrdersService_PlaceThirdPartyOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/PlaceThirdPartyOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.PlaceThirdPartyOrderRequest,
  proto.orders.OrderWithPayInfo,
  /** @param {!proto.orders.PlaceThirdPartyOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderWithPayInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.PlaceThirdPartyOrderRequest,
 *   !proto.orders.OrderWithPayInfo>}
 */
const methodInfo_OrdersService_PlaceThirdPartyOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.OrderWithPayInfo,
  /** @param {!proto.orders.PlaceThirdPartyOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderWithPayInfo.deserializeBinary
);


/**
 * @param {!proto.orders.PlaceThirdPartyOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderWithPayInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderWithPayInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.placeThirdPartyOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/PlaceThirdPartyOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_PlaceThirdPartyOrder,
      callback);
};


/**
 * @param {!proto.orders.PlaceThirdPartyOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderWithPayInfo>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.placeThirdPartyOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/PlaceThirdPartyOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_PlaceThirdPartyOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.DeleteOrderRequest,
 *   !proto.orders.OperationResult>}
 */
const methodDescriptor_OrdersService_DeleteOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/DeleteOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.DeleteOrderRequest,
  proto.orders.OperationResult,
  /** @param {!proto.orders.DeleteOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OperationResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.DeleteOrderRequest,
 *   !proto.orders.OperationResult>}
 */
const methodInfo_OrdersService_DeleteOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.OperationResult,
  /** @param {!proto.orders.DeleteOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OperationResult.deserializeBinary
);


/**
 * @param {!proto.orders.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OperationResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OperationResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.deleteOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_DeleteOrder,
      callback);
};


/**
 * @param {!proto.orders.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OperationResult>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.deleteOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_DeleteOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodDescriptor_OrdersService_GetOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/GetOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.GetOrderRequest,
  proto.orders.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.GetOrderRequest,
 *   !proto.orders.OrderResult>}
 */
const methodInfo_OrdersService_GetOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.OrderResult,
  /** @param {!proto.orders.GetOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OrderResult.deserializeBinary
);


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OrderResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OrderResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.getOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_GetOrder,
      callback);
};


/**
 * @param {!proto.orders.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OrderResult>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.getOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_GetOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListOrders,
      callback);
};


/**
 * @param {!proto.orders.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListDeletedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListDeletedOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListDeletedOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListDeletedOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListDeletedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListDeletedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListDeletedOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListDeletedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListDeletedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listDeletedOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListDeletedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListDeletedOrders,
      callback);
};


/**
 * @param {!proto.orders.ListDeletedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listDeletedOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListDeletedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListDeletedOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListUnpaidOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListUnpaidOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListUnpaidOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListUnpaidOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListUnpaidOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListUnpaidOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListUnpaidOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListUnpaidOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListUnpaidOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listUnpaidOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListUnpaidOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListUnpaidOrders,
      callback);
};


/**
 * @param {!proto.orders.ListUnpaidOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listUnpaidOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListUnpaidOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListUnpaidOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListPaidFailedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListPaidFailedOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListPaidFailedOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListPaidFailedOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListPaidFailedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListPaidFailedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListPaidFailedOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListPaidFailedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListPaidFailedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listPaidFailedOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListPaidFailedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListPaidFailedOrders,
      callback);
};


/**
 * @param {!proto.orders.ListPaidFailedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listPaidFailedOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListPaidFailedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListPaidFailedOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListExpiredOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListExpiredOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListExpiredOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListExpiredOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListExpiredOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListExpiredOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListExpiredOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListExpiredOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListExpiredOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listExpiredOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListExpiredOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListExpiredOrders,
      callback);
};


/**
 * @param {!proto.orders.ListExpiredOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listExpiredOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListExpiredOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListExpiredOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListPaidOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListPaidOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListPaidOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListPaidOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListPaidOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListPaidOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListPaidOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListPaidOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListPaidOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listPaidOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListPaidOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListPaidOrders,
      callback);
};


/**
 * @param {!proto.orders.ListPaidOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listPaidOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListPaidOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListPaidOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.ListUncompletedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodDescriptor_OrdersService_ListUncompletedOrders = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/ListUncompletedOrders',
  grpc.web.MethodType.UNARY,
  proto.orders.ListUncompletedOrdersRequest,
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListUncompletedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.ListUncompletedOrdersRequest,
 *   !proto.orders.ListOrdersReply>}
 */
const methodInfo_OrdersService_ListUncompletedOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.ListOrdersReply,
  /** @param {!proto.orders.ListUncompletedOrdersRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.ListOrdersReply.deserializeBinary
);


/**
 * @param {!proto.orders.ListUncompletedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.ListOrdersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.ListOrdersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.listUncompletedOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/ListUncompletedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListUncompletedOrders,
      callback);
};


/**
 * @param {!proto.orders.ListUncompletedOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.ListOrdersReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.listUncompletedOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/ListUncompletedOrders',
      request,
      metadata || {},
      methodDescriptor_OrdersService_ListUncompletedOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.CheckExternalOrderRequest,
 *   !proto.orders.CheckExternalOrderReply>}
 */
const methodDescriptor_OrdersService_CheckExternalOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/CheckExternalOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.CheckExternalOrderRequest,
  proto.orders.CheckExternalOrderReply,
  /** @param {!proto.orders.CheckExternalOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.CheckExternalOrderReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.CheckExternalOrderRequest,
 *   !proto.orders.CheckExternalOrderReply>}
 */
const methodInfo_OrdersService_CheckExternalOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.CheckExternalOrderReply,
  /** @param {!proto.orders.CheckExternalOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.CheckExternalOrderReply.deserializeBinary
);


/**
 * @param {!proto.orders.CheckExternalOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.CheckExternalOrderReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.CheckExternalOrderReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.checkExternalOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/CheckExternalOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_CheckExternalOrder,
      callback);
};


/**
 * @param {!proto.orders.CheckExternalOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.CheckExternalOrderReply>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.checkExternalOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/CheckExternalOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_CheckExternalOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.orders.RefundOrderRequest,
 *   !proto.orders.OperationResult>}
 */
const methodDescriptor_OrdersService_RefundOrder = new grpc.web.MethodDescriptor(
  '/orders.OrdersService/RefundOrder',
  grpc.web.MethodType.UNARY,
  proto.orders.RefundOrderRequest,
  proto.orders.OperationResult,
  /** @param {!proto.orders.RefundOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OperationResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.orders.RefundOrderRequest,
 *   !proto.orders.OperationResult>}
 */
const methodInfo_OrdersService_RefundOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.orders.OperationResult,
  /** @param {!proto.orders.RefundOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.orders.OperationResult.deserializeBinary
);


/**
 * @param {!proto.orders.RefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.orders.OperationResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.orders.OperationResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.orders.OrdersServiceClient.prototype.refundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/orders.OrdersService/RefundOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_RefundOrder,
      callback);
};


/**
 * @param {!proto.orders.RefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.orders.OperationResult>}
 *     A native promise that resolves to the response
 */
proto.orders.OrdersServicePromiseClient.prototype.refundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/orders.OrdersService/RefundOrder',
      request,
      metadata || {},
      methodDescriptor_OrdersService_RefundOrder);
};


module.exports = proto.orders;


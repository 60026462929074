import {
    EVN
} from "./evn.js";
export const {
    GetListOrdersRequest,GetBalanceByUserRequest
} = require('./wallet-admin_pb.js');

// const walletAdminServiceHost = "https://10.112.41.137:65000";
const walletAdminServiceHost = EVN + "/third/wallet/admin/grpcweb";
// const walletAdminServiceHost = "https://app.yinxiang.com/third/wallet/admin/grpcweb";

const { WalletAdminServiceClient } = require('./wallet-admin_grpc_web_pb.js');

export const WalletAdminService = new WalletAdminServiceClient(
    walletAdminServiceHost,
    null,
    { withCredentials: true }

);

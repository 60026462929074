import React from "react";
import { TreeSelect } from 'antd';
const { TreeNode } = TreeSelect;
const { SHOW_CHILD } = TreeSelect;

export default class TreeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //当前选中的条目:string[]
            selectedValues: this.props.default ? this.props.default : [],
        };
    };

    // 渲染父结点
    renderTreeNode = (list) => {
        let treeNode = [];
        list.forEach((ele, idx) => {
            if (ele.parent === 0) {
                treeNode.push(
                    <TreeNode key={ele.seriesid} value={ele.seriesid} title={ele.name} >
                        {this.renderChild(ele.seriesid, list)}
                    </TreeNode>
                );
            }
        });
        return treeNode;
    }
    //渲染子结点
    renderChild = (parentId, list) => {
        let childLevel = [];
        list.forEach((ele, idx) => {
            if (ele.parent === parentId) {
                childLevel.push(
                    <TreeNode key={ele.seriesid} value={ele.seriesid} title={ele.name} />
                );
            }
        });
        return childLevel;
    }

    //选中树节点时调用此函数
    onSeletChange = value => {
        this.setState({ selectedValues: value }, () => {
            //向父组件传值，注意要写在setState的callback中
            this.props.parent.getChildrenMsg(this, this.state.selectedValues)
        });
        console.log('树节点已选： ', value);
        console.log('state.selectedValues： ', this.state.selectedValues);
    };

    render() {
        const seriesList = this.props.data;
        // debugger;
        return <TreeSelect
            value={this.state.selectedValues}
            onChange={this.onSeletChange}
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder='Please select'
            style={{ width: '100%' }}
            disabled={this.props.disabled}
        >
            {this.renderTreeNode(seriesList)}
        </TreeSelect>;
    }
}
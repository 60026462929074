import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Tree, Button, message, Select
} from 'antd';
import './layout.css';
import {
    OfficialMaterialAdminService,
    PublishGroupRequest,
    GetGroupsRequest,
    GetMaterialTypeRequest
} from "./grpcweb/OfficialMaterialAdminService";

const { Content } = Layout;
const { TreeNode } = Tree;
const { Option } = Select;

export default class PublishSeriesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //查询回待发布的系列-json
            draftSeriesJson: '',
            disablePublishBtn: false,
            currentUrl: '',
            materialType: 2,
        };
    };

    componentDidMount = () => {
        this.setState({
            currentUrl: this.props.location.pathname,
        });
        this.getDraftSeries(this.state.materialType);
        this.queryMaterialType();
    };

    componentWillReceiveProps(nextprops) {
        console.log('nextprops', nextprops);
        // state : currentUrl  (pc) (md)
        if(this.state.currentUrl !== nextprops.location.pathname) {

            this.setState({
                currentUrl: nextprops.location.pathname});
            this.getDraftSeries(this.state.materialType);
        }
    };

    /**
     * 请求素材类型
     */
    queryMaterialType = () => {
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        OfficialMaterialAdminService.getMaterialType(new GetMaterialTypeRequest(), {}, (err, response) => {
            //debugger
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.message " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== "") {
                    message.error(res.message);
                } else {
                    this.setState({ materialTypeList: res.materialtypeList });
                }
            }
        });
    };

    /**
      * 查询待发布系列
      */
    getDraftSeries = (materialType) => {
        console.log("props is ==> ",this.props);
        let request = new GetGroupsRequest();
        request.setMaterialtype(materialType);
        OfficialMaterialAdminService.getAllGroupByType(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                // debugger
                const res = response.toObject();
                if (res.code !== 0) {
                    message.error(res.message);
                } else {
                    this.setState({
                        draftSeriesJson: res.groupsList,
                    });
                }
            }
        });
    };

    /**
       * 发布系列
       */
    publishSelectedSeries = (materialType) => {
        this.setState({ disablePublishBtn: true }, () => {
            let request = new PublishGroupRequest();
            request.setType(materialType);
            OfficialMaterialAdminService.publishGroup(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    const res = response.toObject();
                    switch (res.responsecode) {
                        case 0:
                            message.success('发布系列成功');
                            this.getDraftSeries(this.state.materialType);
                            break;
                        default:
                            message.error(res.msg);
                            break;
                    }
                }
                this.setState({ disablePublishBtn: false });
            });
        });
    };

    // 渲染发布系列的系列树
    renderTreeNode = (draftSeries) => {
        if (!draftSeries) {
            return;
        }
        let treeNode = [];
        draftSeries.forEach((ele) => {
            treeNode.push(
                <TreeNode checkable={false}  key={ele.id} title={ele.name} >
                    {this.renderChild(ele.childgroupList)}
                </TreeNode>
            );
        });
        return treeNode;
    }
    //渲染子结点
    renderChild = (list) => {
        if (!list || typeof (list) === "undefined") {
            return;
        }
        let childLevel = [];
        list.forEach((ele) => {
            childLevel.push(
                <TreeNode checkable={false} key={ele.id} title={ele.name} />
            );
        });
        return childLevel;
    }
    // //渲染孙结点
    // renderGrandChild = (list) => {
    //     if (!list || typeof (list) === "undefined") {
    //         return;
    //     }
    //     let grandChildLevel = [];
    //     list.forEach((ele) => {
    //         // debugger
    //         grandChildLevel.push(
    //             <TreeNode checkable={false} title={ele.title + ' (模版类型:' + ele.templateType + ' 等级:' + ele.serviceLevel + ') '} />
    //         );
    //     });
    //     return grandChildLevel;
    // }

    // //检查一级系列下是否有叶子结点，有才能被勾选
    // isRootCheckable = (node) => {
    //     if (!node.childgroupList || typeof node.childgroupList === 'undefined' || node.childgroupList.length < 1) {
    //         return false;
    //     }
    //     let result = false;
    //
    //     node.childgroupList.forEach((ele) => {
    //         if (ele.templates && typeof ele.templates !== 'undefined' && ele.templates.length > 0) {
    //             result = true;
    //         }
    //     });
    //     return result;
    // }

    //选择素材类型后动作
    handleMaterialTypeChange = (value) => {
        console.log(`selected ${value}`);
        let materialType = (typeof value == "undefined") ? null : value;
        this.setState({materialType: materialType});
        this.getDraftSeries(materialType);
    };

    displayMaterialType = () => {
        if(typeof(this.state.materialTypeList) !== "undefined") {
            return this.state.materialTypeList.map(
              (item) => (<Option key={item.code} value={item.code}>{item.name}</Option>)
            )
        } else {
            return ;
        }
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to="/material/template/list">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发布管理</Breadcrumb.Item>
                        <Breadcrumb.Item>发布系列</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <div>
                        <Row gutter={16}>
                            <Col span={2} offset={0}>
                                素材类型
                            </Col>
                            <Col span={4} offset={0}>
                                <Select defaultValue={this.state.materialType} style={{ width: 160 }} onChange={this.handleMaterialTypeChange} allowClear>
                                    {this.displayMaterialType()}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={6} offset={1}>
                                <p>
                                    <font size="4" face="Verdana">
                                        选择要发布的系列:
                                    </font>
                                </p>
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={10} offset={4}>
                                <Tree checkable defaultExpandAll showLine >
                                    {this.renderTreeNode(this.state.draftSeriesJson)}
                                </Tree>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col span={2} offset={11}>
                            <Button type="primary" disabled={this.state.disablePublishBtn}
                                onClick={() => this.publishSelectedSeries(this.state.materialType)}>
                                发布
                            </Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                </div>
            </Content>
        );
    }
}
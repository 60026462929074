import React, { Component } from 'react';
import './App.css';
import './listSort.css';
import {Modal, Tooltip, Menu, message, Layout, Icon, Button, Collapse, Upload, Spin} from 'antd';
import ListSort from '../../components/edit/template/components/ListSort';
import fetchService from '../../utils/FetchService';
import MetadataEditor from './components/MetadataEditor';
import MetadataTemplateList from './components/MetadataTemplateList';
import TemplateList from './components/TemplateList';
import TemplateConfig from 'TemplateConfig';

const { Sider } = Layout;
const Panel = Collapse.Panel;
const confirm = Modal.confirm;
const Dragger = Upload.Dragger;

const METADATA_TYPE_COLLECTION = "collection";
const METADATA_TYPE_CATEGORY = "category";
const METADATA_TYPE_COLLECTION_TEXT = "系列";
const METADATA_TYPE_CATEGORY_TEXT = "分类";

class Template extends Component {

	state = {
		metadatas: {}, // PublicTemplateMetadata
		snapMetadatas: {}, // PublicTemplateMetadata
		metadataEditorVisible: false,
		metadataId: "-1",
		metadataTypeCount: [],
		templates: {}, // PublicTemplateInfo
		modalImage: {
			'visible': false,
			'img': null,
			'title': "",
		},
		serverUrl: TemplateConfig.serverUrl,
		editorContent: null,
		publishEditorVisible: false,
		panelId: '',
		operations: [],
		versions: {}, // PublicTemplateVersion
		version: {
			version: 0,
			state: 0,
			metadata_order: [],
			default_metadata_id: "",
			description: "",
			template_ids: [],
		},
		snapVersion: {
			version: 0,
			state: 0,
			metadata_order: [],
			default_metadata_id: "",
			description: "",
			template_ids: [],
		},
		diffList: [],
		uploadThumbnail: false,
		badges: {},
		loading: false,
	};

	static defaultProps = {
		className: 'list-sort-demo',
	};

	// Override
	componentDidMount() {
		this.requestVersions();
		this.requestBadges();
		message.config({maxCount: 5});
		console.log('componentDidMount')
	}

	// =================================服务器请求方法=================================

	// 请求版本列表
	requestVersions = () => {
		fetchService.get(this.state.serverUrl + '/getVersions')
				.then(result => this.setState({ versions: result }, () => {
					// 请求草稿
					const {versions} = this.state;
					console.log('INFO:Template.requestVersions : ', versions);
					const requestVersion = this.requestVersion.bind(this);
					Object.keys(versions)
							.filter(id => versions[id] === 0)
							.forEach(id => requestVersion(id));
				}));
	};

	// 请求版本信息
	requestVersion(id, requestMetadata=true, requestTemplates=true) {
		fetchService.get(this.state.serverUrl + '/getVersion/' + id)
				.then(result => this.setState({ version: result, snapVersion: this.snapVersion(result) },
						() => {
					console.log("INFO:Template.requestVersion : ", this.state.version);
					if (requestMetadata) {
						this.requestMetadata.bind(this)(id);
					}
					if (requestTemplates) {
						this.requestTemplates.bind(this)(id);
					}
				}));
	};

	// 请求Metadata
	requestMetadata(id) {
		fetchService.get(this.state.serverUrl + '/getMetadata/' + id)
				.then(result => this.setState({ metadatas: result, snapMetadatas: this.snapMetadata(result) },
						() => {
					const { metadatas, metadataTypeCount, version, requestMetadataCallback } = this.state;
					console.log("INFO:Template.requestMetadata : ", metadatas);
					// 记录类型数量
					if (version.metadata_order) {
						let typeCount = -1;
						const calcBeginIndex = typeCount => typeCount.count + typeCount.beginIndex;
						version.metadata_order.forEach(id => {
							const type = metadatas[id].type;
							if (typeCount < 0 || metadataTypeCount[typeCount].type !== type) {
								typeCount++;
								const beginIndex = typeCount === 0 ? 0 : calcBeginIndex(metadataTypeCount[typeCount - 1]);
								metadataTypeCount[typeCount] = { count: 1, type: type, beginIndex: beginIndex };
							} else {
								metadataTypeCount[typeCount].count++;
							}
						});
						this.setState({ metadataTypeCount, requestMetadataCallback: null });
					}
					console.log('make metadataTypeCount : ', metadataTypeCount);
				}));
	};

	// 请求模板列表
	requestTemplates(id) {
		fetchService.get(this.state.serverUrl + '/getTemplates/' + id)
				.then(result => {
					this.setState({templates: result},
							() => console.log("INFO:Template.requestTemplates : ", this.state.templates));
					Object.values(result).forEach(template =>
							this.requestThumbnail(template.guid, img => template.img = img, 5));
				});
	};

	// 请求执行命令
	requestExecuteCommand = (operations, call, versionId) => {
		const { serverUrl, version } = this.state;
		if (!versionId) {
			versionId = version.version;
		}
		const url = serverUrl + '/executeCommands/' + versionId;
		// 服务器的数据库操作可能生效有延时，这里会等待1秒
		fetchService.post(url, operations).then(() => setTimeout(() => {
			this.setState({loading: false}, call);
		}, 1000));
		this.setState({loading: true});
	};

	// 请求缩略图
	requestThumbnail = (guid, callback, count) => {
		const { serverUrl, templates, version } = this.state;
		const template = templates[guid];
		const id = !template.thumbnail_url || template.thumbnail_url === 'large' ? template.guid : template.orig_note_guid;
		const url = serverUrl + '/getThumbnail/' + version.version + '/' + (template.thumbnail_url ? template.thumbnail_url : 'large') + '/' + id;
		fetchService.getBlob(url).then(result => {
			if (result.size === 0 && count > 0) { // 缩略图生成速度可能会比较慢，这里每8秒重新请求一次，直到重试次数为0
				setTimeout(() => this.requestThumbnail(guid, callback, --count), 8000);
				console.log("continue try request thumbnail : " + count, template);
			} else {
				this.readImage(result, callback);
			}
		});
	};

	// 请求徽标列表
	requestBadges = () => {
		fetchService.get(this.state.serverUrl + '/getBadges').then(result => {
			console.log("requestBadges : ", result);
			result.forEach(id => this.requestBadge(id));
		})
	};

	// 请求徽标
	requestBadge = id => {
		fetchService.getBlob(this.state.serverUrl + '/getBadge/' + id).then(result => {
			this.readImage(result, img => this.state.badges[id] = img);
		});
	};

	// =================================Metadata方法=================================

	// 复制Metadata
	snapMetadata(metadatas) {
		const snapMetadatas = {};
		Object.keys(metadatas).forEach(guid => {
			snapMetadatas[guid] = Object.assign({}, metadatas[guid]);
			snapMetadatas[guid].template_note_ids = Object.assign([], metadatas[guid].template_note_ids);
			snapMetadatas[guid].label = Object.assign({}, metadatas[guid].label);
		});
		return snapMetadatas;
	};

	// 删除Metadata
	onDeleteMetadata = event => {
		const id = event.currentTarget.getAttribute("id");
		const { snapMetadatas, version } = this.state;
		const exec = () => this.showDeleteConfirm(() => {
			const metadata = snapMetadatas[id];
			delete snapMetadatas[id];
			const operation = { command: "DeleteMetadata", data: id };
			this.requestExecuteCommand([operation], () => {
				this.state.metadataId = "-1";
				this.requestVersion(version.version, true, false);
				message.warning('已删除系列：' + metadata.label.zh);
			});
		});
		const unSave = () => {
			this.state.operations = [];
			exec();
		};
		this.askLeave(exec, unSave);
	};

	// 显示Metadata编辑器
	showMetadataEditor = event => {
		const id = event.currentTarget.getAttribute("id") + "";
		const exec = () => this.setState({ metadataEditorVisible: true, metadataId: id });
		const unSave = () => this.setState({ operations: [], metadataEditorVisible: true, metadataId: id });
		this.askLeave(exec, unSave);
	};

	// 隐藏Metadata编辑器
	hideMetadataEditor = () => this.setState({ metadataEditorVisible: false });

	// 新建/修改Metadata编辑完成
	handleMetadataEditorCommit = () => {
		const form = this.formRef.props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const { metadataId, version, snapMetadatas } = this.state;
			const isCreate = metadataId == "-1" || metadataId == "-2";
			const operation = { command: isCreate ? 'AddMetadata' : 'UpdateMetadata', isDefault: values.isDefault };
			const fromValue = metadata => {
				metadata.label.zh = values.label;
				metadata.original_label = values.original_label;
				metadata.badge = values.badge === 'blank' ? '' : values.badge;
				metadata.tag = values.tag;
				console.log("metadata : ", metadataId, metadata);
				return metadata;
			};
			if (isCreate) { // 新建
				operation.data = fromValue({
					type: metadataId == "-1" ? METADATA_TYPE_COLLECTION : METADATA_TYPE_CATEGORY,
					label: {  },
				});
			} else { // 修改
				operation.data = fromValue(snapMetadatas[metadataId]);
				this.updateMetadata(metadataId);
			}

			form.resetFields();
			// 发送到服务器
			this.requestExecuteCommand([operation], () => {
				message.info((isCreate ? '新增' : '修改') + 'Metadata：' + values.label);
				this.requestVersion(version.version, true, false);
			});
			this.setState({metadataEditorVisible: false, metadataId: "-1"});
			console.log('handleMetadataEditorCommit : ', values);
		});
	};

	// 保存Metadata编辑器表单
	saveFormRef = formRef => {
		this.formRef = formRef;
		console.log("saveFormRef : ", formRef)
	};

	// 获取指定Metadata类型的排序起始索引
	getMetadataIndexBegin = type => {
		for (const typeCount of this.state.metadataTypeCount) {
			if (typeCount.type === type) {
				return typeCount.beginIndex;
			}
		}
		return this.state.snapVersion.metadata_order.length;
	};

	// Metadata顺序变化
	onMetadataIndexChange = children => {
		const { snapVersion, snapMetadatas } = this.state;
		const { metadata_order } = snapVersion;
		children.forEach((child, index) => {
			const beginIndex = this.getMetadataIndexBegin(snapMetadatas[child.key].type);
			metadata_order[beginIndex + index] = child.key
		});
		this.addOperation({ command: "OrderMetadata", data: metadata_order.filter((obj) => obj ) });
		console.log('onMetadataIndexChange : ', children, metadata_order)
	};

	// 过滤渲染Metadata
	filterMetadataRender = type => {
		const { snapMetadatas, metadataTypeCount, snapVersion } = this.state;
		const { default_metadata_id, metadata_order } = snapVersion;
		for (const typeCount of metadataTypeCount) {
			if (typeCount.type === type) {
				const { beginIndex, count } = typeCount;
				return metadata_order
						.filter((id, index) => index >= beginIndex && index < beginIndex + count && snapMetadatas[id])
						.map(id => {
							const { label } = snapMetadatas[id];
							// 长度限制5
							const isLongTag = label.zh.length > 5;
							// 超长的字符串截取4个
							const content = isLongTag ? `${label.zh.slice(0, 4)}...` : label.zh;
							// 按钮类型，默认显示的会与众不同
							const buttonType = default_metadata_id === id ? "danger" : "primary";
							// 创建按钮
							let button = <Button
									type={buttonType}
									style={{ width: 100 }}
									onClick={this.selectMetadata}
									id={id}
									htmlType="button"
							>
								{content}
							</Button>;
							// 按需创建提示框
							button = isLongTag ? <Tooltip title={label.zh} key={label.zh}>{button}</Tooltip> : button;
							// 渲染
							return (
									<div key={id} className={`${this.props.className}-list`}>
										{/*Metadata按钮*/}
										{button}
										{/*编辑按钮*/}
										<Button type="primary" shape="circle" icon="edit" id={id} onClick={this.showMetadataEditor} />
										{/*删除按钮*/}
										<Button type="primary" shape="circle" icon="close" id={id} onClick={this.onDeleteMetadata} />
									</div>
							);
						});
			}
		}
		return [];
	};

	// 选择Metadata
	selectMetadata = event => {
		const id = event.currentTarget.getAttribute("id");
		this.updateMetadata(id);
	};

	// 更新Metadata
	updateMetadata = id => {
		const { snapMetadatas } = this.state;
		switch (snapMetadatas[id].type) {
			case METADATA_TYPE_COLLECTION :
				this.refreshMetadataView(id, template => {
					let ret = false;
					for (const guid in snapMetadatas) {
						if (snapMetadatas[guid].template_note_ids.includes(template.orig_note_guid)) {
							if (guid === id) {
								return false;
							} else {
								ret = true;
							}
						}
					}
					return ret;
				});
				break;
			case METADATA_TYPE_CATEGORY :
				this.refreshMetadataView(id,
						template => {
							for (const guid in snapMetadatas) {
								if (snapMetadatas[guid].type === METADATA_TYPE_CATEGORY
										&& snapMetadatas[guid].template_note_ids.includes(template.orig_note_guid)) {
									return false;
								}
							}
							return true;
						});
				break;
			default :
				this.setState({editorContent: null});
		}
	};

	// refreshMetadataView:刷新右侧视图
	// 刷新Metadata视图
	refreshMetadataView = (metadataId, filter) => {
		const { templates, snapMetadatas } = this.state;
		const editorContent = (
				<MetadataTemplateList
						templates={templates}
						snapMetadata={snapMetadatas[metadataId]}
						showTemplateImage={this.showTemplateImage}
						downDataFilter={filter}
						saveOperation={() => this.saveOperation(() => this.setState({editorContent: null}))}
						addOperation={this.addOperation}
						snapMetadatas={snapMetadatas}
				/>
		);
		this.setState({editorContent});
	};

	// Metadata编辑框内容
	getMetadata(metadataId, metadatas) {
		return metadataId == "-1" ? {
			id: "-1",
			original_label: "",
			badge: "",
			label: {},
			type: METADATA_TYPE_COLLECTION,
			tag: "",
		} : metadataId == "-2" ? {
			id: "-2",
			original_label: "",
			badge: "",
			label: {},
			type: METADATA_TYPE_CATEGORY,
			tag: "",
		} : metadatas[metadataId];
	}

	// 渲染Metadata编辑框
	renderMetadataEditor = () => {
		const { metadataId, metadataEditorVisible, version, snapMetadatas, badges } = this.state;
		// Metadata编辑框内容
		const metadata = this.getMetadata(metadataId, snapMetadatas);
		// Metadata编辑框标题
		const title = metadata.type === METADATA_TYPE_COLLECTION ? METADATA_TYPE_COLLECTION_TEXT : METADATA_TYPE_CATEGORY_TEXT;
		return metadataEditorVisible ? (
				<MetadataEditor
						wrappedComponentRef={this.saveFormRef}
						visible={metadataEditorVisible}
						onCancel={this.hideMetadataEditor}
						onCreate={this.handleMetadataEditorCommit}
						title={title}
						isDefault={metadataId == version.default_metadata_id}
						collection={metadata}
						badges={badges}
						getBadgeCallback={(id, img) => badges[id] = img}
				/>
		) : null;
	};

	// =================================模版方法=================================

	// 关闭模板图片
	closeTemplateImage = () => {
		const { uploadThumbnail } = this.state;
		if (uploadThumbnail) {
			message.error("操作未完成");
			return;
		}
		this.setState({ modalImage: { 'visible': false, 'img': null, 'title': "" } });
	};

	// 显示模板图片
	showTemplateImage = (event, editorCallback) => {
		const guid = event.currentTarget.getAttribute("guid");
		const canEditor = event.currentTarget.getAttribute("can_editor");
		// const editorCallback = event.currentTarget.getAttribute("editor_callback");
		const template = this.state.templates[guid];
		this.setState({ modalImage: {
				'visible': true,
				'img': template.img,
				'title': template.title ? template.title : template.description.en,
				'noteId': template.orig_note_guid,
				'guid': template.guid,
				'canEditor': canEditor,
				'editorCallback': editorCallback,
		}});
	};

	// 点击版本-模版事件
	onSelectTemplateVersion = event => {
		const editorContent = (
				<TemplateList
						versionId={event.key}
						publishCallback={() => {
							this.state.editorContent = null;
							this.requestVersions();
						}}
						showTemplateImage={this.showTemplateImage}
						requestExecuteCommand={this.requestExecuteCommand}
						refreshCallback={this.requestVersions}
				/>
		);
		this.setState({ editorContent: null }, () => this.setState({ editorContent: editorContent }));
	};

	// 点击自动生成缩略图
	onAutoThumbnail = event => {
		const noteId = event.currentTarget.getAttribute("noteid");
		const operation = { command: 'AutoThumbnail', data: noteId };
		const { modalImage, templates } = this.state;
		// 复制一份快照，用来图片框被关闭的处理。
		const snapModal = Object.assign({}, modalImage);
		this.requestExecuteCommand([operation], () => {
			message.info('已设置自动生成缩略图');
			const template = templates[snapModal.guid];
			template.thumbnail_url = 'large';
			this.requestThumbnail(template.guid, img => {
				template.img = img;
				const state = {uploadThumbnail: false, templates};
				if (snapModal.guid === modalImage.guid) { // 还是打开同一个缩略图
					modalImage.img = img;
					state.modalImage = modalImage;
				}
				this.setState(state);
				if (snapModal.editorCallback) {
					snapModal.editorCallback(snapModal.guid, img);
				}
			});
		});
		this.setState({ uploadThumbnail: true });
	};

	// =================================命令操作方法=================================

	// 保存操作
	saveOperation = call => {
		const onOk = () => this.requestExecuteCommand(this.state.operations, () => {
			message.info("操作已保存");
			this.setState({ operations: [], metadataId: "-1" }, this.requestVersions);
			if (call) {
				call();
			}
		});
		this.showConfirm('是否保存当前操作?', onOk);
	};

	// 添加操作
	addOperation = op => {
		const operations = this.state.operations;
		operations.push(op);
		return operations;
	};

	// 复制版本
	snapVersion(version) {
		const snapVersion = Object.assign({}, version);
		snapVersion.template_ids = [];
		snapVersion.metadata_order = [];
		if (version.template_ids) {
			version.template_ids.forEach(guid => snapVersion.template_ids.push(guid));
		}
		if (version.metadata_order) {
			version.metadata_order.forEach(guid => snapVersion.metadata_order.push(guid));
		}
		return snapVersion;
	};

	// =================================界面方法=================================

	// 显示删除提示
	showDeleteConfirm = onOk => this.showConfirm('确认删除?', onOk);

	// 显示提交框
	showConfirm = (title, onOk, onCancel) => {
		confirm({
			title: title,
			content: '',
			okText: '是',
			okType: 'danger',
			cancelText: '否',
			onOk: onOk ? onOk : () => {},
			onCancel: onCancel ? onCancel : () => {},
		});
	};

	// 左侧面板选择变化
	onChangePanel = panelId => {
		// 克隆一份模版列表
		const snapMetadatas = this.snapMetadata(this.state.metadatas);
		// 面板变化
		const undo = () => this.setState({ editorContent: null, panelId, operations: [], snapMetadatas });
		const exec = () => this.setState({ editorContent: null, panelId, snapMetadatas });
		this.askLeave(exec, undo);
	};

	// 询问是否离开
	askLeave = (leave, undo) => {
		const { operations, metadatas, version } = this.state;
		if (operations.length > 0) { // 有未保存操作
			this.showConfirm('有未保存操作，是否放弃这些操作?', () => {
				this.state.snapMetadatas = this.snapMetadata(metadatas);
				this.state.snapVersion = this.snapVersion(version);
				undo();
			});
		} else { // 没有未保存操作
			leave();
		}
	};

	// 创建左侧分类和系列内容
	createPanelContent = (title, key, list) => {
		return (
				<Panel
						header={<span><Icon type="paper-clip" /><span>{' ' + title}</span></span>}
						key={key}
				>
					<div className={`${this.props.className}-wrapper`}>
						<div className={this.props.className}>
							<ListSort
									dragClassName="list-drag-selected"
									appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
									onChange={this.onMetadataIndexChange}
							>
								{list}
							</ListSort>
						</div>
					</div>
					<div key='-1' className='create'>
						<Button
								type="primary"
								shape="circle"
								icon="plus-circle"
								id={key == 1 ? "-2" : "-1"}
								onClick={this.showMetadataEditor}
						/>
					</div>
				</Panel>
		)
	};

	// 获取版本名称
	getVersionName(state, id) {
		switch (state) {
			case 0 :
				return "草稿";
			case 1 :
				return "生产";
			default :
				return "历史-" + id;
		}
	}

	// 获取版本状态图标
	getVersionStateIcon(state) {
		switch (state) {
			case 0 :
				return "edit";
			case 1 :
				return "api";
			default :
				return "database";
		}
	}

	// 渲染左边栏
	renderSider(panelId, categoryRender, collectionRender) {
		const { versions } = this.state;
		return (
				<Sider>
					<Collapse accordion onChange={this.onChangePanel} activeKey={panelId}>
						{/*系列*/}
						{this.createPanelContent(METADATA_TYPE_COLLECTION_TEXT, '2', collectionRender)}
						{/*分类*/}
						{this.createPanelContent(METADATA_TYPE_CATEGORY_TEXT, '1', categoryRender)}
						{/*版本*/}
						<Panel key="3" header={<span><Icon type="branches" /><span> 版本</span></span>}>
							<Menu mode="inline" style={{ width: '100%' }} >
								{
									Object.keys(versions).sort((a, b) => parseInt(b) - parseInt(a)).map(id => {
										return (
												<Menu.Item key={id} onClick={this.onSelectTemplateVersion}>
													<span><Icon type={this.getVersionStateIcon(versions[id])} theme="twoTone" /></span>
													<span>{this.getVersionName(versions[id], id)}</span>
												</Menu.Item>
										);
									})
								}
							</Menu>
						</Panel>
					</Collapse>
				</Sider>
		)
	}

	// 读取图片
	readImage = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	// 上传变化
	onThumbnailUploadChange = info => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadThumbnail: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			const { modalImage, templates } = this.state;
			const template = templates[modalImage.guid];
			template.thumbnail_url = 'override';
			this.requestThumbnail(template.guid, img => {
				template.img = img;
				modalImage.img = img;
				this.setState({ uploadThumbnail: false, templates, modalImage });
				if (modalImage.editorCallback) {
					modalImage.editorCallback(modalImage.guid, img);
				}
			});
		}
	};

	// 渲染支持上传的图片模态框
	renderModalImage = modalImage => {
		const { uploadThumbnail, serverUrl } = this.state;
		const { visible, title, noteId, img } = modalImage;
		let thumbnail = uploadThumbnail ? <Icon type='loading' style={{ width: 200, height: 234 }}  /> :
				<img src={img} style={{width: 200}} />;
		thumbnail = modalImage.canEditor === "true" ?
				<Dragger
						name="file"
						listType="picture-card"
						className="avatar-uploader"
						showUploadList={false}
						action={serverUrl + '/uploadImage/UploadNoteThumbnail/' + noteId}
						withCredentials={true}
						// headers={{}}
						// beforeUpload={beforeUpload}
						onChange={this.onThumbnailUploadChange}
				>
					{thumbnail}
				</Dragger> : thumbnail;
		const autoButton = modalImage.canEditor === "true" ?
				<div align="center" style={{ margin: 10 }}>
					<Button type="primary" onClick={this.onAutoThumbnail} noteid={noteId} disabled={uploadThumbnail}>重置缩略图</Button>
				</div> : <div></div>;
		return (
				<Modal
						visible={visible}
						title={title ? title.zh : "缩略图"}
						onCancel={this.closeTemplateImage}
						footer={null}
						mask={true}
						maskClosable={!uploadThumbnail}
						width={260}
				>
					<div align="center" style={{ width: 210 }}>
						{thumbnail}
						{autoButton}
					</div>
				</Modal>
		);
	};

	// Override
  render() {
  	const { modalImage, editorContent, panelId, loading } = this.state;
		// 系列列表
		const collectionRender = this.filterMetadataRender(METADATA_TYPE_COLLECTION);
		// 分类列表
		const categoryRender = this.filterMetadataRender(METADATA_TYPE_CATEGORY);
		// 渲染
    return (
				<Spin spinning={loading} tip="请等待...">
					<div className="App">
						{/*Metadata编辑框*/}
						{this.renderMetadataEditor()}
						{/*图片展示板*/}
						{this.renderModalImage(modalImage)}
						{/*主布局*/}
						<Layout style={{ minHeight: '100vh' }}>
							<Layout>
								{/*左边元素*/}
								{this.renderSider(panelId, categoryRender, collectionRender)}
								{/*模板内容*/}
								<Layout>
									{editorContent}
								</Layout>
							</Layout>
						</Layout>
					</div>
				</Spin>
    );
  }

}

export default (Template);

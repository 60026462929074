import React from "react";
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    Divider,
    Icon,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Row,
    Table
} from 'antd';
import './layout.css';
import {
    AddRecommendTemplateRequest,
    AdvancedTemplateAdminService,
    AdvancedTemplateService,
    GetPublishedTemplatesRequest,
    GetRecommendTemplatesAdminRequest,
    Pageable,
    Template,
    UpdateRecommendTemplateRequest
} from "./grpcweb/AdvancedTemplateService";

const {Content} = Layout;


export default class RecommendTemplateManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //渲染模版查询列表
            recommendTemplatesList: [],
            //模版查询请求参数
            pagesize: 20,
            total: 0,
            current: 1,
            terminalType: 0,
            platform: 1,
            //添加模版模态框
            addTemplateModalShow: false,
            selectedRowKeys: [],
            templatesList: [],
            modalPagesize: 10,
            modalTotal: 0,
            modalCurrent: 1,
        };
    };

    componentDidMount = () => {
        this.queryRecommendTemplates(1);
    }

    /**
     * 请求已推荐模版列表
     */
    queryRecommendTemplates = (page) => {
        let request = new GetRecommendTemplatesAdminRequest();
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        console.log('getRecommendTemplatesAdmin request:', JSON.stringify(request));
        AdvancedTemplateAdminService.getRecommendTemplatesAdmin(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                switch (res.code) {
                    case 200:
                        res.datasList.forEach((data) => {
                            switch (data.template.type) {
                                case 1:
                                    data.template.typeName = '空间模版';
                                    break;
                                case 2:
                                    data.template.typeName = '笔记本模版';
                                    break;
                                case 3:
                                    data.template.typeName = '笔记模版';
                                    break;
                                default:
                                    break;
                            }
                            switch (data.template.servicelevel) {
                                case 1:
                                    data.template.servicelevelName = '免费账户';
                                    break;
                                case 2:
                                    data.template.servicelevelName = '标准账户';
                                    break;
                                case 3:
                                    data.template.servicelevelName = '高级账户';
                                    break;
                                case 5:
                                    data.template.servicelevelName = '专业账户';
                                    break;
                                default:
                                    break;
                            }
                            switch (data.template.terminaltype) {
                                case 0:
                                    data.template.terminaltype = '桌面端';
                                    break;
                                case 1:
                                    data.template.terminaltype = '移动端';
                                    break;
                                case 2:
                                    data.template.terminaltype = '桌面端和移动端';
                                    break;
                                default:
                                    break;
                            }
                        });
                        this.setState(
                            {
                                recommendTemplatesList: res.datasList,
                                total: res.total,
                                current: page,
                            },
                            () => console.log("INFO:getRecommendTemplatesAdmin : ", this.state.total)
                        );
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    };

    /**
     * 上移推荐模板
     */
    moveUpRecommendTemplate = (templateId, previousOrder) => {
        let request = new UpdateRecommendTemplateRequest();
        request.setTemplateid(templateId);
        request.setOrdernum(previousOrder);
        request.setDelete(false);
        console.log('updateRecommendTemplate request:', JSON.stringify(request));
        AdvancedTemplateAdminService.updateRecommendTemplate(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                switch (res.code) {
                    case 200:
                        message.success('操作成功');
                        this.queryRecommendTemplates();
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    };

    //点击删除模版按钮
    handleDeleteTemplate = (record) => {
        console.log("record:", JSON.stringify(record));
        let request = new UpdateRecommendTemplateRequest();
        request.setTemplateid(record.template.templateid);
        request.setDelete(true);
        console.log('updateRecommendTemplate request:', JSON.stringify(request));
        AdvancedTemplateAdminService.updateRecommendTemplate(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                let res = response.toObject();
                switch (res.code) {
                    case 200:
                        Modal.success({content: '删除模版成功'});
                        this.queryRecommendTemplates();
                        break;
                    default:
                        break;
                }
            }
        });
    }

    //新增模版
    addRecommendTemplate = (templateIds) => {
        console.log("templateIds:", JSON.stringify(templateIds));
        let request = new AddRecommendTemplateRequest();
        request.setTemplateidList(templateIds);
        console.log('addRecommendTemplate request:', JSON.stringify(request));
        AdvancedTemplateAdminService.addRecommendTemplate(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                let res = response.toObject();
                switch (res.code) {
                    case 200:
                        Modal.success({content: '新增模版成功'});
                        this.queryRecommendTemplates();
                        break;
                    default:
                        break;
                }
            }
        });
    }

    onChangePage = page => {
        this.queryRecommendTemplates(page);
    };

    onModalChangePage = page => {
        this.getTemplates(page);
        const selectedRowKeys = [];
        this.setState({selectedRowKeys});
    };

    getTemplates = (page) => {
        let request = new GetPublishedTemplatesRequest();
        let template = new Template();
        template.setTitle(this.state.templateTitle);
        request.setTemplate(template);
        template.addPlatform(1);
        template.setTerminaltype(2);
        let pageable = new Pageable();
        pageable.setPagesize(this.state.modalPagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        console.log('getPublishedTemplates request', JSON.stringify(request));
        AdvancedTemplateService.getPublishedTemplates(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                console.log("response.Templates: " + JSON.stringify(res.templatesList));
                res.templatesList.map((template) => {
                    this.getTemplateEnumName(template);
                });
                // debugger;
                console.log("INFO:total : ", res.total)
                const selectedRowKeys = [];
                this.setState(
                    {
                        templatesList: res.templatesList,
                        modalTotal: res.total,
                        modalCurrent: page,
                        selectedRowKeys
                    },
                    () => console.log("INFO:getTemplates : ", this.state.templatesList)
                );
            }
        });
    };

    getTemplateEnumName = (template) => {
        switch (template.type) {
            case 1:
                template.typeName = '空间模版';
                break;
            case 2:
                template.typeName = '笔记本模版';
                break;
            case 3:
                template.typeName = '笔记模版';
                break;
            default: break;
        }
        switch (template.servicelevel) {
            case 1:
                template.servicelevelName = '免费账户';
                break;
            case 2:
                template.servicelevelName = '标准账户';
                break;
            case 3:
                template.servicelevelName = '高级账户';
                break;
            case 5:
                template.servicelevelName = '专业账户';
                break;
            default: break;
        }
    }

    showAddTemplateModal = () => {
        this.setState(
            {
                addTemplateModalShow: true,
            }
        );
        this.getTemplates(1);
    }

    handleAddTemplateOk = () => {
        if (this.state.selectedTableTemplates.length === 0) {
            message.error('请选择要添加的模版');
            return;
        }
        this.setState(
            {
                addTemplateModalShow: false,
                templatesList: [],
            }
        );
        console.log('selectedTableTemplates', this.state.selectedTableTemplates);
        this.addRecommendTemplate(this.state.selectedTableTemplates.map(t => t.templateid));
    };

    handleAddTemplateCancel = () => {
        this.setState(
            {
                addTemplateModalShow: false,
                templatesList: [],
            }
        );
    };

    handelTemplateTitleChange = (e) => {
        this.setState({
            templateTitle: e.target.value
        });
    };

    //上移模板
    moveUp = (record) => {
        console.log("record:", JSON.stringify(record));
        this.moveUpRecommendTemplate(record.template.templateid, record.order);
    };


    render() {
        const {selectedRowKeys} = this.state;
        const templatesRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedTableTemplates: selectedRows,
                    selectedRowKeys
                });
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            selectedRowKeys
        };
        return (
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>新用户推荐模版</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Row gutter={16}>
                        <Col span={1} offset={1}>
                            <Button type="primary" onClick={this.showAddTemplateModal}>新增模版</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                    <div>
                        <Table
                            columns={
                                [
                                    {
                                        title: '模版ID',
                                        dataIndex: 'template.templateid',
                                    },
                                    {
                                        title: '模版名称',
                                        dataIndex: 'template.title',
                                    },
                                    {
                                        title: '类型',
                                        dataIndex: 'template.typeName',
                                    },
                                    {
                                        title: '端类型',
                                        dataIndex: 'template.terminaltype',
                                    },
                                    {
                                        title: '用户限制等级',
                                        dataIndex: 'template.servicelevelName',
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, record) => (
                                            <span>
                                                <Popconfirm title="Sure to delete?"
                                                            onConfirm={() => this.handleDeleteTemplate(record)}>
                                                    <a>删除</a>
                                                </Popconfirm>
                                            </span>
                                        ),
                                    },
                                    {
                                        title: '移动',
                                        key: 'move',
                                        render: (text, record) => {
                                            return (
                                                <span>
                                                    {/* 过滤掉order为1的值 */}
                                                    {record.order !== 1 &&
                                                    (<Button shape="circle" style={{marginRight: 50}}
                                                             onClick={() => this.moveUp(record)}>
                                                        <Icon type="up"/>
                                                    </Button>)
                                                    }
                                                </span>
                                            )
                                        },
                                    },
                                ]
                            }
                            dataSource={this.state.recommendTemplatesList}
                            pagination={{
                                pageSize: this.state.pagesize,
                                total: this.state.total,
                                onChange: this.onChangePage,
                            }}
                        />
                    </div>
                    <Modal title="新增模版" visible={this.state.addTemplateModalShow} onOk={this.handleAddTemplateOk}
                           onCancel={this.handleAddTemplateCancel} width={660} okText="新增"
                           cancelText="返回">
                        <Row gutter={16}>
                            <Col span={4} offset={2}> 模版名称</Col>
                            <Col span={10}>
                                <Input type="text" value={this.state.templateTitle}
                                       onChange={this.handelTemplateTitleChange}/>
                            </Col>
                            <Col span={2} offset={1}>
                                <Button type="primary" style={{width: 100}} onClick={() => this.getTemplates(1)}>
                                    查询
                                </Button>
                            </Col>
                        </Row>
                        <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                        <div>
                            <Table
                                rowSelection={{
                                    type: Checkbox,
                                    ...templatesRowSelection,
                                }}
                                columns={
                                    [
                                        {
                                            title: '模版id',
                                            dataIndex: 'templateid',
                                        },
                                        {
                                            title: '模版名称',
                                            align: 'center',
                                            dataIndex: 'title',
                                        },
                                        {
                                            title: '类型',
                                            dataIndex: 'typeName',
                                        },
                                        {
                                            title: '用户限制等级',
                                            dataIndex: 'servicelevelName',
                                        }
                                    ]
                                }
                                dataSource={this.state.templatesList}
                                pagination={{
                                    pageSize: this.state.modalPagesize,
                                    total: this.state.modalTotal,
                                    onChange: this.onModalChangePage,
                                }}
                            />
                        </div>
                    </Modal>
                </div>
            </Content>
        );
    }
}
import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from "react-router";
import Template from "./admin/template/Template";
import AdvancedTemplate from "./admin/advanced-template/AdvancedTemplate";
import AdvancedTemplateET from "./admin/advanced-template-et/AdvancedTemplate";
import AdvancedTemplateVerse from "./admin/advanced-template-verse/AdvancedTemplate";
import DefaultContent from "./admin/advanced-template/DefaultContent";
import WalletAdmin from "./admin/wallet-admin/WalletAdmin";
import Material from "./admin/material/Material";
import { HashRouter } from "react-router-dom";

class App extends Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/template" component={Template} />
          <Route path="/advanced-template" component={AdvancedTemplate} />
          <Route path="/advanced-template-et" component={AdvancedTemplateET} />
          <Route path="/advanced-template-verse" component={AdvancedTemplateVerse} />
          <Route path="/wallet-admin" component={WalletAdmin} />
          <Route path="/material" component={Material} />
          <Route path="/" component={DefaultContent} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;

import {EVN} from "../../wallet-admin/grpcweb/evn";

export const {
    AddGroupRequest,AddMaterialRequest,ClientType,CommonResponse,DelGroupRequest,DeletedMaterialRequest,
    GetDraftMaterialByTypeReply,GetDraftMaterialByTypeRequest,GetGroupsReply,GetGroupsRequest,GetLastPublishMaterialRely,
    GetLastPublishMaterialRequest,GetMaterialInGroupReply,GetMaterialInGroupRequest,GetMaterialReply,GetMaterialRequest,
    GetMaterialTypeReply,GetMaterialTypeRequest,GetMaterialsReply,GetMaterialsRequest,GetPublishGroupReply,
    GetPublishGroupRequest,GetPublishListReply,GetPublishListRequest,GetPurchasedMaterialReply,GetPurchasedMaterialRequest,
    Group,GroupInfo,MaterialData,MaterialRelation,MaterialType,Pageable,PublishGroup,PublishGroupReply,PublishGroupRequest,
    PublishMaterialData,PublishMaterialRequest,RescindPublishMaterial,ResponseStatus,UpdateGroupOrderRequest,UpdateGroupRequest,
    UpdateMaterialInGroupRequest,UpdateMaterialRequest
} = require('./official-material-type_pb.js');

// const materialServiceHost = "http://10.112.43.208:62000";
// const materialServiceHost = "https://stage.yinxiang.com/third/official-material-service/grpcweb";
// const materialServiceHost = "https://app.yinxiang.com/third/official-material-service/grpcweb";
const materialServiceHost = EVN + "/third/official-material-service/grpcweb";

const { OfficialMaterialAdminServiceClient } = require('./official-material-admin_grpc_web_pb.js');

export const OfficialMaterialAdminService = new OfficialMaterialAdminServiceClient(
  materialServiceHost,
    null,
    { withCredentials: true }
);

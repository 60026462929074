/* eslint-disable no-undef */
/**
 * @fileoverview gRPC-Web generated client stub for official.material
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


// var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var official$material$type_pb = require('./official-material-type_pb.js')
const proto = {};
proto.official = {};
proto.official.material = require('./official-material-admin_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.official.material.OfficialMaterialAdminServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetMaterialTypeRequest,
 *   !proto.types.GetMaterialTypeReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getMaterialType = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getMaterialType',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetMaterialTypeRequest,
  official$material$type_pb.GetMaterialTypeReply,
  /** @param {!proto.types.GetMaterialTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialTypeReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetMaterialTypeRequest,
 *   !proto.types.GetMaterialTypeReply>}
 */
const methodInfo_OfficialMaterialAdminService_getMaterialType = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetMaterialTypeReply,
  /** @param {!proto.types.GetMaterialTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialTypeReply.deserializeBinary
);


/**
 * @param {!proto.types.GetMaterialTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetMaterialTypeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetMaterialTypeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getMaterialType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialType,
      callback);
};


/**
 * @param {!proto.types.GetMaterialTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetMaterialTypeReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getMaterialType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.AddMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_addMaterial = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/addMaterial',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.AddMaterialRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.AddMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.AddMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_addMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.AddMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.AddMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.addMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/addMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_addMaterial,
      callback);
};


/**
 * @param {!proto.types.AddMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.addMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/addMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_addMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.UpdateMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_updateMaterial = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/updateMaterial',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.UpdateMaterialRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.UpdateMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_updateMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.UpdateMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.updateMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateMaterial,
      callback);
};


/**
 * @param {!proto.types.UpdateMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.updateMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetMaterialsRequest,
 *   !proto.types.GetMaterialsReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getMaterials = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getMaterials',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetMaterialsRequest,
  official$material$type_pb.GetMaterialsReply,
  /** @param {!proto.types.GetMaterialsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialsReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetMaterialsRequest,
 *   !proto.types.GetMaterialsReply>}
 */
const methodInfo_OfficialMaterialAdminService_getMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetMaterialsReply,
  /** @param {!proto.types.GetMaterialsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialsReply.deserializeBinary
);


/**
 * @param {!proto.types.GetMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetMaterialsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetMaterialsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterials',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterials,
      callback);
};


/**
 * @param {!proto.types.GetMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetMaterialsReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterials',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetMaterialRequest,
 *   !proto.types.GetMaterialReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getMaterialById = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getMaterialById',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetMaterialRequest,
  official$material$type_pb.GetMaterialReply,
  /** @param {!proto.types.GetMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetMaterialRequest,
 *   !proto.types.GetMaterialReply>}
 */
const methodInfo_OfficialMaterialAdminService_getMaterialById = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetMaterialReply,
  /** @param {!proto.types.GetMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialReply.deserializeBinary
);


/**
 * @param {!proto.types.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetMaterialReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetMaterialReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getMaterialById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialById,
      callback);
};


/**
 * @param {!proto.types.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetMaterialReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getMaterialById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.DeletedMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_deletedMaterialById = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/deletedMaterialById',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.DeletedMaterialRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.DeletedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.DeletedMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_deletedMaterialById = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.DeletedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.DeletedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.deletedMaterialById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/deletedMaterialById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_deletedMaterialById,
      callback);
};


/**
 * @param {!proto.types.DeletedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.deletedMaterialById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/deletedMaterialById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_deletedMaterialById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.AddGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_addGroup = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/addGroup',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.AddGroupRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.AddGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.AddGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_addGroup = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.AddGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.AddGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.addGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/addGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_addGroup,
      callback);
};


/**
 * @param {!proto.types.AddGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.addGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/addGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_addGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.UpdateGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_updateGroup = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/updateGroup',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.UpdateGroupRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.UpdateGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_updateGroup = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateGroup,
      callback);
};


/**
 * @param {!proto.types.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.DelGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_delGroupById = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/delGroupById',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.DelGroupRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.DelGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.DelGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_delGroupById = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.DelGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.DelGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.delGroupById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/delGroupById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_delGroupById,
      callback);
};


/**
 * @param {!proto.types.DelGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.delGroupById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/delGroupById',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_delGroupById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetGroupsRequest,
 *   !proto.types.GetGroupsReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getGroups = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getGroups',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetGroupsRequest,
  official$material$type_pb.GetGroupsReply,
  /** @param {!proto.types.GetGroupsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetGroupsReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetGroupsRequest,
 *   !proto.types.GetGroupsReply>}
 */
const methodInfo_OfficialMaterialAdminService_getGroups = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetGroupsReply,
  /** @param {!proto.types.GetGroupsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetGroupsReply.deserializeBinary
);


/**
 * @param {!proto.types.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetGroupsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetGroupsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getGroups',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getGroups,
      callback);
};


/**
 * @param {!proto.types.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetGroupsReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getGroups',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetGroupsRequest,
 *   !proto.types.GetAllGroupByTypeReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getAllGroupByType = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getAllGroupByType',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetGroupsRequest,
  official$material$type_pb.GetAllGroupByTypeReply,
  /** @param {!proto.types.GetGroupsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetAllGroupByTypeReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetGroupsRequest,
 *   !proto.types.GetAllGroupByTypeReply>}
 */
const methodInfo_OfficialMaterialAdminService_getAllGroupByType = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetAllGroupByTypeReply,
  /** @param {!proto.types.GetGroupsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetAllGroupByTypeReply.deserializeBinary
);


/**
 * @param {!proto.types.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetAllGroupByTypeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetAllGroupByTypeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getAllGroupByType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getAllGroupByType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getAllGroupByType,
      callback);
};


/**
 * @param {!proto.types.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetAllGroupByTypeReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getAllGroupByType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getAllGroupByType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getAllGroupByType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.UpdateGroupOrderRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_updateGroupOrder = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/updateGroupOrder',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.UpdateGroupOrderRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateGroupOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.UpdateGroupOrderRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_updateGroupOrder = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateGroupOrderRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.UpdateGroupOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.updateGroupOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateGroupOrder',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateGroupOrder,
      callback);
};


/**
 * @param {!proto.types.UpdateGroupOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.updateGroupOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateGroupOrder',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateGroupOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetMaterialInGroupRequest,
 *   !proto.types.GetMaterialInGroupReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_GetMaterialInGroup = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/GetMaterialInGroup',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetMaterialInGroupRequest,
  official$material$type_pb.GetMaterialInGroupReply,
  /** @param {!proto.types.GetMaterialInGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialInGroupReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetMaterialInGroupRequest,
 *   !proto.types.GetMaterialInGroupReply>}
 */
const methodInfo_OfficialMaterialAdminService_GetMaterialInGroup = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetMaterialInGroupReply,
  /** @param {!proto.types.GetMaterialInGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialInGroupReply.deserializeBinary
);


/**
 * @param {!proto.types.GetMaterialInGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetMaterialInGroupReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetMaterialInGroupReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getMaterialInGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/GetMaterialInGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_GetMaterialInGroup,
      callback);
};


/**
 * @param {!proto.types.GetMaterialInGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetMaterialInGroupReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getMaterialInGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/GetMaterialInGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_GetMaterialInGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.UpdateMaterialInGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_updateMaterialInGroup = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/updateMaterialInGroup',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.UpdateMaterialInGroupRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateMaterialInGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.UpdateMaterialInGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_updateMaterialInGroup = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.UpdateMaterialInGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.UpdateMaterialInGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.updateMaterialInGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateMaterialInGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateMaterialInGroup,
      callback);
};


/**
 * @param {!proto.types.UpdateMaterialInGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.updateMaterialInGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/updateMaterialInGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_updateMaterialInGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetDraftMaterialByTypeRequest,
 *   !proto.types.GetDraftMaterialByTypeReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getDraftMaterialByType = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getDraftMaterialByType',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetDraftMaterialByTypeRequest,
  official$material$type_pb.GetDraftMaterialByTypeReply,
  /** @param {!proto.types.GetDraftMaterialByTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetDraftMaterialByTypeReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetDraftMaterialByTypeRequest,
 *   !proto.types.GetDraftMaterialByTypeReply>}
 */
const methodInfo_OfficialMaterialAdminService_getDraftMaterialByType = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetDraftMaterialByTypeReply,
  /** @param {!proto.types.GetDraftMaterialByTypeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetDraftMaterialByTypeReply.deserializeBinary
);


/**
 * @param {!proto.types.GetDraftMaterialByTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetDraftMaterialByTypeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetDraftMaterialByTypeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getDraftMaterialByType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getDraftMaterialByType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getDraftMaterialByType,
      callback);
};


/**
 * @param {!proto.types.GetDraftMaterialByTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetDraftMaterialByTypeReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getDraftMaterialByType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getDraftMaterialByType',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getDraftMaterialByType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.PublishMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_publishMaterial = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/publishMaterial',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.PublishMaterialRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.PublishMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.PublishMaterialRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_publishMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.PublishMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.PublishMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.publishMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/publishMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_publishMaterial,
      callback);
};


/**
 * @param {!proto.types.PublishMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.publishMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/publishMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_publishMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetPublishListRequest,
 *   !proto.types.GetPublishListReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getPublishMaterialList = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getPublishMaterialList',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetPublishListRequest,
  official$material$type_pb.GetPublishListReply,
  /** @param {!proto.types.GetPublishListRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPublishListReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetPublishListRequest,
 *   !proto.types.GetPublishListReply>}
 */
const methodInfo_OfficialMaterialAdminService_getPublishMaterialList = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetPublishListReply,
  /** @param {!proto.types.GetPublishListRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPublishListReply.deserializeBinary
);


/**
 * @param {!proto.types.GetPublishListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetPublishListReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetPublishListReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getPublishMaterialList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPublishMaterialList',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPublishMaterialList,
      callback);
};


/**
 * @param {!proto.types.GetPublishListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetPublishListReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getPublishMaterialList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPublishMaterialList',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPublishMaterialList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.RescindPublishMaterial,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_rescindPublishMaterial = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/rescindPublishMaterial',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.RescindPublishMaterial,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.RescindPublishMaterial} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.RescindPublishMaterial,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_rescindPublishMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.RescindPublishMaterial} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.RescindPublishMaterial} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.rescindPublishMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/rescindPublishMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_rescindPublishMaterial,
      callback);
};


/**
 * @param {!proto.types.RescindPublishMaterial} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.rescindPublishMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/rescindPublishMaterial',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_rescindPublishMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.PublishGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodDescriptor_OfficialMaterialAdminService_publishGroup = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/publishGroup',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.PublishGroupRequest,
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.PublishGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.PublishGroupRequest,
 *   !proto.types.CommonResponse>}
 */
const methodInfo_OfficialMaterialAdminService_publishGroup = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.CommonResponse,
  /** @param {!proto.types.PublishGroupRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.types.PublishGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.publishGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/publishGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_publishGroup,
      callback);
};


/**
 * @param {!proto.types.PublishGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.CommonResponse>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.publishGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/publishGroup',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_publishGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetPurchasedMaterialRequest,
 *   !proto.types.GetPurchasedMaterialReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByIdInternal = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByIdInternal',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetPurchasedMaterialRequest,
  official$material$type_pb.GetPurchasedMaterialReply,
  /** @param {!proto.types.GetPurchasedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPurchasedMaterialReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetPurchasedMaterialRequest,
 *   !proto.types.GetPurchasedMaterialReply>}
 */
const methodInfo_OfficialMaterialAdminService_getPurchasedMaterialByIdInternal = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetPurchasedMaterialReply,
  /** @param {!proto.types.GetPurchasedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPurchasedMaterialReply.deserializeBinary
);


/**
 * @param {!proto.types.GetPurchasedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetPurchasedMaterialReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetPurchasedMaterialReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getPurchasedMaterialByIdInternal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByIdInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByIdInternal,
      callback);
};


/**
 * @param {!proto.types.GetPurchasedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetPurchasedMaterialReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getPurchasedMaterialByIdInternal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByIdInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByIdInternal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetPurchasedMaterialRequest,
 *   !proto.types.GetPurchasedMaterialReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByCodeInternal = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByCodeInternal',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetPurchasedMaterialRequest,
  official$material$type_pb.GetPurchasedMaterialReply,
  /** @param {!proto.types.GetPurchasedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPurchasedMaterialReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetPurchasedMaterialRequest,
 *   !proto.types.GetPurchasedMaterialReply>}
 */
const methodInfo_OfficialMaterialAdminService_getPurchasedMaterialByCodeInternal = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetPurchasedMaterialReply,
  /** @param {!proto.types.GetPurchasedMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetPurchasedMaterialReply.deserializeBinary
);


/**
 * @param {!proto.types.GetPurchasedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetPurchasedMaterialReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetPurchasedMaterialReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getPurchasedMaterialByCodeInternal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByCodeInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByCodeInternal,
      callback);
};


/**
 * @param {!proto.types.GetPurchasedMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetPurchasedMaterialReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getPurchasedMaterialByCodeInternal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getPurchasedMaterialByCodeInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getPurchasedMaterialByCodeInternal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.types.GetMaterialRequest,
 *   !proto.types.GetMaterialReply>}
 */
const methodDescriptor_OfficialMaterialAdminService_getMaterialByCodeInternal = new grpc.web.MethodDescriptor(
  '/official.material.OfficialMaterialAdminService/getMaterialByCodeInternal',
  grpc.web.MethodType.UNARY,
  official$material$type_pb.GetMaterialRequest,
  official$material$type_pb.GetMaterialReply,
  /** @param {!proto.types.GetMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.types.GetMaterialRequest,
 *   !proto.types.GetMaterialReply>}
 */
const methodInfo_OfficialMaterialAdminService_getMaterialByCodeInternal = new grpc.web.AbstractClientBase.MethodInfo(
  official$material$type_pb.GetMaterialReply,
  /** @param {!proto.types.GetMaterialRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  official$material$type_pb.GetMaterialReply.deserializeBinary
);


/**
 * @param {!proto.types.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.types.GetMaterialReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.types.GetMaterialReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.official.material.OfficialMaterialAdminServiceClient.prototype.getMaterialByCodeInternal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialByCodeInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialByCodeInternal,
      callback);
};


/**
 * @param {!proto.types.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.types.GetMaterialReply>}
 *     A native promise that resolves to the response
 */
proto.official.material.OfficialMaterialAdminServicePromiseClient.prototype.getMaterialByCodeInternal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/official.material.OfficialMaterialAdminService/getMaterialByCodeInternal',
      request,
      metadata || {},
      methodDescriptor_OfficialMaterialAdminService_getMaterialByCodeInternal);
};


module.exports = proto.official.material;


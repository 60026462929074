/* eslint-disable no-undef */
/**
 * @fileoverview gRPC-Web generated client stub for advancedtemplate
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


// var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var common_pb = require('./common_pb.js')
const proto = {};
proto.advancedtemplate = require('./advanced-template-admin-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.GetProductTemplateRequest,
 *   !proto.advancedtemplate.GetProductTemplateResponse>}
 */
const methodDescriptor_AdvancedTemplateAdminService_getProductTemplate = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/getProductTemplate',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.GetProductTemplateRequest,
  proto.advancedtemplate.GetProductTemplateResponse,
  /** @param {!proto.advancedtemplate.GetProductTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetProductTemplateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetProductTemplateRequest,
 *   !proto.advancedtemplate.GetProductTemplateResponse>}
 */
const methodInfo_AdvancedTemplateAdminService_getProductTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetProductTemplateResponse,
  /** @param {!proto.advancedtemplate.GetProductTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetProductTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetProductTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetProductTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetProductTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.getProductTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getProductTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getProductTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetProductTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetProductTemplateResponse>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.getProductTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getProductTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getProductTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.GetDraftProductTemplateRequest,
 *   !proto.advancedtemplate.GetDraftProductTemplateResponse>}
 */
const methodDescriptor_AdvancedTemplateAdminService_getDraftProductTemplate = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/getDraftProductTemplate',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.GetDraftProductTemplateRequest,
  proto.advancedtemplate.GetDraftProductTemplateResponse,
  /** @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftProductTemplateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetDraftProductTemplateRequest,
 *   !proto.advancedtemplate.GetDraftProductTemplateResponse>}
 */
const methodInfo_AdvancedTemplateAdminService_getDraftProductTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetDraftProductTemplateResponse,
  /** @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftProductTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetDraftProductTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetDraftProductTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.getDraftProductTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getDraftProductTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getDraftProductTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetDraftProductTemplateResponse>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.getDraftProductTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getDraftProductTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getDraftProductTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.TopTemplateInSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_topTemplateInSeries = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/topTemplateInSeries',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.TopTemplateInSeriesRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.TopTemplateInSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_topTemplateInSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.topTemplateInSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/topTemplateInSeries',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_topTemplateInSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.topTemplateInSeries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/topTemplateInSeries',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_topTemplateInSeries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.AddRecommendTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_addRecommendTemplate = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/addRecommendTemplate',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.AddRecommendTemplateRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddRecommendTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddRecommendTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_addRecommendTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddRecommendTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddRecommendTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.addRecommendTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/addRecommendTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_addRecommendTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.AddRecommendTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.addRecommendTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/addRecommendTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_addRecommendTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.UpdateRecommendTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_updateRecommendTemplate = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/updateRecommendTemplate',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.UpdateRecommendTemplateRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateRecommendTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_updateRecommendTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.updateRecommendTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/updateRecommendTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_updateRecommendTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.updateRecommendTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/updateRecommendTemplate',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_updateRecommendTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.GetRecommendTemplatesAdminRequest,
 *   !proto.advancedtemplate.GetRecommendTemplatesAdminReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_getRecommendTemplatesAdmin = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/getRecommendTemplatesAdmin',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.GetRecommendTemplatesAdminRequest,
  proto.advancedtemplate.GetRecommendTemplatesAdminReply,
  /** @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetRecommendTemplatesAdminReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetRecommendTemplatesAdminRequest,
 *   !proto.advancedtemplate.GetRecommendTemplatesAdminReply>}
 */
const methodInfo_AdvancedTemplateAdminService_getRecommendTemplatesAdmin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetRecommendTemplatesAdminReply,
  /** @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetRecommendTemplatesAdminReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetRecommendTemplatesAdminReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetRecommendTemplatesAdminReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.getRecommendTemplatesAdmin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getRecommendTemplatesAdmin',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getRecommendTemplatesAdmin,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetRecommendTemplatesAdminReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.getRecommendTemplatesAdmin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getRecommendTemplatesAdmin',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getRecommendTemplatesAdmin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.AddBannerRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_addBanner = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/addBanner',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.AddBannerRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddBannerRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddBannerRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_addBanner = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddBannerRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddBannerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.addBanner =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/addBanner',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_addBanner,
    callback);
  };


/**
 * @param {!proto.advancedtemplate.AddBannerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.addBanner =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/addBanner',
      request,
      metadata || {},
    methodDescriptor_AdvancedTemplateAdminService_addBanner);
  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.UpdateBannersRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_updateBanner = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/updateBanner',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.UpdateBannersRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateBannersRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateBannersRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_updateBanner = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateBannersRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateBannersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.updateBanner =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/updateBanner',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_updateBanner,
    callback);
  };


/**
 * @param {!proto.advancedtemplate.UpdateBannersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.updateBanner =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/updateBanner',
      request,
      metadata || {},
    methodDescriptor_AdvancedTemplateAdminService_updateBanner);
  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.DeleteBannerRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_deleteBanner = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/deleteBanner',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.DeleteBannerRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.DeleteBannerRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.DeleteBannerRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_deleteBanner = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.DeleteBannerRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.DeleteBannerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.deleteBanner =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/deleteBanner',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_deleteBanner,
    callback);
  };


/**
 * @param {!proto.advancedtemplate.DeleteBannerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.deleteBanner =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/deleteBanner',
      request,
      metadata || {},
    methodDescriptor_AdvancedTemplateAdminService_deleteBanner);
  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.Void,
 *   !proto.advancedtemplate.GetDraftBannersResponse>}
 */
const methodDescriptor_AdvancedTemplateAdminService_getDraftBanners = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/getDraftBanners',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.Void,
  proto.advancedtemplate.GetDraftBannersResponse,
  /** @param {!proto.advancedtemplate.Void} request */
  function (request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftBannersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.Void,
 *   !proto.advancedtemplate.GetDraftBannersResponse>}
 */
const methodInfo_AdvancedTemplateAdminService_getDraftBanners = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetDraftBannersResponse,
  /** @param {!proto.advancedtemplate.Void} request */
  function (request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftBannersResponse.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.Void} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetDraftBannersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetDraftBannersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.getDraftBanners =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getDraftBanners',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_getDraftBanners,
    callback);
  };


/**
 * @param {!proto.advancedtemplate.Void} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetDraftBannersResponse>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.getDraftBanners =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/getDraftBanners',
      request,
      metadata || {},
    methodDescriptor_AdvancedTemplateAdminService_getDraftBanners);
  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.SimpleRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodDescriptor_AdvancedTemplateAdminService_publishBanners = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateAdminService/publishBanners',
  grpc.web.MethodType.UNARY,
  common_pb.SimpleRequest,
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.SimpleRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.SimpleRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateAdminService_publishBanners = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.SimpleRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.SimpleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateAdminServiceClient.prototype.publishBanners =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/publishBanners',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateAdminService_publishBanners,
    callback);
  };


/**
 * @param {!proto.advancedtemplate.SimpleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateAdminServicePromiseClient.prototype.publishBanners =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateAdminService/publishBanners',
      request,
      metadata || {},
    methodDescriptor_AdvancedTemplateAdminService_publishBanners);
  };


module.exports = proto.advancedtemplate;


/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import {Link, Redirect} from 'react-router-dom';
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Icon,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
} from 'antd';
import './layout.css';
import TreeSelector from "./TreeSelector";
import ImgUpload from "./ImgUpload";
import {
    AddTemplateRequest,
    AdvancedTemplateService,
    GetPublicNoteInfosRequest,
    GetSeriesTreeRequest,
    Pageable,
    Series,
    Template
} from "./grpcweb/AdvancedTemplateService";

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

export default class TemplateCreator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //新增模版请求参数
            title: '',
            type: 0,
            templatePlatform: [],
            templateTerminalType: -1,
            description: '',
            serviceLevel: -1,
            construction: '{}', //模版内的notebook、note信息
            thumbnailUrl: '',
            previewUrl: '',

            //新增模版是否完成
            addTemplateConfirmed: false,
            disableSaveBtn: false,

            //系列树，传入treeSelector
            seriesListToChild: [],
            //系列树，由treeSelector传回
            seriesListFromChild: [],

            isLoading: true,
            //模版类型
            isNotebookTemplate: false,
            isNoteTemplate: false,

            //页面构造的notebook、note列表(object)
            tableRecords: {
                notebooks: [],
                notes: []
            },
            showAddNotebookModal: false,
            newNotebookName: '',
            showAddNoteModal: false,
            addOuterNote: null,
            //选中的笔记
            selectedTableNotes: [],
            //每个分页的选择项
            selectedRowKeys: [],
            //要添加笔记的笔记本
            notebookAddingNote: [],
            inputNoteName: '',
            //添加笔记对话框渲染笔记列表
            tableNotes: [],
            total: 0,
            current: 1,
            pagesize: 10,

            //检查是否有重复笔记
            noteIdSet: new Set(),

            //购买支付方式
            purchaseType: 0,
            //会员等级是否可以设置
            serviceLevelDisabled: true,
            //限免标签
            isFree: false,
            //价格
            price: 0,
            //价格是否设置
            priceDisabled: true,
            badge:0
        };
    };

    componentDidMount = () => {
        //首先获取系列树、公共笔记列表
        this.getSeriesTree();
        this.getPublicNotesInfo(1);
    };

    /**
     * 获取子组件treeselector数据
     */
    getChildrenMsg = (result, msg) => {
        this.setState({
            seriesListFromChild: msg
        });
    };

    /**
     * 键入模版名称时触发
     */
    handleTemplateNameChange = (e) => {
        this.setState({
            title: e.target.value
        });
    };

    /**
     * 键入模版描述时触发
     */
    handleDescriptionChange = (e) => {
        this.setState({
            description: e.target.value
        });
    };

    /**
     * 选择模版类型的动作
     */
    onTemplateTypeChange = value => {
        switch (value.toString()) {
            case '1':
                this.setState({
                    type: parseInt(value),
                    isNotebookTemplate: false,
                    isNoteTemplate: false,
                });
                break;
            case '2':
                this.setState({
                    type: parseInt(value),
                    isNotebookTemplate: true,
                    isNoteTemplate: false,
                });
                break;
            case '3':
                this.setState({
                    type: parseInt(value),
                    isNotebookTemplate: false,
                    isNoteTemplate: true,
                });
                break;
            default: break;
        }
    };

    /**
     * 选择模版应用范围
     */
    handleTemplatePlatformChange = value => {
        if (value.length > 0) {
            value.forEach(v => parseInt(v));
            this.setState({
                templatePlatform: value,
            }, () => {
                console.log("模板应用范围：" + this.state.templatePlatform);
            });
        }
    };

    /**
     * 选择模版应用端
     */
    handleTemplateTerminalTypeChange = value => {
        this.setState({
            templateTerminalType: parseInt(value),
        }, () => {
            console.log("模板应用端：" + this.state.templateTerminalType);
        });
    };

    /**
     * 选择用户购买方式
     */
    onPurchaseTypeChange = value => {

        //设置会员等级和金额
        switch (value.toString()) {
            case '1': //账户等级
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: false,
                    priceDisabled: true,
                    //不设置价格
                    price: 0,
                    //默认免费会员
                    serviceLevel:1,
                    isFree: true //限免标签
                });
                break;
            case '2': //会员 + 购买
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: false,
                    priceDisabled: false,
                    serviceLevel:2,
                    isFree: false //限免标签
                });
                break;
            case '3': //购买
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: true,
                    priceDisabled: false,
                    //不设置用户等级
                    serviceLevel: -1,
                    isFree: false //限免标签
                });
                break;
            case '4': //限免
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: true,
                    priceDisabled: true,
                    serviceLevel: 1, //免费用户
                    price: 0, //价格为0
                    isFree: true //限免标签
                });
                break;
            case '5': //花生会员（素材会员）
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: true,
                    priceDisabled: true,
                    serviceLevel: -1, //免费用户
                    price: 0, //价格为0
                    isFree: true //限免标签
                });
                break;
            case '6': //花生会员（素材会员） or 购买 
                this.setState({
                    purchaseType: parseInt(value),
                    serviceLevelDisabled: true,
                    priceDisabled: false,
                    serviceLevel: -1, //免费用户
                    isFree: true //限免标签
                });
                break;
            default: break;
        }
    }

    /**
     * 修改价格
     */
    onPriceChange = value => {
        this.setState({
            price: value
        });
    }

    /**
     * 选择用户身份限制的动作
     */
    onServiceLevelChange = value => {
        this.setState({
            serviceLevel: parseInt(value)
        });
    };

    /**
     * 更新标签
     */
    onBadgeChange = value => {
        this.setState({
            badge: parseInt(value)
        });
    }

    /**
     * 新增笔记本
     */
    onAddNotebook = (newNotebookName) => {
        //给新增的notebook增加order字段(注：新增的notebook一定在notes前)
        let beginIndex = this.state.tableRecords.notebooks.length + 1;
        let newbook = [{
            bookName: newNotebookName,
            notes: [],
            order: beginIndex.toString(),
        }];
        let updatedNotebooks = this.state.tableRecords.notebooks.concat(newbook);
        //Object.assign(target, ...sources)将所有可枚举属性的值从多个sources复制到target
        let updatedTableRecords = Object.assign({}, this.state.tableRecords, { notebooks: updatedNotebooks });
        //后序notes order更新
        this.state.tableRecords.notes.forEach((noteItem) => {
            noteItem.order = (parseInt(noteItem.order) + 1).toString();
        });
        this.setState({
            tableRecords: updatedTableRecords,
        }, () => {
            console.log(JSON.stringify(this.state.tableRecords));
        });
        // this.state.tableRecords格式：
        // { "notebooks": [{ "notes": [{ "order": 1, "title": "月计划", "noteId": "9384750277590", "version": 2 }, { "order": 2, "title": "月度打卡表", "noteId": "13958593", "version": 1 }], "order": 1, "bookName": "月计划" }, { "notes": [{ "order": 1, "title": "月计划", "noteId": "9384750277590", "version": 2 }, { "order": 2, "title": "月度打卡表", "noteId": "13958593", "version": 1 }], "order": 2, "bookName": "年计划" }], "notes": [{ "order": 3, "title": "时间管理", "noteId": "13958593", "version": 1 }] }        
        // console.log("用户身份限制为：" + this.state.serviceLevel);
    };

    /**
     * 新增笔记本外笔记
     */
    onAddNoteOuter = (notes) => {
        // debugger;
        //给notes数组每个元素增加order字段
        let beginIndex = this.state.tableRecords.notes.length + this.state.tableRecords.notebooks.length + 1;
        /** 
         * 复制数组，Array.from是浅拷贝，如果想要深拷贝，可以试试 arr2 = JSON.parse(JSON.stringify(arr))
         * */
        // var noteArray = Array.from(notes);
        var noteArray = JSON.parse(JSON.stringify(notes));
        noteArray.forEach((item) => {
            item['order'] = (beginIndex++).toString();
        });
        let updatedNotes = this.state.tableRecords.notes.concat(noteArray);
        let updatedTableRecords = Object.assign({}, this.state.tableRecords, { notes: updatedNotes });
        this.setState({
            tableRecords: updatedTableRecords,
            // isLoading: true
        }, () => {
            // debugger;
            // this.setState({ isLoading: false });
            console.log(JSON.stringify(this.state.tableRecords));
        });
    };

    /**
     * 删除笔记本外笔记（outer）
     */
    deleteOuterNote = (record) => {
        // debugger;
        //定位元素位置
        let targetNoteIndex = this.state.tableRecords.notes.findIndex(
            item => parseInt(item.order) === parseInt(record.order));
        //删除该元素
        this.state.tableRecords.notes.splice(targetNoteIndex, 1);
        //后序元素order字段更新
        this.state.tableRecords.notes.forEach((item) => {
            // debugger;
            if (parseInt(item.order) > parseInt(record.order)) {
                item.order = (parseInt(item.order) - 1).toString();
            }
        });
        let updatedTableRecords = Object.assign({}, this.state.tableRecords);
        this.setState({
            tableRecords: updatedTableRecords,
        }, () => {
            console.log(JSON.stringify(this.state.tableRecords));
        });
    };

    /**
     * 删除笔记本内笔记（inner）
     */
    deleteInnerNote = (noteRecord) => {
        // debugger;
        //定位note及其所属notebook位置
        let targetNoteIndex;
        let targetNotebookIndex = this.state.tableRecords.notebooks.findIndex((notebook) => {
            targetNoteIndex = notebook.notes.findIndex((noteItem) => noteItem.order === noteRecord.order);
            return targetNoteIndex !== -1;
        });
        //删除该note
        this.state.tableRecords.notebooks[targetNotebookIndex].notes.splice(targetNoteIndex, 1);
        //notes列表后序元素order字段更新
        this.state.tableRecords.notebooks[targetNotebookIndex].notes.forEach((item) => {
            // debugger;
            let noteOrderSuffix = parseInt(item.order.substr(item.order.indexOf('-') + 1));
            let noteRecordOrderSuffix = parseInt(noteRecord.order.substr(noteRecord.order.indexOf('-') + 1));
            if (noteOrderSuffix > noteRecordOrderSuffix) {
                item.order = item.order.substring(0, item.order.indexOf('-')) + '-' + (noteOrderSuffix - 1).toString();
            }
        });
        let updatedNotebook = Object.assign({}, this.state.tableRecords.notebooks[targetNotebookIndex]);
        this.state.tableRecords.notebooks.splice(targetNotebookIndex, 1);
        this.state.tableRecords.notebooks.splice(targetNotebookIndex, 0, updatedNotebook);
        let updatedNotebooks = this.state.tableRecords.notebooks;
        let updatedTableRecords = Object.assign({}, this.state.tableRecords, { notebooks: updatedNotebooks });
        this.setState({
            tableRecords: updatedTableRecords,
        }, () => {
            console.log(JSON.stringify(this.state.tableRecords));
        });
    };

    /**
     * 在笔记本下添加笔记
     */
    onAddNoteInner = (notebook, notes) => {
        let targetNotebookIndex = parseInt(notebook.order) - 1;
        //下面这种方式也可
        // let targetNotebookIndex = this.state.tableRecords.notebooks.findIndex((e) => e.bookName === notebook.title);
        //给notes数组每个元素增加order字段
        let beginIndex = this.state.tableRecords.notebooks[targetNotebookIndex].notes.length + 1;
        /** 
         * 复制数组，Array.from是浅拷贝，如果想要深拷贝，可以试试 arr2 = JSON.parse(JSON.stringify(arr))
         * */
        // var noteArray = Array.from(notes);
        var noteArray = JSON.parse(JSON.stringify(notes));
        noteArray.forEach((item) => {
            item['order'] = (targetNotebookIndex + 1).toString() + '-' + (beginIndex++).toString();
        });
        let updatedInnerNotes = this.state.tableRecords.notebooks[targetNotebookIndex].notes.concat(noteArray);
        let updatedNotebook = Object.assign({}, this.state.tableRecords.notebooks[targetNotebookIndex], { notes: updatedInnerNotes });
        this.state.tableRecords.notebooks.splice(targetNotebookIndex, 1);
        this.state.tableRecords.notebooks.splice(targetNotebookIndex, 0, updatedNotebook);
        let updatedNotebooks = this.state.tableRecords.notebooks;
        let updatedTableRecords = Object.assign({}, this.state.tableRecords, { notebooks: updatedNotebooks });
        this.setState({
            tableRecords: updatedTableRecords,
        }, () => {
            // debugger;
            console.log(JSON.stringify(this.state.tableRecords));
        });
    };

    /**
     * 删除模版下笔记本
     */
    deleteNotebook = (record) => {
        // debugger;
        //定位元素位置
        let targetNotebookIndex = this.state.tableRecords.notebooks.findIndex((notebook) => notebook.bookName === record.title);
        //删除该元素
        this.state.tableRecords.notebooks.splice(targetNotebookIndex, 1);
        //后序notes元素order字段更新
        this.state.tableRecords.notes.forEach((item) => {
            if (parseInt(item.order) > parseInt(record.order)) {
                item.order = (parseInt(item.order) - 1).toString();
            }
        });
        //后序notebooks元素order字段及每个notebook内的notes的order字段更新
        this.state.tableRecords.notebooks.forEach((notebookItem) => {
            if (parseInt(notebookItem.order) > parseInt(record.order)) {
                notebookItem.order = (parseInt(notebookItem.order) - 1).toString();
            }
            //notebook内的notes元素order字段更新
            notebookItem.notes.forEach((noteItem) => {
                // debugger;
                let noteOrderPrefix = parseInt(noteItem.order.substring(0, record.order.indexOf('-')));
                if (noteOrderPrefix > parseInt(record.order)) {
                    noteItem.order = (noteOrderPrefix - 1).toString() + noteItem.order.substr(noteItem.order.indexOf('-'));
                }
            });
        });
        let updatedTableRecords = Object.assign({}, this.state.tableRecords);
        this.setState({
            tableRecords: updatedTableRecords,
        }, () => {
            console.log(JSON.stringify(this.state.tableRecords));
        });
    };

    //上移note or notebook
    moveUp = (record) => {
        if (record.order.indexOf('-') === -1) {
            //外层note or notebook
            if (record.type === "笔记") {
                let arr = this.state.tableRecords.notes;
                let targetIndex = parseInt(record.order) - this.state.tableRecords.notebooks.length - 1;
                //交换targetIndex和targetIndex-1两个位置元素
                arr[targetIndex] = arr.splice(targetIndex - 1, 1, arr[targetIndex])[0];
                //更新两个note的order
                let orderA = arr[targetIndex].order;
                arr[targetIndex].order = arr[targetIndex - 1].order;
                arr[targetIndex - 1].order = orderA;
            } else {
                let arr = this.state.tableRecords.notebooks;
                let targetIndex = parseInt(record.order) - 1;
                arr[targetIndex] = arr.splice(targetIndex - 1, 1, arr[targetIndex])[0];
                //更新两个notebook的order
                let orderA = arr[targetIndex].order;
                arr[targetIndex].order = arr[targetIndex - 1].order;
                arr[targetIndex - 1].order = orderA;
                //更新两个notebook内的notes的order
                [arr[targetIndex], arr[targetIndex - 1]].forEach((notebook) => {
                    notebook.notes.forEach((note) => {
                        note.order = notebook.order + note.order.substr(note.order.indexOf('-'));
                    });
                });
            }
        } else {
            //内层note
            let targetNotebookIndex = parseInt(record.order.substring(0, record.order.indexOf('-'))) - 1;
            let targetNoteIndex = parseInt(record.order.substr(record.order.indexOf('-') + 1)) - 1;
            let arr = this.state.tableRecords.notebooks[targetNotebookIndex].notes;
            //交换targetNoteIndex和targetNoteIndex-1两个位置元素
            arr[targetNoteIndex] = arr.splice(targetNoteIndex - 1, 1, arr[targetNoteIndex])[0];
            //更新两个note的order
            let orderA = arr[targetNoteIndex].order;
            arr[targetNoteIndex].order = arr[targetNoteIndex - 1].order;
            arr[targetNoteIndex - 1].order = orderA;
        }
        let updatedTableRecords = Object.assign({}, this.state.tableRecords);
        this.setState({
            tableRecords: updatedTableRecords,
        });
    };

    /**
     * 请求系列树
     */
    getSeriesTree = () => {
        let request = new GetSeriesTreeRequest();
        AdvancedTemplateService.getSeriesTree(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    console.log("getSeriesTree: " + JSON.stringify(res));
                    this.setState({
                        seriesListToChild: res.seriesList
                    });
                }
            }
        });
    };

    /**
     * 参数校验
     */
    checkParams = () => {
        if (this.state.title.length === 0) {
            return '请输入模版名称';
        }
        else if (this.state.title.length > 30) {
            return '模版名称不能超过30字';
        }
        if (this.state.type === 0) {
            return '请选择模版类型';
        }
        if (this.state.templatePlatform.length === 0) {
            return '请选择模版应用范围';
        }
        if (this.state.templateTerminalType === -1) {
            return '请选择模版端类型';
        }
        if (this.state.purchaseType === 0) {
            return '请选择模板付费类型';
        }
        if (this.state.description.length > 50) {
            return '描述不能超过50字';
        }
        if (this.state.thumbnailUrl === '' || typeof this.state.thumbnailUrl === 'undefined') {
            return '请先上传缩略图';
        }
        if (this.state.tableRecords.notebooks.length < 1 &&
            this.state.tableRecords.notes.length < 1) {
            return '请至少添加一个笔记本或笔记';
        }
        let notesCnt = 0;
        this.state.tableRecords.notebooks.forEach((item) => {
            notesCnt = notesCnt + item.notes.length;
        });
        notesCnt = notesCnt + this.state.tableRecords.notes.length;
        if (notesCnt > 15) {
            return '笔记总数不能超过15';
        }
        //校验单价
        if((this.state.purchaseType === 2 || this.state.purchaseType === 3 || this.state.purchaseType === 6) && this.state.price === 0){

            return '模板单价不能为0'
        }

        return '';
    };

    /**
     * 点击保存button，新增模版
     */
    addTemplate = () => {
        //校验
        let checkMassage = this.checkParams();
        if (checkMassage !== '') {
            message.error(checkMassage);
            return;
        }
        console.log("addTemplate templatePlatform " + this.state.templatePlatform);
        this.setState({ disableSaveBtn: true }, () => {
            let request = new AddTemplateRequest();
            let template = new Template();
            template.setTitle(this.state.title);
            //没有显式使用enum，因为select组件的value已经跟EnumTemplateType定义吻合了,等同于template.setType(EnumTemplateType.SPACE)
            template.setType(this.state.type);
            //设置platform list
            let platformArray = this.state.templatePlatform;
            platformArray.forEach((item) => {
                template.addPlatform(item);
            });
            //设置应用端
            template.setTerminaltype(this.state.templateTerminalType);
            //设置购买方式
            template.setPurchasetype(this.state.purchaseType);
            //设置等级
            template.setServicelevel(this.state.serviceLevel);
            //设置价格
            template.setPrice(this.state.price*100);
            //设置限免标签
            template.setIsfree(this.state.isFree);
            //设置标签
            template.setBadge(this.state.badge);

            template.setDescription(this.state.description);
            // 根据state.tableRecords生成construction string
            let construction = this.genConstructionString(this.state.tableRecords);
            template.setConstruction(construction);
            template.setThumbnailurl(this.state.thumbnailUrl);
            request.setTemplate(template);
            let seriesIdArray = this.state.seriesListFromChild;
            seriesIdArray.forEach((item) => {
                let series = new Series();
                series.setSeriesid(item);
                request.addSeries(series);
            });
            console.log(JSON.stringify(request.array));
            // debugger;
            AdvancedTemplateService.addTemplate(request, {}, (err, response) => {
                // debugger;
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    let res = response.toObject();
                    switch (res.code) {
                        case 3001:
                            message.error('模版参数错误');
                            break;
                        case 200:
                            Modal.success({ content: '新增模版成功', onOk: this.redirectToHome });
                            break;
                        default:
                            message.error(res.message);
                            break;
                    }
                    console.log("新增模版结果: " + res.message);
                }
                this.setState({ disableSaveBtn: false });
            });
        });
    };

    //新增模版完成或者点击返回，重定向
    redirectToHome = () => {
        this.setState({
            addTemplateConfirmed: true
        });
    };

    /**
     * 根据state.tableRecords生成construction string
     */
    genConstructionString = (tableRecords) => {
        // { "notebooks": [{ "bookName": "ksks", "notes": [{ "noteid": "10086", "version": 1, "title": "记账笔记", "editortype": "PESO", "order": "1-1" }, { "noteid": "test1", "version": 1, "title": "测试笔记", "editortype": "UNO", "order": "1-2" }], "order": "1" }, { "bookName": "s", "notes": [], "order": "2" }], "notes": [{ "noteid": "10086", "version": 1, "title": "记账笔记", "editortype": "PESO", "order": "3" }, { "noteid": "test1", "version": 1, "title": "测试笔记", "editortype": "UNO", "order": "4" }] }
        // noteid、order转为int发到后端
        if (!tableRecords.notes && !tableRecords.notebooks) {
            return '{}';
        }
        if (tableRecords.notebooks.length > 0) {
            tableRecords.notebooks.forEach((item) => {
                item.order = parseInt(item.order);
                if (item.notes.length > 0) {
                    item.notes.forEach((noteItem) => {
                        noteItem.order = parseInt(noteItem.order.substr(noteItem.order.indexOf('-') + 1));
                    });
                }
            });
        }
        if (tableRecords.notes.length > 0) {
            tableRecords.notes.forEach((item) => {
                item.order = parseInt(item.order);
            });
        }
        return JSON.stringify(tableRecords);
    }

    /**
     * 根据state.tableRecords生成模版下挂笔记本、笔记表格datasource
     */
    genTableData = (tableRecords) => {
        let newNoteIdSet = new Set();
        if (!tableRecords.notes && !tableRecords.notebooks) {
            return [];
        }
        let outerOrder = 1;
        const tableDataDisp = [];
        if (tableRecords.notebooks.length > 0) {
            tableRecords.notebooks.forEach((item) => {
                tableDataDisp.push({
                    order: outerOrder.toString(),
                    title: item.bookName,
                    type: '笔记本',
                    notes: item.notes,
                });
                if (item.notes.length > 0) {
                    item.notes.forEach((noteItem, idx) => {
                        tableDataDisp.push({
                            order: (outerOrder.toString() + '-' + (idx + 1).toString()),
                            title: noteItem.title,
                            type: '笔记',
                            noteId: noteItem.noteid,
                            version: noteItem.version,
                        });
                        newNoteIdSet.add(noteItem.noteid);
                    });
                }
                outerOrder++;
            });
        }
        if (tableRecords.notes.length > 0) {
            tableRecords.notes.forEach((item) => {
                tableDataDisp.push({
                    order: (outerOrder++).toString(),
                    title: item.title,
                    type: '笔记',
                    noteId: item.noteid,
                    version: item.version,
                });
                newNoteIdSet.add(item.noteid);
            });
        }
        if (newNoteIdSet.size !== this.state.noteIdSet.size) {
            this.setState({
                noteIdSet: newNoteIdSet
            }, () => {
                console.log(this.state.noteIdSet);
            });
        }
        console.log(JSON.stringify(tableDataDisp));
        return tableDataDisp;
    }

    /**
      * 是否禁用”新增笔记本“按钮
      * 1.尚未选择模板类型
      * 2.笔记本模版或者笔记模板
      */
    allowAddNotebook = () => {
        if (this.state.type === 0) {
            return false;
        } else if (this.state.isNotebookTemplate || this.state.isNoteTemplate) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 是否禁用”新增笔记“按钮
     * 1.尚未选择模板类型
     * 2.笔记模版，笔记达到1条
     * 3.其他类型模版，笔记达到15条
     */
    allowAddNote = (tableRecords) => {
        if (this.state.type === 0) {
            return false;
        } else if (this.state.isNoteTemplate) {
            return tableRecords.notes.length === 0;
        } else {
            let notesCnt = 0;
            tableRecords.notebooks.forEach((item) => {
                notesCnt = notesCnt + item.notes.length;
            });
            notesCnt = notesCnt + tableRecords.notes.length;
            return notesCnt < 15;
        }
    }

    /**
     * 显示添加笔记本模态框
     */
    showAddNotebookModal = () => {
        this.setState({
            showAddNotebookModal: true
        });
    };

    /**
     * 添加笔记本-确认
     */
    handleAddNotebookOk = () => {
        if (this.state.newNotebookName.length > 30) {
            message.error('笔记本名称不能超过30字');
            return;
        }
        this.onAddNotebook(this.state.newNotebookName);
        this.setState({
            showAddNotebookModal: false,
            newNotebookName: ''
        });
    };

    handleAddNotebookCancel = e => {
        this.setState({
            showAddNotebookModal: false
        });
    };

    //添加笔记本-input
    handleNotebookNameChange = (e) => {
        // debugger;
        this.setState({
            newNotebookName: e.target.value
        });
    };

    //添加笔记模态框
    showAddNoteModal = (opt, notebookRecord) => {
        this.getPublicNotesInfo(1);
        this.setState({
            showAddNoteModal: true,
            addOuterNote: opt === 'outer' ? true : false,
            notebookAddingNote: notebookRecord,
        });
    };

    //添加笔记-确认
    handleAddNoteOk = () => {
        //不能添加重复笔记
        let duplicatedNoteError = false;
        this.state.selectedTableNotes.forEach((note) => {
            if (this.state.noteIdSet.has(note.noteid)) {
                duplicatedNoteError = true;
            }
        });
        //笔记总数不能超过15
        let notesCnt = 0;
        this.state.tableRecords.notebooks.forEach((item) => {
            notesCnt = notesCnt + item.notes.length;
        });
        notesCnt = notesCnt + this.state.tableRecords.notes.length;
        notesCnt = notesCnt + this.state.selectedTableNotes.length;
        if (duplicatedNoteError) {
            message.error('模版中存在重复笔记');
        } else if (notesCnt > 15) {
            message.error('笔记总数不能超过15');
        } else {
            if (this.state.addOuterNote) {
                this.onAddNoteOuter(this.state.selectedTableNotes);
            } else {
                this.onAddNoteInner(this.state.notebookAddingNote, this.state.selectedTableNotes);
            }
            this.setState({
                showAddNoteModal: false,
                inputNoteName: ''
            });
        }
    };

    handleAddNoteCancel = e => {
        this.setState({
            showAddNoteModal: false
        });
    };

    //添加笔记-input
    handleNoteNameChange = (e) => {
        this.setState({
            inputNoteName: e.target.value
        });
    };

    //请求笔记列表
    getPublicNotesInfo = (page) => {
        let request = new GetPublicNoteInfosRequest();
        request.setTitle(this.state.inputNoteName);
        let platformArray = this.state.templatePlatform.map(p => parseInt(p));
        console.log("platformArray " + JSON.stringify(platformArray));
        request.setEditortype(platformArray.includes(2) ? "MINDMAP" : "");
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        AdvancedTemplateService.getPublicNoteInfos(request, {}, (err, response) => {
            // debugger;
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    res.notesList.forEach((item) => {
                        switch (item.editortype) {
                            case 1:
                                item.editortype = "UNO";
                                break;
                            case 2:
                                item.editortype = "PESO";
                                break;
                            case 3:
                                item.editortype = "MARKDOWN";
                                break;
                            case 4:
                                item.editortype = "MINDMAP";
                                break;
                            case 5:
                                item.editortype = "OTHER";
                                break;
                            case 6:
                                item.editortype = "FLOW_CHART";
                                break;
                            default:
                                break;
                        }
                    });
                    this.setState(
                        {
                            tableNotes: res.notesList,
                            total: res.total,
                            current: page,
                        },
                        () => {
                            // debugger;
                            console.log(JSON.stringify(this.state.tableNotes));
                        }
                    );
                }
                // this.state.tableNotes格式
                // [{ "noteid": "test1", "version": 1, "title": "测试笔记", "editortype": 1 }]
            }
        });
    }

    /**
     * 处理子组件ImgUpload中传回的URL
     */
    getImgUrl = (result, imgUrl) => {
        // debugger;
        if (typeof imgUrl === 'undefined') {
            return;
        }
        this.setState(
            { thumbnailUrl: imgUrl },
            () => console.log(JSON.stringify(this.state.thumbnailUrl))
        );
    };

    onChangePage = page => {
        this.getPublicNotesInfo(page);
        const selectedRowKeys = [];
        this.setState({ selectedRowKeys });
    };


    render() {
        const { selectedRowKeys } = this.state;
        // 添加笔记-笔记列表项被选中触发
        const notesRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedTableNotes: selectedRows,
                    selectedRowKeys
                });
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            selectedRowKeys
        };
        const tableData = this.genTableData(this.state.tableRecords);
        const allowAddNote = this.allowAddNote(this.state.tableRecords);
        const allowAddNotebook = this.allowAddNotebook();

        if (this.state.addTemplateConfirmed) {
            return (<Redirect to="/advanced-template-et/template/list" />);
        } else {
            return (
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>
                            <Link to="/advanced-template-et/template/list">首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/advanced-template-et/template/list">模版管理</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>新增模版</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模版名称
                            </Col>
                            <Col span={8} >
                                <Input onChange={this.handleTemplateNameChange} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模版类型
                            </Col>
                            <Col span={8} >
                                <Select
                                    style={{ width: 375 }}
                                    onChange={this.onTemplateTypeChange}
                                    placeholder="Please select"
                                >
                                    <Option key="1" value="1">空间模版</Option>
                                    <Option key="2" value="2">笔记本模版</Option>
                                    <Option key="3" value="3">笔记模版</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模版应用APP
                            </Col>
                            <Col span={8} >
                                <Select
                                    mode="multiple"
                                    style={{ width: 375 }}
                                    onChange={this.handleTemplatePlatformChange}
                                    placeholder="Please select"
                                >
                                    <Option key="1" value="1">ET App</Option>
                                    {/*<Option key="2" value="2">图记App</Option>*/}
                                    {/*<Option key="3" value="3">其他</Option>*/}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模版应用端
                            </Col>
                            <Col span={8} >
                                <Select
                                    style={{ width: 375 }}
                                    onChange={this.handleTemplateTerminalTypeChange}
                                    placeholder="Please select"
                                >
                                    <Option key="0" value="0">桌面端</Option>
                                    <Option key="1" value="1">移动端</Option>
                                    <Option key="2" value="2">移动端和桌面端</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                系列
                            </Col>
                            <Col span={8} >
                                <TreeSelector
                                    data={this.state.seriesListToChild}
                                    parent={this}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模版付费类型
                            </Col>
                            <Col span={8} >
                                <Select
                                    style={{ width: 375 }}
                                    onChange={this.onPurchaseTypeChange}
                                    placeholder="Please select"
                                >
                                    <Option key="1" value="1">账户等级</Option>
                                    {/*<Option key="2" value="2">会员 or 象票购买</Option>*/}
                                    {/*<Option key="3" value="3">象票购买</Option>*/}
                                    {/*<Option key="4" value="4">限免</Option>*/}
                                    {/*<Option key="5" value="5">印象资源尊享包</Option>*/}
                                    {/*<Option key="6" value="6">印象资源尊享包 or 象票购买</Option>*/}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                用户身份限制
                            </Col>
                            <Col span={8} >
                                <Select
                                    style={{ width: 375 }}
                                    onChange={this.onServiceLevelChange}
                                    value={this.state.serviceLevel.toString()}
                                    disabled = {this.state.serviceLevelDisabled}
                                    placeholder="Please select"
                                >
                                    <Option key="-1" value="-1" disabled = {!this.state.serviceLevelDisabled}>无</Option>
                                    <Option key="1" value="1" disabled = {this.state.purchaseType.toString() === '2'}>免费账户</Option>
                                    {/*<Option key="2" value="2">标准账户</Option>*/}
                                    <Option key="3" value="3">高级账户</Option>
                                    {/*<Option key="5" value="5">专业账户</Option>*/}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                模板单价
                            </Col>
                            <Col span={8} >
                                <InputNumber value = {this.state.price} min={0} max={999.99} step={0.01} onChange={this.onPriceChange} disabled = {this.state.priceDisabled}/>
                                (价格范围：0.01 ～ 999.99 象票)
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                显示标签
                            </Col>
                            <Col span={8} >
                                <Select
                                    style={{ width: 375 }}
                                    onChange={this.onBadgeChange}
                                    placeholder="Please select"
                                >
                                    <Option key="0" value="0">无</Option>
                                    <Option key="1" value="1">最新</Option>
                                    <Option key="2" value="2">热门</Option>
                                    <Option key="3" value="3">精选</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                描述
                            </Col>
                            <Col span={8} >
                                <TextArea
                                    rows={3}
                                    onChange={this.handleDescriptionChange} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={4} offset={4}>
                                缩略图
                            </Col>
                            <Col span={10} >
                                <ImgUpload parent={this} />
                            </Col>
                        </Row>
                        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                        <Row gutter={[16, 16]}>
                            <Modal title="新增笔记本"
                                visible={this.state.showAddNotebookModal}
                                onOk={this.handleAddNotebookOk}
                                onCancel={this.handleAddNotebookCancel}
                                width={600}>
                                <Row gutter={16}>
                                    <Col span={6} > 给笔记本命名:</Col>
                                    <Col span={15} >
                                        <Input type="text"
                                            value={this.state.newNotebookName}
                                            onChange={this.handleNotebookNameChange} />
                                    </Col>
                                </Row>
                            </Modal>
                            <Modal title="新增笔记"
                                visible={this.state.showAddNoteModal}
                                onOk={this.handleAddNoteOk}
                                onCancel={this.handleAddNoteCancel}
                                width={660}>
                                <Row gutter={16}>
                                    <Col span={4} offset={2}> 笔记名称</Col>
                                    <Col span={10} >
                                        <Input type="text"
                                            value={this.state.inputNoteName}
                                            onChange={this.handleNoteNameChange} />
                                    </Col>
                                    <Col span={2} offset={1}>
                                        <Button type="primary"
                                            style={{ width: 100 }}
                                            onClick={() => this.getPublicNotesInfo(1)}>
                                            查询
                                        </Button>
                                    </Col>
                                </Row>
                                <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                                <div>
                                    <Table
                                        pagination={false}
                                        rowSelection={{
                                            type: this.state.isNoteTemplate ? 'radio' : 'checkbox',
                                            ...notesRowSelection,
                                        }}
                                        // 如果 dataSource[i].key 没有提供，应该使用 rowKey 来指定 dataSource 的主键
                                        rowKey={record => record.noteid}
                                        columns={
                                            [
                                                {
                                                    title: '笔记名称',
                                                    align: 'center',
                                                    dataIndex: 'title',
                                                },
                                                {
                                                    title: '编辑器类型',
                                                    align: 'center',
                                                    dataIndex: 'editortype',
                                                },
                                            ]
                                        }
                                        dataSource={this.state.tableNotes}
                                        pagination={{
                                            pageSize: this.state.pagesize,
                                            total: this.state.total,
                                            onChange: this.onChangePage,
                                        }}
                                    />
                                </div>
                            </Modal>
                            <Col span={3} offset={2}>
                                <Button
                                    disabled={!allowAddNotebook}
                                    type="primary"
                                    style={{ width: 100 }}
                                    onClick={this.showAddNotebookModal}>
                                    新增笔记本
                                </Button>
                            </Col>
                            <Col span={3} >
                                <Button
                                    disabled={!allowAddNote}
                                    type="primary"
                                    style={{ width: 100 }}
                                    onClick={() => this.showAddNoteModal('outer')}>
                                    新增笔记
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={20} offset={2}>
                                <div>
                                    <Table
                                        pagination={false}
                                        columns={
                                            [
                                                {
                                                    title: '顺序',
                                                    dataIndex: 'order',
                                                },
                                                {
                                                    title: '名称',
                                                    dataIndex: 'title',
                                                },
                                                {
                                                    title: '类型',
                                                    dataIndex: 'type',
                                                },
                                                {
                                                    title: '操作',
                                                    key: 'action',
                                                    render: (text, record) => {
                                                        return (
                                                            <span>
                                                                <Popconfirm
                                                                    title="确定删除该笔记?" onConfirm={() => this.deleteOuterNote(record)}>
                                                                    {/* order字段长度为1标识outer笔记 */}
                                                                    {record.type === "笔记" && record.order.indexOf('-') === -1
                                                                        && (<a style={{ marginRight: 60 }}>删除笔记</a>)}
                                                                </Popconfirm>
                                                                <Popconfirm
                                                                    title="确定删除该笔记?" onConfirm={() => this.deleteInnerNote(record)}>
                                                                    {/* order字段长度为3标识inner笔记 */}
                                                                    {record.type === "笔记" && record.order.indexOf('-') !== -1
                                                                        && (<a style={{ marginRight: 90 }}>删除</a>)
                                                                    }
                                                                </Popconfirm>
                                                                <Popconfirm
                                                                    title="确定删除该笔记本及其所有笔记?" onConfirm={() => this.deleteNotebook(record)}>
                                                                    {record.type === "笔记本"
                                                                        && (<a style={{ marginRight: 50 }}>删除笔记本</a>)}
                                                                </Popconfirm>
                                                                {record.type === "笔记本"
                                                                    && (<a onClick={() => this.showAddNoteModal('inner', record)}>添加笔记</a>)}
                                                                {(record.order === (this.state.tableRecords.notebooks.length + 1).toString())
                                                                    && (<Tag color="#87d068">首页笔记</Tag>)}
                                                                {/* {(record.order === (this.state.tableRecords.notebooks.length + 1).toString()) ?
                                                                    (<a>首页笔记</a>) : (record.type === "笔记" && record.order.indexOf('-') === -1 && (<a>设为首页笔记</a>))} */}
                                                            </span>
                                                        )
                                                    },
                                                },
                                                {
                                                    title: '移动',
                                                    key: 'move',
                                                    render: (text, record) => {
                                                        return (
                                                            <span>
                                                                {/* 过滤掉order为'1'、’x-1‘、notes第一项的值 */}
                                                                {record.order !== '1' && (record.order !== (this.state.tableRecords.notebooks.length + 1).toString())
                                                                    && record.order.slice(-2) !== '-1' &&
                                                                    (<Button shape="circle" style={{ marginRight: 50 }} onClick={() => this.moveUp(record)}>
                                                                        <Icon type="up" />
                                                                    </Button>)
                                                                }
                                                            </span>
                                                        )
                                                    },
                                                },
                                            ]
                                        }
                                        dataSource={tableData}
                                    // dataSource={[{ "order": "1", "name": "月计划", "type": "笔记本" }, { "order": "1-1", "name": "月计划", "type": "笔记" }, { "order": "1-2", "name": "月度打卡表", "type": "笔记" }, { "order": "2", "name": "年计划", "type": "笔记本" }, { "order": "2-1", "name": "月计划", "type": "笔记" }, { "order": "2-2", "name": "月度打卡表", "type": "笔记" }, { "order": "3", "name": "时间管理", "type": "笔记" }]}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={9} ></Col>
                            <Col span={2} >
                                <Button type="primary" disabled={this.state.disableSaveBtn} onClick={this.addTemplate}>
                                    保存
                                </Button>
                            </Col>
                            <Col span={2} >
                                <Button type="primary" onClick={this.redirectToHome}>
                                    返回
                                </Button>
                            </Col>
                            <Col span={8} ></Col>
                        </Row>

                        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                    </div>
                </Content>
            );
        }
    }
}
import React from "react";
import {Link} from 'react-router-dom';
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Icon,
    Input,
    Layout,
    message,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Table
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService,
    DeletedTemplateRequest,
    GetSeriesRequest,
    GetTemplatesRequest,
    Pageable,
    Template
} from "./grpcweb/AdvancedTemplateService";

const {Option} = Select;
const {Content} = Layout;


export default class TemplateManageContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //一级系列列表
            firstSeries: [],
            //渲染模版查询列表
            templatesMeta: [],
            //模版查询请求参数
            templatetitle: '',
            seriesId: null,
            pagesize: 20,
            //二级系列下拉列表项
            secondSeries: [],
            //控制跳转新增模版页面
            templatesEditorVisible: false,
            total: 0,
            current: 1,
            terminalType: 0,
            platform: 1,

        };
    };

    componentDidMount = () => {
        this.queryTenmplateList(1);
    }

    firstSeriesItems = () => {
        return this.state.firstSeries.map(
            (item) => (<Option key={item.seriesid} value={item.seriesid}>{item.name}</Option>)
        );
    };

    secondSeriesItems = () => {
        return this.state.secondSeries.map(
            (item) => (<Option key={item.seriesid} value={item.seriesid}>{item.name}</Option>)
        );
    };

    /**
     * 请求一级系列列表
     */
    querySieriesByTerminalTypeAndPlatform = (terminalType, platform) => {
        let request = new GetSeriesRequest();
        request.setTerminaltype(terminalType);
        request.setPlatform(platform);
        console.log('getSubSeries request:', JSON.stringify(request.toString()));
        AdvancedTemplateService.getSubSeries(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    this.setState({firstSeries: res.seriesList});
                }
            }
        });
    };

    /**
     * 请求模版列表
     */
    queryTenmplateList = (page) => {
        let request = new GetTemplatesRequest();
        let template = new Template();
        template.setTitle(this.state.templatetitle);
        request.setTemplate(template);
        request.setSeriesid(this.state.seriesId);
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        console.log('getTemplates request:', JSON.stringify(request));
        AdvancedTemplateService.getTemplates(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                // console.log("response.Templates: " + JSON.stringify(res.templatesList));
                switch (res.code) {
                    case 200:
                        res.templatesList.forEach((template) => {
                            switch (template.type) {
                                case 1:
                                    template.typeName = '空间模版';
                                    break;
                                case 2:
                                    template.typeName = '笔记本模版';
                                    break;
                                case 3:
                                    template.typeName = '笔记模版';
                                    break;
                                default:
                                    break;
                            }
                            switch (template.servicelevel) {
                                case 1:
                                    template.servicelevelName = 'Verse basic会员';
                                    break;
                                case 2:
                                    template.servicelevelName = '标准账户（暂不支持）';
                                    break;
                                case 3:
                                    template.servicelevelName = '高级账户（暂不支持）';
                                    break;
                                case 5:
                                    template.servicelevelName = 'Verse pro会员';
                                    break;
                                case 6:
                                    template.servicelevelName = 'Verse svip会员';
                                    break;
                                default:
                                    break;
                            }
                            switch (template.terminaltype) {
                                case 0:
                                    template.terminaltype = 'Web端';
                                    break;
                                case 1:
                                    template.terminaltype = '移动端';
                                    break;
                                case 2:
                                    template.terminaltype = 'Web端和移动端';
                                    break;
                                default:
                                    break;
                            }
                        });
                        this.setState(
                            {
                                templatesMeta: res.templatesList,
                                total: res.total,
                                current: page,
                            },
                            () => console.log("INFO:getTemplates : ", this.state.total)
                        );
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    };

    //搜索框键入文本时触发
    handelInputChange = (e) => {
        this.setState({
            templatetitle: e.target.value
        });
    };

    //点击删除模版按钮
    handleDeleteTemplate = (templateRecord) => {
        console.log('您点击了删除模版');
        let request = new DeletedTemplateRequest();
        request.setTemplateid(templateRecord.templateid);
        AdvancedTemplateService.deletedTemplate(request, {}, (err, response) => {
            // debugger;
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                let res = response.toObject();
                switch (res.code) {
                    case 3002:
                        Modal.warning({content: '当前模版挂载在系列上，不能删除'});
                        break;
                    case 200:
                        Modal.success({content: '删除模版成功'});
                        this.queryTenmplateList();
                        break;
                    default:
                        break;
                }
            }
        });
    }

    //选择平台后动作
    handleTerminalTypeChange = (value) => {
        let terminalType = (typeof value == "undefined") ? null : value;
        this.setState({
            terminalType: terminalType
        });
        // this.querySieriesByTerminalType(terminalType);
    }

    //选择模板应用范围
    handlePlatformChange = (value) => {
        let platform = (typeof value == "undefined") ? null : value;
        this.setState({
            platform: platform
        });
        this.querySieriesByTerminalTypeAndPlatform(this.state.terminalType, platform);
    }

    //用户选择一级系列后的动作
    handleFirstSeriesChange = (value) => {
        console.log(`selected ${value}`);
        let seriesId = (typeof value == "undefined") ? null : value;
        this.querySieriesById(seriesId);
    }

    //用户选择二级系列后的动作
    handleSecondSeriesChange = (value) => {
        console.log(`selected ${value}`);
        this.setState({
            seriesId: value
        });
    };

    // 根据系列id查询其子系列
    querySieriesById = (seriesId) => {
        let request = new GetSeriesRequest();
        request.setSeriesid(seriesId);
        request.setTerminaltype(this.state.terminalType);
        request.setPlatform(this.state.platform);
        console.log('getSubSeries request:', JSON.stringify(request));
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        AdvancedTemplateService.getSubSeries(request, {}, (err, response) => {
            const res = response.toObject();
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                } else {
                    console.log("response.seriesList: " + JSON.stringify(res.seriesList));
                    //seriesId若为空，查询的结果是一级系列列表
                    if (seriesId) {
                        this.setState(
                            {secondSeries: res.seriesList},
                            () => console.log("INFO:secondSeries : ", JSON.stringify(this.state.secondSeries))
                        )
                    }
                }
            }
        });
    };

    openNotification = () => {
        notification.open({
            message: '模版删除规则及条件：',
            description:
                '1.此功能为模版永久删除操作，一旦删除将不可恢复，请慎重操作。2.删除模版将不可查看，用户端同步删除该模版。'
                + '删除条件：不能关联系列，请在系列管理中解除相关系列关系',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    onChangePage = page => {
        this.queryTenmplateList(page);
    };


    render() {
        return (
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>模版管理</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Row gutter={16}>
                        <Col span={2} offset={0}>
                            系列平台
                        </Col>
                        <Col span={2} offset={0}>
                            <Select defaultValue="请选择" style={{width: 120}} onChange={this.handleTerminalTypeChange}
                                    allowClear>
                                <Option value="0">Web端</Option>
                            </Select>
                        </Col>
                        <Col span={3} offset={1}>
                            模板应用范围
                        </Col>
                        <Col span={2} offset={0}>
                            <Select
                                defaultValue="请选择"
                                style={{width: 120}}
                                onChange={this.handlePlatformChange}
                                allowClear
                            >
                                <Option key="1" value="1">Verse</Option>
                            </Select>
                        </Col>

                        <Col span={2} offset={1}>
                            选择系列
                        </Col>
                        <Col span={3} offset={0}>
                            <Select defaultValue="一级系列" style={{width: 120}} onChange={this.handleFirstSeriesChange}
                                    allowClear>
                                <Option value="请选择" disabled>请选择</Option>
                                {this.firstSeriesItems()}
                            </Select>
                        </Col>
                        <Col span={2} offset={0}>
                            <Select defaultValue="二级系列" style={{width: 120}} onChange={this.handleSecondSeriesChange}
                                    allowClear>
                                <Option value="请选择" disabled>请选择</Option>
                                {this.secondSeriesItems()}
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={2} offset={1}>
                            模版名称
                        </Col>
                        <Col span={4} offset={0}>
                            <Input id='name' placeholder="模版名称" onChange={this.handelInputChange}/>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" onClick={() => this.queryTenmplateList(1)}>查询</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary">
                                <Link to={'/advanced-template-verse/template/new'}>
                                    <span>新增</span>
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                    <div>
                        <Table
                            columns={
                                [
                                    {
                                        title: '模版ID',
                                        dataIndex: 'templateid',
                                    },
                                    {
                                        title: '模版名称',
                                        dataIndex: 'title',
                                    },
                                    {
                                        title: '类型',
                                        dataIndex: 'typeName',
                                    },
                                    // {
                                    //     title: '应用范围',
                                    //     dataIndex: 'platform',
                                    // },
                                    {
                                        title: '端类型',
                                        dataIndex: 'terminaltype',
                                    },
                                    {
                                        title: '用户限制等级',
                                        dataIndex: 'servicelevelName',
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, record) => (
                                            <span>
                                                <a style={{marginRight: 16}}>
                                                    <Link to={'/advanced-template-verse/template/edit/' + record.templateid}>
                                                        <span>编辑</span>
                                                    </Link>
                                                </a>
                                                <Popconfirm title="Sure to delete?"
                                                            onConfirm={() => this.handleDeleteTemplate(record)}>
                                                    <a>删除</a>
                                                </Popconfirm>
                                                <Icon type="info-circle" onClick={this.openNotification}/>
                                            </span>
                                        ),
                                    },
                                ]
                            }
                            dataSource={this.state.templatesMeta}
                            pagination={{
                                pageSize: this.state.pagesize,
                                total: this.state.total,
                                onChange: this.onChangePage,
                            }}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}
import React from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table,
    Upload,
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService,
    AdvancedTemplateAdminService,
    Banner, AddBannerRequest, GetBannersReply, GetBannersRequest, DeleteBannerRequest, UpdateBannersRequest, Void, SimpleRequest, EnumTerminalType, Platform
} from "./grpcweb/AdvancedTemplateService";

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

export default class BannerContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidates: "",
            banners: [],
            published: [],
            currentBanner: { bannerid: undefined, bannerImage: undefined, type: undefined, value: undefined },
            previewVisible: false,
            showBannerEditor: false
        }
    }

    showBannerEditor = (record, opt) => {
        this.setState({
            showBannerEditor: true,
        }, () => {
            console.log("INFO: show banner editor")
        });
        const currentBanner = { ...this.state.currentBanner };
        console.log("record: ", record);
        if (opt === 'update') {
            currentBanner.bannerid = record.bannerid;
            currentBanner.bannerImage = record.bannerimage;
            currentBanner.type = record.type;
            currentBanner.value = record.value;
            console.log("currentBanner: ", currentBanner);
            this.setState({
                currentBanner
            }, () => {
                console.log("INFO: : update banner editor ")
            });
        }
        if (opt === 'add') {
            currentBanner.bannerImage = '';
            currentBanner.type = 0;
            currentBanner.value = '';
            this.setState({
                currentBanner
            }, () => {
                console.log("visible: " + this.state.visible + " name :" + this.state.name)
            });
        }
    };

    handleBannerEditorSubmit = () => {
        console.log("current banner: ", this.state.currentBanner);
        const self = this;
        if (this.state.currentBanner.bannerid !== undefined) {
            let currBanner = new UpdateBannersRequest();
            let banner = new Banner();
            banner.setValue(this.state.currentBanner.value);
            banner.setBannerimage(this.state.currentBanner.bannerImage);
            banner.setType(this.state.currentBanner.type);
            banner.setBannerid(this.state.currentBanner.bannerid);
            currBanner.addBanners(banner);
            AdvancedTemplateAdminService.updateBanner(currBanner, {}, (err, response) => {
                const res = response.toObject();
                console.log("response: ", res);
                if (err) {
                    console.log("err.code " + err.code);
                    message.error(err.message);
                    return;
                }
                if (res.code !== 200) {
                    message.error(res.message);
                    return;
                }
                message.success('Update successfully');
                self.refreshDraftBanners();
            });
        } else {
            let request = new AddBannerRequest();
            let banner = new Banner();
            banner.setValue(this.state.currentBanner.value);
            banner.setBannerimage(this.state.currentBanner.bannerImage);
            banner.setType(this.state.currentBanner.type);
            request.setBanner(banner);
            AdvancedTemplateAdminService.addBanner(request, {}, (err, response) => {
                const res = response.toObject();
                console.log("Add response: ", res);
                if (err) {
                    console.log("err.code " + err.code);
                    message.error(err.message);
                    return;
                }
                if (res.code !== 200) {
                    message.error(res.message);
                    return;
                }
                message.success('added successfully');
                self.refreshDraftBanners();
            });
        }
        this.setState({
            showBannerEditor: false,
        });
    };

    refreshDraftBanners = () => {
        const request = new Void();
        const self = this;
        AdvancedTemplateAdminService.getDraftBanners(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("get draft response: ", res);
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
                return;
            }
            if (res.code !== 200) {
                message.error(res.message);
                return;
            }
            self.setState({ banners: res.bannersList });
            message.success('Draft response');
        });
    };

    handleCancel = () => {
        this.setState({
            showBannerEditor: false,
        }, () => {
            console.log("INFO: handleCancel ")
        });
    };

    queryBanners = () => {
        console.log("going to query banner from admin backend");
    };

    componentDidMount() {
        this.setState({
            platform: 1,
            terminalType: 1,
        }, () => {
            console.log('init')
        });
        this.refreshDraftBanners();
        // this.getPublishedBanners();
    }

    delelteBannerConfig = (bannerId) => {
        console.log("Delete Banner by Id ", bannerId);
        const request = new DeleteBannerRequest();
        request.setBannerid(bannerId);
        AdvancedTemplateAdminService.deleteBanner(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("delete response: ", res);
            if (err) {
                console.log("delete banner - err.code " + err.code);
                message.error(err.message);
                return;
            }
            if (res.code !== 200) {
                message.error(res.message);
                return;
            }
            message.success('Banner ', bannerId, ' deleted.');
            this.refreshDraftBanners();
        });
    };

    renderSwitch = (value, record) => {
        switch (record.type) {
            case 1:
                return 'Deeplink';
            case 2:
                return '跳转链接';
            case 3:
                return '模板 ID';
            default:
                return '';
        }
    }

    showEditor = (record, opt) => {
        this.setState({
            showBannerEditor: true,
            opt: opt,
        }, () => {
            console.log("INFO: showModal ")
        }
        );
        if (opt === 'update') {
            this.setState({
                type: record.type,
                value: record.value,
            }, () => {
                console.log("INFO: update ")
            });
        }
        if (opt === 'add') {
            this.setState({
                currentBanner: {
                    type: 0,
                    value: '',
                },
            }, () => {
                console.log("visible: " + this.state.visible + " name :" + this.state.name)
            });
        }
    };

    handleBannerType = e => {
        console.log('Banner type changed: ', e.target.value);
        var currentBanner = { ...this.state.currentBanner };
        currentBanner.type = e.target.value;
        this.setState({
            currentBanner
        });
    };

    handleBannerValue = e => {
        console.log('Banner config value changed: ', e.target.value);
        var currentBanner = { ...this.state.currentBanner };
        currentBanner.value = e.target.value;
        this.setState({
            currentBanner
        });
    };

    handleCandidatesChanged = e => {
        console.log('Banner publish updated: ', e.target.value);
        this.setState({
            candidates: e.target.value
        });
    };

    getPublishedBanners = e => {
        const request = GetBannersRequest()
        request.setTerminaltype(1);
        request.setPlatform(1);
        AdvancedTemplateService.getBanners(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("published banners: ", res);
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
                return;
            }
            if (res.code !== 200) {
                message.error(res.message);
                return;
            }
            this.state.published = res.banners;
        });
    };

    publishBanner = e => {
        console.log('Banner publish: ', this.state.candidates);
        const request = new SimpleRequest();
        request.setParam(this.state.candidates);
        const self = this;
        AdvancedTemplateAdminService.publishBanners(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("publish response: ", res);
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
                return;
            }
            if (res.code !== 200) {
                message.error(res.message);
                return;
            }
            message.success('Publish response');
        });
    };

    handleChange = e => {
        console.log('Banner handle image change: ', e);
        const reader = new FileReader();
        const self = this;
        let currentBanner = {};
        reader.onloadend = function (file) {
            currentBanner = { ...self.state.currentBanner };
            currentBanner.bannerImage = file.target.result;
            self.setState({ currentBanner });
        }
        reader.readAsDataURL(e.file);
    };

    getBase64 = (e) => {
        e.onSuccess();
    }

    handleBeforeUpload = e => {
        console.log('Banner handle image change: ', e);
        console.log(e);
        return false;
    };

    handlePreview = e => {
        console.log('Upload image preview event');
        console.log(e);
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>系列管理</Breadcrumb.Item>
                    <Breadcrumb.Item>移动端 Banner 管理</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={20}>
                        <Col span={2} offset={1}>
                            Banner 管理
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10} offset={10}>
                            <Input type="text" placeholder="格式：模板ID｜模板ID（1｜2｜3）" value={this.state.candidates} onChange={this.handleCandidatesChanged} />
                        </Col>
                        <Col span={1} >
                            <Button type="primary" onClick={() => this.publishBanner()}>发布 Banner</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1} offset={10}>
                            <div>
                                {this.state.published}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={1} offset={10}>
                            <Button type="primary" onClick={() => this.showEditor(0, 'add')}>新增 Banner</Button>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <div>
                            <Table columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'bannerid',
                                }, {
                                    title: '类型',
                                    dataIndex: 'type',
                                }, {
                                    title: '配置',
                                    dataIndex: 'value',
                                }, {
                                    title: '图片',
                                    render: (text, record) => {
                                        return (<img alt="example" style={{ width: "100%" }} src={record.bannerimage} />);
                                    }
                                },
                                {
                                    title: '操作',
                                    key: 'action',
                                    render: (text, record) => {
                                        return (
                                            <div>
                                                <span>
                                                    <Popconfirm title="确定删除？"
                                                        onConfirm={() => this.delelteBannerConfig(record.bannerid)}
                                                        okText="确定" cancelText="取消">
                                                        <a style={{ marginRight: 16 }}>删除</a>
                                                    </Popconfirm>
                                                    <div>
                                                        <a style={{ marginRight: 16 }}
                                                            onClick={() => this.showBannerEditor(record, 'update')}>编辑</a>
                                                    </div>
                                                </span>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                                dataSource={this.state.banners}
                                pagination={false}
                            />
                        </div>
                    </Row>
                    <div>
                        <Modal title="编辑 Banner" visible={this.state.showBannerEditor} onOk={this.handleBannerEditorSubmit} onCancel={this.handleCancel}>
                            <div>
                                <Row gutter={24}>
                                    <Row gutter={16}>
                                        <Col span={8}> 类型：</Col>
                                    </Row>
                                    <Radio.Group onChange={this.handleBannerType} value={this.state.currentBanner.type}>
                                        <Radio type="radio" value={1}>Depplink</Radio>
                                        <Radio type="radio" value={2}>URL</Radio>
                                        <Radio type="radio" value={3}>TemplateId</Radio>
                                    </Radio.Group>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={8}> {
                                        {
                                            1: 'Deeplink',
                                            2: 'URL',
                                            3: '模板 ID',
                                        }[this.state.currentBanner.type]
                                    }：</Col>
                                    <Col span={16}>
                                        {
                                            {
                                                undefined: <Input placeholder="请输入 URL" value={this.state.currentBanner.value} onChange={this.handleBannerValue} />,
                                                1: <Input placeholder="请输入 Deeplink" value={this.state.currentBanner.value} onChange={this.handleBannerValue} />,
                                                2: <Input placeholder="请输入 URL" value={this.state.currentBanner.value} onChange={this.handleBannerValue} />,
                                                3: <Input placeholder="请输入模板 ID" value={this.state.currentBanner.value} onChange={this.handleBannerValue} />,
                                            }[this.state.currentBanner.type]
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Row>Banner图片</Row>
                                    <Row span={16}>
                                        <Upload
                                            customRequest={this.getBase64}
                                            listType="picture-card"
                                            showUploadList={false}
                                            onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                            beforeUpload={this.handleBeforeUpload} // return false so that antd doesn't upload the picture right away
                                        >
                                            <div>
                                                <Row>
                                                    {this.state.currentBanner.bannerImage == undefined ? <div className="ant-upload-text">Upload</div> : <img alt="example" style={{ width: "50%" }} src={this.state.currentBanner.bannerImage} />}
                                                </Row>
                                            </div>
                                        </Upload>
                                    </Row>
                                </Row>
                            </div>
                        </Modal>
                    </div>
                </div>
            </Content>
        );
    }
}
import {Component} from "react";
import {message, Button, Layout, Select, Table, Tag, Comment, Tooltip, Avatar, Modal} from "antd";
import React from "react";
import PropTypes from "prop-types";
import TemplateConfig from 'TemplateConfig';
import fetchService from "../../../utils/FetchService";
import PublishEditor from "./PublishEditor";

const { Content, Footer } = Layout;
// const Option = Select.Option;

const METADATA_TYPE_CATEGORY = "category";

const timeConverter = timestamp => {
	const a = new Date(timestamp);
	const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	const year = a.getFullYear();
	const month = months[a.getMonth()];
	const date = a.getDate();
	const hour = a.getHours();
	const min = a.getMinutes();
	const sec = a.getSeconds();
	const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
	return time;
};

// const lastTimeConverter = versions => {
// 	return timeConverter(versions[0].time);
// };

export default class TemplateList extends Component {

	COLUMNS = [
		{
			title: '标题',
			dataIndex: 'guid',
		}, {
			title: '时间',
			dataIndex: 'time',
			// render: versionGuid => (
			// 		<span>
			// 			<Select
			// 					defaultValue={lastTimeConverter(this.state.templates[versionGuid].versions)}
			// 					style={{ width: 200 }}
			// 					onChange={this.state.templates[versionGuid].onSelectVersion}
			// 					key={versionGuid}
			// 			>
			// 				{this.state.templates[versionGuid].versions.map((version, index) => {
			// 					return <Option key={index}>{timeConverter(version.time)}</Option>;
			// 				})}
			// 			</Select>
			// 		</span>
			// 	),
		}, {
			title: '分类',
			dataIndex: 'category',
		}, {
			title: '系列',
			dataIndex: 'collection',
			render: text => (
					<span>
					{text.map((tag, index) => {
						const color = index % 2 === 1 ? 'volcano' : 'green';
						return <Tag color={color} key={tag}>{tag.toUpperCase()}</Tag>;
					})}
    		</span>
			),
		}
	];

	static propTypes = {
		versionId: PropTypes.string,
		showTemplateImage: PropTypes.any,
		requestExecuteCommand: PropTypes.any,
		publishCallback: PropTypes.any,
		refreshCallback: PropTypes.any,
	};

	static defaultProps = {
		templates: {},
		showTemplateImage: () => {},
	};

	state = {
		selectedRowKeys: [],
		serverUrl: TemplateConfig.serverUrl,
		templates: [],
		metadatas: [],
		loading: false,
		publishEditorVisible: false,
		diffList: [],
		version: {},
		selectedGuids: {},
	};

	// Override
	componentDidMount() {
		const { versionId } = this.props;
		this.requestVersion(versionId);
	}

	// 请求版本信息
	requestVersion = id => {
		this.setState({ loading: true});
		fetchService.get(this.state.serverUrl + '/getVersion/' + id)
				.then(result => this.setState({ version: result },
						() => {
							console.log("INFO:Template.requestVersion : ", this.state.version);

							if (result.state === 0) {
								this.requestDiffList(this.props.versionId);
							} else {
								console.log("Do not request diff version : ", result);
							}

							Promise.all([
								this.requestMetadata.bind(this)(id),
								this.requestTemplates.bind(this)(id)
							]).then(() => {
								this.setState({ loading: false});
								message.info("获取版本完成");
							}).catch(() => message.error("版本获取失败"));
						}));
	};

	// 请求修改内容列表
	requestDiffList = id => {
		fetchService.get(this.state.serverUrl + '/getDiffList/' + id)
				.then(result => this.setState({ diffList: result }));
	};

	// 请求Metadata
	requestMetadata(id) {
		fetchService.get(this.state.serverUrl + '/getMetadata/' + id)
				.then(result => this.setState({ metadatas: result },
						() => console.log("INFO:Template.requestMetadata : ", this.state.metadatas)));
	};

	// 请求模板列表
	requestTemplates(id) {
		fetchService.get(this.state.serverUrl + '/getTemplates/' + id)
				.then(result => {
					this.appendTableRender(result);
					const { selectedGuids } = this.state;
					Object.keys(result)
							.forEach(guid => {
								selectedGuids[guid] = guid;
								const template = result[guid];
								template.onSelectVersion = value => {
									// const { selectedGuids } = this.state;
									// console.log(value, option, guid)
									selectedGuids[guid] = template.versions[value];
								};
								this.setState({templates: result});
								this.requestThumbnail(guid, img => {
									template.img = img;
									this.setState({templates: result});
								}, 5);
							});
					this.setState({templates: result},
							() => console.log("INFO:Template.requestTemplates : ", this.state.templates))
				});
	};

	// 请求模板
	requestTemplate = guid => {
		fetchService.get(this.state.serverUrl + '/getTemplate/' + guid)
				.then(result => {
					const { templates } = this.state;
					templates[guid] = result;
					this.requestThumbnail(guid, img => {
						templates[guid].img = img;
						this.setState({ templates },
								() => console.log("INFO:Template.requestTemplate : ", result))
					}, 1);
				});
	};

	// 请求缩略图
	requestThumbnail = (guid, callback, count) => {
		const { serverUrl, templates, version } = this.state;
		const template = templates[guid];
		if (!template) {
			return;
		}
		const id = !template.thumbnail_url || template.thumbnail_url === 'large' ? template.guid : template.orig_note_guid;
		const url = serverUrl + '/getThumbnail/' + version.version + '/' + (template.thumbnail_url ? template.thumbnail_url : 'large') + '/' + id;
		fetchService.getBlob(url).then(result => {
			if (result.size === 0 && count > 0) { // 缩略图生成速度可能会比较慢，这里每4秒重新请求一次，直到重试次数为0
				setTimeout(() => this.requestThumbnail(guid, callback, --count), 8000);
				console.log("continue try request thumbnail : " + count, template);
			} else {
				this.readImage(result, callback);
			}
		});
	};

	// 读取图片
	readImage = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	// 缩略图变化
	onThumbnailChange = (guid, img) => {
		const { templates } = this.state;
		templates[guid].img = img;
		templates[guid].thumbnail_url = 'override';
		this.setState({ templates });
	};

	// 显示模版图片
	showTemplateImage = event => this.props.showTemplateImage(event, this.onThumbnailChange);

	// 追加表格渲染
	appendTableRender = () => {
		const { version } = this.state;
		const refreshButton = (guid) => version.state === 0 ? (
				<Button
						type="primary"
						shape="circle"
						icon="redo"
						guid={guid}
						onClick={this.refreshTemplate}
						style={{ float: "left" }}
				/>
		) : (<div></div>);
		this.COLUMNS[0].render = guid => (
				<span>
					{refreshButton(guid)}
					<img
							alt={"	"}
							src={this.state.templates[guid].img}
							style={{ float: "left", height: 40 }}
							guid={guid}
							can_editor={version.state === 0 ? "true" : "false"}
							// editor_callback={this.onThumbnailChange}
							onClick={this.showTemplateImage}
					/>
					<span>
						{
							this.state.templates[guid].title.zh
						}
					</span>
				</span>
		);
	};

	// 模板被选择
	onSelectTemplates = selectedRowKeys => this.setState({selectedRowKeys});

	// 刷新选中模版
	refreshTemplate = event => {
		const guid = event.currentTarget.getAttribute("guid");
		const { requestExecuteCommand, refreshCallback } = this.props;
		const { templates } = this.state;
		// 创建操作命令
		const operation = { command: "RefreshTemplate", data: templates[guid].orig_note_guid };
		// 开始等待
		this.setState({ loading: true});
		// 执行命令
		requestExecuteCommand([operation], () => {
			// 请求版本数据
			// this.requestVersion(versionId);
			this.requestTemplate(guid);
			// 结束等待
			this.setState({ loading: false});
			refreshCallback();
			message.info("已刷新模版");
		});
	};

	// 刷新选中模版和新模版
	refreshTemplates = () => {
		const { requestExecuteCommand, versionId, refreshCallback } = this.props;
		const { selectedRowKeys } = this.state;
		// 创建操作命令
		const operation = { command: "RefreshTemplates", data: selectedRowKeys };
		// 开始等待
		this.setState({ loading: true});
		// 执行命令
		requestExecuteCommand([operation], () => {
			// 请求版本数据
			this.requestVersion(versionId);
			// 结束等待
			this.setState({ loading: false});
			refreshCallback();
			message.info("已刷新模版");
		});
	};

	// 渲染发布框
	renderPublishEditor = () => {
		const { requestExecuteCommand, publishCallback, versionId } = this.props;
		const { publishEditorVisible, diffList, selectedRowKeys, templates, selectedGuids, version } = this.state;
		const hideEditor = () => this.setState({ publishEditorVisible: false, loading: true });
		let publishForm;
		// 发布
		const onPublish = () => {
			const form = publishForm.props.form;
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				const selected = selectedRowKeys.length === 0 ? Object.keys(templates) : selectedRowKeys;
				const guids = selected.map(key => selectedGuids[key]);
				const operation = { command: 'Publish', description: values.description, data: guids, state: version.state };
				hideEditor();
				requestExecuteCommand([operation], () => {
					publishCallback();
					this.setState({ loading: false })
					message.success('已发布当前版本');
				}, versionId);
			});
		};
		return (
				<PublishEditor
						visible={publishEditorVisible}
						onCancel={hideEditor}
						onCreate={onPublish}
						diffList={diffList}
						wrappedComponentRef={(form) => {publishForm = form}}
				/>
		);
	};

	// 渲染刷新模版按钮
	renderRefreshTemplatesButton = version => {
		return version.state === 0 ? (
				<div>
					<Button type="primary" onClick={this.refreshTemplates} style={{ float: "left" }}>刷新模板</Button>
					<span style={{ float: "left" }}>----只刷新选中的模板和新模板</span>
					<br/>
					<br/>
				</div>
		) : (
				<div>
					<Comment
							// actions={actions}
							author={version.published_by_name}
							avatar={(<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon="smile" />)}
							content={(
									<span style={{ float: "left" }}>{version.description}</span>
							)}
							datetime={(
									<Tooltip title="发布时间">
										<span>{timeConverter(version.published_ts)}</span>
									</Tooltip>
							)}
					/>
				</div>
		);
	};

	// 渲染发布按钮
	renderPublishButton = version => {
		return version.state !== 1 ? (
				<Footer style={{ textAlign: 'center'}}>
					<Button type="primary" onClick={() => this.setState({ publishEditorVisible: true })}>发布当前内容</Button>
				</Footer>
		) : (<div></div>);
	};

	// Override
	render() {
		const data = [];
		const { templates, metadatas, selectedRowKeys, version } = this.state;
		// 筛选可选列表
		Object.keys(templates)
				.forEach(guid => {
					const template = templates[guid];
					const row = { key: guid, title: template.title, collection: [], versionGuid: guid, guid: guid, time: timeConverter(template.last_edited_ts) };
					Object.keys(metadatas)
							.filter(mid => metadatas[mid].template_note_ids != null
							&& metadatas[mid].template_note_ids.includes(template.orig_note_guid))
							.forEach(mid => {
								if (metadatas[mid].type === METADATA_TYPE_CATEGORY) {
									row.category = metadatas[mid].label.zh;
								} else {
									row.collection.push(metadatas[mid].label.zh)
								}
							});
					data.push(row);
				});
		const rowSelection = { selectedRowKeys, onChange: this.onSelectTemplates };
		const table = version.state === 0 ?
				(<Table columns={this.COLUMNS} dataSource={data} rowSelection={rowSelection} />) :
				(<Table columns={this.COLUMNS} dataSource={data} />);
		return (
				<div>
					{/*发布框*/}
					{this.renderPublishEditor()}
					{/*已选模板列表*/}
					<Content>
						{this.renderRefreshTemplatesButton(version)}
						{/*<Button*/}
								{/*type="primary"*/}
								{/*onClick={showPublishConfirm}*/}
								{/*style={{ float: "left" }}*/}
								{/*disabled={table.selectedRowKeys.length === 0}*/}
						{/*>*/}
							{/*删除模板*/}
						{/*</Button>*/}
						{/*<span style={{ float: "left" }}>----如果笔记本还有该模板的笔记，删除后刷新还会出现被删除的模板</span>*/}
						{/*<br/>*/}
						{/*<br/>*/}
						{table}
					</Content>
					{/*底部元素*/}
					{this.renderPublishButton(version)}
				</div>
		);
	};

}
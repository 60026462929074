/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.orders.CreateOrderAndMarkAsPaidRequest', null, global);
goog.exportSymbol('proto.orders.GetBalanceByUserReply', null, global);
goog.exportSymbol('proto.orders.GetBalanceByUserRequest', null, global);
goog.exportSymbol('proto.orders.GetListOrdersReply', null, global);
goog.exportSymbol('proto.orders.GetListOrdersRequest', null, global);
goog.exportSymbol('proto.orders.GetListPurchaseOrdersWithProductTypeRequest', null, global);
goog.exportSymbol('proto.orders.RefundOrderForAdminRequest', null, global);
goog.exportSymbol('proto.orders.RefundOrderForAdminResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.GetListPurchaseOrdersWithProductTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetListPurchaseOrdersWithProductTypeRequest.displayName = 'proto.orders.GetListPurchaseOrdersWithProductTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.GetListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetListOrdersRequest.displayName = 'proto.orders.GetListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetListOrdersReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.orders.GetListOrdersReply.repeatedFields_, null);
};
goog.inherits(proto.orders.GetListOrdersReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetListOrdersReply.displayName = 'proto.orders.GetListOrdersReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetBalanceByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.GetBalanceByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetBalanceByUserRequest.displayName = 'proto.orders.GetBalanceByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetBalanceByUserReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.GetBalanceByUserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetBalanceByUserReply.displayName = 'proto.orders.GetBalanceByUserReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.CreateOrderAndMarkAsPaidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.CreateOrderAndMarkAsPaidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.CreateOrderAndMarkAsPaidRequest.displayName = 'proto.orders.CreateOrderAndMarkAsPaidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.RefundOrderForAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.RefundOrderForAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.RefundOrderForAdminRequest.displayName = 'proto.orders.RefundOrderForAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.RefundOrderForAdminResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.RefundOrderForAdminResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.RefundOrderForAdminResult.displayName = 'proto.orders.RefundOrderForAdminResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetListPurchaseOrdersWithProductTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    producttype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetListPurchaseOrdersWithProductTypeRequest;
  return proto.orders.GetListPurchaseOrdersWithProductTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetListPurchaseOrdersWithProductTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetListPurchaseOrdersWithProductTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 productType = 2;
 * @return {number}
 */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.getProducttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.GetListPurchaseOrdersWithProductTypeRequest.prototype.setProducttype = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetListOrdersRequest}
 */
proto.orders.GetListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetListOrdersRequest;
  return proto.orders.GetListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetListOrdersRequest}
 */
proto.orders.GetListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 pageNumber = 1;
 * @return {number}
 */
proto.orders.GetListOrdersRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersRequest.prototype.setPagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageSize = 2;
 * @return {number}
 */
proto.orders.GetListOrdersRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersRequest.prototype.setPagesize = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 userId = 3;
 * @return {number}
 */
proto.orders.GetListOrdersRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersRequest.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string orderNumber = 4;
 * @return {string}
 */
proto.orders.GetListOrdersRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.GetListOrdersRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.orders.GetListOrdersReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetListOrdersReply.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetListOrdersReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetListOrdersReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListOrdersReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    common_pb.Order.toObject, includeInstance),
    currentpagenumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nextpagenumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    previouspagenumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalpagenumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalelementsnumber: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetListOrdersReply}
 */
proto.orders.GetListOrdersReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetListOrdersReply;
  return proto.orders.GetListOrdersReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetListOrdersReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetListOrdersReply}
 */
proto.orders.GetListOrdersReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Order;
      reader.readMessage(value,common_pb.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentpagenumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextpagenumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreviouspagenumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpagenumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalelementsnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetListOrdersReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetListOrdersReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetListOrdersReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetListOrdersReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.Order.serializeBinaryToWriter
    );
  }
  f = message.getCurrentpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNextpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPreviouspagenumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalpagenumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalelementsnumber();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.GetListOrdersReply.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.GetListOrdersReply.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.GetListOrdersReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.GetListOrdersReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Order orders = 3;
 * @return {!Array<!proto.orders.Order>}
 */
proto.orders.GetListOrdersReply.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.orders.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Order, 3));
};


/** @param {!Array<!proto.orders.Order>} value */
proto.orders.GetListOrdersReply.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.orders.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.orders.Order}
 */
proto.orders.GetListOrdersReply.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.orders.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.orders.GetListOrdersReply.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int32 currentPageNumber = 4;
 * @return {number}
 */
proto.orders.GetListOrdersReply.prototype.getCurrentpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersReply.prototype.setCurrentpagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 nextPageNumber = 5;
 * @return {number}
 */
proto.orders.GetListOrdersReply.prototype.getNextpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersReply.prototype.setNextpagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 previousPageNumber = 6;
 * @return {number}
 */
proto.orders.GetListOrdersReply.prototype.getPreviouspagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersReply.prototype.setPreviouspagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 totalPageNumber = 7;
 * @return {number}
 */
proto.orders.GetListOrdersReply.prototype.getTotalpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersReply.prototype.setTotalpagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 totalElementsNumber = 8;
 * @return {number}
 */
proto.orders.GetListOrdersReply.prototype.getTotalelementsnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.orders.GetListOrdersReply.prototype.setTotalelementsnumber = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetBalanceByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetBalanceByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetBalanceByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetBalanceByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetBalanceByUserRequest}
 */
proto.orders.GetBalanceByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetBalanceByUserRequest;
  return proto.orders.GetBalanceByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetBalanceByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetBalanceByUserRequest}
 */
proto.orders.GetBalanceByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetBalanceByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetBalanceByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetBalanceByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetBalanceByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.orders.GetBalanceByUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.GetBalanceByUserRequest.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetBalanceByUserReply.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetBalanceByUserReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetBalanceByUserReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetBalanceByUserReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balanceinfo: (f = msg.getBalanceinfo()) && common_pb.BalanceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetBalanceByUserReply}
 */
proto.orders.GetBalanceByUserReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetBalanceByUserReply;
  return proto.orders.GetBalanceByUserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetBalanceByUserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetBalanceByUserReply}
 */
proto.orders.GetBalanceByUserReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.BalanceInfo;
      reader.readMessage(value,common_pb.BalanceInfo.deserializeBinaryFromReader);
      msg.setBalanceinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetBalanceByUserReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetBalanceByUserReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetBalanceByUserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetBalanceByUserReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBalanceinfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.BalanceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.GetBalanceByUserReply.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.GetBalanceByUserReply.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.GetBalanceByUserReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.GetBalanceByUserReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BalanceInfo balanceInfo = 3;
 * @return {?proto.orders.BalanceInfo}
 */
proto.orders.GetBalanceByUserReply.prototype.getBalanceinfo = function() {
  return /** @type{?proto.orders.BalanceInfo} */ (
    jspb.Message.getWrapperField(this, common_pb.BalanceInfo, 3));
};


/** @param {?proto.orders.BalanceInfo|undefined} value */
proto.orders.GetBalanceByUserReply.prototype.setBalanceinfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.GetBalanceByUserReply.prototype.clearBalanceinfo = function() {
  this.setBalanceinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.GetBalanceByUserReply.prototype.hasBalanceinfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.CreateOrderAndMarkAsPaidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalordernumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    redemptioncode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    chargedpriceincent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    accountingordernumber: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.CreateOrderAndMarkAsPaidRequest}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.CreateOrderAndMarkAsPaidRequest;
  return proto.orders.CreateOrderAndMarkAsPaidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.CreateOrderAndMarkAsPaidRequest}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalordernumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedemptioncode(value);
      break;
    case 5:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChargedpriceincent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountingordernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.CreateOrderAndMarkAsPaidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.CreateOrderAndMarkAsPaidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalordernumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRedemptioncode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getChargedpriceincent();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAccountingordernumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string productCode = 2;
 * @return {string}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string externalOrderNumber = 3;
 * @return {string}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getExternalordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setExternalordernumber = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string redemptionCode = 4;
 * @return {string}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getRedemptioncode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setRedemptioncode = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PayType payType = 5;
 * @return {!proto.orders.PayType}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 chargedPriceInCent = 6;
 * @return {number}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getChargedpriceincent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setChargedpriceincent = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string accountingOrderNumber = 7;
 * @return {string}
 */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.getAccountingordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.orders.CreateOrderAndMarkAsPaidRequest.prototype.setAccountingordernumber = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.RefundOrderForAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.RefundOrderForAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.RefundOrderForAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderForAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refundfee: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.RefundOrderForAdminRequest}
 */
proto.orders.RefundOrderForAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.RefundOrderForAdminRequest;
  return proto.orders.RefundOrderForAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.RefundOrderForAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.RefundOrderForAdminRequest}
 */
proto.orders.RefundOrderForAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundfee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.RefundOrderForAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.RefundOrderForAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.RefundOrderForAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderForAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefundfee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.RefundOrderForAdminRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.RefundOrderForAdminRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 refundFee = 2;
 * @return {number}
 */
proto.orders.RefundOrderForAdminRequest.prototype.getRefundfee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.RefundOrderForAdminRequest.prototype.setRefundfee = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.RefundOrderForAdminResult.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.RefundOrderForAdminResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.RefundOrderForAdminResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderForAdminResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.RefundOrderForAdminResult}
 */
proto.orders.RefundOrderForAdminResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.RefundOrderForAdminResult;
  return proto.orders.RefundOrderForAdminResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.RefundOrderForAdminResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.RefundOrderForAdminResult}
 */
proto.orders.RefundOrderForAdminResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.RefundOrderForAdminResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.RefundOrderForAdminResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.RefundOrderForAdminResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderForAdminResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.RefundOrderForAdminResult.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.RefundOrderForAdminResult.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.RefundOrderForAdminResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.RefundOrderForAdminResult.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool result = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.orders.RefundOrderForAdminResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.orders.RefundOrderForAdminResult.prototype.setResult = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.orders);

/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
goog.exportSymbol('proto.orders.AgreementStatus', null, global);
goog.exportSymbol('proto.orders.BalanceInfo', null, global);
goog.exportSymbol('proto.orders.ClientType', null, global);
goog.exportSymbol('proto.orders.DiscountSource', null, global);
goog.exportSymbol('proto.orders.GetOrderRequest', null, global);
goog.exportSymbol('proto.orders.IncentiveType', null, global);
goog.exportSymbol('proto.orders.Order', null, global);
goog.exportSymbol('proto.orders.OrderDetail', null, global);
goog.exportSymbol('proto.orders.OrderResult', null, global);
goog.exportSymbol('proto.orders.OrderStatus', null, global);
goog.exportSymbol('proto.orders.OrderType', null, global);
goog.exportSymbol('proto.orders.PayType', null, global);
goog.exportSymbol('proto.orders.Period', null, global);
goog.exportSymbol('proto.orders.ReplyCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OrderResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.OrderResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OrderResult.displayName = 'proto.orders.OrderResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.orders.Order.repeatedFields_, null);
};
goog.inherits(proto.orders.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.Order.displayName = 'proto.orders.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OrderDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.OrderDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OrderDetail.displayName = 'proto.orders.OrderDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.GetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.GetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.GetOrderRequest.displayName = 'proto.orders.GetOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.BalanceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.BalanceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.BalanceInfo.displayName = 'proto.orders.BalanceInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OrderResult.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OrderResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OrderResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: (f = msg.getOrder()) && proto.orders.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OrderResult}
 */
proto.orders.OrderResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OrderResult;
  return proto.orders.OrderResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OrderResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OrderResult}
 */
proto.orders.OrderResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.orders.Order;
      reader.readMessage(value,proto.orders.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OrderResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OrderResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OrderResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.orders.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.OrderResult.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.OrderResult.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.OrderResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.OrderResult.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Order order = 3;
 * @return {?proto.orders.Order}
 */
proto.orders.OrderResult.prototype.getOrder = function() {
  return /** @type{?proto.orders.Order} */ (
    jspb.Message.getWrapperField(this, proto.orders.Order, 3));
};


/** @param {?proto.orders.Order|undefined} value */
proto.orders.OrderResult.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.OrderResult.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.OrderResult.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.orders.Order.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalprice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    chargedprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    modifiedtime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 11, 0),
    externalordernumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    discountsourceid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    orderdetailsList: jspb.Message.toObjectList(msg.getOrderdetailsList(),
    proto.orders.OrderDetail.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ordertype: jspb.Message.getFieldWithDefault(msg, 16, 0),
    discountvalue: jspb.Message.getFieldWithDefault(msg, 19, 0),
    discountsource: jspb.Message.getFieldWithDefault(msg, 20, 0),
    displaychange: jspb.Message.getFieldWithDefault(msg, 22, 0),
    displaycurrency: jspb.Message.getFieldWithDefault(msg, 23, ""),
    balancechange: jspb.Message.getFieldWithDefault(msg, 24, 0),
    expiredtime: jspb.Message.getFieldWithDefault(msg, 25, 0),
    recurring: jspb.Message.getFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.Order}
 */
proto.orders.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.Order;
  return proto.orders.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.Order}
 */
proto.orders.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChargedprice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedtime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModifiedtime(value);
      break;
    case 9:
      var value = /** @type {!proto.orders.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 11:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalordernumber(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountsourceid(value);
      break;
    case 14:
      var value = new proto.orders.OrderDetail;
      reader.readMessage(value,proto.orders.OrderDetail.deserializeBinaryFromReader);
      msg.addOrderdetails(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 16:
      var value = /** @type {!proto.orders.OrderType} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountvalue(value);
      break;
    case 20:
      var value = /** @type {!proto.orders.DiscountSource} */ (reader.readEnum());
      msg.setDiscountsource(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplaychange(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaycurrency(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalancechange(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredtime(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getChargedprice();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedtime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getModifiedtime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getExternalordernumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDiscountsourceid();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOrderdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.orders.OrderDetail.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getDiscountvalue();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDiscountsource();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getDisplaychange();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getDisplaycurrency();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getBalancechange();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getExpiredtime();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional int32 orderId = 1;
 * @return {number}
 */
proto.orders.Order.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setOrderid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.orders.Order.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 quantity = 3;
 * @return {number}
 */
proto.orders.Order.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 totalPrice = 4;
 * @return {number}
 */
proto.orders.Order.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setTotalprice = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 chargedPrice = 5;
 * @return {number}
 */
proto.orders.Order.prototype.getChargedprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setChargedprice = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.orders.Order.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.orders.Order.prototype.setCurrency = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 createdTime = 7;
 * @return {number}
 */
proto.orders.Order.prototype.getCreatedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setCreatedtime = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 modifiedTime = 8;
 * @return {number}
 */
proto.orders.Order.prototype.getModifiedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setModifiedtime = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional OrderStatus status = 9;
 * @return {!proto.orders.OrderStatus}
 */
proto.orders.Order.prototype.getStatus = function() {
  return /** @type {!proto.orders.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.orders.OrderStatus} value */
proto.orders.Order.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string orderNumber = 10;
 * @return {string}
 */
proto.orders.Order.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.orders.Order.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional PayType payType = 11;
 * @return {!proto.orders.PayType}
 */
proto.orders.Order.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.Order.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string externalOrderNumber = 12;
 * @return {string}
 */
proto.orders.Order.prototype.getExternalordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.orders.Order.prototype.setExternalordernumber = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 discountSourceId = 13;
 * @return {number}
 */
proto.orders.Order.prototype.getDiscountsourceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setDiscountsourceid = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated OrderDetail orderDetails = 14;
 * @return {!Array<!proto.orders.OrderDetail>}
 */
proto.orders.Order.prototype.getOrderdetailsList = function() {
  return /** @type{!Array<!proto.orders.OrderDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.orders.OrderDetail, 14));
};


/** @param {!Array<!proto.orders.OrderDetail>} value */
proto.orders.Order.prototype.setOrderdetailsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.orders.OrderDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.orders.OrderDetail}
 */
proto.orders.Order.prototype.addOrderdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.orders.OrderDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.orders.Order.prototype.clearOrderdetailsList = function() {
  this.setOrderdetailsList([]);
};


/**
 * optional string title = 15;
 * @return {string}
 */
proto.orders.Order.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.orders.Order.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional OrderType orderType = 16;
 * @return {!proto.orders.OrderType}
 */
proto.orders.Order.prototype.getOrdertype = function() {
  return /** @type {!proto.orders.OrderType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {!proto.orders.OrderType} value */
proto.orders.Order.prototype.setOrdertype = function(value) {
  jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional int32 discountValue = 19;
 * @return {number}
 */
proto.orders.Order.prototype.getDiscountvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setDiscountvalue = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional DiscountSource discountSource = 20;
 * @return {!proto.orders.DiscountSource}
 */
proto.orders.Order.prototype.getDiscountsource = function() {
  return /** @type {!proto.orders.DiscountSource} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {!proto.orders.DiscountSource} value */
proto.orders.Order.prototype.setDiscountsource = function(value) {
  jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional int32 displayChange = 22;
 * @return {number}
 */
proto.orders.Order.prototype.getDisplaychange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setDisplaychange = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string displayCurrency = 23;
 * @return {string}
 */
proto.orders.Order.prototype.getDisplaycurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.orders.Order.prototype.setDisplaycurrency = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 balanceChange = 24;
 * @return {number}
 */
proto.orders.Order.prototype.getBalancechange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setBalancechange = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 expiredTime = 25;
 * @return {number}
 */
proto.orders.Order.prototype.getExpiredtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.orders.Order.prototype.setExpiredtime = function(value) {
  jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool recurring = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.orders.Order.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.orders.Order.prototype.setRecurring = function(value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OrderDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OrderDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OrderDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdetailsid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    productname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productcode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitprice: jspb.Message.getFieldWithDefault(msg, 6, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalprice: jspb.Message.getFieldWithDefault(msg, 8, 0),
    chargedprice: jspb.Message.getFieldWithDefault(msg, 9, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdtime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    modifiedtime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 15, 0),
    productvalue: jspb.Message.getFieldWithDefault(msg, 16, 0),
    productunit: jspb.Message.getFieldWithDefault(msg, 17, ""),
    producttype: jspb.Message.getFieldWithDefault(msg, 18, 0),
    featurestype: jspb.Message.getFieldWithDefault(msg, 19, ""),
    featuresdata: jspb.Message.getFieldWithDefault(msg, 20, ""),
    discountamount: jspb.Message.getFieldWithDefault(msg, 21, 0),
    recurring: jspb.Message.getFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OrderDetail}
 */
proto.orders.OrderDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OrderDetail;
  return proto.orders.OrderDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OrderDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OrderDetail}
 */
proto.orders.OrderDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdetailsid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnitprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChargedprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedtime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModifiedtime(value);
      break;
    case 13:
      var value = /** @type {!proto.orders.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 15:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductvalue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductunit(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProducttype(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturestype(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturesdata(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscountamount(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OrderDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OrderDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OrderDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderdetailsid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getProductid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProductname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitprice();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getChargedprice();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCreatedtime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getModifiedtime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getProductvalue();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getProductunit();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getFeaturestype();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFeaturesdata();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDiscountamount();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional int32 orderDetailsId = 1;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getOrderdetailsid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setOrderdetailsid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 orderId = 2;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setOrderid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 productId = 3;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getProductid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setProductid = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string productName = 4;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getProductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setProductname = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string productCode = 5;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 unitPrice = 6;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getUnitprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setUnitprice = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 quantity = 7;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 totalPrice = 8;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setTotalprice = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 chargedPrice = 9;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getChargedprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setChargedprice = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 userId = 10;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 createdTime = 11;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getCreatedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setCreatedtime = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 modifiedTime = 12;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getModifiedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setModifiedtime = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional OrderStatus status = 13;
 * @return {!proto.orders.OrderStatus}
 */
proto.orders.OrderDetail.prototype.getStatus = function() {
  return /** @type {!proto.orders.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {!proto.orders.OrderStatus} value */
proto.orders.OrderDetail.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string orderNumber = 14;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional PayType payType = 15;
 * @return {!proto.orders.PayType}
 */
proto.orders.OrderDetail.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.OrderDetail.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 productValue = 16;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getProductvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setProductvalue = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string productUnit = 17;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getProductunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setProductunit = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 productType = 18;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getProducttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setProducttype = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string featuresType = 19;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getFeaturestype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setFeaturestype = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string featuresData = 20;
 * @return {string}
 */
proto.orders.OrderDetail.prototype.getFeaturesdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.orders.OrderDetail.prototype.setFeaturesdata = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 discountAmount = 21;
 * @return {number}
 */
proto.orders.OrderDetail.prototype.getDiscountamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.orders.OrderDetail.prototype.setDiscountamount = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool recurring = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.orders.OrderDetail.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.orders.OrderDetail.prototype.setRecurring = function(value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.GetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.GetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.GetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offercode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.GetOrderRequest}
 */
proto.orders.GetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.GetOrderRequest;
  return proto.orders.GetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.GetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.GetOrderRequest}
 */
proto.orders.GetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffercode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.GetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.GetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.GetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.GetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffercode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.GetOrderRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.GetOrderRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string offerCode = 2;
 * @return {string}
 */
proto.orders.GetOrderRequest.prototype.getOffercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.GetOrderRequest.prototype.setOffercode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.BalanceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.BalanceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.BalanceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.BalanceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalbalancefordisplay: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalbalanceincents: jspb.Message.getFieldWithDefault(msg, 2, 0),
    itunesbalancefordisplay: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itunesbalanceincents: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nonitunesbalancefordisplay: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nonitunesbalanceincents: jspb.Message.getFieldWithDefault(msg, 6, 0),
    previouspayclient: jspb.Message.getFieldWithDefault(msg, 7, 0),
    balanceunit: jspb.Message.getFieldWithDefault(msg, 8, ""),
    purchaseallowed: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.BalanceInfo}
 */
proto.orders.BalanceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.BalanceInfo;
  return proto.orders.BalanceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.BalanceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.BalanceInfo}
 */
proto.orders.BalanceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalbalancefordisplay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalbalanceincents(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItunesbalancefordisplay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItunesbalanceincents(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonitunesbalancefordisplay(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNonitunesbalanceincents(value);
      break;
    case 7:
      var value = /** @type {!proto.orders.ClientType} */ (reader.readEnum());
      msg.setPreviouspayclient(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceunit(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPurchaseallowed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.BalanceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.BalanceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.BalanceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.BalanceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalbalancefordisplay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalbalanceincents();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getItunesbalancefordisplay();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItunesbalanceincents();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNonitunesbalancefordisplay();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNonitunesbalanceincents();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPreviouspayclient();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getBalanceunit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPurchaseallowed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string totalBalanceForDisplay = 1;
 * @return {string}
 */
proto.orders.BalanceInfo.prototype.getTotalbalancefordisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.BalanceInfo.prototype.setTotalbalancefordisplay = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 totalBalanceInCents = 2;
 * @return {number}
 */
proto.orders.BalanceInfo.prototype.getTotalbalanceincents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.BalanceInfo.prototype.setTotalbalanceincents = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string iTunesBalanceForDisplay = 3;
 * @return {string}
 */
proto.orders.BalanceInfo.prototype.getItunesbalancefordisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.BalanceInfo.prototype.setItunesbalancefordisplay = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 iTunesBalanceInCents = 4;
 * @return {number}
 */
proto.orders.BalanceInfo.prototype.getItunesbalanceincents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.orders.BalanceInfo.prototype.setItunesbalanceincents = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string nonITunesBalanceForDisplay = 5;
 * @return {string}
 */
proto.orders.BalanceInfo.prototype.getNonitunesbalancefordisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.orders.BalanceInfo.prototype.setNonitunesbalancefordisplay = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 nonITunesBalanceInCents = 6;
 * @return {number}
 */
proto.orders.BalanceInfo.prototype.getNonitunesbalanceincents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.orders.BalanceInfo.prototype.setNonitunesbalanceincents = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ClientType previousPayClient = 7;
 * @return {!proto.orders.ClientType}
 */
proto.orders.BalanceInfo.prototype.getPreviouspayclient = function() {
  return /** @type {!proto.orders.ClientType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.orders.ClientType} value */
proto.orders.BalanceInfo.prototype.setPreviouspayclient = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string balanceUnit = 8;
 * @return {string}
 */
proto.orders.BalanceInfo.prototype.getBalanceunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.orders.BalanceInfo.prototype.setBalanceunit = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool purchaseAllowed = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.orders.BalanceInfo.prototype.getPurchaseallowed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.orders.BalanceInfo.prototype.setPurchaseallowed = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.orders.ReplyCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  INVALID_PARAM: 2,
  PERMISSION_DENIED: 3,
  NOT_EXIST: 4,
  EXISTED: 5,
  TOO_OFTEN: 6,
  RATE_LIMIT_REACHED: 7,
  PAY_FAILED: 8,
  SIGN_AGREEMENT_FAILED: 9,
  UN_SIGN_AGREEMENT_FAILED: 11,
  ALREADY_UN_SIGN_AGREEMENT: 10,
  NOT_SUPPORT_UN_SGIN_ITUNES: 12,
  AUTO_CHARGE_FAILED: 13,
  FAIL: 14,
  NOT_SUPPORT_AUTO_CHARGE: 15
};

/**
 * @enum {number}
 */
proto.orders.PayType = {
  ALIPAY_WEB: 0,
  ALIPAY_APP: 1,
  ALIPAY_H5: 2,
  WXPAY_WEB: 3,
  WXPAY_APP: 4,
  WXPAY_H5: 5,
  WXPAY_OFF: 6,
  BALANCE_NON_ITUNES: 7,
  BALANCE_ITUNES: 8,
  ALIPAY: 9,
  WXPAY: 10,
  ITUNES_PAY: 11,
  WXPAY_VOICE_NOTE: 12,
  ALIPAY_VOICE_NOTE: 13,
  ALIPAY_WEB_RECURRING: 14,
  ALIPAY_APP_RECURRING: 15,
  ALIPAY_H5_RECURRING: 16,
  WXPAY_WEB_RECURRING: 17,
  WXPAY_APP_RECURRING: 18,
  WXPAY_H5_RECURRING: 19,
  WXPAY_APP_SCANNER: 20,
  ALIPAY_APP_SCANNER: 21,
  BALANCE_PAY_WITH_ALIPAY_WEB_SIGN: 22,
  BALANCE_PAY_WITH_ALIPAY_APP_SIGN: 23,
  BALANCE_PAY_WITH_ALIPAY_H5_SIGN: 24,
  BALANCE_PAY_WITH_WXPAY_WEB_SIGN: 25,
  BALANCE_PAY_WITH_WXAPY_APP_SIGN: 26,
  BALANCE_PAY_WITH_WXPAY_H5_SIGN: 27,
  ALIPAY_RECURRING_CXL: 28,
  WXPAY_RECURRING_CXL: 29
};

/**
 * @enum {number}
 */
proto.orders.OrderStatus = {
  INITED: 0,
  UNPAID: 1,
  FAILED: 2,
  DELETED: 3,
  EXPIRED: 4,
  PAID: 5,
  REFUND: 6
};

/**
 * @enum {number}
 */
proto.orders.OrderType = {
  BALANCE_DEPOSIT: 0,
  PURCHASE_PRODUCT: 1
};

/**
 * @enum {number}
 */
proto.orders.DiscountSource = {
  NONE: 0,
  INCENTIVE: 1
};

/**
 * @enum {number}
 */
proto.orders.ClientType = {
  UNDEFINED: 0,
  WEB: 1,
  IOS: 2,
  ANDRIOD: 3,
  MAC: 4,
  WINDOWS: 5
};

/**
 * @enum {number}
 */
proto.orders.IncentiveType = {
  SAVING: 0,
  BONUS: 1,
  TRAIL: 2
};

/**
 * @enum {number}
 */
proto.orders.Period = {
  ANNUALLY: 0,
  MONTHLY_: 1
};

/**
 * @enum {number}
 */
proto.orders.AgreementStatus = {
  AGREEMENT_STATUS_UNKNOWN: 0,
  NOT_SIGNED: 1,
  SIGNED: 2,
  SIGNING: 3,
  UN_SIGN: 4
};

goog.object.extend(exports, proto.orders);

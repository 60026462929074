/* eslint-disable no-undef */
/**
 * @fileoverview gRPC-Web generated client stub for advancedtemplate
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


// var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var common_pb = require('./common_pb.js')
const proto = {};
proto.advancedtemplate = require('./advanced-template-verse-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.advancedtemplate.AdvancedTemplateServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.advancedtemplate.AdvancedTemplateServiceClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.advancedtemplate.AdvancedTemplateServiceClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetTemplatesRequest,
 *   !proto.advancedtemplate.GetTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetTemplatesReply,
  /** @param {!proto.advancedtemplate.GetTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetTemplateInfoRequest,
 *   !proto.advancedtemplate.GetTemplateInfoReply>}
 */
const methodInfo_AdvancedTemplateService_getTemplateInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetTemplateInfoReply,
  /** @param {!proto.advancedtemplate.GetTemplateInfoRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetTemplateInfoReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetTemplateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetTemplateInfoReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetTemplateInfoReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getTemplateInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getTemplateInfo',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getTemplateInfo,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetTemplateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetTemplateInfoReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getTemplateInfo =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getTemplateInfo(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_addTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.addTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/addTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_addTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.AddTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.addTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.addTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_updateTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.DeletedTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_deletedTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.DeletedTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.DeletedTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.deletedTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/deletedTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_deletedTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.DeletedTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.deletedTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.deletedTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UploadThumbnailRequest,
 *   !proto.advancedtemplate.UploadThumbnailReply>}
 */
const methodInfo_AdvancedTemplateService_uploadThumbnail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.UploadThumbnailReply,
  /** @param {!proto.advancedtemplate.UploadThumbnailRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.UploadThumbnailReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UploadThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.UploadThumbnailReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.UploadThumbnailReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.uploadThumbnail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/uploadThumbnail',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_uploadThumbnail,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UploadThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.UploadThumbnailReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.uploadThumbnail =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.uploadThumbnail(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPublicNoteInfosRequest,
 *   !proto.advancedtemplate.GetPublicNoteInfosReply>}
 */
const methodInfo_AdvancedTemplateService_getPublicNoteInfos = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPublicNoteInfosReply,
  /** @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPublicNoteInfosReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPublicNoteInfosReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPublicNoteInfosReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPublicNoteInfos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPublicNoteInfos',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPublicNoteInfos,
      callback);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.advancedtemplate.GetBannersRequest,
 *   !proto.advancedtemplate.GetBannersReply>}
 */
const methodDescriptor_AdvancedTemplateService_getBanners = new grpc.web.MethodDescriptor(
  '/advancedtemplate.AdvancedTemplateService/getBanners',
  grpc.web.MethodType.UNARY,
  proto.advancedtemplate.GetBannersRequest,
  proto.advancedtemplate.GetBannersReply,
  /** @param {!proto.advancedtemplate.GetBannersRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetBannersReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetBannersRequest,
 *   !proto.advancedtemplate.GetBannersReply>}
 */
const methodInfo_AdvancedTemplateService_getBanners = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetBannersReply,
  /** @param {!proto.advancedtemplate.GetBannersRequest} request */
  function (request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetBannersReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetBannersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetBannersReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetBannersReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getBanners =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getBanners',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateService_getBanners,
      callback);
  };


/**
 * @param {!proto.advancedtemplate.GetBannersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetBannersReply>}
 *     A native promise that resolves to the response
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getBanners =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getBanners',
      request,
      metadata || {},
      methodDescriptor_AdvancedTemplateService_getBanners);
  };



/**
 * @param {!proto.advancedtemplate.GetPublicNoteInfosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPublicNoteInfosReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPublicNoteInfos =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPublicNoteInfos(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetSeriesRequest,
 *   !proto.advancedtemplate.GetSeriesReply>}
 */
const methodInfo_AdvancedTemplateService_getSubSeries = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetSeriesReply,
  /** @param {!proto.advancedtemplate.GetSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetSeriesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetSeriesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetSeriesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getSubSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getSubSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getSubSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetSeriesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getSubSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getSubSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetSeriesTreeRequest,
 *   !proto.advancedtemplate.GetSeriesReply>}
 */
const methodInfo_AdvancedTemplateService_getSeriesTree = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetSeriesReply,
  /** @param {!proto.advancedtemplate.GetSeriesTreeRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetSeriesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetSeriesTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetSeriesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetSeriesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getSeriesTree =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getSeriesTree',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getSeriesTree,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetSeriesTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetSeriesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getSeriesTree =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getSeriesTree(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetSeriesRelationTemplateRequest,
 *   !proto.advancedtemplate.GetSeriesRelationTemplateReply>}
 */
const methodInfo_AdvancedTemplateService_getSeriesRelationTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetSeriesRelationTemplateReply,
  /** @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetSeriesRelationTemplateReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetSeriesRelationTemplateReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetSeriesRelationTemplateReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getSeriesRelationTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getSeriesRelationTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getSeriesRelationTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetSeriesRelationTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetSeriesRelationTemplateReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getSeriesRelationTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getSeriesRelationTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_addSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.addSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/addSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_addSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.AddSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.addSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.addSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_updateSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.DeleteSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_deleteSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.DeleteSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.DeleteSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.deleteSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/deleteSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_deleteSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.DeleteSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.deleteSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.deleteSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.DeleteRelationRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_deleteRelation = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.DeleteRelationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.DeleteRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.deleteRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/deleteRelation',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_deleteRelation,
      callback);
};


/**
 * @param {!proto.advancedtemplate.DeleteRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.deleteRelation =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.deleteRelation(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddRelationRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_addRelation = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.AddRelationRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.addRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/addRelation',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_addRelation,
      callback);
};


/**
 * @param {!proto.advancedtemplate.AddRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.addRelation =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.addRelation(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateRecommendRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_updateRecommend = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateRecommendRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateRecommendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateRecommend =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateRecommend',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateRecommend,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateRecommendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateRecommend =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateRecommend(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateOrderSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_updateOrderSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateOrderSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateOrderSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateOrderSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateOrderSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateOrderSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateOrderSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateOrderTemplatesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_updateOrderTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateOrderTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateOrderTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateOrderTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateOrderTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateOrderTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateOrderTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.EmptyRequest,
 *   !proto.advancedtemplate.QuerySeriesTypeReply>}
 */
const methodInfo_AdvancedTemplateService_querySeriesType = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.QuerySeriesTypeReply,
  /** @param {!proto.advancedtemplate.EmptyRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.QuerySeriesTypeReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.QuerySeriesTypeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.QuerySeriesTypeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.querySeriesType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/querySeriesType',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_querySeriesType,
      callback);
};


/**
 * @param {!proto.advancedtemplate.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.QuerySeriesTypeReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.querySeriesType =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.querySeriesType(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPublishListRequest,
 *   !proto.advancedtemplate.GetPublishListReply>}
 */
const methodInfo_AdvancedTemplateService_getPublishList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPublishListReply,
  /** @param {!proto.advancedtemplate.GetPublishListRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPublishListReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPublishListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPublishListReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPublishListReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPublishList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPublishList',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPublishList,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPublishListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPublishListReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPublishList =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPublishList(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPublishSeriesRequest,
 *   !proto.advancedtemplate.GetPublishSeriesReply>}
 */
const methodInfo_AdvancedTemplateService_getPublishSeries = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPublishSeriesReply,
  /** @param {!proto.advancedtemplate.GetPublishSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPublishSeriesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPublishSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPublishSeriesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPublishSeriesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPublishSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPublishSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPublishSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPublishSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPublishSeriesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPublishSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPublishSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPublishTemplateRequest,
 *   !proto.advancedtemplate.GetPublishTemplateReply>}
 */
const methodInfo_AdvancedTemplateService_getPublishTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPublishTemplateReply,
  /** @param {!proto.advancedtemplate.GetPublishTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPublishTemplateReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPublishTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPublishTemplateReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPublishTemplateReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPublishTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPublishTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPublishTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPublishTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPublishTemplateReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPublishTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPublishTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetDraftTemplateRequest,
 *   !proto.advancedtemplate.GetDraftTemplateReply>}
 */
const methodInfo_AdvancedTemplateService_getDraftTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetDraftTemplateReply,
  /** @param {!proto.advancedtemplate.GetDraftTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftTemplateReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetDraftTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetDraftTemplateReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetDraftTemplateReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getDraftTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getDraftTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getDraftTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetDraftTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetDraftTemplateReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getDraftTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getDraftTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetDraftSeriesRequest,
 *   !proto.advancedtemplate.GetDraftSeriesReply>}
 */
const methodInfo_AdvancedTemplateService_getDraftSeries = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetDraftSeriesReply,
  /** @param {!proto.advancedtemplate.GetDraftSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetDraftSeriesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetDraftSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetDraftSeriesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetDraftSeriesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getDraftSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getDraftSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getDraftSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetDraftSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetDraftSeriesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getDraftSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getDraftSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.PublishTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_publishTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.PublishTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.PublishTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.publishTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/publishTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_publishTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.PublishTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.publishTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.publishTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.PublishSeriesRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_publishSeries = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.PublishSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.PublishSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.publishSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/publishSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_publishSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.PublishSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.publishSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.publishSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPublishedTemplatesRequest,
 *   !proto.advancedtemplate.GetPublishedTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getPublishedTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPublishedTemplatesReply,
  /** @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPublishedTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPublishedTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPublishedTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPublishedTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPublishedTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPublishedTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPublishedTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPublishedTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPublishedTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPublishedTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetLastVersionTemplatesRequest,
 *   !proto.advancedtemplate.GetLastVersionTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getLastVersionTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetLastVersionTemplatesReply,
  /** @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetLastVersionTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetLastVersionTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetLastVersionTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getLastVersionTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getLastVersionTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getLastVersionTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetLastVersionTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetLastVersionTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getLastVersionTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getLastVersionTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.RescindTemplateRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_rescindTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.RescindTemplateRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.RescindTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.rescindTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/rescindTemplate',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_rescindTemplate,
      callback);
};


/**
 * @param {!proto.advancedtemplate.RescindTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.rescindTemplate =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.rescindTemplate(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.SynNoteRequest,
 *   !proto.advancedtemplate.CommonReply>}
 */
const methodInfo_AdvancedTemplateService_synNote = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.CommonReply,
  /** @param {!proto.advancedtemplate.SynNoteRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.CommonReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.SynNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.CommonReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.CommonReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.synNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/synNote',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_synNote,
      callback);
};


/**
 * @param {!proto.advancedtemplate.SynNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.CommonReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.synNote =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.synNote(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetAllSeriesV2Request,
 *   !proto.advancedtemplate.GetAllSeriesV2Reply>}
 */
const methodInfo_AdvancedTemplateService_getAllSeriesV2 = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetAllSeriesV2Reply,
  /** @param {!proto.advancedtemplate.GetAllSeriesV2Request} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetAllSeriesV2Reply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetAllSeriesV2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetAllSeriesV2Reply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetAllSeriesV2Reply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getAllSeriesV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getAllSeriesV2',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getAllSeriesV2,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetAllSeriesV2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetAllSeriesV2Reply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getAllSeriesV2 =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getAllSeriesV2(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetAllSeriesRequest,
 *   !proto.advancedtemplate.GetAllSeriesReply>}
 */
const methodInfo_AdvancedTemplateService_getAllSeries = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetAllSeriesReply,
  /** @param {!proto.advancedtemplate.GetAllSeriesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetAllSeriesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetAllSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetAllSeriesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetAllSeriesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getAllSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getAllSeries',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getAllSeries,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetAllSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetAllSeriesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getAllSeries =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getAllSeries(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetIncrementalTemplatesRequest,
 *   !proto.advancedtemplate.GetIncrementalTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getIncrementalTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetIncrementalTemplatesReply,
  /** @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetIncrementalTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetIncrementalTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetIncrementalTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getIncrementalTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getIncrementalTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getIncrementalTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetIncrementalTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getIncrementalTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getIncrementalTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetIncrementalTemplatesV2Request,
 *   !proto.advancedtemplate.GetIncrementalTemplatesV2Reply>}
 */
const methodInfo_AdvancedTemplateService_getIncrementalTemplatesV2 = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetIncrementalTemplatesV2Reply,
  /** @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetIncrementalTemplatesV2Reply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetIncrementalTemplatesV2Reply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetIncrementalTemplatesV2Reply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getIncrementalTemplatesV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getIncrementalTemplatesV2',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getIncrementalTemplatesV2,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetIncrementalTemplatesV2Reply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getIncrementalTemplatesV2 =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getIncrementalTemplatesV2(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetIncrementalTemplatesV3Request,
 *   !proto.advancedtemplate.GetIncrementalTemplatesV3Reply>}
 */
const methodInfo_AdvancedTemplateService_getIncrementalTemplatesV3 = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetIncrementalTemplatesV3Reply,
  /** @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetIncrementalTemplatesV3Reply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetIncrementalTemplatesV3Reply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetIncrementalTemplatesV3Reply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getIncrementalTemplatesV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getIncrementalTemplatesV3',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getIncrementalTemplatesV3,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetIncrementalTemplatesV3Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetIncrementalTemplatesV3Reply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getIncrementalTemplatesV3 =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getIncrementalTemplatesV3(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetSigRequest,
 *   !proto.advancedtemplate.GetSigReply>}
 */
const methodInfo_AdvancedTemplateService_getSig = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetSigReply,
  /** @param {!proto.advancedtemplate.GetSigRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetSigReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetSigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetSigReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetSigReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getSig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getSig',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getSig,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetSigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetSigReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getSig =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getSig(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPurchasedTemplatesRequest,
 *   !proto.advancedtemplate.GetPurchasedTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getPurchasedTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPurchasedTemplatesReply,
  /** @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPurchasedTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPurchasedTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPurchasedTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPurchasedTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPurchasedTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPurchasedTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPurchasedTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPurchasedTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPurchasedTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPurchasedTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetPurchasedTemplateIdsRequest,
 *   !proto.advancedtemplate.GetPurchasedTemplateIdsReply>}
 */
const methodInfo_AdvancedTemplateService_getPurchasedTemplateIds = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetPurchasedTemplateIdsReply,
  /** @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetPurchasedTemplateIdsReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetPurchasedTemplateIdsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetPurchasedTemplateIdsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getPurchasedTemplateIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getPurchasedTemplateIds',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getPurchasedTemplateIds,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetPurchasedTemplateIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetPurchasedTemplateIdsReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getPurchasedTemplateIds =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getPurchasedTemplateIds(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetRecommendTemplatesRequest,
 *   !proto.advancedtemplate.GetRecommendTemplatesReply>}
 */
const methodInfo_AdvancedTemplateService_getRecommendTemplates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetRecommendTemplatesReply,
  /** @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetRecommendTemplatesReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetRecommendTemplatesReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetRecommendTemplatesReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getRecommendTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getRecommendTemplates',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getRecommendTemplates,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetRecommendTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetRecommendTemplatesReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getRecommendTemplates =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getRecommendTemplates(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.IncrementTemplateUsageCountRequest,
 *   !proto.advancedtemplate.IncrementTemplateUsageCountReply>}
 */
const methodInfo_AdvancedTemplateService_incrementTemplateUsageCount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.IncrementTemplateUsageCountReply,
  /** @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.IncrementTemplateUsageCountReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.IncrementTemplateUsageCountReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.IncrementTemplateUsageCountReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.incrementTemplateUsageCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/incrementTemplateUsageCount',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_incrementTemplateUsageCount,
      callback);
};


/**
 * @param {!proto.advancedtemplate.IncrementTemplateUsageCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.IncrementTemplateUsageCountReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.incrementTemplateUsageCount =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.incrementTemplateUsageCount(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.AddNoteRequest,
 *   !proto.advancedtemplate.AddNoteReply>}
 */
const methodInfo_AdvancedTemplateService_addNoteByGuid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.AddNoteReply,
  /** @param {!proto.advancedtemplate.AddNoteRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.AddNoteReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.AddNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.AddNoteReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.AddNoteReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.addNoteByGuid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/addNoteByGuid',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_addNoteByGuid,
      callback);
};


/**
 * @param {!proto.advancedtemplate.AddNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.AddNoteReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.addNoteByGuid =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.addNoteByGuid(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.UpdateNoteRequest,
 *   !proto.advancedtemplate.UpdateNoteReply>}
 */
const methodInfo_AdvancedTemplateService_updateNoteByGuid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.UpdateNoteReply,
  /** @param {!proto.advancedtemplate.UpdateNoteRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.UpdateNoteReply.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.UpdateNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.UpdateNoteReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.UpdateNoteReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.updateNoteByGuid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/updateNoteByGuid',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_updateNoteByGuid,
      callback);
};


/**
 * @param {!proto.advancedtemplate.UpdateNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.UpdateNoteReply>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.updateNoteByGuid =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.updateNoteByGuid(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.advancedtemplate.GetResourceDownloadMetaRequest,
 *   !proto.advancedtemplate.GetResourceDownloadMetaResponse>}
 */
const methodInfo_AdvancedTemplateService_getResourceDownloadMeta = new grpc.web.AbstractClientBase.MethodInfo(
  proto.advancedtemplate.GetResourceDownloadMetaResponse,
  /** @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.advancedtemplate.GetResourceDownloadMetaResponse.deserializeBinary
);


/**
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.advancedtemplate.GetResourceDownloadMetaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.advancedtemplate.GetResourceDownloadMetaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServiceClient.prototype.getResourceDownloadMeta =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/advancedtemplate.AdvancedTemplateService/getResourceDownloadMeta',
      request,
      metadata || {},
      methodInfo_AdvancedTemplateService_getResourceDownloadMeta,
      callback);
};


/**
 * @param {!proto.advancedtemplate.GetResourceDownloadMetaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.advancedtemplate.GetResourceDownloadMetaResponse>}
 *     The XHR Node Readable Stream
 */
proto.advancedtemplate.AdvancedTemplateServicePromiseClient.prototype.getResourceDownloadMeta =
    function(request, metadata) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.delegateClient_.getResourceDownloadMeta(
      request, metadata, function (error, response) {
        error ? reject(error) : resolve(response);
      });
  });
};


module.exports = proto.advancedtemplate;


/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.advancedtemplate.AddBannerRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.AddRecommendTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.DeleteBannerRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftBannersResponse', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftProductTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetDraftProductTemplateResponse', null, global);
goog.exportSymbol('proto.advancedtemplate.GetProductTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.GetProductTemplateResponse', null, global);
goog.exportSymbol('proto.advancedtemplate.GetRecommendTemplatesAdminReply', null, global);
goog.exportSymbol('proto.advancedtemplate.GetRecommendTemplatesAdminReplyData', null, global);
goog.exportSymbol('proto.advancedtemplate.GetRecommendTemplatesAdminRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.TopTemplateInSeriesRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateBannersRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.UpdateRecommendTemplateRequest', null, global);
goog.exportSymbol('proto.advancedtemplate.Void', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.Void = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.Void, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.Void.displayName = 'proto.advancedtemplate.Void';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddBannerRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.AddBannerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddBannerRequest.displayName = 'proto.advancedtemplate.AddBannerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateBannersRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.UpdateBannersRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.UpdateBannersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateBannersRequest.displayName = 'proto.advancedtemplate.UpdateBannersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.DeleteBannerRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.DeleteBannerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.DeleteBannerRequest.displayName = 'proto.advancedtemplate.DeleteBannerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftBannersResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetDraftBannersResponse.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetDraftBannersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftBannersResponse.displayName = 'proto.advancedtemplate.GetDraftBannersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetProductTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetProductTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetProductTemplateRequest.displayName = 'proto.advancedtemplate.GetProductTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetProductTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetProductTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetProductTemplateResponse.displayName = 'proto.advancedtemplate.GetProductTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftProductTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetDraftProductTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftProductTemplateRequest.displayName = 'proto.advancedtemplate.GetDraftProductTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetDraftProductTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetDraftProductTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetDraftProductTemplateResponse.displayName = 'proto.advancedtemplate.GetDraftProductTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.TopTemplateInSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.TopTemplateInSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.TopTemplateInSeriesRequest.displayName = 'proto.advancedtemplate.TopTemplateInSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.AddRecommendTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.AddRecommendTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.AddRecommendTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.AddRecommendTemplateRequest.displayName = 'proto.advancedtemplate.AddRecommendTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.UpdateRecommendTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.UpdateRecommendTemplateRequest.displayName = 'proto.advancedtemplate.UpdateRecommendTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetRecommendTemplatesAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetRecommendTemplatesAdminRequest.displayName = 'proto.advancedtemplate.GetRecommendTemplatesAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.advancedtemplate.GetRecommendTemplatesAdminReply.repeatedFields_, null);
};
goog.inherits(proto.advancedtemplate.GetRecommendTemplatesAdminReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetRecommendTemplatesAdminReply.displayName = 'proto.advancedtemplate.GetRecommendTemplatesAdminReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.advancedtemplate.GetRecommendTemplatesAdminReplyData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.displayName = 'proto.advancedtemplate.GetRecommendTemplatesAdminReplyData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.Void.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.Void.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.Void} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.Void.toObject = function (includeInstance, msg) {
    var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.Void}
 */
proto.advancedtemplate.Void.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.Void;
  return proto.advancedtemplate.Void.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.Void} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.Void}
 */
proto.advancedtemplate.Void.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.Void.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.Void.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.Void} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.Void.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.AddBannerRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.AddBannerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.AddBannerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.AddBannerRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      banner: (f = msg.getBanner()) && common_pb.Banner.toObject(includeInstance, f)
    };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddBannerRequest}
 */
proto.advancedtemplate.AddBannerRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddBannerRequest;
  return proto.advancedtemplate.AddBannerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddBannerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddBannerRequest}
 */
proto.advancedtemplate.AddBannerRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_pb.Banner;
        reader.readMessage(value, common_pb.Banner.deserializeBinaryFromReader);
        msg.setBanner(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddBannerRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddBannerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddBannerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddBannerRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBanner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Banner.serializeBinaryToWriter
    );
  }
};


/**
 * optional Banner banner = 1;
 * @return {?proto.advancedtemplate.Banner}
 */
proto.advancedtemplate.AddBannerRequest.prototype.getBanner = function () {
  return /** @type{?proto.advancedtemplate.Banner} */ (
    jspb.Message.getWrapperField(this, common_pb.Banner, 1));
};


/** @param {?proto.advancedtemplate.Banner|undefined} value */
proto.advancedtemplate.AddBannerRequest.prototype.setBanner = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.AddBannerRequest.prototype.clearBanner = function () {
  this.setBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.AddBannerRequest.prototype.hasBanner = function () {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.UpdateBannersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.UpdateBannersRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.UpdateBannersRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.UpdateBannersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.UpdateBannersRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      bannersList: jspb.Message.toObjectList(msg.getBannersList(),
        common_pb.Banner.toObject, includeInstance)
    };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateBannersRequest}
 */
proto.advancedtemplate.UpdateBannersRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateBannersRequest;
  return proto.advancedtemplate.UpdateBannersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateBannersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateBannersRequest}
 */
proto.advancedtemplate.UpdateBannersRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_pb.Banner;
        reader.readMessage(value, common_pb.Banner.deserializeBinaryFromReader);
        msg.addBanners(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateBannersRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateBannersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateBannersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateBannersRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBannersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.Banner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Banner banners = 1;
 * @return {!Array<!proto.advancedtemplate.Banner>}
 */
proto.advancedtemplate.UpdateBannersRequest.prototype.getBannersList = function () {
  return /** @type{!Array<!proto.advancedtemplate.Banner>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Banner, 1));
};


/** @param {!Array<!proto.advancedtemplate.Banner>} value */
proto.advancedtemplate.UpdateBannersRequest.prototype.setBannersList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.advancedtemplate.Banner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Banner}
 */
proto.advancedtemplate.UpdateBannersRequest.prototype.addBanners = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.advancedtemplate.Banner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.UpdateBannersRequest.prototype.clearBannersList = function () {
  this.setBannersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.DeleteBannerRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.DeleteBannerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.DeleteBannerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.DeleteBannerRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      bannerid: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.DeleteBannerRequest}
 */
proto.advancedtemplate.DeleteBannerRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.DeleteBannerRequest;
  return proto.advancedtemplate.DeleteBannerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.DeleteBannerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.DeleteBannerRequest}
 */
proto.advancedtemplate.DeleteBannerRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setBannerid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.DeleteBannerRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.DeleteBannerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.DeleteBannerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.DeleteBannerRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBannerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 bannerId = 1;
 * @return {number}
 */
proto.advancedtemplate.DeleteBannerRequest.prototype.getBannerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.DeleteBannerRequest.prototype.setBannerid = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetDraftBannersResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.advancedtemplate.GetDraftBannersResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.advancedtemplate.GetDraftBannersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.advancedtemplate.GetDraftBannersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.advancedtemplate.GetDraftBannersResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      code: jspb.Message.getFieldWithDefault(msg, 1, 0),
      message: jspb.Message.getFieldWithDefault(msg, 2, ""),
      bannersList: jspb.Message.toObjectList(msg.getBannersList(),
        common_pb.Banner.toObject, includeInstance)
    };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftBannersResponse}
 */
proto.advancedtemplate.GetDraftBannersResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftBannersResponse;
  return proto.advancedtemplate.GetDraftBannersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftBannersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftBannersResponse}
 */
proto.advancedtemplate.GetDraftBannersResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 3:
        var value = new common_pb.Banner;
        reader.readMessage(value, common_pb.Banner.deserializeBinaryFromReader);
        msg.addBanners(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftBannersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftBannersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftBannersResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBannersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.Banner.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.getCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetDraftBannersResponse.prototype.setCode = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetDraftBannersResponse.prototype.setMessage = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Banner banners = 3;
 * @return {!Array<!proto.advancedtemplate.Banner>}
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.getBannersList = function () {
  return /** @type{!Array<!proto.advancedtemplate.Banner>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Banner, 3));
};


/** @param {!Array<!proto.advancedtemplate.Banner>} value */
proto.advancedtemplate.GetDraftBannersResponse.prototype.setBannersList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.advancedtemplate.Banner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.Banner}
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.addBanners = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.advancedtemplate.Banner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetDraftBannersResponse.prototype.clearBannersList = function () {
  this.setBannersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetProductTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetProductTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetProductTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetProductTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productcode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetProductTemplateRequest}
 */
proto.advancedtemplate.GetProductTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetProductTemplateRequest;
  return proto.advancedtemplate.GetProductTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetProductTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetProductTemplateRequest}
 */
proto.advancedtemplate.GetProductTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetProductTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetProductTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetProductTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetProductTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string productCode = 1;
 * @return {string}
 */
proto.advancedtemplate.GetProductTemplateRequest.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetProductTemplateRequest.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetProductTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetProductTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetProductTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetProductTemplateResponse}
 */
proto.advancedtemplate.GetProductTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetProductTemplateResponse;
  return proto.advancedtemplate.GetProductTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetProductTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetProductTemplateResponse}
 */
proto.advancedtemplate.GetProductTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetProductTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetProductTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetProductTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetProductTemplateResponse.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetProductTemplateResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Template template = 3;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 3));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetProductTemplateResponse.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetProductTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftProductTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftProductTemplateRequest}
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftProductTemplateRequest;
  return proto.advancedtemplate.GetDraftProductTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftProductTemplateRequest}
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftProductTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftProductTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.GetDraftProductTemplateRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetDraftProductTemplateRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetDraftProductTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetDraftProductTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetDraftProductTemplateResponse}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetDraftProductTemplateResponse;
  return proto.advancedtemplate.GetDraftProductTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetDraftProductTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetDraftProductTemplateResponse}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetDraftProductTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetDraftProductTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Template template = 3;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 3));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetDraftProductTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.TopTemplateInSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    templateid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    top: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.TopTemplateInSeriesRequest}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.TopTemplateInSeriesRequest;
  return proto.advancedtemplate.TopTemplateInSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.TopTemplateInSeriesRequest}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeriesid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.TopTemplateInSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.TopTemplateInSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTop();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 seriesId = 1;
 * @return {number}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.getSeriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.setSeriesid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 templateId = 2;
 * @return {number}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool top = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.getTop = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.advancedtemplate.TopTemplateInSeriesRequest.prototype.setTop = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.AddRecommendTemplateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.AddRecommendTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.AddRecommendTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddRecommendTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateidList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.AddRecommendTemplateRequest}
 */
proto.advancedtemplate.AddRecommendTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.AddRecommendTemplateRequest;
  return proto.advancedtemplate.AddRecommendTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.AddRecommendTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.AddRecommendTemplateRequest}
 */
proto.advancedtemplate.AddRecommendTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTemplateidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.AddRecommendTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.AddRecommendTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.AddRecommendTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 templateId = 1;
 * @return {!Array<number>}
 */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.getTemplateidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.setTemplateidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.addTemplateid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.AddRecommendTemplateRequest.prototype.clearTemplateidList = function() {
  this.setTemplateidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.UpdateRecommendTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordernum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pb_delete: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.UpdateRecommendTemplateRequest}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.UpdateRecommendTemplateRequest;
  return proto.advancedtemplate.UpdateRecommendTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.UpdateRecommendTemplateRequest}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrdernum(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.UpdateRecommendTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.UpdateRecommendTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrdernum();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDelete();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 templateId = 1;
 * @return {number}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.getTemplateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.setTemplateid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 orderNum = 2;
 * @return {number}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.getOrdernum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.setOrdernum = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool delete = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.getDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.advancedtemplate.UpdateRecommendTemplateRequest.prototype.setDelete = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetRecommendTemplatesAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageable: (f = msg.getPageable()) && common_pb.Pageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetRecommendTemplatesAdminRequest;
  return proto.advancedtemplate.GetRecommendTemplatesAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Pageable;
      reader.readMessage(value,common_pb.Pageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetRecommendTemplatesAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Pageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pageable pageable = 1;
 * @return {?proto.advancedtemplate.Pageable}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.getPageable = function() {
  return /** @type{?proto.advancedtemplate.Pageable} */ (
    jspb.Message.getWrapperField(this, common_pb.Pageable, 1));
};


/** @param {?proto.advancedtemplate.Pageable|undefined} value */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.setPageable = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.clearPageable = function() {
  this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetRecommendTemplatesAdminReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    datasList: jspb.Message.toObjectList(msg.getDatasList(),
    proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminReply}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetRecommendTemplatesAdminReply;
  return proto.advancedtemplate.GetRecommendTemplatesAdminReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminReply}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = new proto.advancedtemplate.GetRecommendTemplatesAdminReplyData;
      reader.readMessage(value,proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.deserializeBinaryFromReader);
      msg.addDatas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetRecommendTemplatesAdminReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.setCode = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.setTotal = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated GetRecommendTemplatesAdminReplyData datas = 4;
 * @return {!Array<!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData>}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.getDatasList = function() {
  return /** @type{!Array<!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.advancedtemplate.GetRecommendTemplatesAdminReplyData, 4));
};


/** @param {!Array<!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData>} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.setDatasList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.addDatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.advancedtemplate.GetRecommendTemplatesAdminReplyData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReply.prototype.clearDatasList = function() {
  this.setDatasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.toObject = function(opt_includeInstance) {
  return proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    template: (f = msg.getTemplate()) && common_pb.Template.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.advancedtemplate.GetRecommendTemplatesAdminReplyData;
  return proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = new common_pb.Template;
      reader.readMessage(value,common_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.advancedtemplate.GetRecommendTemplatesAdminReplyData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.setOrder = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Template template = 2;
 * @return {?proto.advancedtemplate.Template}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.getTemplate = function() {
  return /** @type{?proto.advancedtemplate.Template} */ (
    jspb.Message.getWrapperField(this, common_pb.Template, 2));
};


/** @param {?proto.advancedtemplate.Template|undefined} value */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.advancedtemplate.GetRecommendTemplatesAdminReplyData.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.advancedtemplate);

import { List, Input, Modal, Form } from "antd";
import React from "react";
import InfiniteScroll from 'react-infinite-scroller';

const { TextArea } = Input;

const PublishEditor = Form.create()(

		class extends React.Component {

			render() {
				const { visible, onCancel, onCreate, form, diffList } = this.props;
				const { getFieldDecorator } = form;
				return (
						<Modal
								maskClosable={false}
								visible={visible}
								title="版本发布"
								okText="发布"
								cancelText="取消"
								onCancel={onCancel}
								onOk={onCreate}
						>
							<InfiniteScroll
									initialLoad={false}
									pageStart={0}
									useWindow={false}
									loadMore={() => {}}
							>
								<List
										dataSource={diffList}
										renderItem={(item, index) => (
												<List.Item key={index}>
													<List.Item.Meta
															// avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
															// title={<a href="https://ant.design">{item.name.last}</a>}
															description={item}
													/>
												</List.Item>
										)}
										pagination={{
											onChange: (page) => {},
											pageSize: 10,
										}}
								/>
							</InfiniteScroll>
							<Form layout="vertical">
								<Form.Item label="版本说明">
									{getFieldDecorator('description', {
										// initialValue: label,
										rules: [{ required: true, message: '请输入版本发布信息!' }],
									})(
											<div>
												<TextArea placeholder="版本发布信息" autosize />
											</div>
									)}
								</Form.Item>
							</Form>
						</Modal>
				);
			}

		}

);

export default PublishEditor;
import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Input, Button, Table,
    Select, Popconfirm, Modal, Icon, notification, message
} from 'antd';
import './layout.css';
import {
    OfficialMaterialAdminService, GetMaterialsRequest, Pageable,GetMaterialTypeRequest, MaterialType,DeletedMaterialRequest
} from "./grpcweb/OfficialMaterialAdminService";

const { Option } = Select;
const { Content } = Layout;

// rowSelection object indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
    }),
};

export default class TemplateManageContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //渲染模版查询列表
            templatesMeta: [],
            materialType: 2,
            //模版查询请求参数
            templateTitle: '',
            pageSize: 20,
            //控制跳转新增模版页面
            templatesEditorVisible: false,
            total: 0,
            current: 1,
        };
    };

    componentDidMount = () => {
         this.queryMaterialType();
         this.queryTemplateList(1)
    }


    /**
     * 请求素材类型
     */
    queryMaterialType = () => {
        let request = new GetMaterialTypeRequest();
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        OfficialMaterialAdminService.getMaterialType(request, {}, (err, response) => {
            //debugger
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== "") {
                    message.error(res.message);
                } else {
                    this.setState({ materialTypeList: res.materialtypeList });
                }
            }
        });
    };

    /**
     * 请求模版列表
     */
    queryTemplateList = (page) => {
        let request = new GetMaterialsRequest();
        request.setTitle(this.state.templateTitle);
        let materialType;
        if (this.state.materialType === 2) {
            materialType = MaterialType.WORD_FORM;
        } else if (this.state.materialType === 3) {
            materialType = MaterialType.SHARING_THEMES;
        } else if (this.state.materialType === 4) {
            materialType = MaterialType.SHARING_WATER_MARK;
        } else if (this.state.materialType === 5) {
            materialType = MaterialType.HOME_BACKGROUND;
        }
        request.setType(materialType);
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pageSize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        OfficialMaterialAdminService.getMaterials(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                // console.log("response.Templates: " + JSON.stringify(res.templatesList));
                switch (res.code) {
                    case 0:
                        res.materialsList.forEach((material) => {
                            switch (material.userlimit) {
                                case 0:
                                    material.serviceLevelName = '无';
                                    break;
                                case 1:
                                    material.serviceLevelName = '素材会员';
                                    break;
                                default: break;
                            }
                        });
                        this.setState(
                            {
                                templatesMeta: res.materialsList,
                                total: res.total,
                                current: page,
                            },
                            () => console.log("INFO:getMaterial : ", this.state.total)
                        );
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    };

    //搜索框键入文本时触发
    handelInputChange = (e) => {
        this.setState({
            templateTitle: e.target.value
        });
    };

    //点击删除模版按钮
    handleDeleteTemplate = (templateRecord) => {
        console.log('您点击了删除模版');
        let request = new DeletedMaterialRequest();
        request.setId(templateRecord.id);
        OfficialMaterialAdminService.deletedMaterialById(request, {}, (err, response) => {
            // debugger;
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.message " + err.message);
            } else {
                let res = response.toObject();
                switch (res.status) {
                    case 11011:
                        Modal.warning({ content: '当前素材挂载在系列上，不能删除' });
                        break;
                    case 11017:
                        Modal.warning({ content: '当前素材已发布，不能删除' });
                        break;
                    case 0:
                        Modal.success({ content: '删除素材成功' });
                        this.queryTemplateList(this.state.current);
                        break;
                    default: break;
                }
            }
        });
    }

    //选择素材类型后动作
    handleMaterialTypeChange = (value) => {
        let materialType = (typeof value == "undefined") ? null : value;
        this.setState({materialType: materialType});
    }

    openNotification = () => {
        notification.open({
            message: '模版删除规则及条件：',
            description:
                '1.此功能为模版永久删除操作，一旦删除将不可恢复，请慎重操作。2.删除模版将不可查看，用户端同步删除该模版。'
                + '删除条件：不能关联系列，请在系列管理中解除相关系列关系',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    onChangePage = page => {
        this.setState({current: page});
        this.queryTemplateList(page);
    };

    displayMaterialType = () => {
        if(typeof(this.state.materialTypeList) !== "undefined") {
            return this.state.materialTypeList.map(
              (item) => (<Option key={item.code} value={item.code}>{item.name}</Option>)
            )
        } else {
            return ;
        }
    }

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                {/* 面包屑 */}
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>素材管理</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={16}>
                        <Col span={2} offset={0}>
                            素材类型:
                        </Col>
                        <Col span={3} offset={0}>
                            <Select defaultValue={this.state.materialType} style={{ width: 160 }} onChange={this.handleMaterialTypeChange} allowClear>
                                {this.displayMaterialType()}
                            </Select>
                        </Col>
                        <Col span={2} offset={1}>
                            素材名称:
                        </Col>
                        <Col span={4} offset={0}>
                            <Input id='name' placeholder="模版名称" onChange={this.handelInputChange} />
                        </Col>
                         <Col span={1} offset={1}>
                                <Button type="primary" onClick={() => this.queryTemplateList(1)}>查询</Button>
                            </Col>
                            <Col span={1} offset={1}>
                                <Button type="primary" >
                                    <Link to={'/material/template/new'}>
                                        <span>新增</span>
                                    </Link>
                                </Button>
                            </Col>
                   </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                    <div>
                        <Table
                            columns={
                                [
                                    {
                                        title: '素材ID',
                                        dataIndex: 'id',
                                    },
                                    {
                                        title: '素材名称',
                                        dataIndex: 'title',
                                    },
                                    {
                                        title: '用户限制等级',
                                        dataIndex: 'serviceLevelName',
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, record) => (
                                            <span>
                                                <a style={{ marginRight: 16 }} >
                                                    <Link to={'/material/template/edit/' + record.id}>
                                                        <span>编辑</span>
                                                    </Link>
                                                </a>
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteTemplate(record)}>
                                                    <a>删除</a>
                                                </Popconfirm>
                                                <Icon type="info-circle" onClick={this.openNotification} />
                                            </span>
                                        ),
                                    },
                                ]
                            }
                            dataSource={this.state.templatesMeta}
                            pagination={{
                                pageSize: this.state.pageSize,
                                total: this.state.total,
                                onChange: this.onChangePage,
                            }}
                        />
                    </div>
                </div>
            </Content>
        );
    }
}
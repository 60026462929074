/* eslint-disable no-undef */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

// var google_api_annotations_pb = require('./google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.orders.Banner', null, global);
goog.exportSymbol('proto.orders.CheckExternalOrderReply', null, global);
goog.exportSymbol('proto.orders.CheckExternalOrderRequest', null, global);
goog.exportSymbol('proto.orders.CommonListRequestParams', null, global);
goog.exportSymbol('proto.orders.CreateOrderRequest', null, global);
goog.exportSymbol('proto.orders.CurrencyUnit', null, global);
goog.exportSymbol('proto.orders.DeleteOrderRequest', null, global);
goog.exportSymbol('proto.orders.DisplayCreateOrderRequest', null, global);
goog.exportSymbol('proto.orders.DisplayCreateOrderResult', null, global);
goog.exportSymbol('proto.orders.ITunesRefundMessage', null, global);
goog.exportSymbol('proto.orders.ListDeletedOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListExpiredOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListOrdersReply', null, global);
goog.exportSymbol('proto.orders.ListOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListPaidFailedOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListPaidOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListUncompletedOrdersRequest', null, global);
goog.exportSymbol('proto.orders.ListUnpaidOrdersRequest', null, global);
goog.exportSymbol('proto.orders.NotifyOrderStatus', null, global);
goog.exportSymbol('proto.orders.NotifyType', null, global);
goog.exportSymbol('proto.orders.NotifyVendor', null, global);
goog.exportSymbol('proto.orders.OperationResult', null, global);
goog.exportSymbol('proto.orders.OrderMessage', null, global);
goog.exportSymbol('proto.orders.OrderRefundMessage', null, global);
goog.exportSymbol('proto.orders.OrderWithPayInfo', null, global);
goog.exportSymbol('proto.orders.PlaceThirdPartyOrderRequest', null, global);
goog.exportSymbol('proto.orders.RefundOrderRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.DisplayCreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.DisplayCreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.DisplayCreateOrderRequest.displayName = 'proto.orders.DisplayCreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.DisplayCreateOrderResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.DisplayCreateOrderResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.DisplayCreateOrderResult.displayName = 'proto.orders.DisplayCreateOrderResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.Banner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.Banner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.Banner.displayName = 'proto.orders.Banner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.CreateOrderRequest.displayName = 'proto.orders.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.PlaceThirdPartyOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.PlaceThirdPartyOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.PlaceThirdPartyOrderRequest.displayName = 'proto.orders.PlaceThirdPartyOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.DeleteOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.DeleteOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.DeleteOrderRequest.displayName = 'proto.orders.DeleteOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.CommonListRequestParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.CommonListRequestParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.CommonListRequestParams.displayName = 'proto.orders.CommonListRequestParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListOrdersRequest.displayName = 'proto.orders.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListDeletedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListDeletedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListDeletedOrdersRequest.displayName = 'proto.orders.ListDeletedOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListUnpaidOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListUnpaidOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListUnpaidOrdersRequest.displayName = 'proto.orders.ListUnpaidOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListPaidFailedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListPaidFailedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListPaidFailedOrdersRequest.displayName = 'proto.orders.ListPaidFailedOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListExpiredOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListExpiredOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListExpiredOrdersRequest.displayName = 'proto.orders.ListExpiredOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListPaidOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListPaidOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListPaidOrdersRequest.displayName = 'proto.orders.ListPaidOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListUncompletedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ListUncompletedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListUncompletedOrdersRequest.displayName = 'proto.orders.ListUncompletedOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ListOrdersReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.orders.ListOrdersReply.repeatedFields_, null);
};
goog.inherits(proto.orders.ListOrdersReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ListOrdersReply.displayName = 'proto.orders.ListOrdersReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OrderWithPayInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.OrderWithPayInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OrderWithPayInfo.displayName = 'proto.orders.OrderWithPayInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OperationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.OperationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OperationResult.displayName = 'proto.orders.OperationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OrderMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.orders.OrderMessage.repeatedFields_, null);
};
goog.inherits(proto.orders.OrderMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OrderMessage.displayName = 'proto.orders.OrderMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.ITunesRefundMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.ITunesRefundMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.ITunesRefundMessage.displayName = 'proto.orders.ITunesRefundMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.OrderRefundMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.OrderRefundMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.OrderRefundMessage.displayName = 'proto.orders.OrderRefundMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.CheckExternalOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.CheckExternalOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.CheckExternalOrderRequest.displayName = 'proto.orders.CheckExternalOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.CheckExternalOrderReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.CheckExternalOrderReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.CheckExternalOrderReply.displayName = 'proto.orders.CheckExternalOrderReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.orders.RefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.orders.RefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.orders.RefundOrderRequest.displayName = 'proto.orders.RefundOrderRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.DisplayCreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.DisplayCreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.DisplayCreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DisplayCreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clienttype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    promocode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.DisplayCreateOrderRequest}
 */
proto.orders.DisplayCreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.DisplayCreateOrderRequest;
  return proto.orders.DisplayCreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.DisplayCreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.DisplayCreateOrderRequest}
 */
proto.orders.DisplayCreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 2:
      var value = /** @type {!proto.orders.ClientType} */ (reader.readEnum());
      msg.setClienttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromocode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.DisplayCreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.DisplayCreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.DisplayCreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DisplayCreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClienttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPromocode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string productCode = 1;
 * @return {string}
 */
proto.orders.DisplayCreateOrderRequest.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.DisplayCreateOrderRequest.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ClientType clientType = 2;
 * @return {!proto.orders.ClientType}
 */
proto.orders.DisplayCreateOrderRequest.prototype.getClienttype = function() {
  return /** @type {!proto.orders.ClientType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.orders.ClientType} value */
proto.orders.DisplayCreateOrderRequest.prototype.setClienttype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string promoCode = 3;
 * @return {string}
 */
proto.orders.DisplayCreateOrderRequest.prototype.getPromocode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.DisplayCreateOrderRequest.prototype.setPromocode = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.DisplayCreateOrderResult.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.DisplayCreateOrderResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.DisplayCreateOrderResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DisplayCreateOrderResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    banner: (f = msg.getBanner()) && proto.orders.Banner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.DisplayCreateOrderResult}
 */
proto.orders.DisplayCreateOrderResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.DisplayCreateOrderResult;
  return proto.orders.DisplayCreateOrderResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.DisplayCreateOrderResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.DisplayCreateOrderResult}
 */
proto.orders.DisplayCreateOrderResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.orders.Banner;
      reader.readMessage(value,proto.orders.Banner.deserializeBinaryFromReader);
      msg.setBanner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.DisplayCreateOrderResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.DisplayCreateOrderResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.DisplayCreateOrderResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DisplayCreateOrderResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBanner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.orders.Banner.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.DisplayCreateOrderResult.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.DisplayCreateOrderResult.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.DisplayCreateOrderResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.DisplayCreateOrderResult.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Banner banner = 3;
 * @return {?proto.orders.Banner}
 */
proto.orders.DisplayCreateOrderResult.prototype.getBanner = function() {
  return /** @type{?proto.orders.Banner} */ (
    jspb.Message.getWrapperField(this, proto.orders.Banner, 3));
};


/** @param {?proto.orders.Banner|undefined} value */
proto.orders.DisplayCreateOrderResult.prototype.setBanner = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.DisplayCreateOrderResult.prototype.clearBanner = function() {
  this.setBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.DisplayCreateOrderResult.prototype.hasBanner = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.Banner.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.Banner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.Banner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.Banner.toObject = function(includeInstance, msg) {
  var f, obj = {
    bannercontent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bannerbuttoncontent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bannerlink: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.Banner}
 */
proto.orders.Banner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.Banner;
  return proto.orders.Banner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.Banner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.Banner}
 */
proto.orders.Banner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannercontent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerbuttoncontent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerlink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.Banner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.Banner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.Banner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.Banner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBannercontent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBannerbuttoncontent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBannerlink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string bannerContent = 1;
 * @return {string}
 */
proto.orders.Banner.prototype.getBannercontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.Banner.prototype.setBannercontent = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bannerButtonContent = 2;
 * @return {string}
 */
proto.orders.Banner.prototype.getBannerbuttoncontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.Banner.prototype.setBannerbuttoncontent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bannerLink = 3;
 * @return {string}
 */
proto.orders.Banner.prototype.getBannerlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.Banner.prototype.setBannerlink = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    redemptioncode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    featurestype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    featuresdata: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offercode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.CreateOrderRequest}
 */
proto.orders.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.CreateOrderRequest;
  return proto.orders.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.CreateOrderRequest}
 */
proto.orders.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalordernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedemptioncode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturestype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturesdata(value);
      break;
    case 6:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffercode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalordernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRedemptioncode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFeaturestype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFeaturesdata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOffercode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string productCode = 1;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string externalOrderNumber = 2;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getExternalordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setExternalordernumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string redemptionCode = 3;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getRedemptioncode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setRedemptioncode = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string featuresType = 4;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getFeaturestype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setFeaturestype = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string featuresData = 5;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getFeaturesdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setFeaturesdata = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PayType payType = 6;
 * @return {!proto.orders.PayType}
 */
proto.orders.CreateOrderRequest.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.CreateOrderRequest.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string offerCode = 7;
 * @return {string}
 */
proto.orders.CreateOrderRequest.prototype.getOffercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.orders.CreateOrderRequest.prototype.setOffercode = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.PlaceThirdPartyOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.PlaceThirdPartyOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.PlaceThirdPartyOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.PlaceThirdPartyOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offercode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.PlaceThirdPartyOrderRequest}
 */
proto.orders.PlaceThirdPartyOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.PlaceThirdPartyOrderRequest;
  return proto.orders.PlaceThirdPartyOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.PlaceThirdPartyOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.PlaceThirdPartyOrderRequest}
 */
proto.orders.PlaceThirdPartyOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffercode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.PlaceThirdPartyOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.PlaceThirdPartyOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.PlaceThirdPartyOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.PlaceThirdPartyOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOffercode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.PlaceThirdPartyOrderRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.PlaceThirdPartyOrderRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PayType payType = 2;
 * @return {!proto.orders.PayType}
 */
proto.orders.PlaceThirdPartyOrderRequest.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.PlaceThirdPartyOrderRequest.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string offerCode = 3;
 * @return {string}
 */
proto.orders.PlaceThirdPartyOrderRequest.prototype.getOffercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.PlaceThirdPartyOrderRequest.prototype.setOffercode = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.DeleteOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.DeleteOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.DeleteOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DeleteOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.DeleteOrderRequest}
 */
proto.orders.DeleteOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.DeleteOrderRequest;
  return proto.orders.DeleteOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.DeleteOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.DeleteOrderRequest}
 */
proto.orders.DeleteOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.DeleteOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.DeleteOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.DeleteOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.DeleteOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.DeleteOrderRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.DeleteOrderRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.CommonListRequestParams.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.CommonListRequestParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.CommonListRequestParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CommonListRequestParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    starttime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paytype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    productcode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    producttype: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.CommonListRequestParams}
 */
proto.orders.CommonListRequestParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.CommonListRequestParams;
  return proto.orders.CommonListRequestParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.CommonListRequestParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.CommonListRequestParams}
 */
proto.orders.CommonListRequestParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 7:
      var value = /** @type {!proto.orders.PayType} */ (reader.readEnum());
      msg.setPaytype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.CommonListRequestParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.CommonListRequestParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.CommonListRequestParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CommonListRequestParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaytype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 pageNumber = 1;
 * @return {number}
 */
proto.orders.CommonListRequestParams.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.orders.CommonListRequestParams.prototype.setPagenumber = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageSize = 2;
 * @return {number}
 */
proto.orders.CommonListRequestParams.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.CommonListRequestParams.prototype.setPagesize = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string startTime = 3;
 * @return {string}
 */
proto.orders.CommonListRequestParams.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.orders.CommonListRequestParams.prototype.setStarttime = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string endTime = 4;
 * @return {string}
 */
proto.orders.CommonListRequestParams.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.CommonListRequestParams.prototype.setEndtime = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 userId = 5;
 * @return {number}
 */
proto.orders.CommonListRequestParams.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.orders.CommonListRequestParams.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string orderNumber = 6;
 * @return {string}
 */
proto.orders.CommonListRequestParams.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.orders.CommonListRequestParams.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PayType payType = 7;
 * @return {!proto.orders.PayType}
 */
proto.orders.CommonListRequestParams.prototype.getPaytype = function() {
  return /** @type {!proto.orders.PayType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.orders.PayType} value */
proto.orders.CommonListRequestParams.prototype.setPaytype = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string productCode = 8;
 * @return {string}
 */
proto.orders.CommonListRequestParams.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.orders.CommonListRequestParams.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 productType = 9;
 * @return {number}
 */
proto.orders.CommonListRequestParams.prototype.getProducttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.orders.CommonListRequestParams.prototype.setProducttype = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListOrdersRequest}
 */
proto.orders.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListOrdersRequest;
  return proto.orders.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListOrdersRequest}
 */
proto.orders.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListDeletedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListDeletedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListDeletedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListDeletedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListDeletedOrdersRequest}
 */
proto.orders.ListDeletedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListDeletedOrdersRequest;
  return proto.orders.ListDeletedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListDeletedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListDeletedOrdersRequest}
 */
proto.orders.ListDeletedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListDeletedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListDeletedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListDeletedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListDeletedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListDeletedOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListDeletedOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListDeletedOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListDeletedOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListUnpaidOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListUnpaidOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListUnpaidOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListUnpaidOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListUnpaidOrdersRequest}
 */
proto.orders.ListUnpaidOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListUnpaidOrdersRequest;
  return proto.orders.ListUnpaidOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListUnpaidOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListUnpaidOrdersRequest}
 */
proto.orders.ListUnpaidOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListUnpaidOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListUnpaidOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListUnpaidOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListUnpaidOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListUnpaidOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListUnpaidOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListUnpaidOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListUnpaidOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListPaidFailedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListPaidFailedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListPaidFailedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListPaidFailedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListPaidFailedOrdersRequest}
 */
proto.orders.ListPaidFailedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListPaidFailedOrdersRequest;
  return proto.orders.ListPaidFailedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListPaidFailedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListPaidFailedOrdersRequest}
 */
proto.orders.ListPaidFailedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListPaidFailedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListPaidFailedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListPaidFailedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListPaidFailedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListPaidFailedOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListPaidFailedOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListPaidFailedOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListPaidFailedOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListExpiredOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListExpiredOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListExpiredOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListExpiredOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListExpiredOrdersRequest}
 */
proto.orders.ListExpiredOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListExpiredOrdersRequest;
  return proto.orders.ListExpiredOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListExpiredOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListExpiredOrdersRequest}
 */
proto.orders.ListExpiredOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListExpiredOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListExpiredOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListExpiredOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListExpiredOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListExpiredOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListExpiredOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListExpiredOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListExpiredOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListPaidOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListPaidOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListPaidOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListPaidOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListPaidOrdersRequest}
 */
proto.orders.ListPaidOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListPaidOrdersRequest;
  return proto.orders.ListPaidOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListPaidOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListPaidOrdersRequest}
 */
proto.orders.ListPaidOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListPaidOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListPaidOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListPaidOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListPaidOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListPaidOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListPaidOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListPaidOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListPaidOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListUncompletedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListUncompletedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListUncompletedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListUncompletedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonparams: (f = msg.getCommonparams()) && proto.orders.CommonListRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListUncompletedOrdersRequest}
 */
proto.orders.ListUncompletedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListUncompletedOrdersRequest;
  return proto.orders.ListUncompletedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListUncompletedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListUncompletedOrdersRequest}
 */
proto.orders.ListUncompletedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.orders.CommonListRequestParams;
      reader.readMessage(value,proto.orders.CommonListRequestParams.deserializeBinaryFromReader);
      msg.setCommonparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListUncompletedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListUncompletedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListUncompletedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListUncompletedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.orders.CommonListRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonListRequestParams commonParams = 1;
 * @return {?proto.orders.CommonListRequestParams}
 */
proto.orders.ListUncompletedOrdersRequest.prototype.getCommonparams = function() {
  return /** @type{?proto.orders.CommonListRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.orders.CommonListRequestParams, 1));
};


/** @param {?proto.orders.CommonListRequestParams|undefined} value */
proto.orders.ListUncompletedOrdersRequest.prototype.setCommonparams = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.ListUncompletedOrdersRequest.prototype.clearCommonparams = function() {
  this.setCommonparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.ListUncompletedOrdersRequest.prototype.hasCommonparams = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.orders.ListOrdersReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ListOrdersReply.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ListOrdersReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ListOrdersReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListOrdersReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    common_pb.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ListOrdersReply}
 */
proto.orders.ListOrdersReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ListOrdersReply;
  return proto.orders.ListOrdersReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ListOrdersReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ListOrdersReply}
 */
proto.orders.ListOrdersReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Order;
      reader.readMessage(value,common_pb.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ListOrdersReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ListOrdersReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ListOrdersReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ListOrdersReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.ListOrdersReply.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.ListOrdersReply.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.ListOrdersReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.ListOrdersReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Order orders = 3;
 * @return {!Array<!proto.orders.Order>}
 */
proto.orders.ListOrdersReply.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.orders.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Order, 3));
};


/** @param {!Array<!proto.orders.Order>} value */
proto.orders.ListOrdersReply.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.orders.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.orders.Order}
 */
proto.orders.ListOrdersReply.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.orders.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.orders.ListOrdersReply.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OrderWithPayInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OrderWithPayInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OrderWithPayInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderWithPayInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: (f = msg.getOrder()) && common_pb.Order.toObject(includeInstance, f),
    payinfo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OrderWithPayInfo}
 */
proto.orders.OrderWithPayInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OrderWithPayInfo;
  return proto.orders.OrderWithPayInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OrderWithPayInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OrderWithPayInfo}
 */
proto.orders.OrderWithPayInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new common_pb.Order;
      reader.readMessage(value,common_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OrderWithPayInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OrderWithPayInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OrderWithPayInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderWithPayInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Order.serializeBinaryToWriter
    );
  }
  f = message.getPayinfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.OrderWithPayInfo.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.OrderWithPayInfo.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.OrderWithPayInfo.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.OrderWithPayInfo.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Order order = 3;
 * @return {?proto.orders.Order}
 */
proto.orders.OrderWithPayInfo.prototype.getOrder = function() {
  return /** @type{?proto.orders.Order} */ (
    jspb.Message.getWrapperField(this, common_pb.Order, 3));
};


/** @param {?proto.orders.Order|undefined} value */
proto.orders.OrderWithPayInfo.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.orders.OrderWithPayInfo.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.orders.OrderWithPayInfo.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string payInfo = 4;
 * @return {string}
 */
proto.orders.OrderWithPayInfo.prototype.getPayinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.orders.OrderWithPayInfo.prototype.setPayinfo = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OperationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OperationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OperationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OperationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OperationResult}
 */
proto.orders.OperationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OperationResult;
  return proto.orders.OperationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OperationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OperationResult}
 */
proto.orders.OperationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OperationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OperationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OperationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OperationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.OperationResult.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.OperationResult.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.orders.OperationResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.OperationResult.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool result = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.orders.OperationResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.orders.OperationResult.prototype.setResult = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.orders.OrderMessage.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OrderMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OrderMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OrderMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paytime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    productcodesList: jspb.Message.getRepeatedField(msg, 4)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OrderMessage}
 */
proto.orders.OrderMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OrderMessage;
  return proto.orders.OrderMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OrderMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OrderMessage}
 */
proto.orders.OrderMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaytime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductcodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OrderMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OrderMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OrderMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPaytime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getProductcodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.OrderMessage.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.OrderMessage.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.orders.OrderMessage.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.OrderMessage.prototype.setUserid = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 payTime = 3;
 * @return {number}
 */
proto.orders.OrderMessage.prototype.getPaytime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.orders.OrderMessage.prototype.setPaytime = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string productCodes = 4;
 * @return {!Array<string>}
 */
proto.orders.OrderMessage.prototype.getProductcodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.orders.OrderMessage.prototype.setProductcodesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.orders.OrderMessage.prototype.addProductcodes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.orders.OrderMessage.prototype.clearProductcodesList = function() {
  this.setProductcodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.ITunesRefundMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.ITunesRefundMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.ITunesRefundMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ITunesRefundMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    transid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.ITunesRefundMessage}
 */
proto.orders.ITunesRefundMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.ITunesRefundMessage;
  return proto.orders.ITunesRefundMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.ITunesRefundMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.ITunesRefundMessage}
 */
proto.orders.ITunesRefundMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.ITunesRefundMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.ITunesRefundMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.ITunesRefundMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.ITunesRefundMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transId = 1;
 * @return {string}
 */
proto.orders.ITunesRefundMessage.prototype.getTransid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.ITunesRefundMessage.prototype.setTransid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string productCode = 2;
 * @return {string}
 */
proto.orders.ITunesRefundMessage.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.ITunesRefundMessage.prototype.setProductcode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.OrderRefundMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.OrderRefundMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.OrderRefundMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderRefundMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.OrderRefundMessage}
 */
proto.orders.OrderRefundMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.OrderRefundMessage;
  return proto.orders.OrderRefundMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.OrderRefundMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.OrderRefundMessage}
 */
proto.orders.OrderRefundMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.OrderRefundMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.OrderRefundMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.OrderRefundMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.OrderRefundMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.OrderRefundMessage.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.OrderRefundMessage.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.CheckExternalOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.CheckExternalOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.CheckExternalOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CheckExternalOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalordernumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.CheckExternalOrderRequest}
 */
proto.orders.CheckExternalOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.CheckExternalOrderRequest;
  return proto.orders.CheckExternalOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.CheckExternalOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.CheckExternalOrderRequest}
 */
proto.orders.CheckExternalOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalordernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.CheckExternalOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.CheckExternalOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.CheckExternalOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CheckExternalOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalordernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string externalOrderNumber = 1;
 * @return {string}
 */
proto.orders.CheckExternalOrderRequest.prototype.getExternalordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.CheckExternalOrderRequest.prototype.setExternalordernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.CheckExternalOrderReply.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.CheckExternalOrderReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.CheckExternalOrderReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CheckExternalOrderReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.CheckExternalOrderReply}
 */
proto.orders.CheckExternalOrderReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.CheckExternalOrderReply;
  return proto.orders.CheckExternalOrderReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.CheckExternalOrderReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.CheckExternalOrderReply}
 */
proto.orders.CheckExternalOrderReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.orders.ReplyCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.CheckExternalOrderReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.CheckExternalOrderReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.CheckExternalOrderReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.CheckExternalOrderReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReplyCode code = 1;
 * @return {!proto.orders.ReplyCode}
 */
proto.orders.CheckExternalOrderReply.prototype.getCode = function() {
  return /** @type {!proto.orders.ReplyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.orders.ReplyCode} value */
proto.orders.CheckExternalOrderReply.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string orderNumber = 2;
 * @return {string}
 */
proto.orders.CheckExternalOrderReply.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.orders.CheckExternalOrderReply.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.orders.RefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.orders.RefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.orders.RefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordernumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refundfee: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.orders.RefundOrderRequest}
 */
proto.orders.RefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.orders.RefundOrderRequest;
  return proto.orders.RefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.orders.RefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.orders.RefundOrderRequest}
 */
proto.orders.RefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundfee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.orders.RefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.orders.RefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.orders.RefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.orders.RefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefundfee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string orderNumber = 1;
 * @return {string}
 */
proto.orders.RefundOrderRequest.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.orders.RefundOrderRequest.prototype.setOrdernumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 refundFee = 2;
 * @return {number}
 */
proto.orders.RefundOrderRequest.prototype.getRefundfee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.orders.RefundOrderRequest.prototype.setRefundfee = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.orders.NotifyOrderStatus = {
  ORDER_SUCCESS: 0,
  ORDER_FAILED: 1
};

/**
 * @enum {number}
 */
proto.orders.NotifyType = {
  SYNC: 0,
  SIGN: 1,
  UNSIGN: 2
};

/**
 * @enum {number}
 */
proto.orders.NotifyVendor = {
  VENDOR_ALIPAY: 0,
  VENDOR_WXPAY: 1,
  VENDOR_ITUNES: 2
};

/**
 * @enum {number}
 */
proto.orders.CurrencyUnit = {
  CNY: 0,
  EVERCOIN: 1
};

goog.object.extend(exports, proto.orders);

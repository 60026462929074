import React from "react";
import {
    Layout, Breadcrumb, Row, Col, Divider, Input, Button, message
} from 'antd';
import './layout.css';
import { AddNoteRequest, UpdateNoteRequest, AdvancedTemplateService }
    from "./grpcweb/AdvancedTemplateService";

const { Content } = Layout;

export default class NoteOperationContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            noteid: '',
            disableAddBtn: false,
            disableUpdateBtn: false,
        };
    };

    /**
     * 添加笔记
     */
    addNote = () => {
        this.setState({ disableAddBtn: true }, () => {
            let request = new AddNoteRequest();
            request.setGuid(this.state.noteid);
            AdvancedTemplateService.addNoteByGuid(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    let res = response.toObject();
                    if (res.code !== 200) {
                        message.error(res.message);
                    } else {
                        message.success('添加笔记成功');
                    }
                }
                this.setState({ disableAddBtn: false });
            });
        });
    }

    /**
     * 更新笔记
     */
    updateNote = () => {
        this.setState({ disableUpdateBtn: true }, () => {
            let request = new UpdateNoteRequest();
            request.setGuid(this.state.noteid);
            AdvancedTemplateService.updateNoteByGuid(request, {}, (err, response) => {
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    let res = response.toObject();
                    if (res.code !== 200) {
                        message.error(res.message);
                    } else {
                        message.success('更新笔记成功，并已将未发布模版中的笔记更新为最新版本');
                    }
                }
                this.setState({ disableUpdateBtn: false });
            });
        });
    }

    handelNoteidInputChange = (e) => {
        this.setState({
            noteid: e.target.value
        });
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>笔记操作</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={16}>
                        <Col span={2} offset={2}>
                            Note ID:
                        </Col>
                        <Col span={6} offset={0}>
                            <Input id='noteid' onChange={this.handelNoteidInputChange} />
                        </Col>
                        <Col span={1} offset={2}>
                            <Button type="primary" disabled={this.state.disableAddBtn} onClick={this.addNote}>添加</Button>
                        </Col>
                        <Col span={1} offset={2}>
                            <Button type="primary" disabled={this.state.disableUpdateBtn} onClick={this.updateNote}>更新</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                </div>
            </Content>
        );
    }
}
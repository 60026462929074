import React from 'react';

import {
    Layout, Breadcrumb, Row, Col, Divider, Input, Button, Table,message,Popconfirm,Modal
} from 'antd';

import {
    GetListOrdersRequest,WalletAdminService,GetBalanceByUserRequest
} from "./grpcweb/walletAdminService";

import {
    OrdersService,RefundOrderRequest
} from "./grpcweb/ordersService";

import {
    EVN
} from "./grpcweb/evn.js";

const { Content } = Layout;

class WalletInfoContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           userId: 0,
           orderList: [],
           pageSize: 20,
           balance: [],
           total: 0,
           isTest: false,
           orderNumber: "",
        };
    };

    componentDidMount() {
        var isTest = EVN.startsWith("https://stage");
        console.log("isTest : " + isTest);
        this.setState(
            {
                isTest: isTest
            }
        );
    }

    //搜索框键入文本时触发
    handelInputChange = (e) => {
        this.setState({
            userId: e.target.value
        });
    };

    //查询条件，订单号输入框
    handelOrderNumberChange = (e) => {
        this.setState({
            orderNumber: e.target.value
        });
    };

    queryUserWallet = (page) => {

        this.queryBalance();
        this.queryOrderList(page);
        this.queryOrderState();
    }

    /**
     * 查询账户信息
     */
    queryBalance = () => {
     
        let request = new GetBalanceByUserRequest();
        request.setUserid(this.state.userId);
        WalletAdminService.getBalanceByUser(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                switch (res.code) {
                    case 1:
                        let balance = new Array(res.balanceinfo);
                        this.setState(
                            {
                                balance: balance,
                            }
                        );
                        break;
                    case 3:
                        message.error("权限不足");
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    }

    /**
     * 请求用户订单
     */
    queryOrderList = (page) => {
        let request = new GetListOrdersRequest();
        request.setUserid(this.state.userId);
        request.setPagenumber(page);
        request.setPagesize(this.state.pageSize);
        request.setOrdernumber(this.state.orderNumber);
        WalletAdminService.getListOrders(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                switch (res.code) {
                    case 1:
                        res.ordersList.forEach((order) => {

                            //时间格式化
                            let createdtime = new Date(order.createdtime).toLocaleString();
                            order.createdtime = createdtime;

                            let modifiedtime = new Date(order.modifiedtime).toLocaleString();
                            order.modifiedtime = modifiedtime;

                            let productNameList;
                            order.orderdetailsList.forEach((detail) => {
                                productNameList =  (typeof productNameList == "undefined") ? detail.productname :  detail.productname + "," + productNameList;
                            })
                            order.productNameList = productNameList;
                            switch (order.ordertype) {
                                case 0:
                                    order.orderTypeName = '充值';
                                    break;
                                case 1:
                                    order.orderTypeName = '购买';
                                    break;
                                default: break;
                            }
                            switch (order.status) {
                                case 0:
                                    order.statusName = '初始';
                                    break;
                                case 1:
                                    order.statusName = '未支付';
                                    break;
                                case 2:
                                    order.statusName = '付款失败';
                                    break;
                                case 3:
                                    order.statusName = '删除';
                                    break;
                                case 4:
                                    order.statusName = '过期';
                                    break;
                                case 5:
                                    order.statusName = '付款完成';
                                    break;
                                case 6:
                                    order.statusName = '退款';
                                    break;
                                default: break;
                            }
                            switch (order.paytype) {
                                case 0:
                                    order.payTypeName = '支付宝web';
                                    order.refundDisable = true;
                                    break;
                                case 1:
                                    order.payTypeName = '支付宝app（android）';
                                    order.refundDisable = true;
                                    break;
                                case 2:
                                    order.payTypeName = '支付宝H5';
                                    order.refundDisable = true;
                                    break;
                                case 3:
                                    order.payTypeName = '微信web';
                                    order.refundDisable = true;
                                    break;
                                case 4:
                                    order.payTypeName = '微信app（android）';
                                    order.refundDisable = true;
                                    break;
                                case 5:
                                    order.payTypeName = '微信H5';
                                    order.refundDisable = true;
                                    break;
                                case 6:
                                    order.payTypeName = '微信公号';
                                    order.refundDisable = true;
                                    break;
                                case 7:
                                    order.payTypeName = '非iTunes余额';
                                    order.refundDisable = false;
                                    break;
                                case 8:
                                    order.payTypeName = 'iTunes余额';
                                    order.refundDisable = false;
                                    break;
                                case 9:
                                    order.payTypeName = '支付宝（总类型）';
                                    order.refundDisable = false;
                                    break;
                                case 10:
                                    order.payTypeName = '微信支付（总类型）';
                                    order.refundDisable = false;
                                    break;
                                case 11:
                                    order.payTypeName = 'iTunes支付';
                                    order.refundDisable = false;
                                    break;
                                case 12:
                                    order.payTypeName = '印象语音微信支付';
                                    order.refundDisable = true;
                                    break;
                                case 13:
                                    order.payTypeName = '印象语音支付宝支付';
                                    order.refundDisable = true;
                                    break;
                                case 14:
                                    order.payTypeName = '支付宝web自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 15:
                                    order.payTypeName = '支付宝app（android）自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 16:
                                    order.payTypeName = '支付宝H5自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 17:
                                    order.payTypeName = '微信web自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 18:
                                    order.payTypeName = '微信app（android）自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 19:
                                    order.payTypeName = '微信H5自动续订';
                                    order.refundDisable = true;
                                    break;
                                case 20:
                                    order.payTypeName = '微信app（android）扫描宝';
                                    order.refundDisable = true;
                                    break;
                                case 21:
                                    order.payTypeName = '支付宝app（android）扫描宝';
                                    order.refundDisable = true;
                                    break;
                                case 22:
                                case 23:
                                case 24:
                                    order.payTypeName = '余额 + Alipay 0元签约';
                                    order.refundDisable = false;
                                    break;
                                case 25:
                                case 26:
                                case 27:
                                    order.payTypeName = '余额 + Alipay 0元签约';
                                    order.refundDisable = false;
                                    break;
                                case 28:
                                    order.payTypeName = '支付宝取消订阅';
                                    order.refundDisable = true;
                                    break;
                                case 29:
                                    order.payTypeName = '微信取消订阅';
                                    order.refundDisable = true;
                                    break;
                                default: break;
                            }
                        });
                        this.setState(
                            {
                                orderList: res.ordersList,
                                total: res.totalelementsnumber
                            }
                        );
                        break;
                    case 3:
                        message.error("权限不足");
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    };

    queryOrderState = () => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", "https://app.yinxiang.com/third/wallet/orders/v1/" + this.state.orderNumber);
      xhr.withCredentials = true;
      xhr.send();
      xhr.responseType = "json";
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          const data = xhr.response;
          console.log(data);
        } else {
          console.log(`Error: ${xhr.status}`);
        }
      };
    };

    handleRefund = (record) => {

        let request = new RefundOrderRequest();
        request.setOrdernumber(record.ordernumber);
        OrdersService.refundOrder(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                switch (res.code) {
                    case 1:
                        if(res.result === true){
                            Modal.success({ content: '退款成功'});
                            this.queryUserWallet(0);
                        }
                        else{
                            Modal.warning({ content: '退款失败，原因: ' + res.message });
                        }
                        break;
                    default:
                        message.error(res.message);
                        break;
                }
            }
        });
    }

    onChangePage = page => {
        this.queryOrderList(page);
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                 {/* 面包屑 */}
                 <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>用户钱包信息</Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <Row gutter={16}>
                        <Col span={2} offset={1}>用户ID：</Col>
                        <Col span={4} offset={0}>
                            <Input id='name' placeholder="用户ID" onChange={this.handelInputChange} />
                        </Col>
                        <Col span={2} offset={1}>订单号：</Col>
                        <Col span={6} offset={0}>
                            <Input id='orderNumber' placeholder="订单号：" onChange={this.handelOrderNumberChange} />
                        </Col>
                        <Col span={1} offset={1}>
                                <Button type="primary" onClick={() => this.queryUserWallet(0)}>查询</Button>
                        </Col>
                    </Row>

                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                    <div>
                        <Table
                            columns={
                                [
                                    {
                                        title: 'ITunes余额',
                                        dataIndex: 'itunesbalancefordisplay',
                                    },
                                    {
                                        title: '非ITunes余额',
                                        dataIndex: 'nonitunesbalancefordisplay',
                                    },
                                    {
                                        title: '总余额',
                                        dataIndex: 'totalbalancefordisplay',
                                    },
                                    {
                                        title: '可提现金额',
                                        dataIndex: '',
                                    },
                                ]
                            }
                            dataSource={this.state.balance}
                        />
                    </div>

                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                    <div>
                        <Table
                            columns={
                                [
                                    {
                                        title: '创建时间',
                                        dataIndex: 'createdtime',
                                    },
                                    {
                                        title: '完成时间',
                                        dataIndex: 'modifiedtime',
                                    },
                                    {
                                        title: '交易类型',
                                        dataIndex: 'orderTypeName',
                                    },
                                    {
                                        title: '订单号',
                                        dataIndex: 'ordernumber',
                                    },
                                    {
                                        title: '商品名称',
                                        dataIndex: 'productNameList',
                                    },
                                    {
                                        title: '订单状态',
                                        dataIndex: 'statusName',
                                    },
                                    {
                                        title: '支付金额',
                                        dataIndex: 'chargedprice',
                                    },
                                    {
                                        title: '支付渠道',
                                        dataIndex: 'payTypeName',
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, record) => (
                                            <span>
                                                 {this.state.isTest === true && record.status === 5 && record.refundDisable === true && (
                                                <Popconfirm title="确定退款?" onConfirm={() => this.handleRefund(record)}>
                                                    <a>退款</a>
                                                </Popconfirm>
                                                  )}
                                            </span>
                                        ),
                                    },
                                ]
                            }
                            dataSource={this.state.orderList}
                            pagination={{
                                pageSize: this.state.pageSize,
                                total: this.state.total,
                                onChange: this.onChangePage,
                            }}
                        />
                    </div>

                </div>
            </Content>
        );
    }
}

export default WalletInfoContent;

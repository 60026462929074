import React from 'react';
import { Layout, Menu, message } from 'antd';
import { Redirect, Switch, Link, Route } from 'react-router-dom';
import './layout.css';
import TemplateManageContent from "./TemplateManageContent";
import SeriesManageContent from "./SeriesContent";
import TemplateEditor from "./TemplateEditor";
import TemplateRelationEditor from "./TemplateRelationEditor";
import TemplateCreator from "./TemplateCreator";
import PublishTemplatesContent from "./PublishTemplatesContent";
import PublishSeriesContent from "./PublishSeriesContent";

const { Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Material extends React.Component {

    state = {
        collapsed: false,
        content: null,
        templateMeta: {
            templates: [],
        },
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={240} style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                >
                    <div className="ant-layout-logo">
                        <div className="logo-image"></div>
                        <div className="logo-title">素材会员管理</div>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        <SubMenu title={<span>素材管理</span>}>
                            <Menu.Item key="template">
                                <Link to={'/material/template/list'}>
                                    <span>素材编辑</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu title={<span>素材系列管理</span>}>
                            <Menu.Item key="series">
                                <Link to={{ pathname:'/material/series/list'}}>
                                    <span>系列管理</span>
                                </Link>
                            </Menu.Item>
                         </SubMenu>
                        <SubMenu title={<span>发布管理</span>}>
                            <Menu.Item key="publishTemplate">
                                <Link to={'/material/publish/template'}>
                                    <span>发布素材</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="publishSeries">
                                 <Link to={{ pathname:'/material/publish/series'}}>
                                    <span>发布系列</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: 240 }}>
                    <div className="ant-layout-content">
                        <Switch>
                            <Route path={"/material/template/list"} component={TemplateManageContent} />
                            <Route path={"/material/template/new"} component={TemplateCreator} />
                            <Route path={"/material/template/edit/:id"} component={TemplateEditor} />
                            <Route path={"/material/series/list"} component={SeriesManageContent} />
                            <Route path={"/material/series/bound-template"} component={TemplateRelationEditor} />
                            <Route path={"/material/series/:seriesId"} component={SeriesManageContent} />
                            <Route path={"/material/publish/template"} component={PublishTemplatesContent} />
                            <Route path={"/material/publish/series"} component={PublishSeriesContent} />
                            <Redirect to={"/material/template/list"} />
                        </Switch>
                    </div>
                    <Footer style={{ textAlign: 'center' }}>印象笔记 ©2021 </Footer>
                </Layout>
            </Layout>
        );
    }
}

export default Material;
import {
    EVN
} from "./evn.js";

export const {
    RefundOrderRequest
} = require('./orders_pb.js');


// const ordersServiceHost = "https://10.112.41.137:65000";
const ordersServiceHost = EVN + "/third/wallet/admin/grpcweb";
// const ordersServiceHost = "https://app.yinxiang.com/third/wallet/admin/grpcweb";

const { OrdersServiceClient } = require('./orders_grpc_web_pb.js');

export const OrdersService = new OrdersServiceClient(
    ordersServiceHost,
    null,
    { withCredentials: true }
);

import React from "react";
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Divider, Button, Table,
    Popconfirm, message, Modal, Input, Checkbox, InputNumber
} from 'antd';
import './layout.css';
import {
    AdvancedTemplateService, GetSeriesRelationTemplateRequest, DeleteRelationRequest,
    Template, SeriesRelationTemplate, Pageable, AddRelationRequest,
    UpdateOrderTemplatesRequest, TemplateOrder, GetPublishedTemplatesRequest
} from "./grpcweb/AdvancedTemplateService";

const { Content } = Layout;

export default class TemplateRelationEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentText: '',
            relationList: [],
            seriesid: '',
            seriesidName: '',
            //添加模版模态框
            addTemplateModalShow: false,
            templateTitle: '',
            templatesList: [],
            selectedTableTemplates: [],
            selectedRowKeys: [],
            total: 0,
            current: 1,
            pagesize: 10,
            disableUpdateOrderBtn: false,
            parent: 0,
            terminalType: 0
        };
    };

    showAddTemplateModal = () => {
        this.setState(
            {
                addTemplateModalShow: true,
            }
        );
        this.getTemplates(1);
    }

    handleAddTemplateOk = () => {

        if (this.state.selectedTableTemplates.length === 0) {
            message.error('请选择要添加的模版');
            return;
        }

        this.setState(
            {
                addTemplateModalShow: false,
                templatesList: [],
            }
        );
        this.addRelation();

    };

    handleAddTemplateCancel = () => {
        this.setState(
            {
                addTemplateModalShow: false,
                templatesList: [],
            }
        );
    };

    handelTemplateTitleChange = (e) => {
        this.setState({
            templateTitle: e.target.value
        });
    };

    getTemplates = (page) => {
        let request = new GetPublishedTemplatesRequest();
        let template = new Template();
        template.setTitle(this.state.templateTitle);
        template.addPlatform(this.props.location.state.platform);
        template.setTerminaltype(this.state.terminalType);
        request.setTemplate(template);
        let pageable = new Pageable();
        pageable.setPagesize(this.state.pagesize);
        pageable.setCurrent(page);
        request.setPageable(pageable);
        console.log('getPublishedTemplates request', JSON.stringify(request));
        AdvancedTemplateService.getPublishedTemplates(request, {}, (err, response) => {
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                const res = response.toObject();
                console.log("response: ");
                console.log(res);
                console.log("response.Templates: " + JSON.stringify(res.templatesList));
                res.templatesList.map((template) => {
                    this.getTemplateEnumName(template);
                });
                // debugger;
                console.log("INFO:total : ", res.total)
                const selectedRowKeys = [];
                this.setState(
                    {
                        templatesList: res.templatesList,
                        total: res.total,
                        current: page,
                        selectedRowKeys
                    },
                    () => console.log("INFO:getTemplates : ", this.state.templatesList)
                );
            }
        });
    };

    // 请求模版列表
    getSeriesRelationTemplate = (seriesid) => {

        let request = new GetSeriesRelationTemplateRequest();
        request.setSeriesid(seriesid);

        AdvancedTemplateService.getSeriesRelationTemplate(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                console.log("response.getRelation: " + JSON.stringify(res.relationList));
                res.relationList.map((relation) => {
                    this.getTemplateEnumName(relation.template);
                });

                this.setState(
                    {
                        relationList: res.relationList,
                        seriesid: seriesid
                    },
                    () => console.log("INFO:getRelation : ", this.state.relationList)
                );
            }
        });
    };

    getTemplateEnumName = (template) => {
        switch (template.type) {
            case 1:
                template.typeName = '空间模版';
                break;
            case 2:
                template.typeName = '笔记本模版';
                break;
            case 3:
                template.typeName = '笔记模版';
                break;
            default: break;
        }
        switch (template.servicelevel) {
            case 1:
                template.servicelevelName = '免费账户';
                break;
            case 2:
                template.servicelevelName = '标准账户';
                break;
            case 3:
                template.servicelevelName = '高级账户';
                break;
            case 5:
                template.servicelevelName = '专业账户';
                break;
            default: break;
        }
    }

    //删除关系成功
    delelteRelation = (record) => {

        if (record.template.templateid === '') {
            message.error('请选择要删除模版');
            return;
        }

        console.log("INFO:delelteSeries : ", record);
        let request = new DeleteRelationRequest();
        let template = new Template();
        template.setTemplateid(record.template.templateid);

        let relation = new SeriesRelationTemplate();
        relation.setSeriesid(record.seriesid);
        relation.setTemplate(template);
        relation.setRelationorder(record.relationorder);

        request.setRelation(relation);
        AdvancedTemplateService.deleteRelation(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                }
                else {
                    message.success('删除成功');
                    this.getSeriesRelationTemplate(this.state.seriesid);
                }
            }
        });
    };

    addRelation = () => {
        // debugger;
        console.log("INFO: : addRelation");

        let request = new AddRelationRequest();
        this.state.selectedTableTemplates.map((selectedTableTemplate) => {
            request.addTemplateid(selectedTableTemplate.templateid);
        });

        request.setSeriesid(this.state.seriesid);

        AdvancedTemplateService.addRelation(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.code !== 200) {
                    message.error(res.message);
                }
                else {
                    message.success('添加成功');
                    this.getSeriesRelationTemplate(this.state.seriesid);
                }
            }
        });
    };

    updateOrder = () => {
        console.log('排序');
        let arr = [];
        this.state.relationList.map((order) => {
            arr.push(order.relationorder);
        });
        console.log(arr);
        var arr2 = arr.filter((x, index, self) => self.indexOf(x) === index)
        console.log(arr.length);
        console.log(arr2.length);
        if (arr.length !== arr2.length) {
            message.error('存在重复序号，请检查');
        }
        else {
            this.setState({ disableUpdateOrderBtn: true }, () => {
                let templateOrderList = this.state.relationList;
                let request = new UpdateOrderTemplatesRequest();
                templateOrderList.map((templateOrder) => {

                    let order = new TemplateOrder();
                    order.setTemplateid(templateOrder.template.templateid);
                    order.setOrder(templateOrder.relationorder);
                    request.addTemplateorder(order);
                });
                request.setSeriesid(this.state.seriesid)

                AdvancedTemplateService.updateOrderTemplate(request, {}, (err, response) => {
                    const res = response.toObject();
                    console.log("response: ");
                    console.log(res);
                    if (err) {
                        console.log("err.code " + err.code);
                        message.error(err.message);
                    } else {
                        if (res.code !== 200) {
                            message.error(res.message);
                        }
                        else {
                            message.success('保存成功');
                            this.getSeriesRelationTemplate(this.state.seriesid);
                        }
                    }
                    this.setState({ disableUpdateOrderBtn: false });
                });
            });
        }
    }

    componentDidMount() {
        var data = this.props.location.state;
        console.log('this.props.location.state', JSON.stringify(data));
        if (data !== undefined && data.seriesid !== undefined) {
            this.setState(
                {
                    parent: data.parent,

                });
            this.getSeriesRelationTemplate(data.seriesid);
        }
        if (data !== undefined && data.terminalType !== undefined) {
            this.setState(
                {
                    terminalType: data.terminalType,
                });
        }
    };

    onChange = (value, record) => {
        console.log('changed : ', value);
        console.log('changed', record.template.templateid);
        let orderList = this.state.relationList;
        orderList.map((order) => {
            if (order.template.templateid === record.template.templateid) {
                order.relationorder = value;
            }
        });
        this.setState(
            {
                relationList: orderList,

            });
        console.log('changed', this.state.seriesid);
    };

    onChangePage = page => {
        this.getTemplates(page);
        const selectedRowKeys = [];
        this.setState({ selectedRowKeys });
    };

    //点击返回按钮
    handSeriesContent = () => {
        console.log('您点击了返回');
        this.props.onSeriesContent(this.state.parent);
    };


    render() {
        console.log('render this.state.templatesList', this.state.templatesList);
        const { selectedRowKeys } = this.state;
        // 添加笔记-笔记列表项被选中触发
        const templatesRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // debugger;
                this.setState({
                    selectedTableTemplates: selectedRows,
                    selectedRowKeys
                });
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            selectedRowKeys
        };
        let seriesName = this.props.location.state ? this.props.location.state.name : '';
        let terminalType = this.props.location.state.terminalType === 0 ? 'pc' : 'md'
        let platform = this.props.location.state.platform === 1 ? 'yxbj' :
            this.props.location.state.platform === 2 ? 'yxtj' : 'yxbj';
        console.log('返回linkto ', '/advanced-template-et/series/'
            + platform + '-' + terminalType + '/' + this.state.parent);
        return (
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>系列管理</Breadcrumb.Item>
                    <Breadcrumb.Item>系列与模版</Breadcrumb.Item>
                    <Breadcrumb.Item>{seriesName}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Row gutter={16}>
                        <Col span={1} offset={1}>
                            <Button type="primary" onClick={this.showAddTemplateModal}>新增模版</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" disabled={this.state.disableUpdateOrderBtn}
                                    onClick={this.updateOrder}>保存排序</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary">
                                <Link
                                    to={'/advanced-template-et/series/'
                                      + platform + '-' + terminalType + '/' + this.state.parent}>
                                    <span>返回</span>
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                    <Table
                        columns={
                            [
                                {
                                    title: '顺序',
                                    dataIndex: 'relationorder',
                                    render: (text, record) => {
                                        return (
                                            <InputNumber type="text" min={1} value={record.relationorder.toString()}
                                                         onChange={(event) => this.onChange(event, record)}/>
                                        );
                                    }
                                },
                                {
                                    title: '模版id',
                                    dataIndex: 'template.templateid',
                                },
                                {
                                    title: '模版名称',
                                    dataIndex: 'template.title',
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'template.typeName',
                                },
                                {
                                    title: '用户限制等级',
                                    dataIndex: 'template.servicelevelName',
                                },
                                {
                                    title: '操作',
                                    key: 'action',
                                    render: (text, record) => {
                                        return (
                                            <div>
                                                <span>
                                                    <Popconfirm title="确定从该系列中移除此模版？" title="确定移除模版？"
                                                                onConfirm={() => this.delelteRelation(record)}
                                                                okText="确定" cancelText="取消">
                                                        <a style={{marginRight: 16}}>移除</a>
                                                    </Popconfirm>
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                            ]
                        }
                        dataSource={this.state.relationList}/>
                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>

                    <div>
                        <Modal title="新增模版" visible={this.state.addTemplateModalShow} onOk={this.handleAddTemplateOk}
                               onCancel={this.handleAddTemplateCancel} width={660} okText="新增"
                               cancelText="返回">
                            <Row gutter={16}>
                                <Col span={4} offset={2}> 模版名称</Col>
                                <Col span={10}>
                                    <Input type="text" value={this.state.templateTitle}
                                           onChange={this.handelTemplateTitleChange}/>
                                </Col>
                                <Col span={2} offset={1}>
                                    <Button type="primary" style={{width: 100}} onClick={() => this.getTemplates(1)}>
                                        查询
                                    </Button>
                                </Col>
                            </Row>
                            <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}/>
                            <div>
                                <Table
                                       rowSelection={{
                                           type: Checkbox,
                                           ...templatesRowSelection,
                                       }}
                                       // rowKey={record => record.templateid}
                                       columns={
                                           [
                                               {
                                                   title: '模版id',
                                                   dataIndex: 'templateid',
                                               },
                                               {
                                                   title: '模版名称',
                                                   align: 'center',
                                                   dataIndex: 'title',
                                               },
                                               {
                                                   title: '类型',
                                                   dataIndex: 'typeName',
                                               },
                                               {
                                                   title: '用户限制等级',
                                                   dataIndex: 'servicelevelName',
                                               }
                                           ]
                                       }
                                       dataSource={this.state.templatesList}
                                       pagination={{
                                           pageSize: this.state.pagesize,
                                           total: this.state.total,
                                           onChange: this.onChangePage,
                                       }}
                                />
                            </div>
                        </Modal>
                    </div>
                </div>
            </Content>
        );
    }

}
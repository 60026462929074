import {EVN} from "../../wallet-admin/grpcweb/evn";

export const {
    GetTemplatesRequest, GetTemplateInfoRequest, AddTemplateRequest,
    UpdateTemplateRequest, DeletedTemplateRequest, UploadThumbnailRequest,
    GetPublicNoteInfosRequest, GetSeriesRequest, GetSeriesTreeRequest,
    GetSeriesRelationTemplateRequest, AddSeriesRequest, UpdateSeriesRequest,
    DeleteSeriesRequest, UpdateSeriesRelationRequest, PublishTemplateRequest,
    SynNoteRequest, GetPublishListRequest, GetPublishTemplateRequest,
    GetDraftTemplateRequest, DeleteRelationRequest, AddRelationRequest,
    GetPublishSeriesRequest, GetDraftSeriesRequest, PublishSeriesRequest,
    GetSeriesReply, GetTemplateInfoReply, AddNoteRequest, UpdateNoteRequest,
    EmptyRequest, SeriesRelationTemplate,

    Pageable, UpdateRecommendRequest, UpdateOrderSeriesRequest, OrderSeries,
    UpdateOrderTemplatesRequest, TemplateOrder, GetPublishedTemplatesRequest, RescindTemplateRequest,
    GetLastVersionTemplatesRequest,
} = require('./advanced-template-service_pb.js');

export const {
    EnumPublishState, EnumPublishType, EnumTemplateType,
    Template, Series
} = require('./common_pb.js');


// const advancedTemplateServiceHost = "http://localhost:51052";
// const advancedTemplateServiceHost = "https://stage.yinxiang.com/third/advanced-template-et/grpcweb";
// const advancedTemplateServiceHost = "https://app.yinxiang.com/third/advanced-template-et/grpcweb";
const advancedTemplateServiceHost = EVN + "/third/advanced-template-et/grpcweb";

const {AdvancedTemplateServiceClient} = require('./advanced-template-service_grpc_web_pb.js');

export const AdvancedTemplateService = new AdvancedTemplateServiceClient(
    advancedTemplateServiceHost,
    null,
    {withCredentials: true}
);

import React from "react";
import {
    Layout, Breadcrumb, Row, Col, Divider, Button, Table, Popconfirm, message,
    Modal, Input, Select, Radio, InputNumber
} from 'antd';
import { Link } from 'react-router-dom';
import './layout.css';
import {
    OfficialMaterialAdminService,
    Pageable,
    GetMaterialTypeRequest,
    GetGroupsRequest,
    MaterialType,
    AddGroupRequest,
    Group,
    DelGroupRequest,
    UpdateGroupRequest,
    UpdateGroupOrderRequest
} from "./grpcweb/OfficialMaterialAdminService";

const { Content } = Layout;
const { Option } = Select;

export default class SeriesContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contentText: '',
            //渲染素材查询列表
            seriesList: [],
            visible: false,
            seriesId: 0,
            name: '',
            opt: '',
            firstSeries: [],
            seriesTypes: [],
            seriesType: 0,
            materialTypeShow: '',
            updateSeriesId: '',
            order: '',
            currentRecommend: '',
            perRecommend: '',
            hasBadge: '',
            disableUpdateOrderBtn: false,
            disableSaveRecommendBtn: false,
            initialValueName: '',
            returnSeriesId: 0,
            currentUrl: '',
            materialType: 2,
        };
    };

    componentDidMount() {
        let seriesId = (typeof this.props.match.params.seriesId == "undefined") ?
          0 : parseInt(this.props.match.params.seriesId);

        this.setState({
            returnSeriesId: seriesId,
            currentUrl: this.props.location.pathname,
        }, () => {console.log('init')});
        this.handleReturnSeries(seriesId, this.state.materialType);
        this.querySeriesList(this.state.materialType,seriesId);
        this.queryMaterialType();
    };


    componentWillReceiveProps(nextprops) {

        // state : currentUrl  (pc) (md)
        if(this.state.currentUrl !== nextprops.location.pathname) {
            // debugger;
            let seriesId = (typeof nextprops.match.params.seriesId == "undefined") ?
              0 : parseInt(nextprops.match.params.seriesId);

            this.setState({
                returnSeriesId: seriesId,
                currentUrl: nextprops.location.pathname,
            }, () => {console.log('init')});
            this.handleReturnSeries(seriesId, this.state.materialType);
            this.querySeriesList(this.state.materialType,seriesId);
        }
    };

    firstSeriesItems = () => {
        return this.state.firstSeries.map(
            (item) => (<Option key={item.id} value={item.id}>{item.name}</Option>)
        )
    };

    showModal = (record, opt) => {
        this.setState({
            visible: true,
            opt: opt,
        }, () => { console.log("INFO: : showModal ") }
        );
        if (opt === 'update') {
            this.setState({
                updateSeriesId: record.id,
                name: record.name,
                order: record.order,
            }, () => { console.log("INFO: : update ") });
        }
        if (opt === 'add') {
            this.setState({
                name: '',
                seriesType: this.state.initialValueName === '一级系列' ? 1 : this.state.seriesType,
                order: '',
            }, () => { console.log("visible: " + this.state.visible + " name :" + this.state.name) });
        }
    };

    handleOk = () => {
        this.setState({
            visible: false,
            name: '',
            updateSeriesId: '',
            opt: '',
            order: ''
        }, () => { console.log("INFO: : handleOk ") });
        if (this.state.opt === 'add') {
            this.addSeries();
        }
        else {
            this.updateSeries();
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            name: '',
            updateSeriesId: '',
            opt: '',
            order: '',
        }, () => { console.log("INFO: : handleCancel ") });
    };

    // 请求系列列表
    querySeriesList = (materialType,seriesId) => {
        let request = new GetGroupsRequest();
        request.setParentid(seriesId);
        request.setMaterialtype(materialType);
        OfficialMaterialAdminService.getGroups(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                console.log("response: " + JSON.stringify(res));
                res.groupsList.map((series) => {
                    if (series.parentid === 0) {
                        series.level = '一级系列';
                    }
                    else {
                        series.level = '二级子系列';
                    }
                    if (series.materialtype === MaterialType.WORD_FORM) {
                        series.materialtypeName = 'WORD_FORM';
                    } else if (series.materialtype === MaterialType.SHARING_THEMES) {
                        series.materialtypeName = 'SHARING_THEMES';
                    } else if (series.materialtype === MaterialType.SHARING_WATER_MARK) {
                        series.materialtypeName = 'SHARING_WATER_MARK';
                    } else if (series.materialtype === MaterialType.HOME_BACKGROUND) {
                        series.materialtypeName = 'HOME_BACKGROUND';
                    }
                });
                // debugger;
                if (seriesId === 0 && this.state.returnSeriesId === 0) {
                    this.setState(
                        {
                            firstSeries: res.groupsList,
                        });
                }
                console.log("res.seriesList: " + res.groupsList);
                this.setState(
                    {
                        seriesList: res.groupsList,
                    }
                );
            }
        });
    };

    //删除系列
    deleteSeries = (seriesId) => {
        console.log("INFO:deleteSeries : ", seriesId);
        let request = new DelGroupRequest();
        request.setId(seriesId);

        OfficialMaterialAdminService.delGroupById(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.responsecode !== 0) {
                    message.error(res.message);
                }
                else {
                    message.success('删除成功');
                    this.setState({ returnSeriesId: 0 });
                    this.querySeriesList(this.state.materialType,this.state.seriesId);
                }
            }
        });
    };

    addSeries = () => {
        console.log("INFO:addSeries : " + this.state.name);
        let checkMassage = this.checkSeries();
        console.log("INFO:checkMassage : " + checkMassage);
        if (checkMassage !== '') {
            message.error(checkMassage);
            return '';
        }
        let request = new AddGroupRequest();
        let group = new Group();
        group.setParentid(this.state.seriesId);
        group.setName(this.state.name);
        group.setMaterialtype(this.state.materialType);
        request.setGroup(group);
        OfficialMaterialAdminService.addGroup(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.responsecode !== 0) {
                    message.error(res.message);
                }
                else {
                    message.success('添加成功');
                    // debugger;
                    this.querySeriesList(this.state.materialType, this.state.seriesId);
                }
            }
        });
    };

    checkSeries = () => {
        if (this.state.name === '') {
            return '系列名称不能为空';
        }
        else if (this.state.name.length > 10) {
            return '系列名称不能大于10字';
        }
        return '';
    }

    updateSeries = () => {
        console.log("INFO:updateSeries" + this.state.name);
        let checkMassage = this.checkSeries();
        console.log("INFO:checkMassage : " + checkMassage);
        if (checkMassage !== '') {
            message.error(checkMassage);
            return '';
        }
        let request = new UpdateGroupRequest();
        let group = new Group();
        group.setId(this.state.updateSeriesId);
        group.setName(this.state.name);

        request.setGroup(group);
        console.log(request);
        OfficialMaterialAdminService.updateGroup(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                message.error(err.message);
            } else {
                if (res.responsecode !== 0) {
                    message.error(res.message);
                }
                else {
                    message.success('修改成功');
                    this.querySeriesList(this.state.materialType, this.state.seriesId);
                }
            }
        });
    }

    updateOrderSeries = () => {
        console.log('排序');
        let arr = [];
        this.state.seriesList.map((seriesOrder) => {
            arr.push(seriesOrder.order);
        });
        console.log(arr);
        var arr2 = arr.filter((x, index, self) => self.indexOf(x) === index)
        console.log(arr.length);
        console.log(arr2.length);
        if (arr.length !== arr2.length) {
            message.error('存在重复序号，请检查');
        }
        else {
            this.setState({ disableUpdateOrderBtn: true }, () => {
                let seriesOrderList = this.state.seriesList;
                let request = new UpdateGroupOrderRequest();
                seriesOrderList.map((seriesOrder) => {
                    let group = new Group();
                    group.setId(seriesOrder.id);
                    group.setOrder(seriesOrder.order);
                    request.addGroup(group);
                });
                OfficialMaterialAdminService.updateGroupOrder(request, {}, (err, response) => {
                    const res = response.toObject();
                    console.log("response: ");
                    console.log(res);
                    if (err) {
                        console.log("err.code " + err.code);
                        message.error(err.message);
                    } else {
                        if (res.responsecode !== 0) {
                            message.error(res.msg);
                        }
                        else {
                            message.success('保存成功');
                            this.querySeriesList(this.state.materialType, this.state.seriesId);
                        }
                    }
                    this.setState({ disableUpdateOrderBtn: false });
                });
            });
        }
    }

    //用户选择一级系列后的动作
    handleFirstSeriesChange = (value) => {
        console.log(`selected ${value}`);
        let seriesId = (typeof value == "undefined") ? 0 : value;
        this.querySeriesList(this.state.materialType, seriesId);
        this.setState({ seriesId: seriesId });
        if (parseInt(value) === 0) {
            this.setState({
                initialValueName: '一级系列',
                materialTypeShow: '',
            });
        } else {
            this.state.firstSeries.forEach((item) => {
                if (item.id === seriesId) {
                    this.setState({
                        initialValueName: item.name,
                        materialTypeShow: 'none',
                    });
                }
            });
        }
    }

    handleReturnSeries = (seriesId, materialType) => {
        let request = new GetGroupsRequest();
        request.setMaterialtype(materialType);
        request.setParentid(seriesId);
        OfficialMaterialAdminService.getGroups(request, {}, (err, response) => {
            const res = response.toObject();
            console.log("response: ");
            console.log(res);
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.massage " + err.message);
            } else {
                if (res.code === 0) {
                    console.log("response: " + JSON.stringify(res));
                    res.groupsList.map((groups) => {
                        if (groups.id === seriesId) {
                            this.setState({ initialValueName: groups.name });
                        }
                    });
                    this.setState(
                      {
                          firstSeries: res.groupsList,
                      });
                } else {
                    console.log("code " + res.code);
                    console.log("massage " + res.message);
                }

            }
        });
        if (this.state.firstSeries.length === 0) {
            this.setState({ initialValueName: "一级系列",
                seriesId : seriesId});
        }
        else {
            this.state.firstSeries.map((item) => {
                if (item.seriesId === seriesId) {
                    this.setState({ initialValueName: item.name });
                }
            })
        }
    };

    //系列名称时触发
    handleSeriesNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
        console.log("系列名称：" + this.state.name);
    };

    onClickRecommend = (e, record) => {
        console.log('radio checked ', e.target.value);
        console.log('currentRecommend befor ', this.state.currentRecommend);
        let currentRecommend = ' ';
        if (e.target.value === this.state.currentRecommend) {
            this.setState({
                currentRecommend: ' ',
            }, () => { console.log("INFO: : currentRecommend after " + currentRecommend) });
        }
        else {
            currentRecommend = e.target.value;
            this.setState({
                currentRecommend: e.target.value,
            }, () => { console.log("INFO: : currentRecommend after " + currentRecommend) });
        }

        let recommendList = this.state.seriesList;
        recommendList.map((recommend) => {
            if ((recommend.seriesId === record.seriesId) && currentRecommend !== ' ') {
                recommend.checked = true;
            }
            else {
                recommend.checked = false;
            }
        });
    };


    onChangeOrder = (value, record) => {
        console.log('changed', value);
        let seriesOrderList = this.state.seriesList;
        seriesOrderList.map((seriesOrder) => {
            if (seriesOrder.id === record.id) {
                seriesOrder.order = value;
            }
        });
        this.setState(
            {
                seriesList: seriesOrderList,

            });
        console.log('changed', record.id);
    };

    /**
     * 选择素材类型的动作
     */
    onMaterialTypeChange = value => {
        this.setState({
            materialType: parseInt(value),
        });
        this.handleReturnSeries(this.state.seriesId, value);
        this.querySeriesList(value,0);
    };

    onMaterialTypeChangeForNew = value => {
        this.setState({
            materialType: parseInt(value),
        });
    };
    /**
     * 请求素材类型
     */
    queryMaterialType = () => {
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        OfficialMaterialAdminService.getMaterialType(new GetMaterialTypeRequest(), {}, (err, response) => {
            //debugger
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.message " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== "") {
                    message.error(res.message);
                } else {
                    this.setState({ materialTypeList: res.materialtypeList });
                }
            }
        });
    };

    displayMaterialType = () => {
        if(typeof(this.state.materialTypeList) !== "undefined") {
            return this.state.materialTypeList.map(
              (item) => (<Option key={item.code} value={item.code}>{item.name}</Option>)
            )
        } else {
            return ;
        }
    };

    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>素材系列管理</Breadcrumb.Item>
                    <Breadcrumb.Item>系列管理</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={20}>
                        <Col span={2} offset={1}>
                            素材类型：
                        </Col>
                        <Col span={4} >
                            <Select defaultValue={this.state.materialType} style={{ width: 200 }} onChange={this.onMaterialTypeChange} allowClear>
                                {this.displayMaterialType()}
                            </Select>
                        </Col>
                        <Col span={2} offset={1}>
                            当前所属：
                        </Col>
                        <Col span={1} >
                            <Select value={this.state.initialValueName} style={{ width: 120 }} onChange={this.handleFirstSeriesChange} allowClear >
                                <Option value="0">一级系列</Option>
                                {this.firstSeriesItems()}
                            </Select>
                        </Col>
                        <Col span={1} offset={6}>
                            <Button type="primary" onClick={() => this.showModal(0, 'add')} >新增系列</Button>
                        </Col>
                        <Col span={1} offset={1}>
                            <Button type="primary" disabled={this.state.disableUpdateOrderBtn}
                                onClick={this.updateOrderSeries} >保存排序</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                    <div>
                        <Table
                            columns={[
                                {
                                    title: '顺序',
                                    dataIndex: 'order',
                                    render: (text, record) => {
                                        return (
                                            <InputNumber type="text" min={1} value={record.order.toString()} onChange={(event) => this.onChangeOrder(event, record)} />
                                        );
                                    }
                                },
                                {
                                    title: '系列ID',
                                    dataIndex: 'id',
                                },
                                {
                                    title: '名称',
                                    dataIndex: 'name',
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'materialtypeName',
                                },
                                {
                                    title: '层级',
                                    dataIndex: 'level',
                                },
                                {
                                    title: '操作',
                                    key: 'action',
                                    render: (text, record) => {
                                        return (
                                            <div>
                                                <span>
                                                    <Popconfirm title="确定删除？" title="确定删除？" onConfirm={() => this.deleteSeries(record.id)} okText="确定" cancelText="取消">
                                                        <a style={{ marginRight: 16 }}>删除</a>
                                                    </Popconfirm>
                                                    <div>
                                                        <a style={{ marginRight: 16 }} onClick={() => this.showModal(record, 'update')}>编辑</a>
                                                    </div>
                                                    <a style={{ marginRight: 16 }} >
                                                        <Link to={{
                                                            pathname: '/material/series/bound-template',
                                                            state: {
                                                                groupId: record.id,
                                                                parent: record.parentid,
                                                                name: record.name,
                                                                materialtype: record.materialtype
                                                            }
                                                        }}>
                                                            <span>挂载素材</span>
                                                        </Link>
                                                    </a>
                                                </span>

                                            </div>
                                        )
                                    }
                                },
                            ]
                            }
                            dataSource={this.state.seriesList}/>
                    </div>
                    <div>
                        <Modal title="增加系列" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
                            <Row gutter={24}>
                                <Col span={8} > 系列名称：*</Col>
                                <Col span={16} >
                                    <Input type="text" value={this.state.name.toString()} onChange={this.handleSeriesNameChange} />
                                </Col>
                            </Row>
                            <div style={{display: this.state.materialTypeShow, paddingTop: '5px', paddingButton: '5px'}}>
                                <Row gutter={24}>
                                    <Col span={8} > 素材类型：</Col>
                                    <Col span={8} >
                                        <Select defaultValue={this.state.materialType} style={{ width: 300 }} onChange={this.onMaterialTypeChangeForNew} allowClear>
                                            {this.displayMaterialType()}
                                        </Select>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </div>
                </div>
            </Content>
        );
    }
}
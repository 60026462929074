import React from 'react';
import {Layout, Menu} from 'antd';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import './layout.css';
import TemplateManageContent from "./TemplateManageContent";
import PublishingManageContent from "./PublishingManageContent";
import BannerManageContent from "./BannerContent";
import SeriesManageContent from "./SeriesContent";
import TemplateEditor from "./TemplateEditor";
import TemplateRelationEditor from "./TemplateRelationEditor";
import TemplateCreator from "./TemplateCreator";
import PublishTemplatesContent from "./PublishTemplatesContent";
import PublishSeriesContent from "./PublishSeriesContent";
import NoteOperationContent from "./NoteOperationContent";
import RecommendTemplateManage from "./RecommendTemplateManage";

const {Footer, Sider} = Layout;
const {SubMenu} = Menu;

class AdvancedTemplate extends React.Component {

    state = {
        collapsed: false,
        content: null,
        templateMeta: {
            templates: [],
        },
    };

    onCollapse = (collapsed) => {
        this.setState({collapsed});
    };

    render() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Sider width={240} style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                >
                    <div className="ant-layout-logo">
                        <div className="logo-image"></div>
                        <div className="logo-title">超级笔记模版库</div>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        <Menu.Item key="noteOperation">
                            <Link to={'/advanced-template/note'}>
                                <span>笔记操作</span>
                            </Link>
                        </Menu.Item>
                        <SubMenu title={<span>模版管理</span>}>
                            <Menu.Item key="template">
                                <Link to={'/advanced-template/template/list'}>
                                    <span>模版编辑</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="publishTemplate">
                                <Link to={'/advanced-template/publish/template'}>
                                    <span>发布模版</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu title={<span>印象笔记App</span>}>
                            <Menu.Item key="recommend-template">
                                <Link to={'/advanced-template/recommend-template/list'}>
                                    <span>新用户推荐模版</span>
                                </Link>
                            </Menu.Item>
                            <SubMenu title={<span>系列管理</span>}>
                                <Menu.Item key="yxbjSeriesPc">
                                    <Link to={{pathname: '/advanced-template/series/list/yxbj-pc'}}>
                                        <span>桌面端系列管理</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="yxbjSeriesMd">
                                    <Link to={{pathname: '/advanced-template/series/list/yxbj-md'}}>
                                        <span>移动端系列管理</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="yxbjPublishSeriesMobileBanner">
                                    <Link to={{ pathname: '/advanced-template/publish/series/yxbj-md-banner' }}>
                                        <span>移动端Banner管理</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu title={<span>发布系列</span>}>
                                <Menu.Item key="yxbjPublishSeriesPc">
                                    <Link to={{pathname: '/advanced-template/publish/series/yxbj-pc'}}>
                                        <span>桌面端发布系列</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="yxbjPublishSeriesMd">
                                    <Link to={{ pathname: '/advanced-template/publish/series/yxbj-md' }}>
                                        <span>移动端发布系列</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                        </SubMenu>
                        <SubMenu title={<span>印象图记App</span>}>
                            <SubMenu title={<span>系列管理</span>}>
                                <Menu.Item key="yxtjSeriesPc">
                                    <Link to={{pathname: '/advanced-template/series/list/yxtj-pc'}}>
                                        <span>桌面端系列管理</span>
                                    </Link>
                                </Menu.Item>
                                {/*<Menu.Item key="yxtjSeriesMd">*/}
                                {/*    <Link to={{pathname: '/advanced-template/series/list/yxtj-md'}}>*/}
                                {/*        <span>移动端系列管理</span>*/}
                                {/*    </Link>*/}
                                {/*</Menu.Item>*/}
                            </SubMenu>
                            <SubMenu title={<span>发布系列</span>}>
                                <Menu.Item key="yxtjPublishSeriesPc">
                                    <Link to={{pathname: '/advanced-template/publish/series/yxtj-pc'}}>
                                        <span>桌面端发布系列</span>
                                    </Link>
                                </Menu.Item>
                                {/*<Menu.Item key="yxtjPublishSeriesMd">*/}
                                {/*    <Link to={{pathname: '/advanced-template/publish/series/yxtj-md'}}>*/}
                                {/*        <span>移动端发布系列</span>*/}
                                {/*    </Link>*/}
                                {/*</Menu.Item>*/}
                            </SubMenu>
                        </SubMenu>
                        <SubMenu title={<span>智能本</span>}>
                            <SubMenu title={<span>系列管理</span>}>
                                <Menu.Item key="everpadSeriesMd">
                                    <Link to={{pathname: '/advanced-template/series/list/everpad-md'}}>
                                        <span>移动端系列管理</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu title={<span>发布系列</span>}>
                                <Menu.Item key="everpadPublishSeriesMd">
                                    <Link to={{pathname: '/advanced-template/publish/series/everpad-md'}}>
                                        <span>移动端发布系列</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                        </SubMenu>
                        <Menu.Item key="publishRecord">
                            <Link to={'/advanced-template/publish/history'}>
                                <span>发布记录</span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{marginLeft: 240}}>
                    <div className="ant-layout-content">
                        <Switch>
                            <Route path={"/advanced-template/template/list"} component={TemplateManageContent}/>
                            <Route path={"/advanced-template/template/new"} component={TemplateCreator}/>
                            <Route path={"/advanced-template/template/edit/:templateId"} component={TemplateEditor}/>
                            <Route path={"/advanced-template/note"} component={NoteOperationContent}/>
                            <Route path={"/advanced-template/recommend-template/list"} component={RecommendTemplateManage}/>
                            <Route path={"/advanced-template/series/list/yxbj-pc"} component={SeriesManageContent}/>
                            <Route path={"/advanced-template/series/list/yxbj-md"} component={SeriesManageContent} />
                            <Route path={"/advanced-template/series/list/yxtj-pc"} component={SeriesManageContent}/>
                            {/*<Route path={"/advanced-template/series/list/yxtj-md"} component={SeriesManageContent}/>*/}
                            <Route path={"/advanced-template/series/list/everpad-md"} component={SeriesManageContent}/>
                            <Route path={"/advanced-template/series/bound-template"}
                                   component={TemplateRelationEditor}/>
                            <Route path={"/advanced-template/series/yxbj-pc/:seriesId"} component={SeriesManageContent}/>
                            <Route path={"/advanced-template/series/yxbj-md/:seriesId"} component={SeriesManageContent}/>
                            <Route path={"/advanced-template/series/yxtj-pc/:seriesId"} component={SeriesManageContent}/>
                            <Route path={"/advanced-template/series/everpad-md/:seriesId"} component={SeriesManageContent}/>
                            {/*<Route path={"/advanced-template/series/yxtj-md/:seriesId"} component={SeriesManageContent}/>*/}
                            <Route path={"/advanced-template/publish/history"} component={PublishingManageContent}/>
                            <Route path={"/advanced-template/publish/template"} component={PublishTemplatesContent}/>
                            <Route path={"/advanced-template/publish/series/yxbj-pc"} component={PublishSeriesContent}/>
                            <Route path={"/advanced-template/publish/series/yxbj-md"} component={PublishSeriesContent}/>
                            <Route path={"/advanced-template/publish/series/yxbj-md-banner"} component={BannerManageContent} />
                            <Route path={"/advanced-template/publish/series/yxtj-pc"} component={PublishSeriesContent}/>
                            {/*<Route path={"/advanced-template/publish/series/yxtj-md"} component={PublishSeriesContent}/>*/}
                            <Route path={"/advanced-template/publish/series/everpad-md"} component={PublishSeriesContent}/>
                            <Redirect to={"/advanced-template/template/list"}/>
                        </Switch>
                    </div>
                    <Footer style={{textAlign: 'center'}}>印象笔记 ©2020 </Footer>
                </Layout>
            </Layout>
        );
    }
}

export default AdvancedTemplate;
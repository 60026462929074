import React from 'react';
import { Icon, Modal, Row, Col, Button, message } from 'antd';
import PropTypes from 'prop-types';
import './ImgUpload.css';
import TemplateConfig from 'TemplateConfig';

export default class ImgUpload extends React.Component {
    /** 
      组件属性
      id:组件动态标识，表明组件是否需要修改的唯一标识
      imgTitle：组件的中文描述
      height：图片外框的高度
      imgFile:图片文件
      renderState：图片初始化状态, init：初始化，loading:正在上传，upload：上传成功
      imgSrc:图片src路径，data:URL格式的Base64字符串
      isPreview: false//是否显示弹出层预览全图
      titleClass:"" //文本描述信息的样式
      getImgFile:function(name,file,path):获取图片文件，在图片文件改变时触发
      imgFileToBE: 传至后端的request参数
    */
    constructor(props) {
        super(props)
        this.state = {
            imgFile: null,
            imgSrc: this.props.imgSrc || "",
            renderState: this.props.renderState || "init",
            isPreview: false,
            disabled: this.props.disabled || false,
            imgFileToBE: {
                imgBytes: null,
                fileType: '',
                fileName: '', //已包含文件后缀
            },
            uploading: false,
        }
    }

    static defaultProps = {
        imgTitle: "",
        height: "200px",
        renderState: "init",
        imgSrc: "",
        titleClass: "",
        accept: "*"
    }

    static propTypes = {
        imgTitle: PropTypes.string,
        renderState: PropTypes.oneOf(['init', 'loading', 'upload']),
        imgSrc: PropTypes.string,
        titleClass: PropTypes.string,
        accept: PropTypes.string
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.imgSrc !== nextProps.imgSrc) {
            this.setState({ imgSrc: nextProps.imgSrc })
        }
        if (this.props.renderState !== nextProps.renderState) {
            this.setState({ renderState: nextProps.renderState })
        }
        if (this.props.disabled !== nextProps.disabled) {
            this.setState({ disabled: nextProps.disabled })
        }

    }

    //初始化渲染
    renderInit() {
        return (
            <div className="img-box" style={{ height: this.props.height }}>
                <input type="file" className="img-file" onChange={this.imgChange} accept={this.props.accept} />
                <div className={"img-add " + this.props.titleClass}>
                    <Icon type="plus" className="img-add-icon" />
                    <div>{this.props.imgTitle}</div>
                </div>
            </div>
        )
    }

    //正在上传
    renderLoading() {
        return (
            <div className="img-box" style={{ height: this.props.height }}>
                {/* <img src="/static/loading.gif" className="img-loading" /> */}
                <Icon type="loading" />
            </div>
        )
    }

    //上传完成
    renderUpload() {
        const imgSrc = (this.state.imgSrc && this.state.imgSrc !== "") ? this.state.imgSrc : "/static/admin/img/error_img.jpg"
        const imgBox = this.state.disabled ? "img-box-preview-hide" : "img-box-preview-show";
        let content;
        if (this.state.imgSrc && this.state.imgSrc !== "") {
            content = (
              <div className={imgBox} style={{ height: this.props.height }}>
                  <img src={imgSrc} className="img-wh" alt="素材图片" />
                  <div className="img-preview">
                      <Icon type="eye-o" className="img-operate" onClick={this.original} />
                      <Icon type="delete" className="img-operate" onClick={this.deleteImg} />
                  </div>
              </div>
            );
        } else {
            content = (<div className="img-box" style={{ height: this.props.height }}>
                <input type="file" className="img-file" onChange={this.imgChange} accept={this.props.accept} />
                <div className={"img-add " + this.props.titleClass}>
                    <Icon type="plus" className="img-add-icon" />
                    <div>{this.props.imgTitle}</div>
                </div>
            </div>);
        }
        return content;
    }

    renderImg() {
        if (this.state.renderState === "init") {
            return this.renderInit()
        } else if (this.state.renderState === "loading") {
            return this.renderLoading()
        } else if (this.state.renderState === "upload") {
            return this.renderUpload()
        }
    }

    //获取图片文件
    // File元信息：
    // event.target.files[0]
    // name: "envoy.jpeg"
    // lastModified: 1582093560198
    // lastModifiedDate: Wed Feb 19 2020 14: 26: 00 GMT + 0800(中国标准时间) { }
    // webkitRelativePath: ""
    // size: 98226
    // type: "image/jpeg"
    imgChange = (event) => {
        this.setState({ imgFile: event.target.files[0] }, () => {
            this.handleFile();
        });
    }

    //文件处理
    handleFile = () => {
        const file = this.state.imgFile;
        // 文件校验
        if (file.size > 10 * 1024 * 1024) {
            message.error('缩略图不能大于10M');
            return;
        } else if (!(file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif")) {
            message.error('缩略图类型必须为png/jpg/gif格式');
            return;
        }
        this.setState({ renderState: "loading" });
        const readerPreview = new FileReader();
        // readAsDataURL一旦完成，result属性中将包含一个data:URL格式的Base64字符串以表示所读取文件的内容
        readerPreview.readAsDataURL(file);
        readerPreview.onload = e => {
            this.setState({ renderState: "upload", imgSrc: e.target.result });
        }
        const readerUpload = new FileReader();
        readerUpload.readAsArrayBuffer(file);
        // FileReader.onload在读取操作完成时触发
        readerUpload.onload = e => {
            let imgFileToBEModified = Object.assign({}, this.state.imgFileToBE, { imgBytes: e.target.result },
                { fileName: file.name }, { fileType: file.type });
            this.setState({ imgFileToBE: imgFileToBEModified });
        }
    }

    //原图查看
    original = () => {
        this.setState({ isPreview: true })
    }

    //删除图片
    deleteImg = () => {
        this.setState({ renderState: "init", imgFile: null, isPreview: false, imgSrc: "" })
    }

    resetImgSrc() {
        this.setState({ renderState: this.props.renderState, imgFile: null, imgSrc: this.props.imgSrc })
    }

    getImgFile() {
        return { file: this.state.imgFile, src: this.state.imgSrc }
    }

    //关闭查看原图modal
    cancelModal = () => {
        this.setState({ isPreview: false })
    }

    /**
   * 上传缩略图
   */
    uploadImg = (imgFileToBE, imgFile) => {
        // debugger;
        if (typeof imgFileToBE === 'undefined' || imgFileToBE.imgBytes === null || imgFileToBE.fileName === null) {
            return;
        }
        this.setState({ uploading: true }, () => {
            let materialUuid = this.props.uuid;
            const formData = new FormData()
            formData.append('file', imgFile);
            fetch(TemplateConfig.serverUrl + "/file/upload" + "?code=" + materialUuid, {
              method: 'POST',
              body: formData,
              credentials: 'include',

            }).then(response => response.json()).then((res) => {
              if(res !== 'undefined' && res.code !== "0"){
                message.success('上传缩略图成功');
                this.props.parent.getImgUrl(this, res.result, this.props.id);
              } else {
                console.log("err.code " + res.code);
                message.error(res.message);
              }
              this.setState({ uploading: false });
            });
        });
    };

    render() {
        console.log(this.state.imgSrc);
        //禁用上传按钮：1.未选择图片2.编辑模版时未更换图片3.图片上传中4.选择图片后删除
        let disableUpload = typeof this.state.imgFileToBE === 'undefined'
            || this.state.imgFileToBE.imgBytes === null
            || this.state.uploading
            || this.state.imgFile === null;
        return (
            <div>
                <Row type="flex" align="middle">
                    <Col span={18} offset={0}>
                        {this.renderImg()}
                    </Col>
                    <Col span={2} >
                        <Button onClick={() => this.uploadImg(this.state.imgFileToBE, this.state.imgFile)} disabled={disableUpload} >
                            上传
                        </Button>
                    </Col>
                </Row>
                <Modal visible={this.state.isPreview} footer={null}
                    onCancel={this.cancelModal}
                    width="auto" wrapClassName="img-center"
                    style={{ display: "inline-block" }}
                >
                    <img src={this.state.imgSrc} className="preview-all" alt="原图" />
                </Modal>
            </div>
        )
    }
}


import React from "react";
import { Redirect, Link } from 'react-router-dom';
import {
    Breadcrumb, Button, Col, Divider, Icon, Input, Layout, message, Modal,
    Popconfirm, Row, Select, Table, Tag,InputNumber,Upload,
} from 'antd';
import './layout.css';
import ImgUpload from "./ImgUpload";
import TemplateConfig from 'TemplateConfig';
import {
    OfficialMaterialAdminService, Pageable,GetMaterialTypeRequest,AddMaterialRequest,MaterialData,MaterialType
} from "./grpcweb/OfficialMaterialAdminService";

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

export default class TemplateCreator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //新增模版请求参数
            title: '',
            type: 0,
            description: '',
            serviceLevel: 0,
            thumbnailUrl: '',
            previewUrl: '',
            uuid: '',
            //新增模版是否完成
            addTemplateConfirmed: false,
            disableSaveBtn: false,

            isLoading: true,
            //每个分页的选择项
            selectedRowKeys: [],

            //购买支付方式
            userLimit: 0,
            //价格
            price: 0,
            badge:0,
            materialTypeList:[],
            pattern: 0,
            serviceLevelDisabled: false,
            priceDisabled : false
        };
    };

    componentDidMount = () => {
        //初始化
        this.queryMaterialType();
        this.uuidGenerator();
        if(this.state.userLimit === 0) {
          this.setState({
            serviceLevelDisabled: true,
            priceDisabled: true,
            serviceLevel: 0,
            price: 0,
          });
        }else {
          this.setState({
            serviceLevelDisabled: false,
            priceDisabled: false,
            serviceLevel: 2,
          });
        }
    };

    /**
     * 获取子组件treeselector数据
     */
    getChildrenMsg = (result, msg) => {
        // debugger;
        // console.log(msg)
        this.setState({
            seriesListFromChild: msg
        });
    };

    /**
     * 键入模版名称时触发
     */
    handelTemplateNameChange = (e) => {
        this.setState({
            title: e.target.value
        });
        // console.log("填写了模版名称：" + this.state.title);
    };

    /**
     * 键入模版描述时触发
     */
    handelDescriptionChange = (e) => {
        this.setState({
            description: e.target.value
        });
        // console.log("填写了模版描述：" + this.state.description);
    };

    /**
     * 键入客户端类型时触发
     */
    handleClientChange  = value => {
        this.setState({
            clientType: parseInt(value)
        });
    };
    /**
     * 键入字体时触发
     */
    handleFontChange = (e) => {
        this.setState({
          wordForm: e.target.value
        });
    };
    /**
     * 键入颜色时触发
     */
    handleColorChange = (e) => {
        this.setState({
            color: e.target.value
        });
    };
    /**
     * 选择模版类型的动作
     */
    onMaterialTypeChange = value => {
        if (value !== 2) {
          this.setState({
            materialType: parseInt(value),
            priceDisabled: true,
            price: 0,
          });
        } else {
          this.setState({
            materialType: parseInt(value),
            priceDisabled: false,
            price: 1.00
          });
        }
    };

    /**
     * 修改价格
     */
    onPriceChange = value => {
        this.setState({
            price: value
        });
    }

    /**
     * 选择用户身份限制的动作
     */
    onServiceLevelChange = value => {
        this.setState({
            serviceLevel: parseInt(value)
        });
        // console.log("用户身份限制为：" + this.state.serviceLevel);
    };
    /**
     * 选择用户购买方式
     */
    onUserLimitChange = value => {
        //设置会员等级和金额
      if (value === '0') {
        this.setState({
          serviceLevelDisabled: true,
          priceDisabled: true,
          serviceLevel: 0,
          price: 0,
          userLimit: parseInt(value),
        });

      } else {
          if(this.state.materialType !== 2) {
              this.setState({
                  serviceLevelDisabled: false,
                  priceDisabled: true,
                  serviceLevel: 2,
                  userLimit: parseInt(value),
                  price: 0,
              });
          } else {
              this.setState({
                  serviceLevelDisabled: false,
                  priceDisabled: false,
                  serviceLevel: 2,
                  userLimit: parseInt(value),
                  price: 1.00,
              });
          }
      }
    };

    onThemeTypeChange = value => {
        this.setState({
            pattern: parseInt(value),
        });
    };
    /**
     * 参数校验
     */
    checkParams = () => {
        if (this.state.title.length === 0) {
            return '请输入模版名称';
        }
        else if (this.state.title.length > 30) {
            return '模版名称不能超过30字';
        }
        if (this.state.materialType === 0) {
            return '请选择素材类型';
        }
        if (this.state.userLimit != 0 && this.state.serviceLevel === 0) {
          return '请选择用户身份限制';
        }
        if (this.state.description.length > 50) {
            return '描述不能超过50字';
        }
        // if (this.state.thumbnailUrl === '' || typeof this.state.thumbnailUrl === 'undefined') {
        //     return '请先上传缩略图';
        // }
        // //校验单价
        // if(this.state.price === 0){
        //     return '模板单价不能为0'
        // }
        //校验不同类型模板上传素材
        if (this.state.materialType === 2) {
            if (this.state.wordFormPackage === 'undefined' ||  this.state.wordFormPackage === '') {
                return "请上传字体包"
            }
            if (this.state.wordFormExample === 'undefined' ||  this.state.wordFormExample === '') {
                return "请上传字体包示例图谱"
            }
          if (this.state.wordFormBlackExample === 'undefined' ||  this.state.wordFormBlackExample === '') {
            return "请上传字体包暗黑示例图谱"
          }
        }
        if (this.state.materialType === 3) {
            if (this.state.thumbnail === 'undefined' ||  this.state.thumbnail === '') {
                return "请上传示范缩略图"
            }
            if (this.state.background === 'undefined' ||  this.state.background === '') {
                return "请上传背景图"
            }
            if (this.state.leftUp === 'undefined' ||  this.state.leftUp === '') {
                return "请上传左上角图"
            }
            if (this.state.rightUp === 'undefined' ||  this.state.rightUp === '') {
                return "请上传右上角图"
            }
            if (this.state.leftLow === 'undefined' ||  this.state.leftLow === '') {
                return "请上传左下角图"
            }
            if (this.state.rightLow === 'undefined' ||  this.state.rightLow === '') {
                return "请上传右下角图"
            }
        }
        if (this.state.materialType === 4) {
            if (this.state.wordForm === 'undefined' ||  this.state.wordForm === '') {
                return "请输入字体"
            }
            if (this.state.color === 'undefined' ||  this.state.color === '') {
                return "请输入颜色"
            }
        }
        if (this.state.materialType === 5) {
            if (this.state.background === 'undefined' ||  this.state.background === '') {
                return "请上传背景图"
            }
            if (this.state.thumbnail === 'undefined' ||  this.state.thumbnail === '') {
                return "请上传背景缩略图"
            }
        }
        return '';
    };

    /**
     * 点击保存button，新增模版
     */
    addTemplate = () => {
        debugger;
        //校验
        let checkMassage = this.checkParams();
        if (checkMassage !== '') {
            message.error(checkMassage);
            return;
        }
        this.setState({ disableSaveBtn: true }, () => {
            let request = new AddMaterialRequest();
            let material = new MaterialData();
            material.setTitle(this.state.title);
            material.setMaterialtype(this.state.materialType);
            material.setUserlimit(this.state.userLimit);
            //设置等级
            material.setServicelevel(this.state.serviceLevel);
            //设置价格
            material.setPrice(this.state.price*100);
            //设置描述
            material.setDescription(this.state.description);
            //设置缩略图
            material.setThumbnailurl(this.state.thumbnailUrl);
            material.setMaterialcode(this.state.uuid);
            //设置上传素材内容
            let content;
            if (this.state.materialType === MaterialType.WORD_FORM) {
                //云字体类型content
                content = Object.assign({}, content, {wordFormPackage: this.state.wordFormPackage});
                content = Object.assign({}, content, {wordFormExample: this.state.wordFormExample});
                content = Object.assign({}, content, {wordFormBlackExample: this.state.wordFormBlackExample});
            } else if(this.state.materialType === MaterialType.SHARING_THEMES) {
                //长图分享主题content
                content = Object.assign({}, content, {pattern: this.state.pattern});
                content = Object.assign({}, content, {thumbnail: this.state.thumbnail});
                content = Object.assign({}, content, {background: this.state.background});
                content = Object.assign({}, content, {leftUp: this.state.leftUp});
                content = Object.assign({}, content, {rightUp: this.state.rightUp});
                content = Object.assign({}, content, {leftLow: this.state.leftLow});
                content = Object.assign({}, content, {rightLow: this.state.rightLow});
            } else if(this.state.materialType === MaterialType.SHARING_WATER_MARK) {
                //长图分享水印content
                content = Object.assign({}, content, {wordForm: this.state.wordForm});
                content = Object.assign({}, content, {color: this.state.color});
                content = Object.assign({}, content, {clientType: this.state.clientType});
            }else if(this.state.materialType === MaterialType.HOME_BACKGROUND) {
                //个人主页背景图content
                content = Object.assign({}, content, {background: this.state.background});
                content = Object.assign({}, content, {thumbnail: this.state.thumbnail});
            }
            material.setContent(JSON.stringify(content));
            request.setMaterial(material);

            console.log(JSON.stringify(request.array));
            // debugger;
            OfficialMaterialAdminService.addMaterial(request, {}, (err, response) => {
                // debugger;
                if (err) {
                    console.log("err.code " + err.code);
                    console.log("err.massage " + err.message);
                    message.error(err.message);
                } else {
                    let res = response.toObject();
                    switch (res.responsecode) {
                        case 3001:
                            message.error('素材参数错误');
                            break;
                        case 0:
                            Modal.success({ content: '新增素材成功', onOk: this.redirectToHome });
                            break;
                        default:
                            message.error(res.msg);
                            break;
                    }
                    console.log("新增素材结果: " + res.message);
                }
                this.setState({ disableSaveBtn: false });
            });
        });
    };

    //新增模版完成或者点击返回，重定向
    redirectToHome = () => {
        this.setState({
            addTemplateConfirmed: true
        });
    };

    /**
     * 处理子组件ImgUpload中传回的URL
     */
    getImgUrl = (result, imgUrl, field) => {
        // debugger;
        if (typeof imgUrl === 'undefined') {
            return;
        }
        this.setState(
          { [field]: imgUrl },
          () => console.log(JSON.stringify(this.state))
        );
    };

    onChangePage = page => {
        this.getPublicNotesInfo(page);
        const selectedRowKeys = [];
        this.setState({ selectedRowKeys });
    };

    displayMaterialType = () => {
        if(typeof(this.state.materialTypeList) !== "undefined") {
            return this.state.materialTypeList.map(
              (item) => (<Option key={item.code} value={item.code}>{item.name}</Option>)
            )
        } else {
            return ;
        }
    };

    uuidGenerator = () => {
        let originStr = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          originChar = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
          len = originChar.length;

        let uuid = originStr.replace(/x/g, function(match) {
            return originChar.charAt(Math.floor(Math.random() * len))
        })
        this.setState({uuid: uuid});
    }

    /**
     * 请求素材类型
     */
    queryMaterialType = () => {
        //注意下面的getSubSeries中的callback也要写成箭头函数，否则里面的this无法识别
        OfficialMaterialAdminService.getMaterialType(new GetMaterialTypeRequest(), {}, (err, response) => {
            //debugger
            if (err) {
                console.log("err.code " + err.code);
                console.log("err.message " + err.message);
                message.error(err.message);
            } else {
                const res = response.toObject();
                if (res.code !== "") {
                    message.error(res.message);
                } else {
                    this.setState({ materialTypeList: res.materialtypeList });
                }
            }
        });
    };

    /**
     * 根据素材类型展示模板内容
     */
    displayTemplateByType = () => {
        let type = this.state.materialType;
        let content;
        const uploadProps = {
          name: 'file',
          action: TemplateConfig.serverUrl + "/file/upload" ,
          headers: {
            authorization: 'authorization-text'
          },
          withCredentials: 'include',
          data: {
            'code': this.state.uuid,
          },
          onChange: info => {
            if (info.file.status === 'done') {
                this.setState({wordFormPackage: info.file.response.result});
                message.success(`${info.file.name} 文件上传成功。`);
            } else if (info.file.status === 'error') {
              message.error(`${info.file.name} 文件上传失败。`);
            }
          },
          progress: {
            strokeColor: {
              '0%': '#108ee9',
              '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
          },
        };
        if (type === 2) {
            //云字体类型
            content = <Row gutter={[16, 16]}>
              <Col span={4} offset={4}>
                    字体包
                </Col>
                <Col span={10} >
                    <Upload {...uploadProps}>
                        <Button ><Icon type="upload" /> 字体包上传</Button>
                    </Upload>
                </Col>
                <Col span={4} offset={4}>
                    字体包示例图
                </Col>
                <Col span={10} >
                    <ImgUpload id="wordFormExample" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    字体包暗黑示例图
                </Col>
                <Col span={10} >
                  <ImgUpload id="wordFormBlackExample" parent={this} uuid={this.state.uuid}/>
                </Col>
               </Row>;

        } else if (type === 3) {
            //长图主题分享类型
            content =  (<Row gutter={[16, 16]}>
                <Col span={4} offset={4}>
                    主题类型
                </Col>
                <Col span={10} >
                    <Select
                      style={{ width: 375 }}
                      onChange={this.onThemeTypeChange}
                      placeholder="Please select"
                    >
                        <Option key="0" value="0" >黑夜</Option>
                        <Option key="1" value="1" >白天</Option>
                    </Select>
                </Col>
                <Col span={4} offset={4}>
                    示范缩略图
                </Col>
                <Col span={10} >
                    <ImgUpload id="thumbnail" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    背景图
                </Col>
                <Col span={10} >
                    <ImgUpload id="background" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    左上角图
                </Col>
                <Col span={10} >
                    <ImgUpload id="leftUp" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    右上角图
                </Col>
                <Col span={10} >
                    <ImgUpload id="rightUp" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    左下角图
                </Col>
                <Col span={10} >
                    <ImgUpload id="leftLow" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    右下角图
                </Col>
                <Col span={10} >
                    <ImgUpload id="rightLow" parent={this} uuid={this.state.uuid}/>
                </Col></Row>);
        } else if (type === 4) {
            //长图分享水印类型
            content =  (<Row gutter={[16, 16]}>
                <Col span={4} offset={4}>
                    客户端类型
                </Col>
                <Col span={10} >
                    <Select
                      style={{ width: 375 }}
                      onChange={this.handleClientChange}
                      placeholder="Please select"
                    >
                        <Option key="0" value="0" >ANDROID</Option>
                        <Option key="1" value="1" >IOS</Option>
                    </Select>
                </Col>
                <Col span={4} offset={4}>
                    字体
                </Col>
                <Col span={10} >
                    <Input onChange={this.handleFontChange} />
                </Col>
                <Col span={4} offset={4}>
                    颜色
                </Col>
                <Col span={10} >
                    <Input onChange={this.handleColorChange} />
                </Col></Row>);
        } else if (type === 5 ) {
            //个人主页背景图类型
            content = <Row gutter={[16, 16]}>
                <Col span={4} offset={4}>
                    背景图
                </Col>
                <Col span={10} >
                    <ImgUpload id="background" parent={this} uuid={this.state.uuid}/>
                </Col>
                <Col span={4} offset={4}>
                    背景图缩略图
                </Col>
                <Col span={10} >
                    <ImgUpload id="thumbnail" parent={this} uuid={this.state.uuid}/>
                </Col></Row>;
        } else {
            return;
        }
        return content;
    };

    render() {
        if (this.state.addTemplateConfirmed) {
            return (<Redirect to="/material/template/list" />);
        } else {
            return (
              <Content style={{ margin: '0 16px' }}>
                  <Breadcrumb style={{ margin: '16px 0' }}>
                      <Breadcrumb.Item>
                          <Link to="/material/template/list">首页</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                          <Link to="/material/template/list">素材管理</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>新增素材</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              素材名称
                          </Col>
                          <Col span={8} >
                              <Input onChange={this.handelTemplateNameChange} />
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              素材类型
                          </Col>
                          <Col span={8} >
                              <Select defaultValue="请选择" style={{ width: 300 }} onChange={this.onMaterialTypeChange} allowClear>
                                  {this.displayMaterialType()}
                              </Select>
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              素材付费类型
                          </Col>
                          <Col span={8} >
                              <Select
                                style={{ width: 375 }}
                                onChange={this.onUserLimitChange}
                                placeholder="Please select"
                              >
                                  <Option key="0" value="0" >无</Option>
                                  <Option key="1" value="1">工具会员</Option>
                              </Select>
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              用户身份限制
                          </Col>
                          <Col span={8} >
                              <Select
                                style={{ width: 375 }}
                                onChange={this.onServiceLevelChange}
                                value={this.state.serviceLevel.toString()}
                                disabled = {this.state.serviceLevelDisabled}
                                placeholder="Please select"
                              >
                                  <Option key="2" value="2">素材会员标准账户 or 工具会员高级及以上</Option>
                              </Select>
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              注意：
                          </Col>
                          <Col span={8}>
                              <p>
                                  素材会员标准账户 or 工具会员高级及以上”，实际分别对应可用用户等级为：
                                  <br />云字体库-花生&高级及以上等级会员可用
                                  <br />长图分享主题/水印-花生&专业及以上等级会员可用
                                  <br />个人主页背景图-所有用户均可使用
                              </p>
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              素材单价
                          </Col>
                          <Col span={8} >
                              <InputNumber value = {this.state.price} min={0} max={999.99} step={0.01} onChange={this.onPriceChange} disabled = {this.state.priceDisabled}/>
                              (价格范围：1.00 ～ 999.99 象票)
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              描述
                          </Col>
                          <Col span={8} >
                              <TextArea
                                rows={3}
                                onChange={this.handelDescriptionChange} />
                          </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                          <Col span={4} offset={4}>
                              缩略图
                          </Col>
                          <Col span={10} >
                              <ImgUpload id="thumbnailUrl" parent={this} uuid={this.state.uuid}/>
                          </Col>
                      </Row>
                      <Divider orientation="left" style={{ color: '#000', fontWeight: 'normal' }} />
                      {this.displayTemplateByType()}
                      <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />
                      <Row gutter={[16, 16]}>
                          <Col span={9} ></Col>
                          <Col span={2} >
                              <Button type="primary" disabled={this.state.disableSaveBtn} onClick={this.addTemplate}>
                                  保存
                              </Button>
                          </Col>
                          <Col span={2} >
                              <Button type="primary" onClick={this.redirectToHome}>
                                  返回
                              </Button>
                          </Col>
                          <Col span={8} ></Col>
                      </Row>
                      <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

                  </div>
              </Content>
            );
        }
    }
}